import Checkbox from '@mui/material/Checkbox';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import TextInput from '../common/formik/components/TextInput';
// import Checkbox from '../common/formik/components/Checkbox';
import PostHogManager from '../analytics/postHogManager';
import callApi from '../api';
import AddCcMail from '../common/components/AddCcMail';
import { getSyndicateId, getSyndicatePermalink, getUser } from '../common/utils/common';
import { httpMethods } from '../constants';

export default function SyndicateSettings() {
  const [isAccess, setIsAccess] = useState(false);
  const [savedCcEmail, setSavedCcEmail] = useState('');
  const syndicateId = getSyndicateId();
  const user = getUser();

  const updateAccess = (event) => {
    setIsAccess(event.target.checked);
    PostHogManager.trackEvent('Give Public Access Backer Submitted', {
      syndicateId,
      source: 'Syndicate Settings Page',
      description: 'Give Public Access To Backer Submitted',
      submitted_data: { access: event.target.checked },
    });
    const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
    const url = `${djangoBaseUrl}/v1/syndicates/give_public_access_for_backer/?id=${syndicateId}`;
    callApi(
      url,
      httpMethods.HTTP_POST,
      {
        syndicate_id: syndicateId,
        access_check: event.target.checked,
      },
      {},
      false
    )
      .then((response) => {
        PostHogManager.trackEvent('Give Public Access Backer - Successfull', {
          syndicateId,
          source: 'Syndicate Settings Page',
          description: 'Give Public Access To Backer Successfull',
          response_data: response,
        });
        toast.success('Public access for newly invited backers successfully updated.');
      })
      .catch((error) => {
        PostHogManager.trackEvent('Give Public Access Backer  - Failed', {
          syndicateId,
          source: 'Syndicate Settings Page',
          description: 'Give Public Access To Backer Failed',
          response_data: error,
        });
        toast.error(error.response.data.message);
      });
  };

  const setAccess = () => {
    const syndicateIId = getSyndicateId();
    const syndicatePermalink = getSyndicatePermalink();
    const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
    const url = `${djangoBaseUrl}/v1/syndicates/check_backer_access/?permalink=${syndicatePermalink}`;
    PostHogManager.trackEvent('Fetch Syndicate Settings Submitted', {
      syndicateIId,
      source: 'Syndicate Settings Page',
      description: 'Fetching Syndicate Settings Submitted',
    });
    callApi(
      url,
      httpMethods.HTTP_GET,
      '',
      {},
      false
    )
      .then((res) => {
        PostHogManager.trackEvent('Fetch Syndicate Settings - Successfull', {
          syndicateIId,
          source: 'Syndicate Settings Page',
          description: 'Fetching Syndicate Settings Successfull',
          response_data: res,
        });
        if (res.data.success) {
          setIsAccess(res.data.access);
          setSavedCcEmail(res.data.cc_email);
        }
      })
      .catch((error) => {
        PostHogManager.trackEvent('Fetch Syndicate Settings - Failed', {
          syndicateIId,
          source: 'Syndicate Settings Page',
          description: 'Fetching Syndicate Settings Failed',
          response_data: error,
        });
      });
  };

  useEffect(() => {
    setAccess();
  }, []);

  return (
    <div className="mt-5">
      <div className="container">
        <div className="py-3">
          <h4>Syndicate Settings</h4>
        </div>
        <div style={{ paddingBottom: 20 }}>
          <div style={{ padding: '20px 0 0 20px', background: '#fff' }}>
            <div
              style={{
                padding: '2% 2% 0 2%',
                fontWeight: 'bold',
              }}
            >
              <p
                style={{
                  fontWeight: '500 important!',
                  fontSize: '22px',
                }}
              >
                <h5>Backers’ default access</h5>
              </p>
            </div>
            <div
              style={{
                background: '#fff',
                borderRadius: '5px',
                padding: '30px 1%',
              }}
            >
              <div
                style={{
                  padding: '0 10px 20px 10px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  checked={isAccess}
                  onChange={updateAccess}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <div
                  style={{
                    marginLeft: '1%',
                    fontSize: '19px',
                    fornWight: 500,
                  }}
                >
                  <div>Provide Public access for newly Invited Backers.</div>
                  <div
                    style={{
                      opacity: '0.7',
                      fontSize: '16px',
                    }}
                  >
                    (By checking this, all new users will get access to public
                    deals on the platform by default).
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              paddingLeft: '20px',
              marginTop: 20,
              background: '#fff',
            }}
          />
          <div
            style={{
              padding: '0 20px 20px 20px',
              marginBottom: 20,
              background: '#fff',
            }}
          >
            <div
              style={{
                padding: '2% 2% 0 2%',
              }}
            >
              <p style={{ fontWeight: '800', fontSize: '22px' }}>
                <h5>Current cc: email</h5>
              </p>
              &nbsp; <span style={{ fontWeight: '600', fontSize: '18px' }}>{savedCcEmail}</span>
            </div>

            <AddCcMail />
          </div>
        </div>
      </div>
    </div>
  );
}
