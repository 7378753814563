import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PostHogManager from '../../analytics/postHogManager';
import Icon from '../icons/Icon';
import Menu from './menu';

import { LV_LOGO } from '../../assests/image/IMGAE_CONSTANTS';
import WarningBanner from './WarningBanner';

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function NavBar(props) {
  const { tabValue } = props;
  const [value, setValue] = React.useState(tabValue || -1);
  const history = useHistory();
  const homeRouteHandler = () => {
    PostHogManager.trackEvent('Navbar - Clicked logo', {
      source: 'Navbar',
      description: 'Clicked on logo in the navbar'
    });
    history.push('/home');
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (history.location.pathname === '/home' || history.location.pathname === '/') {
      setValue(0);
    } else if (history.location.pathname === '/my/startup') {
      setValue(1);
    } else if (history.location.pathname === '/manage/investor') {
      setValue(2);
    } else {
      setValue(-1);
    }
  }, [history.location.pathname]);

  return (
    <div style={{ position: 'fixed', width: '100%', top: 0, alignSelf: 'flex-start', zIndex: 10 }}>
      <div
        style={{
          display: 'flex',
          backgroundColor: 'white',
          alignItems: 'center',
          justifyContent: 'space-between',
          maxWidth: '1500px',
          margin: 'auto'
        }}
      >
        <img
          src={LV_LOGO}
          onClick={homeRouteHandler}
          style={{ cursor: 'pointer', margin: 'auto 8px', height: '40px' }}
        />

        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label=" add deal tabs "
          style={{ marginLeft: '40px', width: '80%' }}
        >
          <Tab
            onClick={() => {
              PostHogManager.trackEvent('Navbar - Clicked Dashboard', {
                source: 'Navbar',
                description: 'Clicked on dashboard in the navbar'
              });
              history.push('/home');
            }}
            style={{
              fontSize: '13px',
              fontWeight: '400',
              display: 'flex',
              flexDirection: 'row'
            }}
            label={
              <>
                <Icon name="dashboardlayout" width="18" height="18" style={{ marginRight: '10px' }} /> Dashboard
              </>
            }
            {...tabProps(0)}
          />
          <Tab
            onClick={() => {
              PostHogManager.trackEvent('Navbar - Clicked My Startups', {
                source: 'Navbar',
                description: 'Clicked on my startups in the navbar'
              });
              history.push('/my/startup');
            }}
            style={{
              fontSize: '13px',
              fontWeight: '400',
              display: 'flex',
              flexDirection: 'row'
            }}
            label={
              <>
                <Icon name="boxicon" width="18" height="18" style={{ marginRight: '10px' }} /> My Startups
              </>
            }
            {...tabProps(1)}
          />
          <Tab
            onClick={() => {
              PostHogManager.trackEvent('Navbar - Clicked Backers', {
                source: 'Navbar',
                description: 'Clicked on backers in the navbar'
              });
              history.push('/manage/investor');
            }}
            style={{
              fontSize: '13px',
              fontWeight: '400',
              display: 'flex',
              flexDirection: 'row'
            }}
            label={
              <>
                <Icon name="multiuser" width="18" height="18" style={{ marginRight: '10px' }} /> BACKERS
              </>
            }
            {...tabProps(2)}
          />
        </Tabs>
        <Menu />
      </div>
      <WarningBanner />
    </div>
  );
}

NavBar.propTypes = {
  tabValue: PropTypes.number
};

export default NavBar;
