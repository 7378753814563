import axios from "axios";
import { removeUserSession } from "../common/utils/common";
import { httpMethods } from "../constants";
import callApi from "./index";

export class HttpClient {
  constructor(baseURL) {
    this.instance = axios.create({
      baseURL: baseURL ?? process.env.API_BASE_URL,
    });
    this.defaultRequestInterceptor = this.getDefaultRequestInterceptor();
    this.defaultResponseInterceptor = this.getDefaultResponseInterceptor();
    this.setRequestInterceptor(this.defaultRequestInterceptor);
    this.setResponseInterceptor(this.defaultResponseInterceptor);
  }

  setTokenGenerator(tokenGenerator) {
    this.tokenGenerator = tokenGenerator;
    return this;
  }

  getToken() {
    if (!this.tokenGenerator) throw new Error("Token generator not set!");
    return this.tokenGenerator();
  }

  getInstance() {
    return this.instance;
  }

  setRequestInterceptor(requestInterceptor) {
    this.requestInterceptor = this.instance.interceptors.request.use(
      requestInterceptor.callback,
      requestInterceptor.errorHandler
    );
  }

  setResponseInterceptor(responseInterceptor) {
    this.responseInterceptor = this.instance.interceptors.response.use(
      responseInterceptor.callback,
      responseInterceptor.errorHandler
    );
  }

  enableDefaultRequestInterceptor() {
    this.setRequestInterceptor(this.defaultRequestInterceptor);
  }

  enableDefaultResponseInterceptor() {
    this.setResponseInterceptor(this.defaultResponseInterceptor);
  }

  getDefaultRequestInterceptor() {
    return {
      callback: (config) => {
        const access_token = localStorage.getItem("token");
        if (!access_token) {
          removeUserSession();
          window.location.href = "/";
        }
        if (!!access_token) {
          config.headers["Authorization"] = `Bearer ${access_token}`;
        }
        config.headers["Content-Type"] = "application/json";
        return config;
      },
      errorHandler: (error) => {
        console.log("Error in request:", error);
        return Promise.reject(error);
      },
    };
  }

  getDefaultResponseInterceptor() {
    return {
      callback: (response) => Promise.resolve(response),
      errorHandler: async (error) => {
        console.log("Error in response:", error);

        if (error.response.status === 490 && !error.config._retry) {
          // Get a new access token
          error.config._retry = true;
          try {
            const token = await this.getToken();
            localStorage.setItem("token", token);
            this.instance.defaults.headers["Authorization"] = `Bearer ${token}`;
            this.instance.defaults.headers["Content-Type"] = "application/json";
            error.config.headers["Authorization"] = `Bearer ${token}`;
            const deviceUrl = `/v1/users/create_device_data/`;
            await callApi(deviceUrl, httpMethods.HTTP_POST, "", {}, true, true);
            return this.instance(error.config);
          } catch (err) {
            console.log("Error from response error handler:", err);
            localStorage.removeItem("token");
            window.location.reload();
          }
        }

        if (error.response.status === 401 || error.response.status === 403) {
          removeUserSession();
          window.location.href = "/";
        }

        return Promise.reject(error);
      },
    };
  }
}
