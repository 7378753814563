import { makeStyles } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Oval } from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import PostHogManager from '../../../analytics/postHogManager';
import callApi from '../../../api';
import '../../../assests/style/deal_detail.scss';
import DragAndDrop from '../../../common/formik/components/DragAndDrop';
import LeadInvestementThesisDragAndDrop from '../../../common/formik/components/LeadInvestementThesisDragAndDrop';
import ReactSelectAutocomplete from '../../../common/formik/components/ReactSelectAutoComplete';
import Select from '../../../common/formik/components/Select';
import TextArea from '../../../common/formik/components/TextArea';
import TextInput from '../../../common/formik/components/TextInput';
import Icon from '../../../common/icons/Icon';
import { getSyndicateId, getToken, getUser } from '../../../common/utils/common';
import { httpMethods } from '../../../constants';
import LeadInvestorList from './founderList';

export const rupeeFormatter = new Intl.NumberFormat('en-IN', {
  currency: 'INR'
});

const converter = require('num-words');

const useStyles = makeStyles({
  changePhoto: {
    width: '120px',
    padding: '5px 0',
    position: 'relative',
    cursor: 'pointer',
    color: 'blue',
    '& input': {
      opacity: 0,
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      cursor: 'pointer',
      minWidth: 150
    }
  },
  coverImgae: {
    width: '300px',
    height: '200px',
    borderRadius: '3px'
  }
});

function StartupInformation({ editStartupView, startupData, startup_id }) {
  const classes = useStyles();
  const [openAddFounderModal, setOpenAddFounderModal] = useState(false);
  const [profileImage, setProfileImage] = useState('');
  const [coverImg, setCoverImg] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [startupProfileImage, setStartupProfileImage] = useState('');
  const [startupCoverImage, setStartupCoverImage] = useState('');
  const [leadCarry, setLeadCarry] = useState(null);
  const [founderImage, setfounderImage] = useState(null);
  const [founderImg, setfounderImg] = useState(null);
  const [founderName, setfounderName] = useState(null);
  const [founderEmail, setfounderEmail] = useState(null);
  const [founderNumber, setfounderNumber] = useState(null);
  const [savingStartupDetail, setSavingStartupDetail] = useState(false);
  const [savedOnce, setSavedOnce] = useState(false);
  const [defaultVerticalSectors, setdefaultVerticalSectors] = useState([]);
  const history = useHistory();
  const user = getUser();
  const syndicateType = localStorage.getItem('syndicate_type');

  const stageOptions = [
    { label: 'Select Startup Stage' },
    { label: 'Ideation', value: 1 },
    { label: 'Proof of Concept', value: 2 },
    { label: 'Beta Launched', value: 3 },
    { label: 'Early Revenues', value: 4 },
    { label: 'Steady Revenues', value: 5 }
  ];
  const businessTypeOptions = [
    { label: 'Select' },
    { label: 'B2B', value: 'B2B' },
    { label: 'B2C', value: 'B2C' },
    { label: 'D2C', value: 'D2C' },
    { label: 'P2P', value: 'P2P' },
    { label: 'B2G', value: 'B2G' },
    { label: 'B2B2C', value: 'B2B2C' },
    { label: 'B2B2G', value: 'B2B2G' }
  ];
  const revenueTypeOptions = [
    { label: 'Select', value: '' },
    { label: 'Over INR 100Cr', value: 'true' },
    { label: 'Less than INR 100Cr', value: 'false' }
  ];
  const existenceTypeOptions = [
    { label: 'Select', value: '' },
    { label: 'Less than 10 years', value: 'true' },
    { label: 'Over 10 years', value: 'false' }
  ];
  const countryRegTypeOptions = [
    { label: 'Select', value: '' },
    { label: 'India', value: 'true' },
    { label: 'A country other than india', value: 'false' }
  ];
  const transactionTypeOptions = [
    { label: 'Select', value: '' },
    { label: 'Primary', value: 'true' },
    {
      label: 'Secondary (buying securities from an existing investor, not the startup)',
      value: 'false'
    }
  ];

  const initialValues = {
    name: (startupData && startupData.startup_name) || '',
    entity_name: (startupData && startupData.entity_name) || '',
    stage: (startupData && startupData.startup_stage) || '',
    startup_location_tokens:
      (startupData &&
        startupData.startup_location_tokens &&
        startupData.startup_location_tokens[0] && {
          label: startupData.startup_location_tokens[0].text,
          value: startupData.startup_location_tokens[0].id
        }) ||
      '',
    sector_tokens: (startupData && startupData.sector_tokens && startupData.sector_tokens) || [],
    permalink: (startupData && startupData.permalink) || '',
    business_type: startupData && startupData.business_type,
    summary: (startupData && startupData.summary) || '',
    product_summary: (startupData && startupData.product_summary) || '',
    funding_ask: (startupData && startupData.funding_ask) || '',
    founder_raised: syndicateType == 'zeno' ? '' : (startupData && startupData.founder_raised) || '',
    lead_investor_list: [],
    founders:
      editStartupView && startupData && !!startupData.founder_deatils.length
        ? [
            {
              label: startupData.founder_deatils[0].name,
              profile_image: startupData.founder_deatils[0].profile_image
            }
          ]
        : [],
    my_lead_carry: '',
    investment_thesis: (startupData && startupData.investment_thesis) || '',
    other_info: (startupData && startupData.other_info) || '',
    min_investment: syndicateType == 'zeno' ? '' : (startupData && startupData.min_investment) || '',
    commit_time_window: (startupData && startupData.commit_time_window) || '',
    pitchDeck: (startupData && startupData.startup_pitch_deck) || '',
    pitch_deck_file_name: (startupData && startupData.startup_pitch_deck_file_name) || '',
    leadInvestingThesis: (startupData && startupData.lead_investor_thesis) || '',
    lead_investor_thesis_file_name: (startupData && startupData.lead_investor_thesis_file_name) || '',
    revenue_check: (startupData && startupData.revenue_check) || '',
    startup_existence: (startupData && startupData.startup_existence) || '',
    startup_registered_india: (startupData && startupData.startup_registered_india) || '',
    startup_transaction: (startupData && startupData.startup_transaction) || '',
    edit_startup: editStartupView,
    lv_team_comments: startupData && startupData.lv_team_comments
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    entity_name: Yup.string().required('Required'),
    stage: Yup.string().required('Required'),
    startup_location_tokens: Yup.object().shape({
      label: Yup.string().required('Required'),
      value: Yup.string().required('Required')
    }),
    sector_tokens: Yup.array().required('Required').min(1, 'At least one sector is required'),
    business_type: Yup.string().required('Required'),
    summary: Yup.string().required('Required'),
    product_summary: Yup.string().required('Required'),
    funding_ask: Yup.number()
      .required('Required')
      .positive('Must be a Positive Number')
      .min(25, 'Min INR 25 lakh')
      .max(1000, 'Max INR 10 Crores')
      .integer('Must be an Integer Number'),
    founder_raised:
      syndicateType == 'zeno'
        ? ''
        : Yup.number().positive('Must be a Positive Number').integer('Must be an Integer Number'),
    min_investment:
      syndicateType == 'zeno'
        ? ''
        : Yup.number()
            .required('Required')
            .positive('Must be a Positive Number')
            .max(1000, 'Max INR 10 Crores')
            .integer('Must be an Integer Number'),
    founders: Yup.array().min(1, 'Add the founder details').required('Required'),
    pitchDeck: Yup.string().required('Required'),
    startup_existence: Yup.bool().required('Required'),
    startup_registered_india: Yup.bool().required('Required'),
    revenue_check: Yup.bool().required('Required'),
    startup_transaction: Yup.bool().required('Required')
  });

  const changePhoto = (event) => {
    setStartupProfileImage(event.currentTarget.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setProfileImage(reader.result);
      }
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const changeCoverPhoto = (event) => {
    setStartupCoverImage(event.currentTarget.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setCoverImg(reader.result);
      }
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const changeFounderPhoto = (event) => {
    setfounderImage(event.currentTarget.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setfounderImg(reader.result);
      }
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const handleSelectChange = (e, setFieldValue) => {
    setFieldValue(e.target.name, e.target.value);
  };

  const addFounders = (id) => {
    setOpenAddFounderModal(true);
  };

  const loadInvestorList = async (input, _callback) => {
    if (input.trim() === '') {
      return Promise.resolve({ options: [] });
    }
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/lookups.json?type=all_angel_investors&custom=true&is_lead=true&q=${input}&current_user_id=${user.user_id}`
    );
    const json = await response.json();
    const newJson = json.map((field, k) => ({
      label: field.text,
      options: field.children
        .filter((item) => item.new_entry !== true)
        .map((child, k_1) => ({
          value: child.id,
          label: child.text,
          role: child.role,
          location: child.location,
          profile_image: child.profile_image,
          logo_img: child.logo_img,
          new_entry: child.new_entry,
          type: child.type
        }))
    }));
    return newJson;
  };

  const validateFounderEmail = (email) => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      return false;
    }
    return true;
  };

  const validateFounderNumber = (number) => {
    if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(number)) {
      return false;
    }
    return true;
  };

  const saveFounder = (setFieldValue, values) => {
    if (
      !!founderName &&
      !!founderNumber &&
      validateFounderNumber(founderNumber) &&
      !!founderEmail &&
      validateFounderEmail(founderEmail)
    ) {
      const founder = [
        {
          label: founderName,
          profile_image: founderImg
        }
      ];
      setfounderName(null);
      setfounderEmail(null);
      setfounderNumber(null);
      setFieldValue('founders', founder);
      setOpenAddFounderModal(false);
    } else {
      if (!founderName) {
        const div = document.getElementById('founder_name_error');
        div.textContent = 'Please enter founder name';
      } else {
        const div = document.getElementById('founder_name_error');
        div.textContent = '';
      }
      if (!founderEmail || !validateFounderEmail(founderEmail)) {
        const div = document.getElementById('founder_email_error');
        div.textContent = 'Please enter a valid email address.';
      } else {
        const div = document.getElementById('founder_email_error');
        div.textContent = '';
      }
      if (!founderNumber || !validateFounderNumber(founderNumber)) {
        const div = document.getElementById('founder_number_error');
        div.textContent = 'Please enter a valid phone number.';
      } else {
        const div = document.getElementById('founder_number_error');
        div.textContent = '';
      }
    }
  };

  const handleFounderClose = () => setOpenAddFounderModal(false);

  const removeFounder = (id, setFieldValue, leadInvestorList) => {
    const confirmation = window.confirm('Do you really want to remove this Founder?');
    if (leadInvestorList && confirmation) {
      const filtered_lead_investor = leadInvestorList.filter((item) => item.value !== id);
      setFieldValue('founders', filtered_lead_investor);
    }
  };

  const clickProfileImage = () => {
    const imageUploadDiv = document.getElementById('profile_image_input');
    imageUploadDiv.click();
  };

  const clickCoverImage = () => {
    const imageUploadDiv = document.getElementById('cover_image_input');
    imageUploadDiv.click();
  };

  const onSubmit = (values) => {
    setSavingStartupDetail(true);
    setDisableSubmit(true);
    const syndicate_id = getSyndicateId();
    const token = getToken();
    const formData = new FormData();
    formData.append('startup_profile_image', startupProfileImage);
    formData.append('startup_banner_image', startupCoverImage);
    formData.append('startup_name', values.name);
    formData.append('entity_name', values.entity_name);
    formData.append('startup_stage', values.stage);
    formData.append('startup_location_tokens', values.startup_location_tokens && values.startup_location_tokens.value);
    formData.append('permalink', `${values.name}-1`);
    formData.append('business_type', values.business_type);
    formData.append('summary', values.summary);
    formData.append('product_summary', values.product_summary);
    formData.append('funding_ask', values.funding_ask);
    formData.append('founder_raised', values.founder_raised);
    formData.append(
      'startup_pitch_deck',
      (editStartupView && startupData && startupData.startup_pitch_deck) === values.pitchDeck ? '' : values.pitchDeck
    );
    formData.append(
      'lead_investor_thesis',
      (editStartupView && startupData && startupData.lead_investor_thesis) === values.leadInvestingThesis
        ? ''
        : values.leadInvestingThesis
    );
    formData.append('syndicate_id', syndicate_id);
    formData.append('startup_transaction', values.startup_transaction);
    formData.append('sector_tokens', values.sector_tokens.map((item) => item.value).join(','));
    formData.append('investment_thesis', values.investment_thesis);
    formData.append('other_info', values.other_info);
    formData.append('min_investment', values.min_investment);
    formData.append('commit_time_window', values.commit_time_window);
    formData.append('founder_image', founderImage);
    if (values.founder_name) formData.append('founder_name', values.founder_name);
    if (values.founder_email) formData.append('founder_email', values.founder_email);
    if (values.founder_number) formData.append('founder_number', values.founder_number);
    formData.append('startup_existence', values.startup_existence);
    formData.append('startup_registered_india', values.startup_registered_india);
    formData.append('revenue_check', values.revenue_check);
    formData.append('lv_team_comments', values.lv_team_comments);
    const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
    const type_of_action = editStartupView ? 'update' : 'create';
    const apiURL = editStartupView
      ? `${djangoBaseUrl}/v1/syndicates/create_or_update_startup/?syndicate_id=${syndicate_id}&startup_id=${startup_id}&type_of_action=${type_of_action}&data_source=glassboard`
      : `${djangoBaseUrl}/v1/syndicates/create_or_update_startup/?syndicate_id=${syndicate_id}&type_of_action=${type_of_action}&data_source=glassboard`;
    PostHogManager.trackEvent('Add Deal Data Submitted', {
      syndicate_id,
      source: 'My Startups -> Create Deal',
      description: 'Add Deal Data submitted',
      submitted_data: values
    });
    const method = editStartupView ? httpMethods.HTTP_PUT : httpMethods.HTTP_POST;
    callApi(apiURL, method, formData, {}, false)
      .then((response) => {
        PostHogManager.trackEvent('Add Deal Completed - Success', {
          syndicate_id,
          source: 'My Startups -> Create Deal',
          description: 'Deal Creation Successfull',
          deal_status: 'Draft - Deal created',
          response_data: response
        });
        setSavingStartupDetail(false);
        toast.success(
          `${
            editStartupView
              ? 'Startup updated successfully'
              : 'Startup created successfully. Proceed to adding the scheme info.'
          }`
        );
        localStorage.setItem('dealDetalilPermalink', response.data.permalink);
        localStorage.setItem('deal_id', response.data.startup_id);
        history.push(`/deal/${response.data.permalink}`);
        setDisableSubmit(false);
      })
      .catch((error) => {
        PostHogManager.trackEvent('Add Deal Completed - Failure', {
          syndicate_id,
          source: 'My Startups -> Create Deal',
          description: 'Deal Creation Failed',
          deal_status: 'Draft - Deal not created',
          response_data: error
        });
        setSavingStartupDetail(false);
        setDisableSubmit(false);
        if (err.response.status == 400) {
          toast.error(error && error.response && error.response.data && error.response.data.detail);
        } else {
          toast.error('something went wrong');
        }
      });
  };

  return (
    <div style={{ maxWidth: '60vw' }}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize onSubmit={onSubmit}>
        {({ touched, values, setFieldValue, setFieldError, setFieldTouched, errors, validateForm }) => (
          <Form>
            <div className="startup_input_box">
              <TextInput
                labeltitle="Startup Name"
                name="name"
                notes="Customer-facing name, not the registered name. e.g the name should be <b>PayTM</b> instead of <b>One97 Communications Ltd</b>."
                ismandatory
              />
            </div>
            <div className="startup_input_box">
              <Select
                name="revenue_check"
                labeltitle="Max revenue in any 12 months period in the startup's history"
                placeholder="Select Revenue Type"
                touched={touched}
                options={revenueTypeOptions}
                multi={false}
                ismandatory
                errorText="Required"
                // setDisableSaveDraftButton={setDisableSaveDraftButton}
                tooltipTitle="As per SEBI laws, AIF's can only invest in startups with less than 100 Cr revenue."
              />
            </div>
            <div className="startup_input_box">
              <Select
                name="startup_existence"
                labeltitle="The company has been registered for"
                placeholder="Please confirm"
                touched={touched}
                options={existenceTypeOptions}
                multi={false}
                ismandatory
                errorText="Required"
                // setDisableSaveDraftButton={setDisableSaveDraftButton}
              />
            </div>
            <div className="startup_input_box">
              <Select
                name="startup_registered_india"
                labeltitle="Country of registration"
                placeholder="Please confirm"
                touched={touched}
                options={countryRegTypeOptions}
                multi={false}
                ismandatory
                errorText="Required"
                // setDisableSaveDraftButton={setDisableSaveDraftButton}
              />
            </div>
            <div className="startup_input_box">
              <Select
                name="startup_transaction"
                labeltitle="Type of transaction"
                placeholder="Please confirm"
                touched={touched}
                options={transactionTypeOptions}
                multi={false}
                ismandatory
                errorText="Required"
                notes="LetsVenture can’t do Secondary transactions"
                // setDisableSaveDraftButton={setDisableSaveDraftButton}
              />
            </div>
            <div className={`${classes.photo} d-flex pt-5`}>
              <div
                style={{
                  height: '100px',
                  minWidth: '100px',
                  border: profileImage ? 'none' : '1px solid grey',
                  cursor: 'pointer'
                }}
                aria-hidden="true"
                onClick={clickProfileImage}
              >
                {(profileImage || editStartupView) && (
                  <img
                    src={profileImage || (!profileImage && startupData && startupData.startup_profile_image)}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                  />
                )}
              </div>
              <div style={{ marginLeft: '25px' }}>
                <p
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    color: '#000',
                    marginBottom: 0
                  }}
                  aria-hidden="true"
                  onClick={clickProfileImage}
                >
                  Profile Image <span className="text-mandatory">*</span>
                </p>
                <div className={classes.changePhoto}>
                  <Icon name="edit" width={16} height={16} style={{ marginRight: '10px', cursor: 'pointer' }} />
                  <input
                    id="profile_image_input"
                    type="file"
                    value=""
                    onChange={(event) => changePhoto(event)}
                    accept=".jpg, .jpeg, .png"
                    style={{ cursor: 'pointer' }}
                  />
                </div>
                <div className="edit_profile_notes" style={{ fontSize: '11px' }}>
                  JPG or PNG only
                  <br />
                  Max 5 MB
                  <br />
                  For the best results: A square image. Dimensions of 400*400 pixels
                </div>
              </div>
            </div>
            {!profileImage && savedOnce && <div className="text-danger">Required</div>}
            <div className={classes.photo}>
              <div style={{ marginTop: '20px' }}>
                <div className={`${classes.changePhoto} d-flex`}>
                  <div style={{ width: '100%', minWidth: 300, padding: '10px 0' }}>
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: '20px',
                        color: '#000'
                      }}
                    >
                      Cover Image {/* <span className="text-mandatory">*</span> */}{' '}
                      <Icon name="edit" width={16} height={16} style={{ marginLeftt: '30px' }} />
                      <input
                        id="cover_image_input"
                        type="file"
                        value=""
                        onChange={(event) => changeCoverPhoto(event)}
                        accept=".jpg, .jpeg, .png"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={classes.coverImgae}
                style={{
                  // height: "100%",
                  // width: "fit-content",
                  border: '1px solid grey',
                  cursor: 'pointer'
                }}
                aria-hidden="true"
                onClick={clickCoverImage}
              >
                {(coverImg || editStartupView) && (
                  <img
                    src={coverImg || (startupData && startupData.startup_banner_image)}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                  />
                )}
              </div>
              <div className="edit_profile_notes" style={{ fontSize: '11px' }}>
                JPG or PNG only
                <br />
                Max 10 MB
                <br />
                For the best results: A square image. Dimensions of 1200x800 pixels
              </div>
            </div>
            <div className="startup_input_box">
              <TextInput
                labeltitle="Enter the entity name as per the ROC records"
                name="entity_name"
                notes="Every company in India needs to be registered with ROC (Registrar of Companies). Enter the name of your Startup as per ROC records."
                ismandatory
              />
            </div>
            {/* <div className="startup_input_box">
              <TextInput
                labeltitle="Permalink"
                name="permalink"
                value={values.name && `${values.name}-1`}
                ismandatory
                disabled
                tooltipTitle="Share this deal using this link with your backers. Only your backers will be able to view this deal."
              />
            </div> */}
            <div className="startup_input_box">
              <div style={{ width: '70%' }}>
                <Field
                  name="startup_location_tokens"
                  component={() => (
                    <ReactSelectAutocomplete
                      name="startup_location_tokens"
                      value={values.startup_location_tokens}
                      labeltitle="Location/City"
                      placeholder="Enter the location name"
                      touched={touched}
                      errors={errors}
                      notes="Select the city from where you are operating the startup."
                      multi={false}
                      setFieldValue={setFieldValue}
                      handleSelectChange={(e) => handleSelectChange(e, setFieldValue)}
                      values={values}
                      ismandatory
                    />
                  )}
                />
              </div>
            </div>
            <div className="startup_input_box">
              <Select
                name="stage"
                labeltitle="Stage"
                placeholder="Select Startup Stage"
                touched={touched}
                options={stageOptions}
                notes="Select the most appropriate stage you think your startup is currently in."
                multi={false}
                ismandatory
              />
            </div>
            <div className="startup_input_box">
              <div style={{ width: '70%' }}>
                <Field
                  name="sector_tokens"
                  component={() => (
                    <ReactSelectAutocomplete
                      name="sector_tokens"
                      value={values.sector_tokens}
                      labeltitle="Sectors"
                      placeholder="Enter the sector name"
                      touched={touched}
                      notes="Max 3 sectors"
                      multi
                      setFieldValue={setFieldValue}
                      handleSelectChange={(e) => handleSelectChange(e, setFieldValue)}
                      values={values}
                      notInArr={values.sector_tokens || []}
                      // disabled={
                      //   values.sector_tokens && values.sector_tokens.length >= 3
                      // }
                      ismandatory
                    />
                  )}
                />
              </div>
            </div>
            <div className="startup_input_box">
              <Select
                name="business_type"
                labeltitle="Type of Business"
                placeholder="Select"
                touched={touched}
                options={businessTypeOptions}
                notes=""
                multi={false}
                ismandatory
              />
            </div>
            <div className="startup_input_box">
              <TextArea
                labeltitle="Elevator Pitch"
                labelName="summary"
                ismandatory
                notes=""
                size="200"
                placeholder="Describe the startup idea briefly. This will be shown to investors during the time of commitment…"
              />
            </div>
            <div className="startup_input_box">
              <TextArea
                labeltitle="Product/Service Summary"
                labelName="product_summary"
                ismandatory
                size="100"
                placeholder="Summarise the product/service offerings in a few words. This will be shown to investors during the time of commitment…"
              />
            </div>
            <div className={classes.scheme_input} style={{ maxWidth: '70%' }}>
              <Field
                title="Upload pitch deck"
                name="pitchDeck"
                component={DragAndDrop}
                labeltitle="Pitch Deck"
                Pitch_deck_error={errors && errors.pitchDeck ? errors.pitchDeck : ''}
                pitch_deck_file_name={values.pitch_deck_file_name}
                pitch_deck_file_size={values.pitch_deck_file_size}
                remove_pitch_deck={values.remove_pitch_deck}
                setFieldValue={setFieldValue}
                ismandatory
              />
            </div>
            <div className="startup_input_box">
              <Field
                component={() => (
                  <LeadInvestorList
                    user="founder"
                    isMandatory
                    labelTitle="Details of one founder"
                    labelName="founders"
                    investorList={values.founders}
                    removeInvestor={removeFounder}
                    addInvestors={addFounders}
                    setFieldValue={setFieldValue}
                    innerText="Add Founder"
                    errors={errors}
                    touched={touched}
                    removeIcon
                  />
                )}
              />
            </div>

            {syndicateType !== 'zeno' && (
              <div className="startup_input_box" style={{ maxWidth: '70%' }}>
                <TextInput
                  labeltitle="How much of their personal capital have the Founders invested in this company? (optional)"
                  name="founder_raised"
                  symbol="Lakhs"
                  type="number"
                  notes={
                    values.founder_raised && !String(values.founder_raised).includes('.')
                      ? `INR ${rupeeFormatter.format(values.founder_raised * 100000)}/- ${
                          values.founder_raised < 9999 && values.founder_raised > 0
                            ? converter(values.founder_raised * 100000).replace(/^./, (str) => str.toUpperCase())
                            : ''
                        } only`
                      : ''
                  }
                  style={{ maxWidth: '15%', textAlign: 'end' }}
                />
              </div>
            )}
            <div className="startup_input_box">
              <TextArea
                labeltitle="Why are you (the Lead) investing in this startup?"
                labelName="investment_thesis"
                notes="Of all the deals you see, why do you have particular conviction in this startup?"
                size="500"
              />
            </div>
            <div className={classes.scheme_input} style={{ maxWidth: '70%' }}>
              <Field
                title="Upload Lead investing thesis"
                name="leadInvestingThesis"
                component={LeadInvestementThesisDragAndDrop}
                labeltitle="Lead Investing Thesis"
                Pitch_deck_error={errors && errors.leadInvestingThesis ? errors.leadInvestingThesis : ''}
                pitch_deck_file_name={values.lead_investor_thesis_file_name}
                pitch_deck_file_size={values.lead_investor_thesis_file_size}
                remove_pitch_deck={values.remove_lead_investor_thesis}
                setFieldValue={setFieldValue}
              />
            </div>
            {/* <div className="startup_input_box">
              <TextArea
                labeltitle="Other Information"
                labelName="other_info"
                // ismandatory
                size="500"
                tooltipTitle="Other relevant information which will allow your backers to commit confidently to the deal faster. "
              />
            </div> */}
            <div className="startup_input_box">
              <TextInput
                labeltitle="What is the amount you want to raise (Upper limit of LV Scheme Size)"
                textinfo="Min INR 25 lakh. Max INR 10 Crores."
                name="funding_ask"
                ismandatory
                type="number"
                symbol="Lakhs"
                max="1000"
                min="25"
                notes={
                  values.funding_ask && !String(values.funding_ask).includes('.')
                    ? `INR ${rupeeFormatter.format(values.funding_ask * 100000)}/- ${
                        values.funding_ask < 9999 && values.funding_ask > 0
                          ? converter(values.funding_ask * 100000).replace(/^./, (str) => str.toUpperCase())
                          : ''
                      } only`
                    : ''
                }
                style={{ maxWidth: '10%', textAlign: 'end' }}
              />
              <div className="edit_profile_notes" style={{ fontSize: '14px' }}>
                If you have 1.2 Cr allocation, but might ask the startup to increase it to 1.4 Cr, please specify 1.5 Cr
                here. The scheme will be “up to” the amount you specify here. If the final amount ends up being below
                (within reason), that’s fine. If the final amount is higher, we will need to revise the scheme document
                and investors may need to reconsent.
              </div>
            </div>
            {syndicateType !== 'zeno' && (
              <div className="startup_input_box">
                <TextInput
                  labeltitle="Minimum Commitment per investor"
                  name="min_investment"
                  ismandatory
                  type="number"
                  symbol="Lakhs"
                  textinfo="Cannot be less than INR 1 lakh"
                  notes={
                    values.min_investment && !String(values.min_investment).includes('.')
                      ? `INR ${rupeeFormatter.format(values.min_investment * 100000)}/- ${
                          values.min_investment < 9999 && values.min_investment > 0
                            ? converter(values.min_investment * 100000).replace(/^./, (str) => str.toUpperCase())
                            : ''
                        } only`
                      : ''
                  }
                  style={{ maxWidth: '10%', textAlign: 'end' }}
                />
              </div>
            )}
            <div className="startup_input_box">
              <TextArea
                labeltitle="Comments / Notes for the LV team"
                labelName="lv_team_comments"
                // ismandatory
                size="500"
              />
            </div>

            {!!Object.keys(errors).length && (
              <p className="pt-5 text-danger">Please fill in all the mandatory fields to create the deal.</p>
            )}
            <div className={Object.keys(errors).length ? 'pb-5 pt-1' : 'py-5'}>
              <Button
                variant="primary"
                disabled={disableSubmit}
                size="md"
                type="submit"
                style={{
                  background: '#09f',
                  borderRadius: '3px',
                  fontSize: 14
                }}
                onClick={async () => {
                  // const formValid = await validateForm();
                  // if (Object.keys(formValid).length) window.scrollTo(0, 0);
                  setSavedOnce(true);
                }}
              >
                {savingStartupDetail ? (
                  <div className="d-flex align-items-center">
                    Saving... <Oval height="25" width="25" radius="9" color="#0099ff" />
                  </div>
                ) : (
                  `${editStartupView ? 'Update Startup' : 'Create Startup'}`
                )}
              </Button>
            </div>
            <Modal show={openAddFounderModal} onHide={handleFounderClose}>
              <Modal.Header closeButton>
                <Modal.Title>Add Founder</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {false && (
                  <AsyncSelect
                    loadOptions={loadInvestorList}
                    placeholder="Enter the name of your investor"
                    noOptionsMessage={({ inputValue }) =>
                      !inputValue ? 'Please Enter 1 or more character' : 'No results found'
                    }
                    onChange={(investor) => {
                      setFieldValue('selected_lead_investor', investor);
                    }}
                  />
                )}
                <>
                  <div className={`${classes.photo} d-flex pt-5`}>
                    <div
                      style={{
                        height: '100px',
                        minWidth: '100px',
                        border: founderImg ? 'none' : '1px solid grey'
                      }}
                      aria-hidden="true"
                      onClick={() => {
                        document.getElementById('founder_image_input').click();
                      }}
                    >
                      {founderImg && (
                        <img
                          src={founderImg}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                          }}
                        />
                      )}
                    </div>
                    <div style={{ marginLeft: '25px' }}>
                      <p
                        style={{
                          fontWeight: 'bold',
                          fontSize: '20px',
                          color: '#000',
                          marginBottom: 0
                        }}
                      >
                        Profile Image {/* <span className="text-mandatory">*</span> */}
                      </p>
                      <div className={classes.changePhoto}>
                        <Icon name="edit" width={16} height={16} style={{ marginRight: '10px', cursor: 'pointer' }} />
                        <input
                          id="founder_image_input"
                          type="file"
                          value=""
                          onChange={(event) => changeFounderPhoto(event)}
                          accept=".jpg, .jpeg, .png"
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                      <div className="edit_profile_notes" style={{ fontSize: '11px' }}>
                        JPG or PNG only
                        <br />
                        Max 5 MB
                        <br />
                        For the best results: A square image. Dimensions of 400*400 pixels
                      </div>
                    </div>
                  </div>
                  <div className="form-group mt-4">
                    <div className="my-4">
                      <label htmlFor="leadCarry" className="fw-bold mb-1">
                        Name
                      </label>
                      <span className="text-mandatory">*</span>
                      <input
                        type="text"
                        name="founder_name"
                        className="form-control"
                        id="founderName"
                        aria-describedby="founderName"
                        placeholder="Enter Founder Name"
                        onChange={(e) => {
                          setfounderName(e.target.value);
                          setFieldValue('founder_name', e.target.value);
                          if (!e.target.value) {
                            const div = document.getElementById('founder_name_error');
                            div.textContent = 'Required';
                          } else {
                            const div = document.getElementById('founder_name_error');
                            div.textContent = '';
                          }
                        }}
                      />
                      <div id="founder_name_error" style={{ color: 'red' }} />
                    </div>
                    <div className="my-4">
                      <label htmlFor="leadCarry" className="fw-bold mb-1">
                        Email
                      </label>
                      <span className="text-mandatory">*</span>
                      <input
                        type="email"
                        className="form-control"
                        name="founder_email"
                        id="founderEmail"
                        aria-describedby="founderEmail"
                        placeholder="Enter Founder Email"
                        onChange={(e) => {
                          setfounderEmail(e.target.value);
                          setFieldValue('founder_email', e.target.value);
                          if (e.target.value) {
                            if (!validateFounderEmail(e.target.value)) {
                              const div = document.getElementById('founder_email_error');
                              div.textContent = 'Please enter a valid email address.';
                            } else {
                              const div = document.getElementById('founder_email_error');
                              div.textContent = '';
                            }
                          } else {
                            const div = document.getElementById('founder_email_error');
                            div.textContent = 'Required';
                          }
                        }}
                      />
                      <div id="founder_email_error" style={{ color: 'red' }} />
                    </div>
                    <div className="my-4">
                      <label htmlFor="leadCarry" className="fw-bold mb-1">
                        Phone Number
                      </label>
                      <span className="text-mandatory">*</span>
                      <input
                        type="text"
                        name="founder_number"
                        className="form-control"
                        id="founderNumber"
                        aria-describedby="founderNumber"
                        placeholder="Enter Founder Number"
                        onChange={(e) => {
                          setfounderNumber(e.target.value);
                          setFieldValue('founder_number', e.target.value);
                          if (e.target.value) {
                            if (!validateFounderNumber(e.target.value)) {
                              const div = document.getElementById('founder_number_error');
                              div.textContent = 'Please enter a valid phone number.';
                            } else {
                              const div = document.getElementById('founder_number_error');
                              div.textContent = '';
                            }
                          } else {
                            const div = document.getElementById('founder_number_error');
                            div.textContent = 'Required';
                          }
                        }}
                      />
                      <div id="founder_number_error" style={{ color: 'red' }} />
                    </div>
                  </div>
                </>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleFounderClose}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={() => saveFounder(setFieldValue, values)}>
                  Save
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default StartupInformation;
