import React from 'react';

export const UserCircle = (props) => {
  return;
    <svg
      width={13}
      height={13}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
        <path
          d="M10 12.813a3.437 3.437 0 1 0 0-6.875 3.437 3.437 0 0 0 0 6.875z"
          fill="#000"
        />
        <path
          d="M10 1.875A8.125 8.125 0 1 0 18.125 10 8.14 8.14 0 0 0 10 1.875zm5.14 12.688a6.351 6.351 0 0 0-1.913-1.797 4.664 4.664 0 0 1-6.454 0 6.352 6.352 0 0 0-1.914 1.796 6.875 6.875 0 1 1 10.282 0z"
          fill="#000"
        />
    </svg>;
};

export const UserManagement = (props) => (
  <svg
    width={13}
    height={13}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.25 15.75v-1.5a3 3 0 0 1 3-3h3a3 3 0 0 1 3 3v1.5M12 2.348a3 3 0 0 1 0 5.812m3.75 7.59v-1.5a3 3 0 0 0-2.25-2.887M9.75 5.25a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
      stroke="#000"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Logout = (props) => (
  <svg
    width={13}
    height={13}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.667 6.667V5A1.667 1.667 0 0 0 10 3.333H4.167A1.667 1.667 0 0 0 2.5 5v10a1.667 1.667 0 0 0 1.667 1.667H10A1.667 1.667 0 0 0 11.667 15v-1.666M5.833 10H17.5m0 0L15 7.5m2.5 2.5L15 12.5"
      stroke="#000"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ChangePassword = (props) => (
  <svg
    width={13}
    height={13}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.125 4.375v11.25M8.125 7.5V10M5.75 9.227 8.125 10M6.656 12.023 8.125 10M9.594 12.023 8.125 10M10.5 9.227 8.125 10M15.625 7.5V10M13.25 9.227l2.375.773M14.156 12.023 15.625 10M17.094 12.023 15.625 10M18 9.227 15.625 10"
      stroke="#000"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);