import { ErrorMessage, Field } from "formik";
import PropTypes from "prop-types";
import React from "react";
import "../assets/styles.scss";
// import PopOverHtml from "./PopOverHtml";
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import classNames from "classnames";
import renderHTML from "react-render-html";

function ErrorView({ errorText }) {
  return (
    <div className="text-danger" style={{ marginTop: "0.3rem", fontSize: 14 }}>
      {errorText}
    </div>
  );
}

function TextAreaView({ field, form: { touched, errors, values }, ...props }) {
  const { size } = props;
  const fieldLength = !field.value ? 0 : field.value.length;
  const rowSize = fieldLength < 200 ? 200 : fieldLength;
  const rows = rowSize / 50;
  const remainingSize = size - fieldLength;
  return (
    <div className="edit-textarea-wrapper mt-3">
      <textarea
        value={field.value}
        rows={rows}
        maxLength={size}
        {...field}
        {...props}
      />
      <span>{`${remainingSize} Characters  `}</span>
    </div>
  );
}

function TextArea(props) {
  const { customError, smallerText } = props;
  const isError =
    customError !== undefined ? customError[props.labelName] : false;
  return (
    <div className="edit-form-row">
      <label className={classNames("edit__profile_label-title")} style={smallerText ? { fontSize: '16px' } : null}>
        {props.labeltitle}{" "}
        {props.ismandatory ? <span className="text-mandatory">*</span> : ""}
        {props.tooltipTitle && (
          <Tooltip title={props.tooltipTitle}>
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        )}
      </label>
      <div className="edit__profile_form-textarea">
        <div className="edit_profile_notes">{props.textinfo}</div>
        <Field
          component={TextAreaView}
          className={classNames("edit-textarea", {
            error: isError,
          })}
          size={props.size}
          name={props.labelName}
          validate={props.validate}
          {...props}
        />
        {props.popoverTitle && (
          <PopOverHtml
            show={props.popoverShow}
            title={props.popoverTitle}
            body={renderHTML(props.popoverBody)}
          />
        )}
        <ErrorMessage
          name={props.labelName}
          render={(msg) => <ErrorView errorText={msg} />}
        />
      </div>
      <div className="edit_profile_notes">
        {props.notes && renderHTML(props.notes)}
      </div>
    </div>
  );
}

TextArea.propTypes = {
  labeltitle: PropTypes.string.isRequired,
  labelName: PropTypes.string.isRequired,
  notes: PropTypes.string,
  ismandatory: PropTypes.bool,
  customError: PropTypes.object,
};

TextArea.defaultProps = {
  ismandatory: false,
};

export default TextArea;
