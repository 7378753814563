import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import NavBar from '../components/navbar';
import { getToken } from './common';

// handle the private routes
function PrivateRoute({ component: Component, ...rest }) {
  const token = getToken();
  return (
    <>
      <NavBar />
      {process.env.REACT_APP_ENV !== 'production' ? (
        <>
          <br />
          <br />
        </>
      ) : null}
      <Route
        {...rest}
        render={(props) =>
          token ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        }
      />
    </>
  );
}

export default PrivateRoute;
