import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import callApi from '../api';
import Loading from '../common/components/Loading';
import Icon from '../common/icons/Icon';
import { getSyndicateId } from '../common/utils/common';
import { httpMethods } from '../constants';
import StartupInformation from './components/StartupInformation';

export default function CreateStartup() {
  const history = useHistory();
  const { startup_id } = useParams();
  const syndicateId = getSyndicateId();
  const [startupData, setStartupData] = useState();
  const [editStartupView, setEditStartupView] = useState(false);

  const reloadToStartupPage = () => {
    history.push('/my/startup');
  };

  const fetchStartupData = (startup_id) => {
    if (startup_id) {
      const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
      callApi(
        `${djangoBaseUrl}/v1/syndicates/fetch_startup_data/?startup_id=${startup_id}&syndicate_id=${syndicateId}`,
        httpMethods.HTTP_GET,
        '',
        {},
        false
      ).then((response) => {
        setStartupData(response.data.startup);
        setEditStartupView(true);
      }).catch((error) => {
        toast.error(
          error &&
          error.response &&
          error.response.data &&
          (error.response.data.message || 'Something went wrong please contact at contactus@letsventure.com.')
        );
        setTimeout(reloadToStartupPage, 500);
      });
    } else {
      setEditStartupView(false);
    }
  };

  useEffect(() => {
    fetchStartupData(startup_id);
  }, [startup_id]);

  return (
    <>
      {/* <NavBar tabValue={1} /> */}
      {startup_id && !startupData ? (
        <Loading />
      ) : (
        <div className="container" style={{ marginTop: 80 }}>
          <h3>{startup_id ? 'Update Deal' : 'Create Deal'}</h3>
          <p
            style={{
              background: '#d9d9d9',
              padding: '5px 20px',
              width: '10%',
              borderRadius: '100px',
              textAlign: 'center',
            }}
          >
            <Icon name="privateIcon" width="16" height="16" />
            <span style={{ marginLeft: 15 }}>Private</span>
          </p>
          <div className="d-flex">
            {/* <div
                style={{ position: 'sticky', top: '40px', marginRight: '5px' }}
                className="startup_sidebar"
              >
                <ul
                  style={{
                    background: '#ffffff',
                    listStyleType: 'none',
                    paddingLeft: 0,
                  }}
                >
                  {Links.map((link) => (
                    <li style={{ padding: 20, width: 250 }}>
                      <div className="d-flex">
                        <div className="circle-mark" />
                        {' '}
                        {link.label}
                      </div>
                    </li>
                  ))}
                </ul>
              </div> */}
            <div style={{ width: '100%' }}>
              <div
                style={{
                  background: '#E7E7E7',
                  textAlign: 'left',
                  padding: 20,
                  fontSize: 22,
                }}
              >
                Startup Information
              </div>
              <div
                style={{
                  background: '#FFFFFF',
                  textAlign: 'left',
                  padding: '0 20px',
                }}
              >
                <StartupInformation
                  editStartupView={editStartupView}
                  startupData={startupData}
                  startup_id={startup_id}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
