import posthog from 'posthog-js';
import { getSyndicateId, getSyndicatePermalink, getUser } from '../common/utils/common';
/* eslint no-console: "error" */
class PostHogManager {
  static hasUserIdentified = false;

  static identifyUser() {
    const syndicateId = getSyndicateId();
    const syndicatePermalink = getSyndicatePermalink();
    const user = getUser();
    if (!PostHogManager.hasUserIdentified) {
      if (!!user) {
        const identifyUserProperty = { user_id: user.user_id, name: user.name, email: user.email };
        const identifySyndicateProperty = { syndicateId, syndicatePermalink };
        const uniqId = user.user_id;
        posthog.identify(uniqId, identifyUserProperty, identifySyndicateProperty);
      } else {
        posthog.identify('anonymous');
      }
      PostHogManager.hasUserIdentified = true;
    }
  }

  static initiatePostHog() {
    posthog.init('phc_sFUOWnawdX3mMr643gIOsGJGpcsYwkEx9KlrVzp0K0H', {
      api_host: 'https://app.posthog.com',
      loaded() {
        PostHogManager.identifyUser();
      }
    });
  }

  static trackPage() {
    PostHogManager.identifyUser();
    // posthog.capture('Root Page');
  }

  static trackEvent(eventName, propertyObj) {
    PostHogManager.identifyUser();
    if (process.env.REACT_APP_ENV === 'production') {
      posthog.capture(eventName, propertyObj);
    }
  }
}
export default PostHogManager;
