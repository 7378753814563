import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';

class Modal extends Component {
  constructor(props, context) {
    super(props, context);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show !== this.props.show) {
      if (nextProps.show) {
        $(findDOMNode(this)).stop(true, true).fadeIn('slow');
      } else {
        $(findDOMNode(this)).stop(true, true).fadeOut('slow');
      }
    }
  }

  static LARGE = 'modal-lg';

  static SMALL = 'modal-sm';

  render() {
    if (!this.props.show) {
      return null;
    }
    const modalStyles = {
      display: 'block',
      '--bs-modal-width': this.props.width ? this.props.width : '100%',
      margin: this.props.margin,
      ...this.props.style
    };
    return (
      <div className={this.props.className}>
        <div
          className="modal fade show"
          role="dialog"
          aria-labelledby={this.props.ariaLabel}
          aria-hidden="true"
          style={modalStyles}
        >
          <div
            className={`modal-dialog ${this.props.modalSize || ''}`}
            role="document"
            style={
              this.props.modalMargin
                ? {
                  margin: this.props.modalMargin,
                }
                : { margin: '5%' }
            }
          >
            <div className="modal-content">{this.props.children}</div>
          </div>
        </div>
        <div className="modal-backdrop show" />
      </div>
    );
  }
}

export default Modal;
