export function UserIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      {...props}
    >
      <path
        d="M41.667 43.75v-4.167a8.333 8.333 0 0 0-8.334-8.333H16.667a8.333 8.333 0 0 0-8.334 8.333v4.167M25 22.917A8.333 8.333 0 1 0 25 6.25a8.333 8.333 0 0 0 0 16.667z"
        stroke="#111"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function UserPlus(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      {...props}
    >
      <path
        d="M33.333 43.75v-4.167A8.333 8.333 0 0 0 25 31.25H10.417a8.333 8.333 0 0 0-8.334 8.333v4.167M17.708 22.917a8.333 8.333 0 1 0 0-16.667 8.333 8.333 0 0 0 0 16.667zM41.667 16.666v12.5M47.917 22.916h-12.5"
        stroke="#111"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function MultiUser(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        d="M12.75 15.75v-1.5a3 3 0 0 0-3-3h-6a3 3 0 0 0-3 3v1.5M6.75 8.25a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM17.25 15.75v-1.5A3 3 0 0 0 15 11.348M12 2.348a3 3 0 0 1 0 5.812"
        stroke="#111"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
