import { useAuth0 } from '@auth0/auth0-react';
import InfoIcon from '@mui/icons-material/Info';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import loginImg from '../assests/image/loginImg.png';
import lvLogo from '../assests/image/lvLogo.png';
import '../assests/style/common.scss';
import { LV_LOGO } from '../assests/image/IMGAE_CONSTANTS';
import WarningBanner from '../common/components/WarningBanner';

export const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value.trim());
  };
  return { value, onChange: handleChange };
};

function Login() {
  const { loginWithRedirect } = useAuth0();

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ position: 'fixed', width: '100%' }}>
        <WarningBanner />
      </div>
      <div className="loginImgDiv">
        <img alt="" className="loginImg" src={loginImg} />
      </div>

      <div className="loginFrmDiv">
        <Form>
          <img alt="" width="240" src={LV_LOGO} />
          <div className="loginSyndicte" style={{ fontSize: '28px', marginBottom: 20 }}>
            Syndicate
          </div>
          <div className="d-flex align-items-center" style={{ paddingTop: 24 }}>
            <div style={{ width: '58%' }}>
              <Button type="button" onClick={loginWithRedirect} className="loginBtn">
                Login
              </Button>
            </div>
            <div>
              <Tooltip title="If you are an existing LetsVenture user, try signing in using your LetsVenture credentials.">
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className="mt-5">
            <b>Note</b>
            <span>: For any login-related concerns, please email </span>
            <a
              target="_blank"
              href="https://mail.google.com/mail/?view=cm&fs=1&to=contactus@letsventure.com"
              rel="noreferrer"
            >
              contactus@letsventure.com
            </a>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default Login;
