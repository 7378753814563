import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Alert from '@mui/material/Alert';
import PostHogManager from '../analytics/postHogManager';
import callApi from '../api';
import TextArea from '../common/formik/components/TextArea';
import TextInput from '../common/formik/components/TextInput';
import Icon from '../common/icons/Icon';
import { getSyndicateId, getToken } from '../common/utils/common';
import { httpMethods } from '../constants';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function invitePeopleTabProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function AddUserSlide(props) {
  const [open, setOpen] = useState(!!props.open);
  const [value, setValue] = React.useState(0);
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [inviteMsg, setInviteMsg] = useState('');
  const [openAlert, setOpenAlert] = React.useState(false);
  const initialValues = {
    email: props.email || '',
    fullName: props.fullName || '',
    inviteMsg: props.inviteMsg || ''
  };

  const handleClose = (event, reason) => {
    PostHogManager.trackEvent('Closed Add Admin Slider', {
      source: 'Syndicate Admin Management Page',
      description: 'Closed Add Admin Slider'
    });
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const syndicateId = getSyndicateId();
  const token = getToken();
  let alertMsg;

  const validateForm = Yup.object({
    email: Yup.string().email().required('Required'),
    fullName: Yup.string().required('Required'),
    inviteMsg: Yup.string().required('Required')
  });

  const reloadWindow = () => {
    window.location.reload();
  };

  const onSubmit = (values) => {
    PostHogManager.trackEvent('Send Admin Invite Submitted', {
      syndicateId,
      source: 'Invite Admin Slider',
      description: 'Send Admin Invite Submitted',
      submitted_data: values
    });
    const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
    const url = `${djangoBaseUrl}/v1/syndicates/invite_member/?type_of_member=admin&type_of_action=invite&id=${syndicateId}`;
    callApi(
      url,
      httpMethods.HTTP_POST,
      {
        email: values.email,
        name: values.fullName,
        inviteMsg: values.inviteMsg
      },
      {},
      false
    )
      .then((response) => {
        PostHogManager.trackEvent('Send Admin Invite  - Success', {
          syndicateId,
          source: 'Invite Admin Slider',
          description: 'Send Admin Invite Successfull',
          response_data: response
        });
        if (response.data.success) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
        setTimeout(reloadWindow, 500);
      })
      .catch((error) => {
        PostHogManager.trackEvent('Send Admin Invite  - Failed', {
          syndicateId,
          source: 'Invite Admin Slider',
          description: 'Send Admin Invite Failed',
          response_data: error
        });
      });
  };

  const getList = () => (
    <div style={{ width: '100%', minWidth: 500 }}>
      <Box sx={{ width: '100%', minWidth: 500 }}>
        <div
          onClick={() => setOpen(false)}
          style={{
            padding: 10,
            display: 'flex',
            flexDirection: 'row-reverse',
            color: 'rgba(0, 0, 0, 0.45)'
          }}
        >
          <Icon name="close" width="32" height="32" style={{ cursor: 'pointer' }} />
        </div>
        <TabPanel value={value} index={0} style={{ minWidth: 500, marginTop: 30 }}>
          <div>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validateForm}>
              <Form>
                <div className="form-group" style={{ margin: '10px' }}>
                  <TextInput
                    labeltitle="Full Name"
                    name="fullName"
                    type="text"
                    placeholder="Full Name"
                    notes=""
                    ismandatory
                    style={{ width: '100%', fontSize: '14px' }}
                    smallerText
                  />
                </div>
                <div className="form-group" style={{ margin: '10px' }}>
                  <TextInput
                    labeltitle="Email"
                    name="email"
                    type="text"
                    placeholder="Enter Email ID"
                    notes=""
                    ismandatory
                    style={{ width: '100%', fontSize: '14px' }}
                    smallerText
                  />
                </div>
                <div className="form-group" style={{ fontSize: '13px', margin: '10px' }}>
                  <TextArea
                    labeltitle="Invitation Message"
                    labelName="inviteMsg"
                    placeholder="Enter Invite Message"
                    notes=""
                    ismandatory
                    style={{ width: '100%', fontSize: '14px' }}
                    smallerText
                    size="500"
                  />
                </div>
                <div style={{ margin: '10px' }}>
                  <Button
                    variant="primary"
                    size="md"
                    type="submit"
                    style={{
                      background: '#09f',
                      borderRadius: '3px',
                      fontSize: 14,
                      color: '#fff'
                    }}
                  >
                    Invite as Admin
                  </Button>
                </div>
              </Form>
            </Formik>
          </div>
        </TabPanel>
      </Box>
    </div>
  );

  return (
    <div>
      <Button
        dataToggle="tooltip"
        data-placement="top"
        title="Adding users can help you in managing the Syndicate effectively. They can login to this application and operate on behalf of you."
        variant="contained"
        onClick={() => {
          PostHogManager.trackEvent('Opened Add Admin Slider', {
            source: 'Syndicate Admin Management Page',
            description: 'Opened Add Admin Slider'
          });
          setOpen(true);
        }}
        style={{
          backgroundColor: '#0099FF',
          fontWeight: 'bold',
          height: '30px',
          fontSize: '12px',
          color: 'white'
        }}
      >
        Add an Admin
      </Button>
      <Drawer
        open={open}
        style={{ marginTop: '150px', width: '55%', minWidth: 500 }}
        anchor="right"
        onClose={() => {
          PostHogManager.trackEvent('Closed Add Admin Slider', {
            source: 'Syndicate Admin Management Page',
            description: 'Closed Add Admin Slider'
          });
          setOpen(false);
        }}
      >
        {getList()}
        <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            {alertMsg} !
          </Alert>
        </Snackbar>
      </Drawer>
    </div>
  );
}
