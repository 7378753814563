import { useAuth0 } from '@auth0/auth0-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import ZenoProfile from './PreCommitSyndicateProfile/ZenoProfile';
import ZenoCorpus from './PreCommitSyndicateProfile/zenoCorpus';
import PostHogManager from './analytics/postHogManager';
import callApi, { httpClient } from './api';
import SyndicateSettings from './auth/SyndicateSettings';
import Login from './auth/Login';
import UserLoginRedirection from './auth/userLoginRedirection';
import './common/formik/assets/styles.scss';
import PrivateRoute from './common/utils/PrivateRoute';
import { getToken, setUserSession } from './common/utils/common';
import Home from './components/Home';
import { httpMethods } from './constants';
import DealDetail from './deal/DealDetail';
import MyStartup from './mystartup/mystartup';
import NonZenoBackerDetail from './people/components/NonZenoBackerDetail';
import People from './people/people';
import preCommitSyndicate from './preCommitSyndicate/PreCommitSyndicate';
import RedirectToComputer from './redirectToComputer/RedirectToComputer';
import CreateStartup from './startup';
import StartupHome from './startup/components/startupHome';
import SyndicateProfile from './syndicateProfile/SyndicateProfile';
import UserManagement from './userManagement/UserManagement';

function App() {
  const pathname = window.location.search;
  const pathparams = new URLSearchParams(pathname);
  const { getAccessTokenSilently } = useAuth0();
  const impersonateAccessToken = !!pathparams.get('access_token')
    ? pathparams.get('access_token').replaceAll(' ', '+')
    : pathparams.get('access_token');
  const access = pathparams.get('access');
  const [logintrigered, setLoginTriggered] = useState(false);
  if (!!access && !!impersonateAccessToken && !logintrigered) {
    localStorage.setItem('token', impersonateAccessToken);
    console.log('called');
    callApi('/api/v1/user_components/me/glasdoor_login', httpMethods.HTTP_POST, {
      access_token: impersonateAccessToken
    })
      .then((response) => {
        if (response.data.status !== 500) {
          PostHogManager.trackEvent('Login Successfull - Impersonation', {
            source: 'Login Page',
            description: 'Login Successfull',
            response_data: response
          });
          setUserSession(
            response.data.data.access_token,
            response.data.data.user,
            response.data.syndicate_id,
            response.data.syndicate_type,
            response.data.syndicate_permalink,
            response.data.data.refresh_token
          );
          window.location.href = '/home';
        } else {
          PostHogManager.trackEvent('Login Failed', {
            source: 'Login Page',
            description: 'Login Failed',
            response_data: response
          });
          window.location.href = '/';
        }
        setLoginTriggered(true);
      })
      .catch((error) => {
        PostHogManager.trackEvent('Login Failed', {
          source: 'Login Page',
          description: 'Login Failed',
          response_data: error
        });
        let errorMessage = '';
        if (error.response.status === 404) {
          errorMessage = error.response.data.message;
        } else if (error.response.status === 500) {
          errorMessage = error.response.errors;
        } else {
          errorMessage = 'Username or Password is wrong';
        }
        toast.error(errorMessage);
        window.location.href = '/';
        setLoginTriggered(true);
      });
  }

  const token = getToken();
  useEffect(() => {
    PostHogManager.trackPage();
    httpClient.setTokenGenerator(() =>
      getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_GLASSBOARD_AUTH0_AUDIENCE
        }
      })
    );
    httpClient.enableDefaultRequestInterceptor();
    httpClient.enableDefaultResponseInterceptor();
  });

  let isMobileDevice = false;
  const uagent = navigator.userAgent.toLowerCase();
  if (
    uagent.search('iphone') > -1 ||
    uagent.search('ipad') > -1 ||
    uagent.search('android') > -1 ||
    uagent.search('blackberry') > -1 ||
    uagent.search('webos') > -1
  ) {
    isMobileDevice = true;
  } else {
    isMobileDevice = false;
  }

  if (isMobileDevice) {
    return (
      <div>
        <RedirectToComputer />
      </div>
    );
  }

  return (
    <div className="wrapper">
      <BrowserRouter>
        <Switch>
          <PrivateRoute path="/home" component={Home} />
          <PrivateRoute exact path="/deal/:permalink" component={DealDetail} />
          <PrivateRoute exact path="/capital" component={preCommitSyndicate} />
          <PrivateRoute exact path="/zeno_profile" component={ZenoProfile} />
          <PrivateRoute exact path="/zeno_corpus" component={ZenoCorpus} />
          <PrivateRoute exact path="/manage/investor/:investor_id" component={NonZenoBackerDetail} />
          <PrivateRoute path="/manage/investor" component={People} />
          <PrivateRoute path="/startup/home" component={StartupHome} />
          <PrivateRoute path="/my/startup" component={MyStartup} />
          <PrivateRoute path="/startup/create_deal" component={CreateStartup} />
          <PrivateRoute path="/startup/update_deal/:startup_id" component={CreateStartup} />
          <PrivateRoute path="/auth/syndicate_settings" component={SyndicateSettings} />
          <PrivateRoute path="/syndicate_admin_management" component={UserManagement} />
          <PrivateRoute path="/syndicate_profile" component={SyndicateProfile} />
          <Route exact path="/callback" component={UserLoginRedirection} />

          {token ? (
            <Redirect to={{ pathname: '/home', state: { from: '/' } }} />
          ) : (
            <Route exact path="/" component={Login} />
          )}
        </Switch>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
