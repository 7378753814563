import { makeStyles } from '@material-ui/core';
import { Close, Warning } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';

const useStyles = makeStyles({
  alertContainer: {
    height: '2rem',
    background: 'orange',
    display: 'flex',
    alignItems: 'center',
    padding: '0 2rem',
    textAlign: 'center',
    justifyContent: 'center',
    position: 'relative',
    color: '#fff'
  },
  alertContent: { padding: 0, margin: 0, fontWeight: 500 },
  warningIcon: { left: 20, top: 5, position: 'absolute' },
  closeIcon: { right: 20, top: 5, position: 'absolute', cursor: 'pointer' }
});

const WarningBanner = () => {
  const classes = useStyles();

  return process.env.REACT_APP_ENV === 'staging' ? (
    <Box className={classes.alertContainer}>
      <Typography className={classes.alertContent}>
        Currently you are using testing server, for Production server login to{' '}
        <a href="https://glassboard.letsventure.com/" target="_blank" style={{ textDecoration: 'underline' }}>
          glassboard.letsventure.com
        </a>
      </Typography>
      <Warning className={classes.warningIcon} fontSize="small" />
    </Box>
  ) : null;
};

export default WarningBanner;
