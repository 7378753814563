import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Icon from '../../icons/Icon';
import '../assets/draganddrop.scss';

function DragAndDrop(props) {
  const [fileDropError, setFileDropError] = useState('');
  const [isUploaded, setIsUploaded] = useState(false);
  const [fileName, setFileName] = useState('');
  const [fileSize, setFileSize] = useState('');
  const [file, setFiles] = useState('');

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: '1px',
    borderRadius: '2px',
    borderColor: props.pitch_deck_file_name ? '#00e676' : '#979797',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: 'black',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };

  const activeStyle = {
    borderColor: '#2196f3'
  };

  const acceptStyle = {
    borderColor: '#00e676'
  };

  const rejectStyle = {
    borderColor: '#ff1744'
  };

  const { acceptedFiles, fileRejections, isDragActive, isDragAccept, isDragReject, getRootProps, getInputProps } =
    useDropzone({
      accept: 'application/pdf'
    });

  const [style, setStyle] = useState({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  });

  const setStartupPitchDeck = (pitchDeck, pitchDeckFile, pitchDeckSize, removeStatus) => {
    props.setFieldValue('Pitch_deck_error', '');
    props.setFieldValue('pitchDeck', pitchDeck);
    if (props.field.name === 'draft_agreement_file') {
      props.setFieldValue('draft_agreement_file_name', pitchDeckFile.length > 0 ? `${pitchDeckFile} - ` : null);
    } else {
      props.setFieldValue('pitch_deck_file_name', pitchDeckFile.length > 0 ? `${pitchDeckFile} - ` : null);
    }
    props.setFieldValue('pitch_deck_file_size', pitchDeckSize.length > 0 ? `${pitchDeckSize} MB` : null);
    props.setFieldValue('remove_pitch_deck', removeStatus);
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (['application/pdf'].includes(acceptedFiles[0].type) === false) {
      setFileDropError('Invalid file format. Please upload file again!!');
      setStyle({ ...style, borderColor: '#ff1744' });
    } else {
      setFileDropError('');
      const reader = new FileReader();

      reader.onload = function (readerEvt) {
        const binaryString = readerEvt.target.result;
        const result = btoa(binaryString);
      };
      reader.readAsBinaryString(acceptedFiles[0]);
      setStyle({ ...style, borderColor: '#00e676' });
    }
    acceptedFiles.forEach((file) => {
      setFileName(file.name);
      setFileSize((file.size / (1024 * 1024)).toFixed(2));
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        const binaryStr = reader.result;
      };
      reader.readAsArrayBuffer(file);
      setStartupPitchDeck(file, file.name, (file.size / (1024 * 1024)).toFixed(2), true);
    });
  }, []);

  const remove = () => {
    acceptedFiles.length = 0;
    setStartupPitchDeck('', '', '', false);
    setFileDropError('');
    setIsUploaded(false);
  };

  useEffect(() => {
    if (acceptedFiles[0] !== undefined) {
      setIsUploaded(true);
      onDrop(acceptedFiles);
    }
  }, [acceptedFiles]);

  return (
    <div className="edit-form-row">
      <label className="edit__profile_label-title">
        {props.title} {props.ismandatory ? <span className="text-mandatory">*</span> : ''}
      </label>
      <div className="form-group draganddrop mt-3" style={{ cursor: 'pointer', width: '100%' }}>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} max={20971520} />
          <center>
            <p>
              <span style={{ fontSize: 16 }}>{props.labelTitle}</span>
              <div>
                <a href="#">
                  <CloudUploadIcon />
                </a>
              </div>
              <div className="mb-3" />
              <span style={{ fontSize: 12, color: '#9CA3AF' }}>
                PDF only. <b>Max 20 MB</b>
              </span>
            </p>
          </center>
        </div>
        {isUploaded === true ? (
          <div style={{ display: 'none' }}>
            {' '}
            {props.Pitch_deck_error.length > 0 ? (
              <p className="text-danger" style={{ marginTop: '0.3rem' }}>
                {props.Pitch_deck_error}
              </p>
            ) : null}
          </div>
        ) : (
          <div style={{ display: 'block' }}>
            {' '}
            {props.Pitch_deck_error.length > 0 ? (
              <p className="text-danger" style={{ marginTop: '0.3rem' }}>
                {props.Pitch_deck_error}
              </p>
            ) : null}
          </div>
        )}
        {fileDropError.length > 0 ? (
          <p className="text-danger" style={{ marginTop: '0.3rem' }}>
            {fileDropError}
          </p>
        ) : null}
        {props.pitch_deck_file_name && props.pitch_deck_file_name.length > 0 ? (
          <p style={{ marginTop: '15px', fontWeight: '500' }}>
            <Icon name="paperclip" style={{ color: '#00e676' }} />
            {props.pitch_deck_file_name}
            {props.pitch_deck_file_size}
            {props.remove_pitch_deck === true ? (
              <span className="text-danger" style={{ float: 'right', fontSize: '18px' }} onClick={remove}>
                <Icon name="thrash" />
              </span>
            ) : null}
          </p>
        ) : null}
      </div>
    </div>
  );
}

export default DragAndDrop;
