import React, { useState } from 'react';

import { Grid } from '@mui/material';


import BackerDetails from './BackerDetails';
import BackersList from './BackersList';

const preCommitSyndicate = () => {
  const [selectedBackerId, setSelectedBackerId] = useState();

  return (
    // <AallocationPage startupId={9906} />
    <Grid sm={12} style={{ marginTop: '50px' }}>
      {!!selectedBackerId && selectedBackerId != null ? (
        <BackerDetails backerId={selectedBackerId} />
      ) : (
        <BackersList setBacker={setSelectedBackerId} />
      )}
    </Grid>
  );
};

export default preCommitSyndicate;
