// return the user data from the session storage
export const getUser = () => {
  const userStr = localStorage.getItem('user');
  if (userStr !== 'undefined') return JSON.parse(userStr);
  return null;
};

export const getSyndicateId = () => localStorage.getItem('syndicate_id');

export const getSyndicatePermalink = () =>
  localStorage.getItem('syndicate_permalink');
// return the token from the session storage
export const getToken = () => localStorage.getItem('token') || null;

export const getRefreshToken = () =>
  localStorage.getItem('refreshToken') || null;

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('syndicate_id');
  localStorage.removeItem('syndicate_type');
  localStorage.removeItem('syndicate_permalink');
  localStorage.removeItem('refreshToken');
};

// set the token and user from the session storage
export const setUserSession = (
  token,
  user,
  id,
  syndicateType,
  permalink,
  refreshToken = ''
) => {
  localStorage.setItem('token', token);
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('syndicate_id', id);
  localStorage.setItem('syndicate_type', syndicateType);
  localStorage.setItem('syndicate_permalink', permalink);
  localStorage.setItem('refreshToken', refreshToken);
};

export const getCurrencySymbol = (name) =>
  name === 'USD' ? '\u0024' : '\u20B9';

export const formatAmountByPrecision = (amount, precision) => {
  if (!(`${amount}`).includes('e')) {
    return +(`${Math.round(`${amount}e+${precision}`)}e-${precision}`);
  }
  const arr = (`${amount}`).split('e');
  let sig = '';
  if (+arr[1] + precision > 0) {
    sig = '+';
  }
  return +(`${Math.round(`${+arr[0]}e${sig}${+arr[1] + precision}`)}e-${precision}`);
};
