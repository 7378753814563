import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import callApi from '../api';
import '../assests/style/backer_details.scss';
import StartupCard from '../common/components/StartupCard';
import Icon from '../common/icons/Icon';
import { getSyndicateId, getSyndicatePermalink } from '../common/utils/common';
import { httpMethods } from '../constants';

const useStyles = makeStyles({
  cellHeaderRoot: {
    flexGrow: '0',
    margin: '0 0 33px',
    fontFamily: 'proxima-nova',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.29',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#696969',
  },
  cellDataRoot: {
    flexGrow: '0',
    margin: '33px 84px 0 1px',
    fontFamily: 'proxima-nova',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.79',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#000',
  },
  typographyRoot: {},
});

export const rupeeFormatter = new Intl.NumberFormat('en-IN', {
  currency: 'INR',
});

const backerDetails = ({ backerId }) => {
  const syndicatePermalink = getSyndicatePermalink();
  const syndicateId = getSyndicateId();
  const [backerData, setBackerData] = useState({});
  const classes = useStyles();

  const getBackerData = () => {
    const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
    const url = `${djangoBaseUrl}/v1/syndicates/get_backer_details_page/?id=${syndicateId}&inv_id=${backerId}`;
    callApi(
      url,
      httpMethods.HTTP_GET,
      '',
      {},
      false
    ).then((response) => {
      setBackerData(response.data.data);
    });
  };

  useEffect(() => {
    getBackerData();
  }, []);

  return (
    <Container
      maxWidth={false}
      sx={{
        width: '100%',
        padding: '40px 4% 50px 4% !important',
      }}
    >
      <div
        style={{ marginBottom: '15px', fontSize: '20px', fontWeight: 'bold' }}
      >
        <a
          href="/capital"
          style={{ textDecoration: 'none', color: '#000' }}
        >
          <Icon
            name="ArrowLeft"
            style={{ marginRight: '10px' }}
            height="22"
            width="22"
          />{' '}
          <span style={{ maeginLeft: '10px' }}>Back</span>
        </a>
      </div>
      <Stack spacing={2}>
        <Grid
          sm={12}
          sx={{
            height: '292px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            backgroundColor: '#effaff',
            borderRadius: '10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '42%',
              padding: '15px 25px 15px 0px',
              height: '292px',
              background: '#fff',
            }}
          >
            <div style={{ width: '202px', height: '202px' }}>
              <Avatar
                style={{
                  width: '80%',
                  height: '80%',
                  margin: '25px 42px 36px 20px',
                }}
                alt={backerData.name}
                // src="https://eirikso.files.wordpress.com/2008/12/seasons10241.jpg"
                src={backerData.profile_image}
              />
            </div>
            <div style={{ paddingLeft: '20px' }}>
              <Typography
                variant="h4"
                sx={{ fontSize: '30px', lineHeight: 2, fontWeight: 'bold' }}
              >
                {backerData.name}
              </Typography>
              <Typography sx={{ fontSize: '16px', lineHeight: 1.6 }}>
                {backerData.email}
              </Typography>
              <Typography sx={{ fontSize: '16px', lineHeight: 1.6 }}>
                {backerData.phone_no}
              </Typography>
              <Typography sx={{ fontSize: '16px', lineHeight: 1.6 }}>
                {backerData.address}
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '15%',
              borderRight: '1px solid rgb(0,0,0,0.2)',
            }}
          >
            <div style={{ paddingLeft: '10%' }}>
              <Typography sx={{ padding: '10px', fontSize: '20px !important' }}>
                Balance Amount
              </Typography>
              <Typography
                sx={{
                  padding: '10px',
                  fontWeight: 'bold',
                  fontSize: '20px !important',
                }}
              >
                ₹ {rupeeFormatter.format(backerData.balance_amount)}
              </Typography>
            </div>
            <div className="separator"></div>
            <div style={{ paddingLeft: '10%' }}>
              <Typography sx={{ padding: '10px', fontSize: '20px !important' }}>
                Amount Invested
              </Typography>
              <Typography
                sx={{
                  padding: '10px',
                  fontWeight: 'bold',
                  fontSize: '20px !important',
                }}
              >
                ₹ {rupeeFormatter.format(backerData.total_invested)}
              </Typography>
            </div>
          </div>
          <List
            style={{ width: '35%', marginLeft: '4%', lineHeight: 2.5 }}
            className="listFont"
          >
            <ListItem
              key={1}
              sx={{ fontSize: '16px' }}
              secondaryAction={
                <span>
                  <b>₹ {rupeeFormatter.format(backerData.committed_amount)}</b>
                </span>
              }
              disablePadding
            >
              <span>Total Committed</span>
            </ListItem>
            <ListItem
              key={2}
              sx={{ fontSize: '16px' }}
              secondaryAction={
                <span>
                  <b>
                    ₹ {rupeeFormatter.format(backerData.total_amount_received)}
                  </b>
                </span>
              }
              disablePadding
            >
              <span>Amount Received over Drawdowns</span>
            </ListItem>
            <ListItem
              key={3}
              sx={{ fontSize: '16px' }}
              secondaryAction={
                <span>
                  <b>₹ {rupeeFormatter.format(backerData.total_uncalled)}</b>
                </span>
              }
              disablePadding
            >
              <span>Money Yet to Call</span>
            </ListItem>
            <ListItem
              key={4}
              sx={{ fontSize: '16px' }}
              secondaryAction={
                <span>
                  <b>₹ {rupeeFormatter.format(backerData.investible_amount)}</b>
                </span>
              }
              disablePadding
            >
              <span>Investible Amount</span>
            </ListItem>
            <ListItem
              key={5}
              sx={{ fontSize: '16px' }}
              secondaryAction={
                <span>
                  <b>₹ {rupeeFormatter.format(backerData.management_fee)}</b>
                </span>
              }
              disablePadding
            >
              <span>Management Fee (Incl GST)</span>
            </ListItem>
          </List>
        </Grid>
        <Box>
          <TableContainer component={Paper} sx={{ borderRadius: '10px' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '16px' }} align="center">
                    <b>Drawdown</b>
                  </TableCell>
                  {/* <TableCell
                    align="right"
                    sx={{ color: '#0099ff', fontSize: '16px' }}
                  >
                    View Details
                  </TableCell> */}
                </TableRow>
                <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                  <TableCell
                    classes={{ root: classes.cellHeaderRoot }}
                    align="center"
                  >
                    DRAW STAGE
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.cellHeaderRoot }}
                    align="center"
                  >
                    CALLED IN
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.cellHeaderRoot }}
                    align="center"
                  >
                    DATE
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.cellHeaderRoot }}
                    align="center"
                  >
                    AMOUNT RECEIVED
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.cellHeaderRoot }}
                    align="center"
                  >
                    BALANCE
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.cellHeaderRoot }}
                    align="center"
                  >
                    MONEY YET TO CALL
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!!backerData.sent_drawdown_arr &&
                  backerData.sent_drawdown_arr.length > 0 &&
                  backerData.sent_drawdown_arr.map((row) => (
                    <TableRow
                      key={row.index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell
                        classes={{ root: classes.cellDataRoot }}
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {row.index + 1}
                      </TableCell>
                      <TableCell
                        classes={{ root: classes.cellDataRoot }}
                        align="center"
                      >
                        ₹ {rupeeFormatter.format(row.called_in)}
                      </TableCell>
                      <TableCell
                        classes={{ root: classes.cellDataRoot }}
                        align="center"
                      >
                        {row.date}
                      </TableCell>
                      <TableCell
                        classes={{ root: classes.cellDataRoot }}
                        align="center"
                      >
                        ₹ {rupeeFormatter.format(row.received_amount)}
                      </TableCell>
                      <TableCell
                        classes={{ root: classes.cellDataRoot }}
                        align="center"
                      >
                        ₹ {rupeeFormatter.format(row.balance)}
                      </TableCell>
                      <TableCell
                        classes={{ root: classes.cellDataRoot }}
                        align="center"
                      >
                        ₹ {rupeeFormatter.format(row.yet_to_called_in)}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Container maxWidth={false} sx={{ padding: '0 !important' }}>
          <StartupCard startupList={backerData.committed_startups} />
        </Container>
      </Stack>
    </Container>
  );
};

export default backerDetails;
