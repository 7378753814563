import React from 'react';
import { Button, Card } from 'react-bootstrap';
import '../../assests/style/startup.scss';
import Icon from '../../common/icons/Icon';

export default function StartupHome() {
  return (
    <div className="startup-home container">
      <div className="heading">Create Deal</div>
      <div className="description pt-2">
        Create Deals by filling up all the relevant details of the startups.
        These are helpful for the investors to understand the startup and decide
        to commit to the Deal.
      </div>
      <div className="d-flex mt-4 justify-content-between">
        {/* <Card className="col-md-5 p-5" style={{ width: '48%' }}>
          <Card.Body>
            <Card.Title>Public</Card.Title>
            <Card.Text className="description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit.
            </Card.Text>
          </Card.Body>
        </Card> */}
        <Card
          className="col-md-5 p-5"
          style={{
            width: '48%',
            boxShadow:
              '0 0 4px 0 rgba(0, 0, 0, 0.1), 0 12px 8px 0 rgba(0, 0, 0, 0.1)',
          }}
        >
          <Card.Body>
            <div className="py-4">
              <Icon name="PrivateIcon" width="50" height="50" />
            </div>
            <Card.Title style={{ fontWeight: 'bold', paddingBottom: '1rem' }}>Private</Card.Title>
            <Card.Text className="description">
              By creating delas in private mode, they are visible only to the
              investors who are part of your syndicate.
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
      <div className="py-4">
        <Button
          variant="primary"
          size="md"
          style={{ background: '#09f' }}
          onClick={() => {
            window.location.href = '/startup/create_deal';
          }}
        >
          Get Started
        </Button>
      </div>
    </div>
  );
}
