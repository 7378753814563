import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import { Alert, Switch, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Slider from '@mui/material/Slider';
import { DataGrid } from '@mui/x-data-grid';

import callApi from '../api';
import '../assests/style/allocation_page.scss';
import Modal from '../common/components/Modal';
import { getSyndicateId, getSyndicatePermalink } from '../common/utils/common';
import { httpMethods } from '../constants';
// import '../assests/style/backerselectionmodal.scss';

const useStyles = makeStyles({
  root: {
    color: '#0bc11d',
    height: '17px',
  },
  sliderThumb: {
    width: '16px',
    height: '40px',
    color: '#fff',
    borderRadius: '30%',
  },
  sliderRail: {
    opacity: 1,
    background: '#ffffff',
    width: '102%',
  },
  mark: {
    width: '10px',
  },
  switchThumb: {
    width: '13px',
    height: '13px',
    borderRadius: '50%',
    margin: '5px 0px 0px 3px',
    backgroundColor: '#fff',
  },
  switchTrackOn: {
    backgroundColor: '#000 !important',
    opacity: '1 !important',
    height: '18px !important',
  },
  switchTrackOff: {
    backgroundColor: '#000 !important',
    height: '18px !important',
  },
  switchTrackError: {
    backgroundColor: '#ed1153 !important',
    height: '18px !important',
    opacity: '1 !important',
  },
});

export const rupeeFormatter = new Intl.NumberFormat('en-IN', {
  currency: 'INR',
});

const allocationPage = ({
  startupId,
  onPublish,
  showAllocation,
  setPublish,
  loadDealData,
}) => {
  const [overallData, setOverallData] = useState();
  const [backersList, setBackersList] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [submitLoader, setSubmitLoader] = useState(false);
  // const [value, setValue] = useState(0);
  const [isZeno, setIsZeno] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const syndicatePermalink = getSyndicatePermalink();
  const syndicateId = getSyndicateId();
  const classes = useStyles();

  const handleSelectBacker = (event) => {
    const { id, checked } = event.target;
    const idd = id.split('switch_')[1];
    if (checked) {
      document.getElementById(`commit_${idd}`).disabled = false;
    } else {
      document.getElementById(`commit_${idd}`).value = '';
      document.getElementById(`commit_${idd}`).disabled = true;
    }
  };

  const handlePercentageCommit = (event) => {
    setIsError(false);
    document.getElementById('errorMssage').style.display = 'none';
    const percent = event.target.value;
    if (
      (overallData.total_corpus * percent) / 100 >
      overallData.lv_allocated_ask
    ) {
      document.getElementById(
        'allotted_amount'
      ).value = `₹${` ${rupeeFormatter.format(overallData.lv_allocated_ask)}`}`;
      alert('The allotted amount is more than LV ask.');
      return;
    }
    if ((overallData.total_corpus * percent) / 100 < 2500000) {
      // document.getElementById(
      //   'allotted_amount'
      // ).value = `₹${` ${rupeeFormatter.format(2500000)}`}`;
      alert('The total commitment amount can not be less than 25 Lakh.');
      return;
    }
    setPercentage(percent);
    // setValue(percent);
    const allottedCommit =
      (!!overallData && overallData.total_corpus * percent) / 100;
    document.getElementById(
      'allotted_amount'
    ).value = `₹${` ${rupeeFormatter.format(allottedCommit)}`}`;
    backersList.forEach((backer) => {
      const commitAmount = Math.floor(
        (backer.precommit_amount * percent) / 100
      );
      if (commitAmount < 100000) {
        setIsError(true);
        document.getElementById('errorMssage').style.display = 'block';
        setErrorMsg('An Investor can not have a commitment less than 1 Lakh.');
        document.getElementById('submitBtn').style.disabled = true;
      }
      document.getElementById(`commit_${backer.id}`).value = commitAmount;
    });
  };

  const handleAllottedAmount = (event) => {
    const amount = parseInt(event.target.value, 10);
    if (amount > overallData.lv_allocated_ask) {
      document.getElementById(
        'allotted_amount'
      ).value = `₹${` ${rupeeFormatter.format(overallData.lv_allocated_ask)}`}`;
      alert('The allotted amount is more than LV ask000.');
      return;
    }
    if (amount < 2500000) {
      document.getElementById(
        'allotted_amount'
      ).value = `₹${` ${rupeeFormatter.format(overallData.lv_allocated_ask)}`}`;
      alert('The total commitment amount can not be less than 25 Lakh000.');
      return;
    }
    const percent = Math.round((amount * 1000) / overallData.total_corpus) / 10;
    setPercentage(percent);
    // document.getElementById('_percetage').value = percent;
  };

  const columns = [
    {
      field: 'id',
      headerName: '',
      width: 150,
      renderCell: (params) => (
        <div style={{ marginLeft: '30%' }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  classes={
                    isZeno &&
                    !params.row.balance_amount <= 0 &&
                    params.row.balance_amount >=
                      (params.row.precommit_amount * percentage) / 100
                      ? {
                          thumb: classes.switchThumb,
                          track: classes.switchTrackOn,
                        }
                      : {
                          thumb: classes.switchThumb,
                          track: classes.switchTrackError,
                        }
                  }
                  id={`switch_${params.id}`}
                  onChange={handleSelectBacker}
                  disabled={isZeno}
                  defaultChecked
                  checked={
                    isZeno &&
                    params.row.balance_amount >=
                      (params.row.precommit_amount * percentage) / 100
                  }
                />
              }
            />
          </FormGroup>
        </div>
      ),
    },
    {
      field: 'name',
      headerName: 'BACKERS',
      width: 300,
      renderCell: (params) => (
        <div>
          <span
            className="d-flex align-items-center"
            style={{ fontSize: '14px', fontWeight: 'bold' }}
            // onClick={() => switchToDetailedPage(params.row.id)}
          >
            <Avatar
              style={{
                width: '30px',
                height: '30px',
                marginRight: '10px',
              }}
              alt={params.row.name}
              src={params.row.profile_image}
            />
            <span className="d-inline-block commonFont">
              <div
                disabled
                style={
                  isZeno &&
                  !params.row.balance_amount <= 0 &&
                  params.row.balance_amount >=
                    (params.row.precommit_amount * percentage) / 100
                    ? {
                        pointerEvents: 'none',
                        fontSize: '15px',
                        lineHeight: '0',
                      }
                    : {
                        pointerEvents: 'none',
                        color: '#ed1153',
                        fontSize: '15px',
                        lineHeight: '0',
                      }
                }
              >
                {params.row.name}
              </div>
            </span>
          </span>
        </div>
      ),
      renderHeader: () => (
        <div style={{ textAlign: 'center' }}>
          <strong>BACKERS</strong>
        </div>
      ),
    },
    {
      field: 'email',
      headerName: 'EMAIL ADDRESS',
      width: 400,
      renderCell: (params) => (
        <div style={{ fontSize: '14px', lineHeight: '1.79', color: '#000' }}>
          {params.row.email}
        </div>
      ),
      renderHeader: () => (
        <div style={{ textAlign: 'center' }}>
          <strong>EMAIL ADDRESS</strong>
        </div>
      ),
    },
    {
      field: 'balance_amount',
      headerName: 'BALANCE CAPITAL',
      width: 250,
      renderCell: (params) => (
        <div style={{ fontSize: '14px', lineHeight: '1.79', color: '#000' }}>
          ₹ {rupeeFormatter.format(params.row.balance_amount)}
        </div>
      ),
      renderHeader: () => (
        <div style={{ textAlign: 'center' }}>
          <strong>BALANCE CAPITAL</strong>
        </div>
      ),
    },
    {
      field: '',
      headerName: 'ALLOTED AMOUNT',
      width: 250,
      renderCell: (params) => {
        const amount =
          Math.round(params.row.precommit_amount * percentage) / 100;
        return (
          <div style={{ textAlign: 'center', display: 'flex' }}>
            <span style={{ fontSize: '16px', alignSelf: 'center' }}>₹</span>
            <input
              className="commonFont backerAlocation"
              style={
                isZeno &&
                !params.row.balance_amount <= 0 &&
                params.row.balance_amount >=
                  (params.row.precommit_amount * percentage) / 100
                  ? { color: '#000' }
                  : { color: '#ed1153', border: 'solid 1px #ed1153' }
              }
              type="number"
              disabled={isZeno}
              id={`commit_${params.id}`}
              value={amount}
              min="100000"
              step="1000"
            />
          </div>
        );
      },
      renderHeader: () => (
        <div style={{ textAlign: 'center' }}>
          <strong>ALLOTTED AMOUNT</strong>
        </div>
      ),
    },
  ];

  const submitCommitments = () => {
    if (isError) {
      document.getElementById('submitBtn').style.disabled = true;
      return;
    }
    document.getElementById('errorMssage').style.display = 'none';
    let isAllEligible = true;
    if (backersList.length > 0) {
      isZeno &&
        backersList.forEach((backer) => {
          const commitValue = document.getElementById(
            `commit_${backer.id}`
          ).value;
          if (
            backer.balance_amount <= 0 ||
            backer.balance_amount < parseInt(commitValue, 10)
          ) {
            isAllEligible = false;
          }
        });

      if (isAllEligible) {
        setSubmitLoader(true);
        const backersArr = [];
        backersList.forEach((backer) => {
          const commitValue = document.getElementById(
            `commit_${backer.id}`
          ).value;

          if (isZeno) {
            backersArr.push({
              id: backer.id,
              investor_id: backer.investor_id,
              proposed_amount: commitValue,
            });
          } else if (!isZeno && commitValue !== '') {
            backersArr.push({
              id: backer.id,
              investor_id: backer.investor_id,
              proposed_amount: commitValue,
            });
          }
        });

        callApi(
          `/api/v3/web/syndicates/syndicate/${syndicatePermalink}/add_precommit_syndicate_startup_commitment`,
          httpMethods.HTTP_POST,
          {
            startup_id: startupId,
            backer_list: backersArr,
          }
        )
          .then((response) => {
            if (response.status === 200) {
              setSubmitLoader(false);
              showAllocation(false);
              setPublish(true);
              loadDealData();
            } else {
              alert('Something Went wrong.');
            }
          })
          .catch((error) => {
            alert('Something Went wrong.');
          });
      } else {
        document.getElementById('errorMssage').style.display = 'block';
        setErrorMsg(
          'A few backers do not have sufficient balance capital. Please recheck.'
        );
      }
    } else {
      document.getElementById('errorMssage').style.display = 'block';
      setErrorMsg('There are no eligible backers present for commitment.');
    }
  };

  function valuetext(value) {
    return `${value}%`;
  }

  const getAllocationPageDetails = () => {
    const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
    const url = `${djangoBaseUrl}/v1/syndicates/get_allocation_page_details/?id=${syndicateId}&startup_id=${startupId}`;
    callApi(
      url,
      httpMethods.HTTP_GET,
      '',
      {},
      false
    )
      .then((response) => {
        setOverallData(response.data.data.overall);
        setBackersList(response.data.data.backers_details);
        const amount = response.data.data.overall.lv_allocated_ask;
        document.getElementById('allotted_amount').value =
          response.data.data.backers_details.length > 0
            ? `₹${` ${rupeeFormatter.format(amount)}`}`
            : 0;
        const percent =
          Math.round(
            (amount * 1000) / response.data.data.overall.total_corpus
          ) / 10;
        setPercentage(percent);
        response.data.data.overall.syndicate_type === 'zeno'
          ? setIsZeno(true)
          : setIsZeno(false);
      })
      .catch((error) => {
        alert('Something Went wrong.');
      });
  };

  useEffect(() => {
    getAllocationPageDetails();
  }, []);

  return (
    <div style={{ marginTop: '200px', height: '100vh', width: '100%' }}>
      <Modal
        title="Enter One-Time Password"
        show
        ariaLabel="otpLabel"
        width="100%"
      >
        <div
          style={{
            width: '100%',
            backgroundColor: 'white',
            borderRadius: '10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              // padding: '20px 30px',
              height: '120px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '21px 0px 21px 43px',
                flex: 3,
              }}
            >
              <Avatar
                style={{ marginBottom: '14px' }}
                alt={!!overallData && overallData.startup_name}
                src={!!overallData && overallData.logo && overallData.logo.url}
              />
              <Typography
                sx={{
                  height: '28px',
                  margin: '9px 55px 0 0',
                  fontWeight: 'bold',
                  fontSize: '20px !important',
                  fontFamily: 'Inter',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: '0.9',
                  letterSpacing: 'normal',
                  textAlign: 'left',
                }}
              >
                {!!overallData && overallData.startup_name}
              </Typography>
            </div>
            <div className="partition" />
            <div className="commonFont" style={{ flex: 4, marginLeft: '2%' }}>
              <Typography sx={{ padding: '10px', fontSize: '20px !important' }}>
                Available Funds
              </Typography>
              <Typography
                sx={{
                  padding: '0px 10px',
                  fontWeight: 'bold',
                  fontSize: '20px !important',
                  fontFamily: 'Inter',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: '0.9',
                  letterSpacing: 'normal',
                  textAlign: 'left',
                }}
              >
                ₹{' '}
                {rupeeFormatter.format(
                  !!overallData && overallData.total_available_funds
                )}
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 13,
                background: 'rgb(55 201 238 / 40%)',
                padding: '10px 2% 10px 4%',
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                  sx={{ padding: '10px', fontSize: '20px !important' }}
                >
                  Allocate Funds
                </Typography>
                <input
                  className="commonFont allocationBox"
                  id="allotted_amount"
                  onChange={handleAllottedAmount}
                  style={{ fontSize: '20px' }}
                  disabled
                />
              </div>
              <Box>
                <Slider
                  classes={{
                    root: classes.root,
                    thumb: classes.sliderThumb,
                    rail: classes.sliderRail,
                    mark: classes.mark,
                  }}
                  aria-label="_percetage"
                  aria-labelledby="_percentage_id"
                  defaultValue={
                    !!overallData &&
                    Math.round(
                      (overallData.total_corpus /
                        overallData.lv_allocated_ask) *
                        1000
                    ) / 10
                  }
                  value={percentage}
                  getAriaValueText={valuetext}
                  valueLabelDisplay="on"
                  step={0.1}
                  marks
                  min={0}
                  max={100.0}
                  onChange={handlePercentageCommit}
                  id="percentage_slider"
                />
              </Box>
            </div>
          </div>
          <div className="divider" />

          <div>
            <div
              style={{
                fontSize: '22px',
                fontWeight: '500',
                fontFamily: 'inter',
                padding: '10px 30px',
                backgroundColor: 'rgb(217, 217, 217, 0.3)',
              }}
            >
              <Typography
                sx={{
                  padding: '10px',
                  fontSize: '20px !important',
                  fontWeight: 'bold',
                }}
              >
                Backers &nbsp;| &nbsp;
                {backersList.length}
              </Typography>
            </div>
          </div>
          {backersList && backersList.length > 0 && (
            <DataGrid
              className="container"
              style={{ fontSize: '14px', maxWidth: '100%' }}
              rows={backersList}
              columns={columns}
              // rowCount={backersList && backersList.length}
              hideFooter
              autoHeight
            />
          )}
        </div>
        <div
          style={{
            bottom: '50px',
            padding: '100px 0 50px 10%',
          }}
        >
          {isZeno && (
            <div
              id="errorMssage"
              style={{ display: 'none', marginBottom: '10px', width: '40%' }}
            >
              <Alert severity="error">{errorMsg}</Alert>
            </div>
          )}

          <button
            id="submitBtn"
            type="button"
            onClick={submitCommitments}
            className="btn btn-outline-primary commonFont actionButton"
            style={
              submitLoader
                ? {
                    background: '#0099ff',
                    color: '#ffffff',
                    width: '10%',
                    // padding: '5px 10px 15px 10px',
                  }
                : {
                    background: '#0099ff',
                    color: '#ffffff',
                    width: '10%',
                    // paddingLeft: '35px',
                  }
            }
            disabled={submitLoader || isError}
          >
            {submitLoader ? (
              <div
                style={{ padding: '0 0 5% 15%' }}
                className="d-flex align-items-center commonFont actionButton"
              >
                Submitting...
                {/* <Oval
                  height="25"
                  width="25"
                  radius="9"
                  color="#0099ff"
                  style={{ marginLeft: '-50px' }}
                /> */}
              </div>
            ) : (
              'Submit'
            )}
          </button>
          <button
            type="button"
            onClick={() => showAllocation(false)}
            className="btn btn-outline-primary commonFont actionButton"
            style={{
              color: '#0099ff',
              background: '#ffffff',
              marginLeft: '10px',
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default allocationPage;
