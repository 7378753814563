import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import React from 'react';
import renderHTML from 'react-render-html';

function Select(props) {
  const {
    name,
    options,
    multi,
    placeholder,
    labeltitle,
    ismandatory,
    errors,
    touched,
    disabled,
    notes,
    setDisableSaveDraftButton,
    smallerText
  } = props;

  const { handleChange, setFieldValue } = useFormikContext();

  function ErrorView({ errorText, top }) {
    return (
      <div className="text-danger" style={{ marginTop: '0.3rem', fontSize: 14 }}>
        {errorText}
      </div>
    );
  }

  const manualhandleChange = (e) => {
    handleChange(e);
    setFieldValue(e.target.name, e.target.value);
    if (e.target.name === 'revenue_check' && e.target.value === 'true') {
      setFieldValue(e.target.name, '');
      alert(
        'LetsVenture can only invest in SEBI defined startups:\n\nRevenue: never exceeded INR 100 Cr in any 12 month period\n\nDate of Registration: less than 10 years ago'
      );

      setDisableSaveDraftButton && setDisableSaveDraftButton(true);
    } else if (e.target.name === 'startup_existence' && e.target.value === 'false') {
      setFieldValue(e.target.name, '');
      alert(
        'LetsVenture can only invest in SEBI defined startups:\n\nRevenue: never exceeded INR 100 Cr in any 12 month period\n\nDate of Registration: less than 10 years ago'
      );
      setDisableSaveDraftButton && setDisableSaveDraftButton(true);
    } else if (e.target.name === 'startup_transaction' && e.target.value === 'false') {
      setFieldValue(e.target.name, '');
      alert('Not eligible to raise funds ');
      setDisableSaveDraftButton && setDisableSaveDraftButton(true);
    } else if (e.target.name === 'startup_registered_india' && e.target.value === 'false') {
      setFieldValue(e.target.name, '');
      alert(
        '\nTo invest in a company registered outside India, please do not fill in this form \n\nPlease connect with your contact at LetsVenture.'
      );
      setDisableSaveDraftButton && setDisableSaveDraftButton(true);
    } else if (e.target.name === 'revenue_check') {
      setDisableSaveDraftButton && setDisableSaveDraftButton(false);
    }
  };

  return (
    <div className="edit-form-row">
      {labeltitle && (
        <label className={classNames('edit__profile_label-title')} style={smallerText ? { fontSize: '16px' } : null}>
          {labeltitle} {ismandatory ? <span className="text-mandatory">*</span> : ''}
          {props.tooltipTitle && (
            <Tooltip title={props.tooltipTitle}>
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          )}
        </label>
      )}
      <div className="edit__profile_form-item" style={smallerText ? { fontSize: '14px' } : null}>
        <Field
          as="select"
          name={name}
          key={name}
          disabled={disabled}
          multi={multi}
          placeholder={placeholder}
          onChange={manualhandleChange}
        >
          {options.map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </Field>
        {errors ? (
          <>{ismandatory && errors[name] && touched[name] && <ErrorView errorText={errors[name]} />}</>
        ) : (
          <ErrorMessage name={name} render={(msg) => <ErrorView errorText={msg} top={props.top} />} />
        )}
      </div>
      {notes && <div className="edit_profile_notes"> {renderHTML(notes)} </div>}
    </div>
  );
}

export default Select;
