import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { useHistory } from 'react-router-dom';
import PostHogManager from '../../analytics/postHogManager';
import callApi from '../../api';
import { httpMethods } from '../../constants';
import { getUser, removeUserSession } from '../utils/common';

import Icon from '../icons/Icon';

function SettingsMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userDetails = getUser();
  const history = useHistory();
  const syndicateType = localStorage.getItem('syndicate_type');
  const { logout } = useAuth0();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const loginRouteHandler = () => {
    removeUserSession();
    window.location.href = '/';
  };

  const handleRoute = (routeName) => {
    setAnchorEl(null);
    switch (routeName) {
      case 'syndicateSettings':
        PostHogManager.trackEvent('Clicked on Syndicate Settings', {
          source: 'Navbar Menu',
          description: 'Clicked on Syndicate Settings',
        });
        return history.push('/auth/syndicate_settings');
      case 'zenoProfile':
        PostHogManager.trackEvent('Clicked on Zeno Profile', {
          source: 'Navbar Menu',
          description: 'Clicked on Zeno Profile',
        });
        return history.push('/zeno_profile');
      case 'zenoCorpus':
        PostHogManager.trackEvent('Clicked on Zeno Profile', {
          source: 'Navbar Menu',
          description: 'Clicked on Zeno Profile',
        });
        return history.push('/zeno_corpus');
      case 'userManagement':
        PostHogManager.trackEvent('Clicked on Syndicate Admin Management', {
          source: 'Navbar Menu',
          description: 'Clicked on Syndicate Admin Management',
        });
        return history.push('/syndicate_admin_management');
      default:
        return null;
    }
  };

  const logMeOut = async () => {
    PostHogManager.trackEvent('Logout Triggered', {
      source: 'Navbar Menu',
      description: 'Logout Triggered',
    });
    const a = await callApi(
      '/signout-glasdoor?error=200&source=from_web',
      httpMethods.HTTP_GET
    ).then((res) => {
      if (res.data.success === true) {
        logout({
          logoutParams: {
            returnTo: `${window.location.origin}`,
          },
        });
        PostHogManager.trackEvent('Logout Successfull', {
          source: 'Navbar Menu',
          description: 'Logout Successfull',
        });
        loginRouteHandler();
      } else {
        PostHogManager.trackEvent('Logout Failed', {
          source: 'Navbar Menu',
          description: 'Logout Failed',
        });
      }
    });
  };
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <img
          src={
            userDetails && userDetails.user_pic && userDetails.user_pic.location
          }
          // src=""
          width={32}
          height={32}
          alt="User"
          style={{
            border: '1px solid',
            borderRadius: 50,
            borderColor: 'black',
          }}
        />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => handleRoute('userManagement')}
          style={{ fontSize: '13px' }}
        >
          <Icon
            name="userManagement"
            width={13}
            height={13}
            style={{ marginRight: '10px' }}
          />
          Syndicate Admin Management
        </MenuItem>
        {syndicateType == 'zeno' && (
          <MenuItem
            onClick={() => handleRoute('zenoProfile')}
            style={{ fontSize: '13px' }}
          >
            <Icon
              name="userManagement"
              width={13}
              height={13}
              style={{ marginRight: '10px' }}
            />
            Zeno Profile
          </MenuItem>
        )}
        {syndicateType == 'zeno' && (
          <MenuItem
            onClick={() => handleRoute('zenoCorpus')}
            style={{ fontSize: '13px' }}
          >
            <Icon
              name="userManagement"
              width={13}
              height={13}
              style={{ marginRight: '10px' }}
            />
            Zeno Corpus
          </MenuItem>
        )}
        <MenuItem
          onClick={() => handleRoute('syndicateSettings')}
          style={{ fontSize: '13px' }}
        >
          <Icon
            name="changePassword"
            width={13}
            height={13}
            style={{ marginRight: '10px' }}
          />
          Syndicate Settings
        </MenuItem>
        <MenuItem onClick={logMeOut} style={{ fontSize: '13px' }}>
          <Icon
            name="logout"
            width={13}
            height={13}
            style={{ marginRight: '10px' }}
          />
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}

export default SettingsMenu;
