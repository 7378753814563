import { makeStyles } from '@material-ui/core';
import { Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Oval } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import TextArea from '../common/formik/components/TextArea';
import TextInput from '../common/formik/components/TextInput';
import { getSyndicateId, getToken } from '../common/utils/common';

import PostHogManager from '../analytics/postHogManager';
import callApi from '../api';
import Icon from '../common/icons/Icon';
import { httpMethods } from '../constants';

const useStyles = makeStyles({
  photo: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'flex-start'
  },
  changePhoto: {
    width: '120px',
    padding: '5px 0',
    position: 'relative',
    cursor: 'pointer',
    color: 'blue',
    '& input': {
      opacity: 0,
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      cursor: 'pointer'
    }
  },
  coverImgae: {
    width: '40%',
    height: '195px',
    margin: '26px 75px 58px 2px',
    // padding: "72px 0 71px",
    borderRadius: '3px'
  },
  syn_input_box: {
    padding: '4px 0',
    width: '75%'
  }
});

const zenoProfile = (props) => {
  const classes = useStyles();

  const [synDetails, setSynDetails] = useState();
  const [profileImage, setProfileImage] = useState();
  const [synProfileImage, setSynProfileImage] = useState();
  const [syndicateProfileImage, setSyndicateProfileImage] = useState('');
  // const [profileImageError, setProfileImageError] = useState(false);
  const [savingSyndicateDetail, setSavingSyndicateDetail] = useState(false);
  const [coverImage, setCoverImage] = useState();
  const [synCoverImage, setSynCoverImage] = useState();
  const [syndicateCoverImage, setSyndicateCoverImage] = useState('');
  // const [profileImageError, setProfileImageError] = useState(false);

  const changePhoto = (event) => {
    setSyndicateProfileImage(event.currentTarget.files[0]);
    // setSyndicateProfileError(false);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setProfileImage(reader.result);
      }
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const changeCoverPhoto = (event) => {
    setSyndicateCoverImage(event.currentTarget.files[0]);
    // setSyndicateProfileError(false);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      if (fileReader.readyState === 2) {
        setCoverImage(fileReader.result);
      }
    };
    fileReader.readAsDataURL(event.target.files[0]);
  };

  const validateYouTubeUrl = (youtubeUrl) => {
    if (
      youtubeUrl.length > 0 &&
      ((youtubeUrl.match('youtube.com') && youtubeUrl.match(/.*.v=([a-zA-Z0-9_-]{11})/)) ||
        (youtubeUrl.match('youtu.be') && youtubeUrl.substr(-12, 1) === '/'))
    ) {
      return true;
    }
    if (youtubeUrl.length === 0) {
      return true;
    }
    return false;
  };

  const getId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && match[2].length === 11) {
      return match[2];
    }
    return '';
  };

  const validationSchema = Yup.object({
    syn_name: Yup.string().required('Required'),
    syn_header: Yup.string().required('Required'),
    // about: Yup.string().required('Required'),
    thesis: Yup.string().required('Required'),
    pitch_video_url: Yup.string()
      .default('')
      .test('validateUrl', 'Enter a valid Youtube url.', (value) => validateYouTubeUrl(value))
  });

  const onSubmit = async (values) => {
    setSavingSyndicateDetail(true);
    const syndicateId = getSyndicateId();
    const token = getToken();
    const option = {
      style: { color: '#fff' }
    };
    const formData = new FormData();
    formData.append('syn_name', values.syn_name);
    formData.append('syn_header', values.syn_header);
    // formData.append('about', values.about);
    formData.append('thesis', values.thesis);
    formData.append('pitch_video_url', values.pitch_video_url);
    if (syndicateProfileImage != null && syndicateProfileImage !== '') {
      formData.append('syn_profile_image', syndicateProfileImage);
    } else {
      formData.append('syn_profile_image', synProfileImage);
    }
    if (syndicateCoverImage != null && syndicateCoverImage !== '') {
      formData.append('syn_cover_image', syndicateCoverImage);
    } else {
      formData.append('syn_cover_image', synCoverImage);
    }

    PostHogManager.trackEvent('Zeno Profile Update Submitted', {
      syndicateId,
      source: 'Zeno Profile Page',
      description: 'Zeno Profile Update Submitted',
      submitted_data: values
    });
    const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
    const url = `${djangoBaseUrl}/v1/syndicates/update_syndicate_profile_part/?id=${syndicateId}`;
    callApi(
      url,
      httpMethods.HTTP_POST,
      formData,
      {},
      false
    )
      .then((response) => {
        PostHogManager.trackEvent('Zeno Profile Update Completed - Success', {
          syndicateId,
          source: 'Zeno Profile Page',
          description: 'Zeno Profile Update Successfull',
          response_data: response
        });
        setSavingSyndicateDetail(false);
        toast.success('Zeno Profile successfully created', option);
        window.location.reload();
      })
      .catch((error) => {
        PostHogManager.trackEvent('Zeno Profile Update Completed - Failed', {
          syndicateId,
          source: 'Zeno Profile Page',
          description: 'Zeno Profile Update Failed',
          response_data: error
        });
        toast.error(error.message, option);
        setSavingSyndicateDetail(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const syndicateId = getSyndicateId();
    const token = getToken();
    async function init() {
      if (syndicateId) {
        const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
        const url = `${djangoBaseUrl}/v1/syndicates/get_syndicate_profile_part/?id=${syndicateId}`;
        callApi(
          url, 
          httpMethods.HTTP_GET,
          '',
          {},
          false
        )
          .then((response) => {
            setSynDetails(response.data.results);
            // setSynProfileImage('response.data.syndicate_profile_img.url');
            setSynProfileImage(response.data.results.syndicate_profile_img);
            setSynCoverImage(response.data.results.syndicate_cover_image);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    init();
  }, []);

  return (
    <div>
      <div
        style={{
          marginBottom: 50,
          marginTop: 70,
          padding: '15px 0',
          maxWidth: '90%'
        }}
        className="container"
      >
        <Typography
          style={{
            fontSize: '18px'
          }}
          className="pt-4 pb-3"
        >
          <span style={{ marginRight: '10px' }}>
            <Icon name="newspapericon" width="32" height="32" />
          </span>
          Zeno Profile
        </Typography>

        <div style={{ backgroundColor: '#ffffff' }}>
          <div style={{ padding: '4% 0 2% 4%' }}>
            <div className={classes.photo}>
              <div
                style={{
                  height: '100%',
                  width: 'fit-content'
                  // border: "1px solid grey",
                }}
              >
                <img
                  src={profileImage || synProfileImage || syndicateProfileImage}
                  alt=""
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: 50,
                    border: 'solid 1px #b6b6b6',
                    objectFit: 'cover'
                  }}
                />
              </div>
              <div style={{ marginTop: '10px', marginLeft: '25px' }}>
                <Typography style={{ fontWeight: 'bold' }}>Profile Image</Typography>
                <div className={classes.changePhoto} style={{ cursor: 'pointer' }}>
                  <Icon name="edit" width={16} height={16} style={{ marginRight: '10px' }} />
                  <input
                    type="file"
                    value=""
                    onChange={(event) => changePhoto(event)}
                    accept=".jpg, .jpeg, .png"
                    style={{ padding: '20px', marginLeft: '-10px' }}
                  />
                </div>
              </div>
              {/* {profileImageError && (
              <span className="text-danger">Profile picture is mandatory</span>
              )} */}
            </div>

            <div className={classes.photo}>
              <div
                className={classes.coverImgae}
                style={{
                  height: '100%',
                  width: 'fit-content'
                  // border: '1px solid grey',
                }}
              >
                <img
                  src={coverImage || synCoverImage || syndicateCoverImage}
                  alt=""
                  style={{
                    width: 500,
                    height: 200,
                    // borderRadius: 50,
                    border: 'solid 1px #b6b6b6',
                    objectFit: 'cover'
                  }}
                />
              </div>
              <div style={{ marginTop: '40px', marginLeft: '0px' }}>
                <Typography style={{ fontWeight: 'bold' }}>Change Cover Image</Typography>
                <div className={classes.changePhoto} style={{ cursor: 'pointer' }}>
                  <Icon name="edit" width={16} height={16} style={{ marginRight: '10px' }} />
                  <input
                    type="file"
                    value=""
                    onChange={(event) => changeCoverPhoto(event)}
                    accept=".jpg, .jpeg, .png"
                    style={{ padding: '20px', marginLeft: '-10px' }}
                  />
                </div>
              </div>
              {/* {profileImageError && (
            <span className="text-danger">Profile picture is mandatory</span>
          )} */}
            </div>

            <Formik
              initialValues={{
                syn_name: (synDetails && synDetails.syndicate_name) || '',
                syn_header: (synDetails && synDetails.header) || '',
                // about: (synDetails && synDetails.description) || '',
                thesis: (synDetails && synDetails.investment_thesis) || '',
                pitch_video_url: (synDetails && synDetails.syndicate_pitch_video) || ''
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {({ touched, values, setFieldValue }) => (
                <Form>
                  <div className={classes.syn_input_box}>
                    <TextInput
                      labeltitle="Name"
                      name="syn_name"
                      placeholder="Enter Syndicate Name"
                      // value={values.syn_name}
                      ismandatory
                      popoverTitle="Name info"
                      popoverShow
                      popoverBody="This is just infomation text"
                      tooltipTitle="Please enter a unique name"
                    />
                  </div>
                  <div className={classes.syn_input_box}>
                    <TextInput
                      labeltitle="Header"
                      name="syn_header"
                      placeholder="Enter a 1-line description"
                      // value={values.syn_header}
                      ismandatory
                    />
                  </div>
                  {/* <div className={classes.syn_input_box}>
                    <TextArea
                      labeltitle="About"
                      labelName="about"
                      ismandatory
                      // value={values.about}
                      placeholder="Enter a few details about your syndicate"
                      size="200"
                    />
                  </div> */}
                  <div className={classes.syn_input_box}>
                    <TextArea
                      labeltitle="Investment thesis"
                      labelName="thesis"
                      ismandatory
                      // value={values.thesis}
                      placeholder="Enter Investment Thesis"
                      size="200"
                    />
                  </div>
                  <div className={classes.syn_input_box}>
                    <TextInput
                      labeltitle="Pitch Video"
                      name="pitch_video_url"
                      placeholder="www.youtube.com/abcdefgh"
                      // value={values.syn_header}
                      ismandatory={false}
                      top="0"
                    />
                  </div>

                  {!!values.pitch_video_url && (
                    <div className="mt-5" style={{ display: 'flex', flexDirection: 'column' }}>
                      <div className="player_header">
                        <p className="left fw-600">Preview Pitch Video</p>
                      </div>
                      <iframe
                        className="youtube_player"
                        src={`//www.youtube.com/embed/${getId(values.pitch_video_url)}`}
                        frameBorder="0"
                        // gesture="media"
                        width="55%"
                        height="250px"
                        allowFullScreen
                      />
                    </div>
                  )}
                  <div className="pt-2 pb-4">
                    <Button
                      variant="primary"
                      size="md"
                      type="submit"
                      disabled={savingSyndicateDetail}
                      style={{
                        background: '#09f',
                        borderRadius: '3px',
                        fontSize: 14,
                        width: '10%',
                        fontWeight: 'bold',
                        marginTop: '80px'
                      }}
                    >
                      {savingSyndicateDetail ? (
                        <div className="d-flex align-items-center">
                          Saving...
                          <Oval height="25" width="25" radius="9" color="#0099ff" />
                        </div>
                      ) : (
                        'Submit'
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default zenoProfile;
