import { makeStyles } from '@material-ui/core';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button } from 'react-bootstrap';
import { Oval } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import TextArea from '../common/formik/components/TextArea';
import TextInput from '../common/formik/components/TextInput';
import { getSyndicateId, getSyndicatePermalink, getToken } from '../common/utils/common';

import PostHogManager from '../analytics/postHogManager';
import callApi from '../api';
import Icon from '../common/icons/Icon';
import { httpMethods } from '../constants';

const useStyles = makeStyles({
  photo: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'flex-start'
  },
  changePhoto: {
    width: '120px',
    padding: '5px 0',
    position: 'relative',
    cursor: 'pointer',
    color: 'blue',
    '& input': {
      opacity: 0,
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      cursor: 'pointer'
    }
  },
  coverImgae: {
    width: '40%',
    height: '195px',
    margin: '26px 75px 58px 2px',
    // padding: "72px 0 71px",
    borderRadius: '3px'
  },
  syn_input_box: {
    padding: '4px 0',
    width: '75%'
  }
});

export default function SyndicateProfile(props) {
  const classes = useStyles();

  const [synDetails, setsSynDetails] = useState();
  const [profileImage, setProfileImage] = useState();
  const [synProfileImage, setSynProfileImage] = useState();
  const [syndicateProfileImage, setSyndicateProfileImage] = useState('');
  // const [profileImageError, setProfileImageError] = useState(false);
  const [state, setState] = useState({
    preview: null,
    src: null
  });
  const [savingSyndicateDetail, setSavingSyndicateDetail] = useState(false);
  // const [coverImg, setCoverImg] = useState("");
  // const [syndicateCoverImage, setSyndicateCoverImage] = useState("");
  // const [profileImageError, setProfileImageError] = useState(false);
  // const [coverState, setCoverState] = useState({
  //   preview: null,
  //   src: null,
  // });

  // const syndicateTypes = [
  //   { label: "Select Syndicate Type" },
  //   { label: "Ideation", value: 1 },
  //   { label: "Proof of Concept", value: 2 },
  //   { label: "Beta Launched", value: 3 },
  //   { label: "Early Revenues", value: 4 },
  //   { label: "Steady Revenues", value: 5 },
  // ];

  const changePhoto = (event) => {
    setSyndicateProfileImage(event.currentTarget.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setProfileImage(reader.result);
      }
    };
    reader.readAsDataURL(event.target.files[0]);
    // setPhotoChanged(true)
  };

  // const changeCoverPhoto = (event) => {
  //   setSyndicateCoverImage(event.currentTarget.files[0]);
  //   // setSyndicateProfileError(false);
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     if (reader.readyState === 2) {
  //       setCoverImg(reader.result);
  //     }
  //   };
  //   reader.readAsDataURL(event.target.files[0]);
  //   // setPhotoChanged(true)
  // };

  const validationSchema = Yup.object({
    syn_name: Yup.string().required('Required'),
    syn_header: Yup.string().required('Required'),
    about: Yup.string().required('Required'),
    thesis: Yup.string().required('Required'),
    deal_per_yr: Yup.number().required('Required').positive().min(1, 'Must be more than 0'),
    // syn_type: Yup.string().required("Required"),
    min_investment: Yup.number().required('Required').positive().min(25, 'Must be more than or equal to 25 Lakhs'),
    max_investment: Yup.number().required('Required').positive().max(1000, 'Must be less than or equal to 10 Cr')
  });

  const onSubmit = async (values) => {
    setSavingSyndicateDetail(true);
    const syndicate_id = getSyndicateId();
    const syndicate_permalink = getSyndicatePermalink();
    const token = getToken();
    const option = {
      style: { color: '#fff' }
    };
    // alert(JSON.stringify(values));
    const formData = new FormData();
    formData.append('from_glassboard', true);
    formData.append('syn_name', values.syn_name);
    formData.append('syn_header', values.syn_header);
    formData.append('about', values.about);
    formData.append('thesis', values.thesis);
    formData.append('deal_per_yr', values.deal_per_yr);
    // formData.append("syn_type", values.syn_type);
    formData.append('min_investment', values.min_investment);
    formData.append('max_investment', values.max_investment);
    if (syndicateProfileImage != null && syndicateProfileImage !== '') {
      formData.append('syn_profile_image', syndicateProfileImage);
    } else {
      formData.append('syn_profile_image', synProfileImage);
    }
    // if (syndicateCoverImage != null && syndicateCoverImage !== "") {
    //   formData.append(
    //     "syn_cover_image",
    //     syndicateCoverImage
    //     // syndicateCoverImage.name
    //   );
    // }
    PostHogManager.trackEvent('Syndicate Profile Update Submitted', {
      syndicate_id,
      source: 'Syndicate Profile Page',
      description: 'Syndicate Profile Update Submitted',
      submitted_data: values
    });
    const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
    const url = `${djangoBaseUrl}/v1/syndicates/create_or_update_syndicate/?permalink=${syndicate_permalink}&data_source=glassboard`;
    callApi(url, httpMethods.HTTP_PUT, formData, {}, false)
      .then((response) => {
        PostHogManager.trackEvent('Syndicate Profile Update Completed - Success', {
          syndicate_id,
          source: 'Syndicate Profile Page',
          description: 'Syndicate Profile Update Successfull',
          response_data: response
        });
        setSavingSyndicateDetail(false);
        toast.success('Syndicate Profile successfully created', option);
        window.location.reload();
      })
      .catch((error) => {
        PostHogManager.trackEvent('Syndicate Profile Update Completed - Failed', {
          syndicate_id,
          source: 'Syndicate Profile Page',
          description: 'Syndicate Profile Update Failed',
          response_data: error
        });
        toast.error(error.message, option);
        setSavingSyndicateDetail(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const syndicate_id = getSyndicateId();
    const token = getToken();
    async function init() {
      if (!!syndicate_id) {
        PostHogManager.trackEvent('Fetch Syndicate Detail Submitted', {
          syndicate_id,
          source: 'Syndicate Profile Page',
          description: 'Fetch Syndicate Detail Submitted',
          submitted_data: syndicate_id
        });
        const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
        const url = `${djangoBaseUrl}/v1/syndicates/get_about_syndicate/?id=${syndicate_id}`;
        callApi(
          url, 
          httpMethods.HTTP_GET,
          '',
          {},
          false
        )
          .then((response) => {
            PostHogManager.trackEvent('Fetch Syndicate Detail Completed - Success', {
              syndicate_id,
              source: 'Syndicate Profile Page',
              description: 'Fetch Syndicate Detail Successfull',
              response_data: response
            });
            setsSynDetails(response.data.results);
            setSynProfileImage(response.data.results.profile_image);
          })
          .catch((error) => {
            PostHogManager.trackEvent('Fetch Syndicate Detail Completed - Failed', {
              syndicate_id,
              source: 'Syndicate Profile Page',
              description: 'Fetch Syndicate Detail Failed',
              response_data: error
            });
            console.log(error);
          });
      }
    }
    init();
  }, []);

  return (
    <div>
      <div className="syndicate_profile" style={{ background: '#d9d9d9', padding: '15px 0', marginBottom: 30 }}>
        <div className="container">
          <Breadcrumb className="m-0">
            <Breadcrumb.Item href="/home" className="m-0">
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item active href="#" className="m-0">
              Syndicate Profile
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div
        style={{
          backgroundColor: '#f1f1f1',
          marginBottom: 50
        }}
        className="container"
      >
        <Typography
          style={{
            fontSize: '18px'
          }}
          className="pt-4 pb-3"
        >
          <span style={{ marginRight: '10px' }}>
            <Icon name="newspapericon" width="32" height="32" />
          </span>
          Syndicate Profile
        </Typography>
        <div style={{ backgroundColor: '#ffffff' }}>
          <div style={{ padding: '1% 0 2% 4%' }}>
            <div className={classes.photo}>
              <div
                style={{
                  height: '100%',
                  width: 'fit-content'
                  // border: "1px solid grey",
                }}
              >
                <img
                  src={profileImage || synProfileImage || syndicateProfileImage}
                  alt=""
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: 50,
                    border: 'solid 1px #b6b6b6',
                    objectFit: 'cover'
                  }}
                />
              </div>
              <div style={{ marginTop: '10px', marginLeft: '25px' }}>
                <Typography style={{ fontWeight: 'bold' }}>Profile Image</Typography>
                <div className={classes.changePhoto} style={{ cursor: 'pointer' }}>
                  <Icon name="edit" width={16} height={16} style={{ marginRight: '10px' }} />
                  <input
                    type="file"
                    value=""
                    onChange={(event) => changePhoto(event)}
                    accept=".jpg, .jpeg, .png"
                    style={{ padding: '20px', marginLeft: '-10px' }}
                  />
                </div>
              </div>
              {/* {profileImageError && (
              <span className="text-danger">Profile picture is mandatory</span>
              )} */}
            </div>

            {/* <div className={classes.photo}>
              <div
                className={classes.coverImgae}
                style={{
                  // height: "100%",
                  // width: "fit-content",
                  border: "1px solid grey",
                }}
              >
                <img
                  src={
                    coverImg.length > 0
                      ? coverImg
                      : coverState.src
                      ? coverState.src
                      : props.prfile_img
                  }
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    // borderRadius: 50,
                    objectFit: "cover",
                  }}
                />
              </div>
              <div className={classes.changePhoto}>
                Change Cover Photo
                <input
                  type="file"
                  value=""
                  onChange={(event) => changeCoverPhoto(event)}
                  accept=".jpg, .jpeg, .png"
                />
              </div>
              {profileImageError && (
              <span className="text-danger">Profile picture is mandatory</span>
              )}
            </div> */}

            <Formik
              initialValues={{
                syn_name: (synDetails && synDetails.name) || '',
                syn_header: (synDetails && synDetails.header) || '',
                about: (synDetails && synDetails.description) || '',
                thesis: (synDetails && synDetails.investment_thesis) || '',
                deal_per_yr: (synDetails && synDetails.deal_per_year) || '',
                min_investment: (synDetails && synDetails.min_typical_investment) || '',
                max_investment: (synDetails && synDetails.max_typical_investment) || ''
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {({ touched, values, setFieldValue }) => (
                <Form>
                  <div className={classes.syn_input_box}>
                    <TextInput
                      labeltitle="Syndicate Name"
                      name="syn_name"
                      placeholder="Enter Syndicate Name"
                      // value={values.syn_name}
                      ismandatory
                      popoverTitle="Name info"
                      popoverShow={true}
                      popoverBody="This is just infomation text"
                      tooltipTitle="Please enter a unique name"
                    />
                  </div>
                  <div className={classes.syn_input_box}>
                    <TextInput
                      labeltitle="1-line description"
                      name="syn_header"
                      placeholder="Enter a 1-line description"
                      // value={values.syn_header}
                      ismandatory
                    />
                  </div>
                  <div className={classes.syn_input_box}>
                    <TextArea
                      labeltitle="About"
                      labelName="about"
                      ismandatory
                      // value={values.about}
                      placeholder="Enter a few details about your syndicate"
                      size="200"
                    />
                  </div>
                  <div className={classes.syn_input_box}>
                    <TextArea
                      labeltitle="Investment thesis"
                      labelName="thesis"
                      ismandatory
                      // value={values.thesis}
                      placeholder="Enter Investment Thesis"
                      size="200"
                    />
                  </div>
                  <div className={classes.syn_input_box}>
                    <label className={classNames('edit__profile_label-title')}>
                      Typical Investment Size
                      <span className="text-mandatory">*</span>
                    </label>
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: '20px' }}>
                        <TextInput
                          style={{ width: '100%' }}
                          textinfo="Minimum (In Lakhs)"
                          // labeltitle="Syndicate Typical Investment"
                          name="min_investment"
                          // value={values.min_investment}
                          type="number"
                          placeholder="Min"
                          notes="Min INR 25 Lakhs"
                        />
                      </div>
                      <TextInput
                        style={{ width: '100%' }}
                        textinfo="Maximum (In Lakhs)"
                        // labeltitle="Syndicate Typical Investment"
                        name="max_investment"
                        // value={values.max_investment}
                        type="number"
                        placeholder="Max"
                        // ismandatory
                        notes="Max INR 10 Crores"
                      />
                    </div>
                  </div>
                  <div className={classes.syn_input_box}>
                    <TextInput
                      labeltitle="Number of investments you expect to make in a year"
                      name="deal_per_yr"
                      // value={values.deal_per_yr}
                      placeholder="#"
                      type="number"
                      ismandatory
                      tooltipTitle="How many deals the syndicate is planning to bring in an year?"
                    />
                  </div>
                  {/* <div className={classes.syn_input_box}>
                    <Select
                      name="syn_type"
                      labeltitle="Syndicate Type"
                      placeholder="Select the syndicate type."
                      touched={touched}
                      options={syndicateTypes}
                      multi={false}
                      ismandatory
                    />
                  </div> */}
                  <div className="pt-2 pb-4">
                    <Button
                      variant="primary"
                      size="md"
                      type="submit"
                      style={{
                        background: '#09f',
                        borderRadius: '3px',
                        fontSize: 14
                      }}
                    >
                      {savingSyndicateDetail ? (
                        <div className="d-flex align-items-center">
                          Saving... <Oval height="25" width="25" radius="9" color="#0099ff" />
                        </div>
                      ) : (
                        'Submit'
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
