import Divider from '@mui/material/Divider';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import callApi from '../api';
import EnvelopeOpen from '../assests/image/EnvelopeOpen.png';
import '../assests/style/backerselectionmodal.scss';
import '../assests/style/common.scss';
import '../assests/style/deal_detail.scss';
import Modal from '../common/components/Modal';
import Icon from '../common/icons/Icon';
import { getSyndicatePermalink } from '../common/utils/common';
import ShareBackerListing from '../components/ShareBackerListing';
import { httpMethods } from '../constants';
import PostHogManager from '../analytics/postHogManager';

const option = {
  style: { color: '#fff' },
  autoClose: 7000
};

export const rupeeFormatter = new Intl.NumberFormat('en-IN', {
  currency: 'INR'
});

function CustomBackerModal(props) {
  const { backerList, adminList, isModify = false } = props;

  const unInvitedBackers = isModify ? [] : backerList.filter((backer) => backer.status === null);
  const [backersData, setBackersData] = useState(unInvitedBackers);
  const [modifyBackers, setModifyBackers] = useState([]);
  const [backersDataMap, setBackersDataMap] = useState(new Map());
  const [isFiltered, setIsFiltered] = useState(false);
  const syndicate_permalink = getSyndicatePermalink();

  const filteredDataSort = (a, b, targetLetter) => {
    const aFirstPosition = a.indexOf(targetLetter);
    const bFirstPosition = b.indexOf(targetLetter);
    // Compare the positions of the target letter in both strings
    if (aFirstPosition !== bFirstPosition) {
      return aFirstPosition - bFirstPosition;
    }
    // If the target letter is at the same position, sort alphabetically
    return a.localeCompare(b);
  };

  const filterBacker = (event) => {
    const searchText = event.currentTarget.value;
    if (searchText === '' || searchText === ' ') {
      setIsFiltered(false);
      setBackersData(isModify ? modifyBackers : unInvitedBackers);
    } else {
      setIsFiltered(true);
      const filteredData = backersData.filter(
        (item) =>
          item.name.toLowerCase().includes(searchText.toLowerCase()) ||
          item.email.toLowerCase().includes(searchText.toLowerCase())
      );
      filteredData.sort((a, b) =>
        filteredDataSort(a.name.toLowerCase(), b.name.toLowerCase(), searchText.toLowerCase())
      );
      setBackersData(filteredData);
    }
  };

  const getModifyBackersData = () => {
    const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
    const startupId = localStorage.getItem('deal_id');
    const url = `${djangoBaseUrl}/v1/syndicates/all_backer_syn_deal_commit_record/?permalink=${syndicate_permalink}&startup_id=${startupId}`;
    callApi(url, httpMethods.HTTP_GET, '', {}, false)
      .then((res) => {
        if (res.status === 200) {
          setBackersData(res.data.backers);
          setModifyBackers(res.data.backers);
        }
      })
      .catch((error) => {
        toast.error(error.message, option);
      });
  };

  const handleModifyBackersData = () => {
    const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
    const startupId = localStorage.getItem('deal_id');
    const url = `${djangoBaseUrl}/v1/syndicates/syndicate_deal_commitment_record/?permalink=${syndicate_permalink}&startup_id=${startupId}`;
    callApi(url, httpMethods.HTTP_POST, { backer_record: [...backersDataMap.values()] }, {}, false)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Backers data modified successfully.', option);
          props.handleCloseModal();
          setTimeout(window.location.reload(), 3000);
        }
      })
      .catch((error) => {
        toast.error(error.message, option);
      });
  };

  useEffect(() => {
    isModify && getModifyBackersData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="backer-selection-modal">
      <Modal
        title="Enter One-Time Password"
        show
        ariaLabel="otpLabel"
        className="scheme-modal"
        width="100%"
        // margin="2% 0 0 2%"
        height="100%"
        modalMargin="0"
      >
        <div className="modal-body" style={{ margin: '20px 15px 10px 30px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <img
                src={EnvelopeOpen}
                width={29}
                height={29}
                alt="Logo"
                style={{
                  margin: '2px 15px 10px 0px'
                }}
              />
              <h3
                className="font-weight-bold"
                style={{
                  fontSize: 20,
                  fontWeight: 'bold',
                  marginTop: '6px'
                }}
              >
                {isModify ? 'Modify the specified commitment values' : 'Share deal with uninvited backers'}
              </h3>
            </div>
            <div onClick={props.handleCloseModal}>
              <Icon name="close" width="32" height="32" style={{ cursor: 'pointer' }} />
            </div>
          </div>
          <div style={{ margin: '-3px 0px 10px 44px', fontSize: 13, width: '90%' }}>
            {isModify
              ? 'You can modify the min commitment value or the specified commitment value of the investors '
              : 'You can select who you wish to share the deal with. Only the backers who have not received an invitation to the deal yet will be displayed here.'}
          </div>
        </div>

        <Divider sx={{ borderColor: 'black' }} />
        <div style={{ marginTop: '24px' }}>
          <div className="col-sm-24">
            <div
              width="100%"
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '40%',
                  margin: '0px 0px 10px 5%'
                }}
              >
                <div className="ui icon input">
                  <input
                    type="text"
                    placeholder="Search Backers"
                    className="prompt"
                    onChange={(event) => filterBacker(event)}
                  />
                  <i className="search icon"></i>
                </div>
              </div>
              <div
                className="backer-list"
                style={
                  isModify
                    ? { height: '66vh', width: '100%', marginTop: '8px' }
                    : { height: '63vh', width: '100%', marginTop: '8px' }
                }
              >
                <ShareBackerListing
                  rows={backersData}
                  setBackersDataMap={setBackersDataMap}
                  backersDataMap={backersDataMap}
                  isShowColumns={isModify}
                  adminList={isModify ? adminList : []}
                  isModify={isModify}
                  isFiltered={isFiltered}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                  // position: 'relative',
                  // margin: '8% auto 2% auto',
                }}
              >
                <div style={isModify ? { margin: '1% auto 2% auto' } : { margin: '1% auto 2% auto' }}>
                  <div className="d-flex mt-3">
                    {!isModify ? (
                      <button
                        type="button"
                        onClick={() => {
                          if (backersDataMap.size > 0) {
                            props.handleShareWith(backersDataMap);
                            props.handleCloseModal();
                            PostHogManager.trackEvent('Share page submissions', {
                              source: 'Share Modal popup',
                              description: 'Whenever the user clicks on Share button, trigger an event '
                            });
                          } else {
                            alert('Please select backers ');
                          }
                        }}
                        className="btn btn-outline-primary"
                        style={{ background: '#0099ff', color: '#ffffff' }}
                      >
                        Share
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => {
                          if (backersDataMap.size > 0) {
                            handleModifyBackersData();
                          } else {
                            alert('Please select backers ');
                          }
                          PostHogManager.trackEvent('Modify page submissions', {
                            source: 'Modify Modal popup',
                            description: 'Whenever the user clicks on submit button in Modify modal, trigger an event '
                          });
                        }}
                        className="btn btn-outline-primary"
                        style={{ background: '#0099ff', color: '#ffffff' }}
                      >
                        Modify
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={() => {
                        props.handleCloseModal();
                      }}
                      style={{ marginLeft: '20px' }}
                      className="btn btn-outline-primary"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CustomBackerModal;
