import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Oval } from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../assests/style/backerselectionmodal.scss';
import '../assests/style/common.scss';
import '../assests/style/deal_detail.scss';

import callApi from '../api';
import Modal from '../common/components/Modal';
import Icon from '../common/icons/Icon';
import { getSyndicatePermalink } from '../common/utils/common';
import { httpMethods } from '../constants';

const useStyles = makeStyles({
  label: {
    fontSize: '16px !important'
    // fontWeight: '600 !important',
  },
  labelRoot: {
    fontSize: '18px !important',
    fontWeight: '600 !important',
    color: 'rgb(0 0 0 / 70%) !important',
    marginLeft: '-50px !important'
  }
});

function CommunicationModal({
  invId,
  startupId = '',
  communicationType,
  closeModal,
  invCommitId = '',
  selectedInvArr = [],
  communicationPurpose = '',
  resendDrawdownNotice = ''
}) {
  const syndicatePermalink = getSyndicatePermalink();
  const startupPermalink = localStorage.getItem('dealDetalilPermalink');
  const [mode, setMode] = useState('email');
  const [showWhatsapp, setShowWhatsapp] = useState(false);
  const [isEmailEligible, setIsEmailEligible] = useState(true);
  const [isWhatsappEligible, setIsWhatsappEligible] = useState(true);
  const [lastEmail, setLastEmail] = useState(null);
  const [lastWhatsapp, setLastWhatsapp] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [message, setMesssage] = useState('To preview, please select a communication channel.');
  const [submitting, setSubmitting] = useState(false);
  const [whatsappImg, setWhatsappImg] = useState('');
  const [emailImg, setEmailImg] = useState('');
  const classes = useStyles();
  const history = useHistory();
  const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;

  const handleChange = (event) => {
    if (event.target.value === 'whatsapp' && isWhatsappEligible) {
      setMode(event.target.value);
    } else if (event.target.value === 'whatsapp' && !isWhatsappEligible) {
      setMode(event.target.value);
      setMesssage(errorMsg);
      alert(errorMsg);
    }
    if (event.target.value === 'email' && isEmailEligible) {
      setMode(event.target.value);
    } else if (event.target.value === 'email' && !isEmailEligible) {
      setMode(event.target.value);
      setMesssage(errorMsg);
      alert(errorMsg);
    }
  };

  const handlePreviewNote = () => {
    let note = '';
    if (communicationPurpose == 'bulk_remind') {
      note = (
        <div>
          {communicationType === 'not_transfered'
            ? ' Some investors might not receive reminders if they have exhausted their maximum number of reminders.'
            : ' Some investors might not receive reminders if they have exhausted their maximum number of reminders.'}
        </div>
      );
    } else if (communicationPurpose == 'bulk_reinvite') {
      note = (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            textAlign: 'left',
            marginBottom: '4rem'
          }}
        >
          <li> Some investors might not receive reminders if they have exhausted their maximum number of reminders.</li>
        </div>
      );
    } else if (communicationPurpose == 're-invite') {
      note = (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginBottom: '4rem'
          }}
        >
          <li> Emails may end up in spam.</li>
        </div>
      );
    } else {
      note = (
        <div>
          Emails may end up in spam and WhatsApp messages will only be delivered if the number is associated with
          WhatsApp.
        </div>
      );
    }
    return note;
  };

  const getCommunicationDetails = () => {
    callApi(
      `${djangoBaseUrl}/v1/syndicates/check_syndicate_communication_record/?permalink=${syndicatePermalink}&startup_id=${startupId}&inv_id=${invId}&type_of_comm=${communicationType}`,
      httpMethods.HTTP_GET,
      '',
      {},
      false
    )
      .then((response) => {
        if (response.status == 200) {
          setIsEmailEligible(response.data.data.let_choose_email);
          setIsWhatsappEligible(response.data.data.let_choose_whatsapp);
          setLastEmail(response.data.data.last_email_reminder_sent_on);
          setLastWhatsapp(response.data.data.last_whatsapp_reminder_sent_on);
          communicationType != 'no_profile' && setShowWhatsapp(response.data.data.show_whatsapp);
          if (response.data.data.show_error && response.data.data.show_error != '') {
            setMesssage(response.data.data.show_error);
            setErrorMsg(response.data.data.show_error);
          } else {
            setMesssage('To preview, please select a communication channel.');
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 400 || error.response.status === 404) {
          toast.error(error.response.data.error, {
            style: { color: '#fff' }
          });
        }
      });
  };

  const setPreviewImage = () => {
    switch (communicationType) {
      case 'no_profile':
        setWhatsappImg(
          'https://letsventure.s3.ap-southeast-1.amazonaws.com/uploads/Profile+complete+reminder+whatsapp.jpg'
        );
        setEmailImg('https://letsventure.s3.ap-southeast-1.amazonaws.com/uploads/Complete+Profile+Reminder+(1).png');
        return;
      case 'no_ca_sign':
        setWhatsappImg('https://letsventure.s3.ap-southeast-1.amazonaws.com/uploads/Ca+e-sign+whtsapp.jpg');
        setEmailImg('https://letsventure.s3.ap-southeast-1.amazonaws.com/uploads/e_Sign+CA+email+cropped.png');
        return;
      case 'not_viewed':
      case 'not_committed':
        setWhatsappImg('https://letsventure.s3.ap-southeast-1.amazonaws.com/uploads/Commit+Reminder+whtsapp.png.jpg');
        setEmailImg('https://letsventure.s3.ap-southeast-1.amazonaws.com/uploads/Commit+Reminder+(1).png');
        return;
      case 'not_transfered':
        setWhatsappImg(
          'https://letsventure.s3.ap-southeast-1.amazonaws.com/uploads/Fund+Transfer+Whtsapp+Reminder.png.jpg'
        );
        setEmailImg('https://letsventure.s3.ap-southeast-1.amazonaws.com/uploads/Drawdown+cropped+email.png');
    }
  };

  const handleSubmit = () => {
    setSubmitting(true);
    const url =
      communicationPurpose == 'bulk_remind' || communicationPurpose == 'bulk_reinvite'
        ? `${djangoBaseUrl}/v1/syndicates/bulk_syndicate_communication_request/?permalink=${syndicatePermalink}&source=glassboard`
        : `${djangoBaseUrl}/v1/syndicates/syndicate_communication_request/?permalink=${syndicatePermalink}&source=glassboard`;
    callApi(
      url,
      httpMethods.HTTP_POST,
      {
        startup_id: startupId || '',
        inv_id: invId,
        mode_of_comm: mode,
        type_of_comm: communicationType,
        investorCommitmentId: invCommitId,
        resend_drawdown_notice: resendDrawdownNotice,
        backer_array: selectedInvArr || []
      },
      {},
      false
    )
      .then((res) => {
        setSubmitting(false);
        if (res.status == 200) {
          if (communicationPurpose == 'bulk_reinvite') {
            toast.success('Invitation sent successfully.', {
              style: { color: '#fff' }
            });
          } else {
            toast.success('Reminder sent successfully', {
              style: { color: '#fff' }
            });
          }
          closeModal();
          const currentLocation = history.location.pathname;
          history.push(currentLocation);
        } else {
          toast.error(res.data.message, {
            style: { color: 'red' }
          });
        }
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.response.status === 400 || error.response.status === 404) {
          toast.error(error.response.data.error, {
            style: { color: '#fff' }
          });
        }
      });
  };

  const checkIsDisabled = () => {
    if ((mode === 'whatsapp' && isWhatsappEligible) || (mode === 'email' && isEmailEligible)) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    communicationPurpose !== 'bulk_remind' && communicationPurpose !== 'bulk_reinvite' && getCommunicationDetails();
    setPreviewImage();
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="backer-selection-modal">
      <Modal title="Communication Modal" show ariaLabel="otpLabel" className="scheme-modal">
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            className="modal-body"
            style={{
              // height: '59%',
              // margin: '30px 30px 0px 50px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              maxHeight: '1000px',
              paddingLeft: '50px'
            }}
          >
            {communicationPurpose === 'bulk_remind' || communicationPurpose == 'bulk_reinvite' ? (
              <div
                style={{
                  marginTop: '15px',
                  fontSize: '18px',
                  fontWeight: 'bold'
                }}
              >
                {communicationPurpose == 'bulk_reinvite' ? 'Re-Invite All' : 'Remind All'}
              </div>
            ) : (
              <div
                style={{
                  border: '1px solid grey',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '10px',
                  margin: '20px'
                }}
              >
                <table>
                  <tbody style={{ fontSize: 16 }}>
                    {!!lastEmail && (
                      <tr>
                        <td style={{ paddingRight: '20px', fontWeight: 500 }}>Last Email reminder sent on</td>
                        <td>{lastEmail}</td>
                      </tr>
                    )}
                    {!lastEmail && (
                      <tr>
                        <td style={{ paddingRight: '20px', fontWeight: 500 }}>
                          Last Email reminder : No reminders sent yet
                        </td>
                      </tr>
                    )}
                    {showWhatsapp && lastWhatsapp && (
                      <tr>
                        <td style={{ paddingRight: '20px', fontWeight: 500 }}>Last Whatsapp reminder sent on</td>
                        <td>{lastWhatsapp}</td>
                      </tr>
                    )}
                    {showWhatsapp && !lastWhatsapp && (
                      <tr>
                        <td style={{ paddingRight: '20px', fontWeight: 500 }}>
                          Last Whatsapp reminder : No reminders sent yet
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
            {communicationPurpose != 'bulk_remind' && communicationPurpose != 'bulk_reinvite' && (
              <div style={{ padding: '10px', margin: '10px auto' }}>
                <FormControl>
                  <FormLabel id="demo-controlled-radio-buttons-group" classes={{ root: classes.labelRoot }}>
                    Select the communication channel
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={mode}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="email"
                      control={<Radio />}
                      label="Email"
                      classes={{ label: classes.label }}
                    />
                    {showWhatsapp && (
                      <FormControlLabel
                        value="whatsapp"
                        control={<Radio />}
                        label="WhatsApp"
                        classes={{ label: classes.label }}
                      />
                    )}
                  </RadioGroup>
                </FormControl>
              </div>
            )}
            <div
              style={
                communicationPurpose == 'bulk_remind' || communicationPurpose == 'bulk_reinvite'
                  ? {
                      height: '80%',
                      width: '100%',
                      marginTop: '7%'
                    }
                  : { height: '80%', width: '100%' }
              }
            >
              {((mode === 'whatsapp' && isWhatsappEligible) || (mode === 'email' && isEmailEligible)) && (
                <img
                  src={mode == 'whatsapp' ? whatsappImg : mode == 'email' ? emailImg : ''}
                  alt="Preview Message"
                  height="90%"
                  style={{
                    display: 'block',
                    margin: 'auto',
                    border: '1px solid grey',
                    maxWidth: '100%'
                  }}
                />
              )}
              {(mode == '' ||
                mode == null ||
                (mode === 'whatsapp' && !isWhatsappEligible) ||
                (mode === 'email' && !isEmailEligible)) && (
                <div
                  style={{
                    padding: '100px 20px',
                    margin: '100px 150px',
                    color: 'red',
                    fontSize: 18,
                    textAlign: 'center',
                    border: '1px solid grey'
                  }}
                >
                  {message}
                </div>
              )}
            </div>
          </div>
          <div
            onClick={() => closeModal()}
            style={{
              padding: '10px 10px 10px 0',
              display: 'flex',
              flexDirection: 'row-reverse',
              color: 'rgba(0, 0, 0, 0.45)',
              height: '50px'
            }}
          >
            <Icon name="close" width="32" height="32" style={{ cursor: 'pointer' }} />
          </div>
        </div>
        <div
          style={
            communicationPurpose == 'bulk_reinvite'
              ? {
                  margin: '-8% auto 40px 25%',
                  width: '70%',
                  paddingRight: '10%'
                }
              : communicationPurpose == 're-invite'
              ? {
                  margin: '-3% auto 40px 25%',
                  width: '70%',
                  paddingRight: '10%'
                }
              : communicationPurpose === 'bulk_remind'
              ? {
                  margin: '0px auto 40px 20%',
                  width: '70%',
                  paddingRight: '10%'
                }
              : {
                  margin: '0px auto 40px 25%',
                  width: '70%',
                  paddingRight: '10%'
                }
          }
        >
          <div
            style={
              checkIsDisabled()
                ? { display: 'none' }
                : {
                    fontSize: '14px',
                    textAlign: 'center',
                    marginBottom: '30px'
                  }
            }
          >
            <div style={{ marginRight: communicationPurpose === 'bulk_remind' ? '0' : '17%' }}>
              <b>Note</b>
            </div>
            {handlePreviewNote()}
          </div>
          {/* <div
            style={
              checkIsDisabled()
                ? { display: 'none' }
                : { fontSize: '14px', color: 'red', textAlign: 'center' }
            }
          >
            Please check last sent date before sending the reminder.
          </div> */}
          <div
            className="d-flex mt-3"
            style={{ justifyContent: 'center', marginRight: communicationPurpose === 'bulk_remind' ? '0' : '17%' }}
          >
            <button
              type="button"
              onClick={() => handleSubmit()}
              className="btn btn-outline-primary"
              style={{ background: '#0099ff', color: '#ffffff' }}
              disabled={submitting || checkIsDisabled()}
            >
              {submitting ? (
                <div className="d-flex align-items-center">
                  Submitting... <Oval height="25" width="25" radius="9" color="#0099ff" />
                </div>
              ) : (
                'Submit'
              )}
            </button>
            <button
              type="button"
              onClick={() => closeModal()}
              style={{ marginLeft: '20px' }}
              className="btn btn-outline-primary"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CommunicationModal;
