import { makeStyles } from '@material-ui/core';

const CommonStyles = makeStyles({
  primaryButton: {
    padding: '7px 16px',
    borderRadius: 5,
    backgroundColor: 'blue',
    color: '#fff',
    border: '1px solid',
    borderColor: 'blue',
    '&:active': {
      backgroundColor: 'blue',
      color: '#fff',
      borderColor: 'blue',
    },
    '&:hover': {
      backgroundColor: 'blue',
      color: '#fff',
      borderColor: 'blue',
    },
    '&.big': {
      padding: '10px 24px',
    },
    '&:disabled': {
      opacity: 0.3,
    },
  },
});

export default CommonStyles;
