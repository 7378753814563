import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import UserImage from '../../../assests/image/user.png';
import { getSyndicateId, getUser } from '../../../common/utils/common';

export default function LeadInvestorList(props) {
  const {
    user,
    isMandatory,
    carryError,
    labelTitle,
    investorList,
    notes,
    innerText,
    saveLeadInvestor,
    // addInvestors,
    removeInvestor,
    index = null,
    setFieldValue,
    removeIcon,
    errors,
    labelName,
    values,
    textinfo,
    validateField,
    isZeno = false
  } = props;

  const syndicateId = getSyndicateId();
  const [leadInvestor, setLeadInvestor] = useState(null);
  const [leadCarry, setLeadCarry] = useState(null);
  const [openForm, setOpenForm] = useState(null);
  const totalCarry = investorList.reduce((sum, item) => sum + Number(item.lead_carry), 0.0);
  const leadCarryAllocationLeft = values.total_lead_carry ? values.total_lead_carry - totalCarry : 0;

  const loadInvestorList = async (input, _callback) => {
    if (input.trim() === '') {
      return Promise.resolve({ options: [] });
    }
    const response = await fetch(
      `${
        process.env.REACT_APP_API_BASE_URL
      }/lookups.json?type=all_angel_investors&custom=true&is_lead=true&q=${input}&syndicate_id=${syndicateId}&current_user_id=${
        getUser().user_id
      }`
    );
    const json = await response.json();
    const newJson = json.map((field, k) => ({
      label: field.text,
      options: field.children
        .filter((item) => item.new_entry !== true)
        .map((child, k1) => ({
          value: child.id,
          label: child.text,
          role: child.role,
          location: child.location,
          profile_image: child.profile_image,
          logo_img: child.logo_img,
          new_entry: child.new_entry,
          type: child.type
        }))
    }));
    return newJson;
  };

  const addLeadInvestor = () => {
    const leadInvestorObj = { label: leadInvestor.label, value: leadInvestor.value, lead_carry: leadCarry };
    // investorList.push(leadInvestorObj);
    values.selected_lead_investor = leadInvestorObj;
    setOpenForm(false);
    saveLeadInvestor(setFieldValue, values);
  };

  return (
    <div className="edit-form-row">
      <label className="edit__profile_label-title">
        {labelTitle} {isMandatory ? <span className="text-danger">*</span> : ''}
      </label>
      <div className="d-flex justify-content-start mt-1 row" style={{ marginLeft: '-5px' }}>
        {!isZeno && (user === 'founder' ? investorList.length === 0 : true) && (
          <div
            className="add-investor-box d-flex justify-content-center align-items-center m-2 col-2"
            onClick={() => setOpenForm(true)}
          >
            <span className="d-flex justify-content-center">
              <AddIcon style={{ marginRight: '10px' }} />
              {innerText}
            </span>
          </div>
        )}
        {investorList.map((item, key) => (
          <div
            className="add-investor-box custom-box d-flex align-items-center justify-content-start m-2 p-3 col-3"
            style={{ width: '18%' }}
            key={key}
          >
            <div className="p-1">
              <img src={item.profile_image || UserImage} height="50px" width="50px" className="rounded-circle" alt="" />
            </div>
            <div className="d-flex flex-column p-1 ms-3">
              <div>
                {!!item.label && <div style={{ fontSize: '14px' }}>{`${item.label.slice(0, 15)}...`}</div>}
                {!!item.lead_carry && <div style={{ fontSize: '12px' }}>{`${item.lead_carry}%`}</div>}
              </div>
            </div>
            {!isZeno && !!removeIcon && (
              <div
                className="ms-auto"
                style={{ paddingLeft: 30, cursor: 'pointer' }}
                onClick={() => removeInvestor(item.value, setFieldValue, investorList)}
              >
                <DeleteOutlinedIcon />
              </div>
            )}
          </div>
        ))}
      </div>
      {!!textinfo && (
        <div className="edit_profile_notes" style={{ marginLeft: '5px', fontSize: '16px' }}>
          {textinfo}
        </div>
      )}
      {!!openForm && (
        <div>
          <div className="d-flex align-items-center">
            <AsyncSelect
              className="col-3 p-1"
              loadOptions={loadInvestorList}
              placeholder="Enter the name of your investor"
              noOptionsMessage={({ inputValue }) =>
                !inputValue ? 'Please Enter 1 or more character' : 'No results found'
              }
              onChange={(investor) => {
                setLeadInvestor(investor);
              }}
            />
            {/* <TextInput
              name="carry"
              type="text"
              placeholder="Enter lead carry"
              notes=""
              style={{ width: '100%', marginLeft: '30px' }}
            /> */}
            <input
              type="number"
              min="0"
              max={leadCarryAllocationLeft}
              name="carry"
              style={{
                width: '15%',
                marginLeft: '30px',
                maxHeight: '46px',
                padding: '8px 14px',
                borderRadius: '5px',
                border: 'solid 1px #b6b6b6',
                backgroundColor: '#fff'
              }}
              onChange={(carry) => {
                let carryValue = carry.target.value;
                if (carry.target.value < 0) carryValue = 0;
                if (carry.target.value > leadCarryAllocationLeft) carryValue = leadCarryAllocationLeft;
                carry.target.value = carryValue;
                setLeadCarry(carryValue);
              }}
              placeholder="Enter lead carry"
            />
            <div style={{ marginLeft: '50px' }}>
              <Button
                variant="primary"
                onClick={() => {
                  addLeadInvestor();
                  setTimeout(() => {
                    validateField('lead_investor_list');
                  }, 1000);
                }}
              >
                Save
              </Button>
            </div>
          </div>
          {values.total_lead_carry > 0.5 ? (
            <div style={{ margin: '7px' }}>{`Lead carry allocation left is ${leadCarryAllocationLeft}%`} </div>
          ) : (
            ''
          )}
        </div>
      )}
      {(isMandatory || carryError) && errors && errors[labelName] && (
        <div className="text-danger" style={{ marginTop: '0.3rem', fontSize: 14 }}>
          {errors[labelName]}
        </div>
      )}
      {notes && <div className="edit_profile_notes"> {notes} </div>}
    </div>
  );
}
