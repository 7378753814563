import { Form, Formik } from 'formik';
import React, { Fragment, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
// import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Modal from 'react-bootstrap/Modal';
import TextInput from '../formik/components/TextInput';
// import Checkbox from '../common/formik/components/Checkbox';
import PostHogManager from '../../analytics/postHogManager';
import callApi from '../../api';
import { httpMethods } from '../../constants';
import CommonStyles from '../commonStyles';
import { getSyndicateId, getToken, getUser } from '../utils/common';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 400,
    marginTop: 20
  },
  textField: {},
  formControl: {
    margin: '20px 0',
    minWidth: 120
  },
  selectEmpty: {
    marginTop: 20
  }
});

function AddCcMail(props) {
  const syndicateId = getSyndicateId();
  const token = getToken();
  const user = getUser();

  const [ccEmail, setCcEmail] = useState('');
  const [emailModal, setEmailModal] = useState(false);

  const emailInputComponent = () => {
    const commonClasses = CommonStyles();
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState(null);

    const closeModal = () => {
      setEmailModal(false);
    };

    const reloadWindoww = () => {
      window.location.reload();
    };

    const submitOTP = (event) => {
      event.preventDefault();
      PostHogManager.trackEvent('CC Email OTP Submitted', {
        source: 'Syndicate Settings Page OTP Modal',
        description: 'Submitting OTP for CC Email',
        submitted_data: { otp, cc_email: ccEmail, syndicate_id: syndicateId, user_id: user.user_id }
      });
      const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
      const url = `${djangoBaseUrl}/v1/syndicates/verify_email_update_via_diy_profile/?id=${syndicateId}`;
      callApi(
        url,
        httpMethods.HTTP_POST,
        {
          otp,
          cc_email: ccEmail,
          syndicate_id: syndicateId,
          user_id: user.user_id
        },
        {},
        false
      )
        .then((response) => {
          if (response.data.detail) {
            PostHogManager.trackEvent('CC Email OTP Submitted - Successfull', {
              source: 'Syndicate Settings Page OTP Modal',
              description: 'Submitting OTP for CC Email Successfull',
              response_data: response
            });
            setEmailModal(false);
            toast.success('Cc email successfully added');
            setTimeout(reloadWindoww, 500);
          } else {
            PostHogManager.trackEvent('CC Email OTP Submitted - Failed', {
              source: 'Syndicate Settings Page OTP Modal',
              description: 'Submitting OTP for CC Email Failed',
              response_data: response
            });
            setOtpError('Invalid OTP');
          }
        })
        .catch((error) => {
          PostHogManager.trackEvent('CC Email OTP Submitted - Failed', {
            source: 'Syndicate Settings Page OTP Modal',
            description: 'Submitting OTP for CC Email Failed',
            response_data: error
          });
        });
    };

    const handleChange = (event) => {
      event.preventDefault();
      const otpp = event.currentTarget.value;
      setOtp(otpp);
    };
    const initialValues = {
      email: ''
    };

    const validateForm = Yup.object({
      email: Yup.string().email().required('Required')
    });

    const reloadWindow = () => {
      window.location.reload();
    };

    const onSubmit = (values) => {
      setCcEmail(values.email);
      PostHogManager.trackEvent('Validate CC Email Submitted', {
        source: 'Syndicate Settings Page',
        description: 'Clicked On Validate CC Email',
        submitted_data: { cc_email: values.email, user_id: user.user_id }
      });
      const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
      const url = `${djangoBaseUrl}/v1/syndicates/email_update_via_diy_profile/?id=${syndicateId}`;
      callApi(
        url,
        httpMethods.HTTP_POST,
        {
          cc_email: values.email,
          user_id: user.user_id
        },
        {},
        false
      )
        .then((response) => {
          PostHogManager.trackEvent('Validate CC Email - Successfull', {
            source: 'Syndicate Settings Page',
            description: 'Validating CC Email Successfull',
            response_data: response
          });
          if (response.data.detail) {
            setEmailModal(true);
          } else {
            PostHogManager.trackEvent('Validate CC Email - Failed', {
              source: 'Syndicate Settings Page',
              description: 'Validating CC Email Failed',
              response_data: response
            });
            toast.error(response.data.message);
            // setTimeout(reloadWindow, 500);
          }
        })
        .catch((error) => {
          PostHogManager.trackEvent('Validate CC Email - Failed', {
            source: 'Syndicate Settings Page',
            description: 'Validating CC Email Failed',
            response_data: error
          });
        });
    };

    return (
      <div style={{ width: '50%', padding: 20 }}>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validateForm}>
          <Form>
            <div className="form-group" style={{ margin: '10px' }}>
              <TextInput
                labeltitle="Edit cc: email"
                name="email"
                type="text"
                placeholder="Enter Email ID"
                notes=""
                tooltipTitle="This email id will be CC’ed on all Backer and Admin user invitations."
                // ismandatory
                style={{ width: '100%' }}
              />
            </div>
            <div style={{ margin: '10px' }}>
              <Button
                variant="primary"
                size="md"
                type="submit"
                style={{
                  background: '#09f',
                  borderRadius: '3px',
                  fontSize: 14,
                  color: '#fff'
                }}
              >
                Validate
              </Button>
            </div>
          </Form>
        </Formik>

        <div style={{ zIndex: 10000 }}>
          <Modal
            style={{ zIndex: 10000 }}
            show={emailModal}
            // onHide={() => setEmailModal(false)}
          >
            <Modal.Header>
              <Modal.Title>Verify your Self</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="col-sm-24 text-center">
                {/* <h4 className="heading mb-5">Verify your Self</h4> */}
                <div className="form-group">
                  <label htmlFor="otp_key" className="sub-heading font-weight-bold" style={{ marginBottom: 50 }}>
                    Please check your Email for a four-digit One Time Password
                  </label>
                  <input
                    style={{
                      border: 'none',
                      width: '40%',
                      textAlign: 'center',
                      borderBottom: '1px solid',
                      margin: 'auto',
                      fontSize: 24
                    }}
                    type="text"
                    name="otp_key"
                    maxLength="4"
                    className="form-control mb-4"
                    value={otp}
                    onChange={handleChange}
                  />
                  {!!otpError && <div style={{ color: 'red' }}>{otpError}</div>}
                </div>
                <button
                  type="button"
                  className={commonClasses.primaryButton}
                  style={{ cursor: 'pointer' }}
                  onClick={submitOTP}
                >
                  Verify
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  };

  return <Fragment style={{ width: '50%' }}>{emailInputComponent()}</Fragment>;
}

export default AddCcMail;
