import { Refresh } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { toast } from 'react-toastify';

const option = {
  style: { color: '#fff' },
};

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

function FileUploadComponent(props) {
  const { handleBulkUpload, isUploading = false } = props;
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileName(file.name);
    setSelectedFile(file);
  };

  const handleUpload = () => {
    if (selectedFile) {
      handleBulkUpload(selectedFile);
    } else {
      toast.error('No file selected for upload', option);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        <Button
          component="label"
          variant="outlined"
          startIcon={<CloudUploadIcon />}
          onChange={handleFileChange}
        >
          <span
            style={{
              fontWeight: 400,
              fontFamily: 'inherit',
              lineHeight: 1.5,
              fontSize: '13px',
            }}
          >
            Select file
          </span>
          <VisuallyHiddenInput type="file" accept=".xlsx" />
        </Button>
        <Button
          sx={{ marginLeft: 15, minWidth: '6rem' }}
          variant="contained"
          onClick={handleUpload}
          disabled={!selectedFile || isUploading}
        >
          {isUploading ? <Refresh /> : 'Upload'}
        </Button>
      </div>
      {!selectedFile ? (
        <span style={{ marginTop: '10px' }}>File format: XLSX</span>
      ) : null}

      <div style={{ marginTop: '10px' }}>{fileName}</div>
    </div>
  );
}

export default FileUploadComponent;
