import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { CloudDownload } from '@mui/icons-material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Snackbar from '@mui/material/Snackbar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Oval } from 'react-loader-spinner';
import renderHTML from 'react-render-html';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import PostHogManager from '../../analytics/postHogManager';
import callApi from '../../api';
import '../../assests/style/invite_people_slide.scss';
import FileUploadComponent from '../../common/formik/components/FileUpload';
import Select from '../../common/formik/components/Select';
import TextArea from '../../common/formik/components/TextArea';
import TextInput from '../../common/formik/components/TextInput';
import Icon from '../../common/icons/Icon';
import { getSyndicateId, getToken } from '../../common/utils/common';
import { httpMethods } from '../../constants';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

// function invitePeopleTabProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function InvitePeopleSlide(props) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState(0);
  const [openModalCheck, setOpenModalCheck] = useState(false);
  const [sendingInvite, setSendingInvite] = useState(false);
  const [samplePath, setSamplePath] = useState('');
  const [isBulkInvite, setIsBulkInvite] = useState(false);
  const [openErrorBox, setOpenErrorBox] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const SamplePathList = {
    '': 'https://letsventure.s3.ap-southeast-1.amazonaws.com/Individual+Invite.png',
    individual: 'https://letsventure.s3.ap-southeast-1.amazonaws.com/Individual+Invite.png',
    partnership: 'https://letsventure.s3.ap-southeast-1.amazonaws.com/Partnership+(1).png',
    huf: 'https://letsventure.s3.ap-southeast-1.amazonaws.com/HUF+(1).png',
    joint_account: 'https://letsventure.s3.ap-southeast-1.amazonaws.com/Joint+Account+Updated+(1).png'
  };

  const initialValues = {
    email: props.email || '',
    fullName: props.fullName || '',
    type_of_investment_profile: props.type_of_investment_profile || '',
    inviteMsg: props.inviteMsg || ''
  };

  const validateForm = Yup.object({
    email: Yup.string().email().required('Required'),
    fullName: Yup.string().required('Required'),
    type_of_investment_profile: Yup.string().required('Required')
  });

  const handleClose = (event, reason) => {
    PostHogManager.trackEvent('Closed Invite Backer Slider', {
      source: 'Backers Page',
      description: 'Closed Invite Backer Slider'
    });
    if (reason === 'clickaway') {
      return;
    }
    setOpenErrorBox(false);
  };

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const openSampleMail = (selectedProfile) => {
    PostHogManager.trackEvent('Clicked on Sample Email', {
      source: 'Invite Backer Slider',
      description: 'Clicked on sample email in the invite backer slider',
      selectedProfile
    });
    setSamplePath(SamplePathList[selectedProfile]);
    setOpenModalCheck(true);
    setOpen(true);
  };

  const investmentProfileTypeOptions = [
    { label: 'Select', value: '' },
    { label: 'Individual/Corporate', value: 'individual' },
    { label: 'Partnership', value: 'partnership' },
    { label: 'Joint Account', value: 'joint_account' }
  ];

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.grey[100],
      color: '#333333',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      letterSpacing: 1.1
    }
  }))(Tooltip);

  const syndicateId = getSyndicateId();
  const token = getToken();
  let alertMsg;
  const option = {
    style: { color: '#fff' }
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleBulkUpload = (selectedFile) => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append('file', selectedFile);
    const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
    callApi(
      `${djangoBaseUrl}/v1/syndicates/bulk_invite_members/?id=${syndicateId}`,
      httpMethods.HTTP_POST,
      formData,
      {
        'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
      },
      false
    )
      .then((response) => {
        if (response.status === 200) {
          setIsUploading(false);
          setOpen(false);
          PostHogManager.trackEvent('Bulk Invite Backers- Success', {
            syndicate_id: syndicateId,
            source: 'Invite Backer Modal',
            description:
              'Whenever the user clicks on submit button in Bulk Invite modal and it is successful, trigger an event',
            response_data: response
          });
          toast.success(
            'Your invites are successfully scheduled. Please check ‘Backers’ page after sometime for accurate details',
            option
          );
        }
      })
      .catch((error) => {
        setIsUploading(false);
        PostHogManager.trackEvent('Bulk Invite Backers- Failure', {
          syndicate_id: syndicateId,
          source: 'Invite Backer Modal',
          description:
            'Whenever the user clicks on submit button in Bulk Invite modal and it is failed, trigger an event',
          error
        });
        if (error.response.status === 400) {
          setOpenErrorBox(true);
          const container = document.createElement('div');
          error.response.data.error.forEach((element, index) => {
            const div = document.createElement('div');
            div.textContent = `${element.error} (email: ${element.email})`;
            container.appendChild(div);
          });
          setErrorMessage(container.innerHTML);
        } else {
          toast.error('Something went Wrong in file upload.', option);
        }
      });
  };

  const sendInvite = (values) => {
    const formVals = { ...values, email: values.email.trim() };

    setSendingInvite(true);
    PostHogManager.trackEvent('Send Backer Invite Submitted', {
      syndicate_id: syndicateId,
      source: 'Invite Backer Slider',
      description: 'Send Backer Invite Submitted',
      submitted_data: formVals
    });
    const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
    const url = `${djangoBaseUrl}/v1/syndicates/invite_member/?type_of_member=backer&type_of_action=invite&id=${syndicateId}`;
    callApi(
      url,
      httpMethods.HTTP_POST,
      {
        email: values.email,
        name: values.fullName,
        type_of_investment_profile: values.type_of_investment_profile,
        inviteMsg: values.inviteMsg
      },
      {},
      false
    )
      .then((response) => {
        PostHogManager.trackEvent('Send Backer Invite - Success', {
          syndicate_id: syndicateId,
          source: 'Invite Backer Slider',
          description: 'Send Backer Invite Successfull',
          response_data: response
        });
        setSendingInvite(false);
        alertMsg = response.data.message;
        // setOpenAlert(true);
        setOpen(false);
        if (response.data.success) {
          toast.success(alertMsg, option);
        } else {
          toast.error(alertMsg, option);
        }
        props.handlePeopleApiData();
      })
      .error((err) => {
        PostHogManager.trackEvent('Send Backer Invite - Failed', {
          syndicate_id: syndicateId,
          source: 'Invite Backer Slider',
          description: 'Send Backer Invite Failed',
          response_data: err
        });
        setSendingInvite(false);
        setOpen(false);
        alertMsg = err.data.message;
        toast.error(alertMsg, option);
      });
  };

  const getList = () => (
    <div style={{ width: '100%', minWidth: 500 }}>
      <div
        style={{
          padding: 10,
          margin: '20px 20px 0px 24px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div style={{ fontWeight: 'bold', lineHeight: '18px', fontSize: '14px' }}>Invite Backer</div>
        <div
          style={{ marginTop: '0px', color: 'rgba(0, 0, 0, 0.45)' }}
          onClick={() => {
            setOpen(false);
            setOpenErrorBox(false);
            setErrorMessage('');
            setValue(0);
            setIsBulkInvite(false);
          }}
        >
          <Icon name="close" width="32" height="32" style={{ cursor: 'pointer' }} />
        </div>
      </div>
      <div
        style={{
          width: '100%',
          alignSelf: 'flex-start'
        }}
      >
        <div
          style={{
            display: 'flex',
            backgroundColor: 'white',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label=" add deal tabs "
            style={{ marginLeft: '24px', width: '60%' }}
          >
            <Tab
              onClick={() => {
                setIsBulkInvite(false);
                setOpenErrorBox(false);
                setErrorMessage('');
              }}
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginRight: '20px',
                height: 18,
                // fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: '1.29',
                letterSpacing: 'normal',
                textAlign: 'left'
                // color: '#000',
              }}
              label={<Typography fontFamily="Proxima-Nova">Individual Invite</Typography>}
              {...tabProps(0)}
            />
            <Tab
              onClick={() => {
                setIsBulkInvite(true);
              }}
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginRight: '20px',
                height: 18,
                // fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: '1.29',
                letterSpacing: 'normal',
                textAlign: 'left'
                // color: '#000',
              }}
              label={<Typography fontFamily="Proxima-Nova">Bulk Invite</Typography>}
              {...tabProps(1)}
            />
          </Tabs>
        </div>
      </div>
      <hr
        style={{
          height: '1px',
          opacity: 0.3,
          backgroundColor: '#000',
          margin: 0
        }}
      />
      {isBulkInvite ? (
        <Box sx={{ width: '100%', minWidth: 500, marginTop: 0 }}>
          <div
            style={{ marginLeft: '24px', marginTop: '30px' }}
            onClick={() => {
              setOpenErrorBox(false);
              setErrorMessage('');
            }}
          >
            <FileUploadComponent handleBulkUpload={handleBulkUpload} isUploading={isUploading} />
          </div>

          <div
            style={{
              marginLeft: '24px',
              marginTop: '30px',
              color: 'blue !important'
            }}
          >
            <a href="https://letsventure.s3.ap-southeast-1.amazonaws.com/BulkInviteSample.xlsx">
              Download Sample File
              <CloudDownload sx={{ marginLeft: 1 }} />
            </a>
          </div>
          <div
            style={{
              marginLeft: '24px',
              marginTop: '20px'
            }}
          >
            <b>Note:</b> Email invitations will not be sent to investors who have already been invited.
          </div>
        </Box>
      ) : (
        <Box sx={{ width: '100%', minWidth: 500, marginTop: 0 }}>
          <TabPanel value={value} index={0} style={{ minWidth: 500 }}>
            <div>
              <Formik initialValues={initialValues} onSubmit={sendInvite} validationSchema={validateForm}>
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className="form-group" style={{ margin: '10px' }}>
                      <TextInput
                        labeltitle="Full Name (Minimum 3 letters)"
                        name="fullName"
                        type="text"
                        placeholder="Full Name"
                        notes=""
                        ismandatory
                        style={{ width: '100%', fontSize: '14px' }}
                        smallerText
                      />
                    </div>
                    <div className="form-group" style={{ margin: '10px' }}>
                      <TextInput
                        labeltitle="Email"
                        name="email"
                        type="text"
                        placeholder="Investor Email ID"
                        notes=""
                        ismandatory
                        style={{ width: '100%', fontSize: '14px' }}
                        smallerText
                        value={values.email}
                        onChange={(e) => {
                          setFieldValue('email', e.target.value.trim());
                        }}
                      />
                    </div>
                    <div className="form-group" style={{ margin: '10px' }}>
                      <Select
                        name="type_of_investment_profile"
                        labeltitle="Type of Investing Entity"
                        placeholder="Select Type of Investment Profile"
                        options={investmentProfileTypeOptions}
                        notes=""
                        multi={false}
                        ismandatory
                        style={{ width: '100%', fontSize: '14px' }}
                        smallerText
                      />
                    </div>
                    <div className="form-group" style={{ fontSize: '16px', margin: '10px' }}>
                      <TextArea
                        labeltitle="Invitation Message"
                        labelName="inviteMsg"
                        placeholder="Enter Invite Message"
                        notes=""
                        style={{ width: '100%', fontSize: '14px' }}
                        smallerText
                        size="500"
                      />
                    </div>
                    <div
                      style={{
                        cursor: 'pointer',
                        color: 'blue',
                        textDecorationLine: 'underline',
                        margin: 10
                      }}
                      onClick={() => {
                        openSampleMail(values.type_of_investment_profile);
                      }}
                    >
                      {/* <span style={{ cursor: 'pointer', marginLeft: 3 }}>
                      <LightTooltip title="click here to check the sample email">
                        <InfoIcon className="brandColorDark" />
                      </LightTooltip>
                    </span> */}
                      <span>View a sample email</span>
                    </div>
                    <Button
                      variant="contained"
                      type="submit"
                      style={{
                        backgroundColor: '#0099FF',
                        fontWeight: 'bold',
                        height: '38px',
                        fontSize: '13px',
                        margin: '10px'
                      }}
                      disabled={sendingInvite}
                    >
                      <span style={{ fontWeight: 'bold' }}>
                        {sendingInvite ? (
                          <div className="d-flex align-items-center">
                            Sending... <Oval height="25" width="25" radius="9" color="#0099ff" />
                          </div>
                        ) : (
                          'Send Invite'
                        )}
                      </span>
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </TabPanel>
        </Box>
      )}
    </div>
  );

  React.useEffect(() => {
    setOpenErrorBox(false);
    setErrorMessage('');
    setValue(0);
    setIsBulkInvite(false);
  }, []);

  return (
    <div>
      <div>
        <Button
          variant="contained"
          onClick={() => {
            PostHogManager.trackEvent('Opened Invite Backer Slider', {
              source: 'Backers Page',
              description: 'Opened Invite Backer Slider'
            });
            setOpen(true);
          }}
          style={{
            backgroundColor: '#0099FF',
            fontWeight: 'bold',
            height: '38px',
            fontSize: '13px',
            width: '200px'
          }}
        >
          Invite Backer
        </Button>
        <Drawer
          open={open}
          style={{ marginTop: '100px' }}
          anchor="right"
          onClose={() => {
            PostHogManager.trackEvent('Closed Invite Backer Slider', {
              source: 'Backers Page',
              description: 'Closed Invite Backer Slider'
            });
            setOpen(false);
          }}
          hideBackdrop
        >
          {getList()}
          <Snackbar open={openErrorBox} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
              {renderHTML(errorMessage)}
            </Alert>
          </Snackbar>
        </Drawer>
      </div>
      <div style={{ zIndex: 10000 }}>
        <Modal style={{ zIndex: 10000 }} show={openModalCheck} onHide={() => setOpenModalCheck(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Sample Email</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img
              src={samplePath}
              width="100%"
              height="100%"
              alt="Logo"
              style={{
                // border: '1px solid',
                // borderRadius: 50,
                // borderColor: 'black',
                margin: '2px 24px 21px 0px'
              }}
            />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
