import { httpMethods } from "../constants";
import { HttpClient } from "./httpClient";

const axiosConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
};

export const httpClient = new HttpClient(axiosConfig.baseURL);
const axiosInstance = httpClient.getInstance();

export const callApi = async (
  endpoint,
  method,
  postParams = "",
  config = {},
  concatBaseUrl = true,
  redirectToDjango = false
) => {
  const BASE_URL = redirectToDjango
    ? process.env.REACT_APP_DJANGO_BASE_URL
    : axiosConfig.baseURL;
  const API_END_POINT = concatBaseUrl ? BASE_URL + endpoint : endpoint;

  if (!Object.values(httpMethods).includes(method)) {
    throw new Error("Unsupported method.");
  }

  try {
    const requestData = {
      method,
      url: API_END_POINT,
      ...config,
    };

    // Only add data if it's not a GET or DELETE request
    if (method !== httpMethods.HTTP_GET && method !== httpMethods.HTTP_DELETE) {
      requestData.data = postParams;
    }

    const response = await axiosInstance(requestData);
    return response;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;
  }
};

export default callApi;
