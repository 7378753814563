import { Divider, FormControl, FormControlLabel, Radio, RadioGroup, Switch, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../assests/style/deal_detail.scss';
import EnvelopeOpen from '../assests/image/EnvelopeOpen.png';
import '../assests/style/backerselectionmodal.scss';
import Modal from '../common/components/Modal';
import Icon from '../common/icons/Icon';
import ShareBackerListing from '../components/ShareBackerListing';
import PostHogManager from '../analytics/postHogManager';

export const rupeeFormatter = new Intl.NumberFormat('en-IN', {
  currency: 'INR'
});

function BackerSelectionModal(props) {
  const { backerList, adminList } = props;
  const [value, setValue] = React.useState('allbackers');
  const [backersData, setBackersData] = useState(backerList);
  const [backersDataMap, setBackersDataMap] = useState(new Map());
  const [showAllColumn, setShowAllColumn] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleChangeSwitch = (event) => {
    setShowAllColumn(event.target.checked);
    PostHogManager.trackEvent('Configure commitment Amount Toggle bar clicks', {
      source: 'Publish popup, Share Popup',
      description: 'Whenever the user switches the toggler bar (on/off), trigger an event ',
      toggle: event.target.checked
    });
    if (event.target.checked) {
      setValue('custom');
    } else {
      setValue('allbackers');
    }
  };

  const filteredDataSort = (a, b, targetLetter) => {
    const aFirstPosition = a.indexOf(targetLetter);
    const bFirstPosition = b.indexOf(targetLetter);
    // Compare the positions of the target letter in both strings
    if (aFirstPosition !== bFirstPosition) {
      return aFirstPosition - bFirstPosition;
    }
    // If the target letter is at the same position, sort alphabetically
    return a.localeCompare(b);
  };

  const filterBacker = (event) => {
    const searchText = event.currentTarget.value;
    if (searchText === '' || searchText === ' ') {
      setIsFiltered(false);
      setBackersData(backerList);
    } else {
      setIsFiltered(true);
      const filteredData = backersData.filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase()));
      filteredData.sort((a, b) =>
        filteredDataSort(a.name.toLowerCase(), b.name.toLowerCase(), searchText.toLowerCase())
      );
      setBackersData(filteredData);
    }
  };

  const handlePublishAndShare = () => {
    if ((backersDataMap.size > 0 && value === 'custom') || value === 'allbackers') {
      const allBackers = value === 'allbackers';
      props.handlePublish(backersDataMap, allBackers);
      props.handleCloseModal();
    } else {
      alert('Please select backers ');
    }
  };

  const handleAllBackersPublish = () => {
    backerList.forEach((row) => {
      backersDataMap.set(row.id, row);
    });
    setBackersDataMap(backersDataMap);
    const allBackers = value === 'allbackers';
    props.handlePublish(backersDataMap, allBackers);
    props.handleCloseModal();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="backer-selection-modal">
      <Modal
        title="Enter One-Time Password"
        show
        ariaLabel="otpLabel"
        className="scheme-modal"
        width="100%"
        margin="auto"
        height="100%"
        modalMargin="0"
      >
        <div>
          <div style={{ margin: '30px 30px 12px 50px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex' }}>
                <img
                  src={EnvelopeOpen}
                  width={29}
                  height={29}
                  alt="Logo"
                  style={{
                    margin: '2px 15px 10px 0px'
                  }}
                />
                <h3
                  className="font-weight-bold"
                  style={{
                    fontSize: 20,
                    fontWeight: 'bold',
                    marginTop: '6px'
                  }}
                >
                  Share Deal with Backers
                </h3>
              </div>
              <div onClick={props.handleCloseModal}>
                <Icon name="close" width="32" height="32" style={{ cursor: 'pointer' }} />
              </div>
            </div>
            <div style={{ marginLeft: '44px' }}>
              <div style={{ fontSize: 13, marginTop: '-3px', width: '90%' }}>
                You can select who you wish to share the deal with.
              </div>
              <FormControl style={{ marginTop: '17px' }}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  defaultValue="female"
                  name="controlled-radio-buttons-group"
                  row
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="allbackers"
                    control={
                      <Radio
                        sx={{
                          color: 'gray',
                          '&.Mui-checked': {
                            color: '#343434'
                          }
                        }}
                      />
                    }
                    label={
                      <Typography sx={value === 'allbackers' ? { fontWeight: 'bold', fontSize: 13 } : { fontSize: 13 }}>
                        All Backers
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="custom"
                    control={
                      <Radio
                        sx={{
                          color: 'gray',
                          '&.Mui-checked': {
                            color: '#343434'
                          }
                        }}
                      />
                    }
                    label={
                      <Typography sx={value === 'custom' ? { fontWeight: 'bold', fontSize: 13 } : { fontSize: 13 }}>
                        Custom
                      </Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </div>
            {value === 'allbackers' && (
              <div style={{ marginLeft: '3%', marginTop: '3%' }}>
                <FormControlLabel
                  control={<Switch onChange={handleChangeSwitch} />}
                  label="Customize commitment values"
                />
              </div>
            )}
          </div>
          {value === 'custom' ? (
            <>
              <Divider sx={{ borderColor: 'black' }} />
              <div className="col-sm-24">
                <div style={{ marginTop: '16px' }}>
                  <div className="col-sm-24">
                    <div
                      width="100%"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '40%',
                          margin: '0px 0px 10px 5%'
                        }}
                      >
                        <div className="ui icon input">
                          <input
                            type="text"
                            placeholder="Search Backers"
                            className="prompt"
                            onChange={(event) => filterBacker(event)}
                          />
                          <i className="search icon"></i>
                        </div>
                      </div>
                      <div
                        className="backer-list"
                        style={{
                          height: '60vh',
                          width: '100%',
                          marginTop: '8px'
                        }}
                      >
                        <ShareBackerListing
                          rows={backersData}
                          adminList={adminList}
                          setBackersDataMap={setBackersDataMap}
                          backersDataMap={backersDataMap}
                          isShowColumns={showAllColumn}
                          handlePublishAndShare={handlePublishAndShare}
                          handleCloseModal={props.handleCloseModal}
                          isFiltered={isFiltered}
                        />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center'
                          // position: 'relative',
                          // margin: '8% auto 2% auto',
                        }}
                      >
                        <div style={{ margin: '3% auto 2% auto' }}>
                          <div className="d-flex mt-3">
                            <button
                              type="button"
                              onClick={() => {
                                if ((backersDataMap.size > 0 && value === 'custom') || value === 'allbackers') {
                                  const allBackers = value === 'allbackers';
                                  props.handlePublish(backersDataMap, allBackers);
                                  props.handleCloseModal();
                                } else {
                                  alert('Please select backers ');
                                }
                              }}
                              className="btn btn-outline-primary"
                              style={{
                                background: '#0099ff',
                                color: '#ffffff'
                              }}
                            >
                              Share
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                props.handleCloseModal();
                              }}
                              style={{ marginLeft: '20px' }}
                              className="btn btn-outline-primary"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
                // position: 'relative',
                // margin: '8% auto 2% auto',
              }}
            >
              <div style={{ margin: '30px 0px 30px 94px' }}>
                <div className="d-flex mt-3">
                  <button
                    type="button"
                    onClick={() => {
                      if ((backersDataMap.size > 0 && value === 'custom') || value === 'allbackers') {
                        handleAllBackersPublish();
                      } else {
                        alert('Please select backers ');
                      }
                    }}
                    className="btn btn-outline-primary"
                    style={{
                      background: '#0099ff',
                      color: '#ffffff'
                    }}
                  >
                    Share
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      props.handleCloseModal();
                    }}
                    style={{ marginLeft: '20px' }}
                    className="btn btn-outline-primary"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default BackerSelectionModal;
