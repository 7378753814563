import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import UserImage from '../../../assests/image/user.png';

export default function LeadInvestorList(props) {
  const {
    user,
    isMandatory,
    labelTitle,
    investorList,
    notes,
    innerText,
    addInvestors,
    removeInvestor,
    index = null,
    setFieldValue,
    removeIcon,
    errors,
    labelName
  } = props;
  return (
    <div className="edit-form-row">
      <label className="edit__profile_label-title">
        {labelTitle} {isMandatory ? <span className="text-danger">*</span> : ''}
      </label>
      <div className="d-flex justify-content-start mt-1 row" style={{ marginLeft: '-5px' }}>
        {(user === 'founder' ? investorList.length === 0 : true) && (
          <div
            className="add-investor-box d-flex justify-content-center align-items-center m-2 col-3"
            onClick={index != null ? (e) => addInvestors(e, index) : addInvestors}
          >
            <span className="d-flex justify-content-center">
              <AddIcon style={{ marginRight: '10px' }} />
              {innerText}
            </span>
          </div>
        )}
        {investorList.map((item, key) => (
          <div
            className="add-investor-box custom-box d-flex align-items-center justify-content-start m-2 p-3 col-3"
            key={key}
          >
            <div className="p-1">
              <img src={item.profile_image || UserImage} height="50px" width="50px" className="rounded-circle" alt="" />
            </div>
            <div className="d-flex flex-column p-1 ms-3">
              <div>
                {!!item.label && <div style={{ fontSize: '14px' }}>{item.label}</div>}
                {!!item.lead_carry && <div style={{ fontSize: '12px' }}>{`${item.lead_carry}%`}</div>}
              </div>
            </div>
            {!!removeIcon && (
              <div
                className="align-self-start"
                style={{ paddingLeft: 30, cursor: 'pointer' }}
                onClick={() => removeInvestor(item.value, setFieldValue, investorList)}
              >
                <DeleteOutlinedIcon />
              </div>
            )}
          </div>
        ))}
      </div>
      {isMandatory && errors && errors[labelName] && (
        <div className="text-danger" style={{ marginTop: '0.3rem', fontSize: 14 }}>
          {errors[labelName]}
        </div>
      )}
      {notes && <div className="edit_profile_notes"> {notes} </div>}
    </div>
  );
}
