import React from 'react';
import Card from 'react-bootstrap/Card';
import { useHistory } from 'react-router-dom';
import PostHogManager from '../analytics/postHogManager';
import '../assests/style/common.scss';
import Icon from '../common/icons/Icon';
import { getUser } from '../common/utils/common';

function Home() {
  const history = useHistory();
  const userDetails = getUser();

  const dealRouteHandler = () => {
    history.push({
      pathname: '/deal',
      tabValue: 2,
    });
  };

  const syndicateType = localStorage.getItem('syndicate_type');

  return (
    <div className="homeMainDiv container mt-5">
      <div className="homeFlxDiv">
        <span style={{ fontSize: '20px', fontWeight: '400' }}>
          Hi {userDetails.name}!
        </span>

        <span className="usrGreet">
          Welcome to LetsVenture Syndicate Portal!
        </span>
      </div>
      <div className="cardDiv">
        <Card
          className="homeCard"
          onClick={() => {
            PostHogManager.trackEvent('Add Deal Card Click', {
              source: 'Dashboard',
              description: 'Clicked on Add Deal Card on Dashboard',
            });
            history.push('/startup/create_deal');
          }}
        >
          <Card.Body className="p-5">
            <div className="py-4">
              <Icon name="BoxIcon" width="50" height="50" />
            </div>
            <div className="cardHead">Add Deal</div>
            <div className="cardDtls">
              Create a deal and get commitments from your backers{' '}
            </div>
            <div className="">
              <Icon
                name="ArrowRight"
                width="24"
                height="24"
                style={{ position: 'absolute', bottom: 20 }}
              />
            </div>
          </Card.Body>
        </Card>
        {syndicateType == 'zeno' && (
          <Card
            className="homeCard"
            onClick={() => {
              PostHogManager.trackEvent('Add Deal Card Click', {
                source: 'Dashboard',
                description: 'Clicked on Add Deal Card on Dashboard',
              });
              history.push('/capital');
            }}
          >
            <Card.Body className="p-5">
              <div className="py-4">
                <Icon name="BoxIcon" width="50" height="50" />
              </div>
              <div className="cardHead">Manage Capital</div>
              <div className="cardDtls">
                Manage PreCommit Syndicate details.{' '}
              </div>
              <div className="">
                <Icon
                  name="ArrowRight"
                  width="24"
                  height="24"
                  style={{ position: 'absolute', bottom: 20 }}
                />
              </div>
            </Card.Body>
          </Card>
        )}
        <Card
          className="homeCard"
          onClick={() => {
            PostHogManager.trackEvent('Manage Backers Card Click', {
              source: 'Dashboard',
              description: 'Clicked on Manage Backers Card on Dashboard',
            });
            history.push('/manage/investor');
          }}
        >
          <Card.Body className="p-5">
            <div className="py-4">
              <Icon name="MultiUser" width="50" height="50" />
            </div>
            <div className="cardHead">Manage Backers</div>
            <div className="cardDtls">
              Invite and manage all the backers that are backing your syndicate.{' '}
            </div>
            <div className="">
              <Icon
                name="ArrowRight"
                width="24"
                height="24"
                style={{ position: 'absolute', bottom: 20 }}
              />
            </div>
          </Card.Body>
        </Card>
        <Card
          className="homeCard"
          onClick={() => {
            PostHogManager.trackEvent('Syndicate Profile Card Click', {
              source: 'Dashboard',
              description: 'Clicked on Syndicate Profile Card on Dashboard',
            });
            history.push('/syndicate_profile');
          }}
        >
          <Card.Body className="p-5">
            <div className="py-4">
              <Icon name="UserIcon" width="50" height="50" />
            </div>
            <div className="cardHead">Syndicate Profile</div>
            <div className="cardDtls">
              Edit your Syndicate&apos;s profile page (backers see this)
            </div>
            <div className="">
              <Icon
                name="ArrowRight"
                width="24"
                height="24"
                style={{ position: 'absolute', bottom: 20 }}
              />
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default Home;
