import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Avatar from '@mui/material/Avatar';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { DataGrid } from '@mui/x-data-grid';

import callApi from '../api';
import '../assests/style/backers_list.scss';
import Icon from '../common/icons/Icon';
import { getSyndicateId, getSyndicatePermalink } from '../common/utils/common';
import { httpMethods } from '../constants';

const useStyles = makeStyles({
  gridRoot: {
    paddingLeft: '5%',
  },
});

export const rupeeFormatter = new Intl.NumberFormat('en-IN', {
  currency: 'INR',
});

const backersList = (props) => {
  const syndicatePermalink = getSyndicatePermalink();
  const [backerDetails, setBackerDetails] = useState([]);
  const [backerTransactionsData, setBackerTransationData] = useState([]);
  const [open, setOpen] = useState(true);
  const classes = useStyles();

  const handleClick = (id) => {
    setOpen(!open);
  };

  const switchToDetailedPage = (id) => {
    props.setBacker(id);
  };

  const backersDataColumns = [
    {
      field: 'name',
      headerName: 'BACKERS NAME',
      width: 400,
      renderCell: (params) => (
        <div style={{ paddingLeft: '5%' }}>
          <span
            className="d-flex align-items-center"
            style={{ fontSize: '13px', fontWeight: 'bold' }}
            onClick={() => switchToDetailedPage(params.row.id)}
          >
            <Avatar
              style={{
                width: '30px',
                height: '30px',
                marginRight: '20px',
              }}
              alt={params.row.name}
              src={params.row.profile_image}
            />
            <span className="d-inline-block">
              <div disabled style={{ pointerEvents: 'none' }}>
                {params.row.name}
              </div>
            </span>
          </span>
        </div>
      ),
      renderHeader: () => (
        <div style={{ textAlign: 'center' }}>
          <strong>BACKERS NAME</strong>
        </div>
      ),
    },
    {
      field: 'email',
      headerName: 'EMAIL ADDRESS',
      width: 400,
      renderCell: (params) => (
        <div style={{ paddingLeft: '5%' }}>{params.row.email}</div>
      ),
      renderHeader: () => (
        <div style={{ textAlign: 'center' }}>
          <strong>EMAIL ADDRESS</strong>
        </div>
      ),
    },
    {
      field: 'committed_amount',
      headerName: 'Precommitted  Amount',
      width: 300,
      renderCell: (params) => (
        <div style={{ textAlign: 'right', paddingLeft: '5%' }}>
          ₹ {rupeeFormatter.format(params.row.committed_amount)}
        </div>
      ),
      renderHeader: () => (
        <div style={{ textAlign: 'center' }}>
          <strong>Precommitted Amount</strong>
        </div>
      ),
    },
    {
      field: 'investible_amount',
      headerName: 'INVESTIBLE AMOUNT',
      width: 300,
      renderCell: (params) => (
        <div style={{ paddingLeft: '5%' }}>
          ₹ {rupeeFormatter.format(params.row.investible_amount)}
        </div>
      ),
      renderHeader: () => (
        <div style={{ textAlign: 'center' }}>
          <strong>INVESTIBLE AMOUNT</strong>
        </div>
      ),
    },
    {
      field: 'balance_amount',
      headerName: 'AVAILABLE AMOUNT',
      width: 300,
      renderCell: (params) => (
        <div style={{ paddingLeft: '5%' }}>
          ₹ {rupeeFormatter.format(params.row.balance_amount)}
        </div>
      ),
      renderHeader: () => (
        <div style={{ textAlign: 'center' }}>
          <strong>AVAILABLE AMOUNT</strong>
        </div>
      ),
    },
  ];

  const getSyndicateDetails = () => {
    const syndicateId = getSyndicateId();
    const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
    const url = `${djangoBaseUrl}/v1/syndicates/get_all_backers_page/?id=${syndicateId}`;
    callApi(
      url,
      httpMethods.HTTP_GET,
      '',
      {},
      false
    ).then((response) => {
      setBackerDetails(response.data.backers_details);
      setBackerTransationData(response.data.backer_transactions_data);
    });
  };

  useEffect(() => {
    getSyndicateDetails();
  }, []);

  return (
    <>
      <div className="syndicateInfoHeader">
        <ListItemButton onClick={handleClick}>
          <ListItemText
            primary="Summary"
            primaryTypographyProps={{
              fontSize: '16px !important',
              fontWeight: 600,
            }}
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div className="commonText syndicateInfo">
            <div className="infoColumn">
              <div>Total Pre Committed Fund</div>
              <div>
                <b>
                  ₹{' '}
                  {rupeeFormatter.format(
                    backerTransactionsData.total_committed
                  )}
                </b>
                <p style={{ fontSize: '12px', lineHeight: '2' }}>
                  Including Management Fee + GST
                </p>
              </div>
            </div>
            <div className="infoColumn">
              <div>Total Called In Capital</div>
              <div>
                <b>
                  ₹{' '}
                  {rupeeFormatter.format(
                    backerTransactionsData.total_called_in
                  )}
                </b>
              </div>
            </div>
            <div className="infoColumn">
              <div>Total Paid In Capital</div>
              <div>
                <b>
                  ₹{' '}
                  {rupeeFormatter.format(backerTransactionsData.total_paid_in)}
                </b>
              </div>
            </div>
            <div className="infoColumn">
              <div>Total Investible Corpus </div>
              <div>
                <b>
                  ₹ {rupeeFormatter.format(backerTransactionsData.total_investible_funds)}
                </b>
              </div>
            </div>
            <div className="infoColumn">
              <div>Amount Deployed</div>
              <div>
                <b>
                  ₹{' '}
                  {rupeeFormatter.format(backerTransactionsData.total_amount_committed)}
                </b>
              </div>
            </div>
            <div className="infoColumn">
              <div>Balance Capital with LV</div>
              <div>
                <b>
                  ₹{' '}
                  {rupeeFormatter.format(backerTransactionsData.total_available_funds)}
                </b>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
      <div className="containerBox">
        <div>
          <div className="dataHeader">
            <Icon name="ThreeCirclesIcon" style={{ marginRight: '10px' }} />
            Manage Capital &nbsp;| &nbsp;
            {backerDetails.length}
          </div>
        </div>
        <DataGrid
          classes={{ columnHeaderTitleContainerContent: classes.gridRoot }}
          className="container"
          style={{
            fontSize: '14px',
            backgroundColor: '#fff',
            // height: '85%',
            maxWidth: '100%',
          }}
          rows={backerDetails}
          columns={backersDataColumns}
          hideFooter
          autoHeight
        />
      </div>
    </>
  );
};

export default backersList;
