import { makeStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import PostHogManager from '../analytics/postHogManager';

const enhancedTableHeadStyles = makeStyles((theme) => ({
  checkboxRoot: {
    alignItems: 'center',
    marginTop: '14%',
    textAlign: 'center',
    width: 'fit-content'
  },
  commonCheckboxRoot: {
    textAlign: 'center'
  },
  tableHead: {
    zIndex: '900',
    position: 'relative'
  },
  tableHeadRow: {
    backgroundColor: '#f4f4f4',
    position: 'sticky',
    top: 0
  },
  tableRowCommon: {
    fontSize: '14px',
    lineHeight: '15.5px'
  }
}));

function EnhancedTableHead(props) {
  const { onSelectAllClick, selectedRows, rowCount, showColumn, isModify } = props;

  const classes1 = enhancedTableHeadStyles();
  const selectedLength = () => {
    const count = selectedRows.length;
    return count;
  };

  const headCells = showColumn
    ? [
        {
          id: 'name',
          numeric: false,
          disablePadding: true,
          label: 'Name'
        },
        {
          id: 'email',
          numeric: true,
          disablePadding: false,
          label: 'Email Id'
        },
        {
          id: 'condition',
          numeric: true,
          disablePadding: false,
          label: 'Select Condition'
        },
        {
          id: 'proposed_amount',
          numeric: true,
          disablePadding: false,
          label: 'Enter Amount'
        }
      ]
    : [
        {
          id: 'name',
          numeric: false,
          disablePadding: true,
          label: 'Name'
        },
        {
          id: 'email',
          numeric: true,
          disablePadding: false,
          label: 'Email Id'
        }
      ];

  return (
    <TableHead classes={{ root: classes1.tableHead }}>
      <TableRow classes={{ root: classes1.tableHeadRow }}>
        <TableCell
          padding="checkbox"
          classes={selectedLength() > 0 ? { root: classes1.checkboxRoot } : { root: classes1.commonCheckboxRoot }}
        >
          <Checkbox
            indeterminate={selectedLength() > 0 && selectedLength() < rowCount}
            checked={rowCount > 0 && selectedLength() === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
            disabled={isModify}
          />
          <span>{selectedLength() > 0 && selectedLength()}</span>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            classes={{ root: classes1.tableRowCommon }}
          >
            <b>{headCell.label}</b>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    position: 'static',
    height: '55vh'
  },
  paper1: {
    width: '100%',
    marginBottom: theme.spacing(2),
    position: 'static',
    height: '59vh'
  },
  tableContainer: {
    position: 'relative',
    height: '100%'
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  rootCheckbox: {
    width: '4% !important',
    textAlign: 'center'
  },
  amountCell: {
    // width: '30%',
  },
  emailCell: {
    padding: '26px 26px 26px 16px',
    width: '30%'
  },
  nameCell: {
    width: '20%'
  },
  blockedRow: {
    filter: 'blur(0.5px)',
    backdropFilter: 'blur(1px)',
    backgroundColor: 'rgba(217, 217, 217, 0.54)'
  }
}));

export default function ShareBackerListing(props) {
  const {
    rows,
    adminList = [],
    setBackersDataMap,
    backersDataMap,
    isShowColumns = false,
    isModify = false,
    isFiltered = false
  } = props;
  const classes = useStyles();
  const BackersMap = new Map();
  !!rows &&
    rows.forEach((row) => {
      BackersMap.set(row.id, row);
    });
  const eligibleBackers = !!rows && rows.filter((row) => !row.user_deactivated);
  const [selected, setSelected] = useState({});
  const [showColumn, setShowColumn] = useState(isShowColumns);
  const [selectedIds, setSelectedIds] = useState([]);
  const [flag, setFlag] = useState(false);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const idArr = [];
      rows.forEach((row, index) => {
        idArr.push(row.id);
        selected[row.id] = row;
      });
      setSelectedIds(idArr);
      setSelected(selected);
      for (const [key, value] of BackersMap) {
        if (!value.user_deactivated) {
          backersDataMap.set(key, value);
        }
      }
      setBackersDataMap(backersDataMap);
      return;
    }
    setSelected([]);
    setBackersDataMap(new Map());
  };

  const allRows = (rws, admins) => {
    return isFiltered ? [...rows] : [...admins, ...rws];
  };

  const handleSelectClick = (event, selectedIndex, rowId) => {
    if (event.target.checked) {
      selected[rowId] = BackersMap.get(rowId);
      setSelectedIds([...selectedIds, rowId]);
      backersDataMap.set(rowId, BackersMap.get(rowId));
      setBackersDataMap(backersDataMap);
    } else if (selected[rowId]) {
      const newSelectedIds = selectedIds.filter((id) => id !== rowId);
      setSelectedIds(newSelectedIds);
      const newSelectedBackers = rows.filter((row) => row.id !== rowId);
      backersDataMap.delete(rowId);
      setBackersDataMap(backersDataMap);
      delete selected[rowId];
    }

    setSelected(selected);
  };

  const handleOptionSelect = (event, index, rowId) => {
    const val = event.target.value;
    rows.filter((row) => row.id == rowId)[0].condition = val;
    const obj = BackersMap.get(rowId);
    obj.condition = val;
    if (val === '') {
      obj.proposed_amount = '';
    }
    backersDataMap.set(rowId, obj);
    setFlag(!flag);
    setBackersDataMap(backersDataMap);
  };

  const handleAmountChange = (event, index, rowId) => {
    const val = event.target.value;
    if (val !== '' && (val < 1 || val % 0.5 !== 0)) {
      document.getElementById('proposed_amt').value = '';
      toast.error('You can enter amount in the multiples of 0.5 lakh only.');
    } else {
      rows.filter((row) => row.id == rowId)[0].proposed_amount = val;
      const obj = BackersMap.get(rowId);
      obj.proposed_amount = val;
      backersDataMap.set(rowId, obj);
      setFlag(!flag);
      setBackersDataMap(backersDataMap);
    }
  };

  const handleChangeDense = (event) => {
    setShowColumn(event.target.checked);
    PostHogManager.trackEvent('Configure commitment Amount Toggle bar clicks', {
      source: 'Publish popup, Share Popup',
      description: 'Whenever the user switches the toggler bar (on/off), trigger an event ',
      toggle: event.target.checked
    });
  };

  const isSelected = (idd) => {
    return backersDataMap.has(idd);
  };

  return (
    <div className={classes.root}>
      <div style={{ marginLeft: '5%', marginBottom: '2%' }}>
        <FormControlLabel
          control={<Switch checked={showColumn} onChange={handleChangeDense} disabled={isModify} />}
          label="Customize commitment values"
        />
      </div>
      <Paper className={isModify ? classes.paper1 : classes.paper}>
        <TableContainer classes={{ root: classes.tableContainer }}>
          <Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
            <EnhancedTableHead
              selectedRows={[...backersDataMap.values()]}
              onSelectAllClick={handleSelectAllClick}
              rowCount={eligibleBackers.length}
              showColumn={showColumn}
              isModify={isModify}
            />
            <TableBody classes={{ root: classes.tableBody }}>
              {!!rows &&
                allRows(rows, adminList).map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <Tooltip
                      title={
                        !!row.admin
                          ? isModify
                            ? 'This user is an Admin for this syndicate. Values cannot be modified for them.'
                            : 'This user is an Admin for this syndicate. A default access to the deal will be granted to them'
                          : ''
                      }
                      arrow
                    >
                      <TableRow
                        hover
                        classes={
                          isModify
                            ? (!!row.admin || !!!row.can_edit || !!row.user_deactivated) && {
                                root: classes.blockedRow
                              }
                            : (!!row.admin || !!row.user_deactivated) && {
                                root: classes.blockedRow
                              }
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox" size="medium" classes={{ root: classes.rootCheckbox }}>
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleSelectClick(event, index, row.id)}
                            inputProps={{ 'aria-labelledby': labelId }}
                            disabled={
                              isModify
                                ? !!row.admin || !!!row.can_edit || !!row.user_deactivated
                                : !!row.admin || !!row.user_deactivated
                            }
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          align="left"
                          classes={{ root: classes.nameCell }}
                        >
                          <div style={{ width: '20vw' }}>
                            <span
                              className="d-flex align-items-left"
                              style={{
                                fontSize: '12px',
                                fontWeight: 'bold',
                                alignItems: 'center'
                              }}
                            >
                              <Avatar
                                style={{
                                  width: '30px',
                                  height: '30px',
                                  marginRight: '10px'
                                }}
                                alt={row.name}
                                src={row.profile_image}
                              />

                              <span className="d-inline-block">
                                <div disabled style={{ pointerEvents: 'none' }}>
                                  {row.name}
                                </div>
                              </span>
                            </span>
                          </div>
                        </TableCell>
                        <TableCell align="left" classes={{ root: classes.emailCell }}>
                          <div style={{ width: '20vw' }}>{row.email}</div>
                        </TableCell>
                        {showColumn && (
                          <>
                            <TableCell align="left">
                              <select
                                value={!!row && row.condition}
                                onChange={(e) => handleOptionSelect(e, index, row.id)}
                                disabled={
                                  isModify
                                    ? !!row.admin || !!!row.can_edit || !!row.user_deactivated
                                    : !!row.admin || !!row.user_deactivated
                                }
                                style={{
                                  width: '23vw',
                                  padding: '10px',
                                  borderRadius: '5px',
                                  border: 'solid 1px #898989',
                                  backgroundColor: `${
                                    isModify
                                      ? !!row.admin || !!!row.can_edit || !!row.user_deactivated
                                        ? 'rgba(217, 217, 217, 0.54)'
                                        : '#fff'
                                      : !!row.admin || !!row.user_deactivated
                                      ? 'rgba(217, 217, 217, 0.54)'
                                      : '#fff'
                                  }`,
                                  cursor: 'pointer',
                                  textAlign: 'left'
                                }}
                              >
                                <option aria-label="None" value="" style={{ padding: '10px', fontSize: '13px' }}>
                                  None
                                </option>
                                <option value={0} style={{ padding: '10px' }}>
                                  Specify min commitment amount
                                </option>
                                <option value={1} style={{ padding: '10px' }}>
                                  Specify commitment amount (Don’t allow modifications)
                                </option>
                                <option value={2} style={{ padding: '10px' }}>
                                  Specify commitment amount and allow greater than the specified value
                                </option>
                              </select>
                            </TableCell>
                            <TableCell align="left" classes={{ root: classes.amountCell }}>
                              <div style={{ fontSize: '14px', width: '12vw' }}>
                                {'\u20B9'} &nbsp;
                                <input
                                  id="proposed_amt"
                                  type="number"
                                  min="0"
                                  step="0.5"
                                  style={{
                                    border: '1px solid grey',
                                    padding: '5px',
                                    width: '30%'
                                  }}
                                  value={
                                    isModify
                                      ? row.proposed_amount
                                      : (backersDataMap.has(row.id) && backersDataMap.get(row.id).proposed_amount) || ''
                                  }
                                  onChange={(event) => handleAmountChange(event, index, row.id)}
                                  disabled={
                                    isModify
                                      ? !!row.admin || !!!row.can_edit || !!row.user_deactivated
                                      : !!row.admin ||
                                        !!row.user_deactivated ||
                                        !backersDataMap.has(row.id) ||
                                        backersDataMap.get(row.id).condition === '' ||
                                        backersDataMap.get(row.id).condition === null
                                  }
                                />
                                &nbsp; Lakh
                              </div>
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    </Tooltip>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
