import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button } from 'react-bootstrap';
import PostHogManager from '../analytics/postHogManager';
import callApi from '../api';
import '../assests/style/datagrid.scss';
import Icon from '../common/icons/Icon';
import { getSyndicateId, getToken } from '../common/utils/common';
import { httpMethods } from '../constants';
import AddUserSlide from './AddUserSlide';

const remove = (values) => {
  const syndicateId = getSyndicateId();
  const token = getToken();
  const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
  const url = `${djangoBaseUrl}/v1/syndicates/invite_member/?type_of_member=admin&type_of_action=deactivate&id=${syndicateId}`;
  PostHogManager.trackEvent('Remove Syndicate Member Submitted', {
    syndicateId,
    source: 'Syndicate Admin Management Page',
    description: 'Removing Syndicate Member Submitted',
    submitted_data: { syndicate_member_id: values.syndicate_member_id, email: values.email, name: values.fullName }
  });
  callApi(
    url,
    httpMethods.HTTP_POST,
    {
      syndicate_member_id: values.syndicate_member_id,
      email: values.email,
      name: values.fullName
    },
    {},
    false
  )
    .then((response) => {
      PostHogManager.trackEvent('Remove Syndicate Members - Successfull', {
        syndicateId,
        source: 'Syndicate Admin Management Page',
        description: 'Removing Syndicate Member Successfull',
        response_data: response
      });
      window.location.reload();
    })
    .catch((error) => {
      PostHogManager.trackEvent('Remove Syndicate Members - Failed', {
        syndicateId,
        source: 'Syndicate Admin Management Page',
        description: 'Removing Syndicate Member Failed',
        response_data: error
      });
    });
};

export default function UserManagement() {
  const [userList, setUserList] = useState();
  const [rowCount, setRowCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [tempParams, setTempParams] = useState({});
  const [isLocked, setIsLocked] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    const syndicateId = getSyndicateId();
    PostHogManager.trackEvent('Closed Remove Syndicate Member Modal', {
      syndicateId,
      source: 'Syndicate Admin Management Page',
      description: 'Closed Remove Syndicate Member Modal'
    });
    setOpen(false);
  };

  const confirmRemove = (values) => {
    const syndicateId = getSyndicateId();
    PostHogManager.trackEvent('Opened Remove Syndicate Member Modal', {
      syndicateId,
      source: 'Syndicate Admin Management Page',
      description: 'Opened Remove Syndicate Member Modal',
      submitted_data: values
    });
    setTempParams(values);
    handleClickOpen();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const syndicateId = getSyndicateId();
    const token = getToken();
    const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
    const url = `${djangoBaseUrl}/v1/syndicates/fetch_syndicate_members/?id=${syndicateId}`;
    async function init() {
      if (syndicateId) {
        PostHogManager.trackEvent('Fetch Syndicate Members Submitted', {
          syndicateId,
          source: 'Syndicate Admin Management Page',
          description: 'Fetching Syndicate Members'
        });
        callApi(url, httpMethods.HTTP_GET, '', {}, false)
          .then((response) => {
            PostHogManager.trackEvent('Fetch Syndicate Members - Successfull', {
              syndicateId,
              source: 'Syndicate Admin Management Page',
              description: 'Fetching Backers List Successfull',
              response_data: response
            });
            setUserList(response.data.result);
            setRowCount(response.data.result.length);
            setIsLocked(response.data.is_locked);
          })
          .catch((error) => {
            PostHogManager.trackEvent('Fetch Syndicate Members - Failed', {
              syndicateId,
              source: 'Syndicate Admin Management Page',
              description: 'Fetching Backers List Failed',
              response_data: error
            });
          });
      }
    }
    init();
  }, []);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      headerClassName: 'headerStyle',
      width: 200
    },
    { field: 'email', headerName: 'Email Id', width: 400 },
    {
      field: 'active_from',
      headerName: 'Active from',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160
    },
    {
      field: 'onboarding_status',
      headerName: 'Onboarding Status',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160
    },
    {
      field: 'role',
      headerName: 'Action',
      width: 300,
      renderCell: (params) => (
        <div className="d-flex justify-content-between w-100">
          {params.row.status === 'Active' && false && <div className="my-auto">{params.value}</div>}
          {rowCount > 1 && params.row.can_remove && !params.row.primary_owner && (
            <Button
              variant="contained"
              onClick={() =>
                confirmRemove({
                  syndicate_member_id: params.row.id,
                  email: params.row.email,
                  name: params.row.name
                })
              }
              style={{
                backgroundColor: 'white',
                fontWeight: 'bold',
                height: '30px',
                fontSize: '12px',
                border: '1px solid #0099FF',
                color: '#0099FF'
              }}
            >
              Remove
            </Button>
          )}
        </div>
      )
    }
  ];

  const rows = userList || [];

  return (
    <div>
      <div className="user_management" style={{ background: '#d9d9d9', padding: '10px 0px 1px 0px' }}>
        <div className="container">
          <Breadcrumb className="m-0">
            <Breadcrumb.Item href="/home" className="m-0">
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item active href="#" className="m-0">
              Syndicate Admin Management
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div>
        <div
          className="container my-5"
          style={{
            display: 'flex',
            flexDirection: 'row',
            margintLeft: '50px'
          }}
        >
          <Icon name="userManagement" width={30} height={30} style={{ marginRight: '10px' }} />
          <div style={{ fontSize: '20px' }}>Syndicate Admin Management</div>
        </div>
        <div className="container">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '10px'
            }}
          >
            <h4>
              {rows.length}
              {'  '}
              Admin(s)
            </h4>
            {!isLocked && <AddUserSlide />}
          </div>
        </div>
      </div>
      <div
        style={{
          height: '100vh',
          width: '100%',
          backgroundColor: 'white',
          paddingTop: '30px',
          paddingBottom: '30px'
        }}
      >
        <DataGrid
          className="container"
          style={{ fontSize: '14px' }}
          rows={rows}
          columns={columns}
          hideFooter
          // hideFooterPagination
        />
      </div>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">CONFIRM</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to remove this user?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>No</Button>
            <Button onClick={() => remove(tempParams)} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
