import { makeStyles } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { toast } from 'react-toastify';
import PostHogManager from '../analytics/postHogManager';
import callApi from '../api';
import '../assests/style/common.scss';
import Loading from '../common/components/Loading';
import { getSyndicateId, getSyndicatePermalink, getToken } from '../common/utils/common';
import { httpMethods } from '../constants';
import CommunicationModal from '../deal/CommunicationModal';
import InvitePeopleSlide from './components/invitePeopleSlide';

import '../assests/style/datagrid.scss';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5)
}));

const useStyles = makeStyles({
  invitedBar: {
    backgroundColor: '#dcf0f7 !important'
  },
  selectInvitedBar: {
    backgroundColor: '#dcf0f7 !important',
    borderRight: '3px solid #09f'
  },
  profileCreatedBar: {
    backgroundColor: '#fbe0d9 !important'
  },
  selectProfileCreatedBar: {
    backgroundColor: '#fbe0d9 !important',
    borderRight: '3px solid #09f'
  },
  caSentBar: {
    backgroundColor: '#fdefd8 !important'
  },
  selectCaSentBar: {
    backgroundColor: '#fdefd8 !important',
    borderRight: '3px solid #09f'
  },
  caSignBar: {
    backgroundColor: '#eafcf3 !important'
  }
});

export default function People() {
  const classes = useStyles();
  const token = getToken();
  const [loading, setLoading] = useState(true);
  const syndicatePermalink = getSyndicatePermalink();
  const [peopleData, setPeopleData] = useState([]);
  const [open, setOpen] = useState(true);
  const [pendingList, setPendingList] = useState([]);
  const [onBoardList, setOnBoardList] = useState([]);

  const [userList, setUserList] = useState();
  const [backersList, setBackersList] = useState();

  const [menuPendingValue, setPendingMenu] = useState(false);
  const [menuBoaredValue, setBoarededMenu] = useState(true);
  const [chipData, setChipData] = useState([]);
  const [pendingCount, setPendingCount] = useState(0);
  const [selectedTab, setSelectedTab] = useState('backers');
  const [isLocked, setIsLocked] = useState(false);
  const [openCommunication, setOpenCommunication] = useState(false);
  const [selectedInvId, setSelectedInvId] = useState(null);
  const [typeOfCommunication, setTypeOfCommunication] = useState('');
  const [communicationPurpose, setCommunicationPurpose] = useState(null);
  const isZeno = localStorage.getItem('syndicate_type') === 'zeno';

  const resendInvite = (invId, type, communicationPurpose) => {
    setSelectedInvId(invId);
    setTypeOfCommunication(type);
    setOpenCommunication(true);
    setCommunicationPurpose(communicationPurpose);
  };

  const handleRemindAll = (type) => {
    setTypeOfCommunication(type);
    setCommunicationPurpose('bulk_remind');
    setOpenCommunication(true);
  };

  const handleReInviteAll = (type) => {
    setTypeOfCommunication(type);
    setCommunicationPurpose('bulk_reinvite');
    setOpenCommunication(true);
  };

  const goToInvestor = (investorId) => {
    sessionStorage.setItem('redirect_to', 'Backers');
    PostHogManager.trackEvent('Investor Details clicks - Backers Page', {
      investorId,
      source: 'Backers Page',
      description: 'Whenever the user clicks on any investor name in Backer Page-Backer Tab, trigger an event '
    });
    window.location.href = `/manage/investor/${investorId}`;
  };

  const onBoardedBackersRows = backersList || [];
  const onBoardedBackersColumns = [
    {
      field: 'name',
      headerClassName: 'headerStyle',
      width: 300,
      renderCell: (params) => (
        <div>
          <span className="d-flex align-items-center" style={{ fontSize: '13px', fontWeight: 'bold' }}>
            <Avatar
              style={{
                width: '30px',
                height: '30px',
                marginRight: '10px'
              }}
              alt={params.row.name}
              src={params.row.profile_image}
            />

            <span className="d-inline-block">
              <div
                onClick={isZeno ? null : () => goToInvestor(params.row.inv_id)}
                style={isZeno ? null : { cursor: 'pointer', color: '#0099ff' }}
              >
                {params.row.name}
              </div>
            </span>
          </span>
        </div>
      ),
      renderHeader: () => <strong>Name</strong>
    },
    {
      field: 'email',
      headerName: 'Email Id',
      width: 300,
      renderCell: (params) => <div>{params.row.email}</div>,
      renderHeader: () => (
        <div style={{ textAlign: 'center' }}>
          <strong>Email Id</strong>
        </div>
      )
    },
    {
      field: 'joined',
      headerName: 'Backed/CA e-Signed Date',
      width: 400,
      renderCell: (params) => <div>{params.row.joined && moment(params.row.joined).format('Do MMM YYYY')}</div>,
      renderHeader: () => <strong>Backed/CA e-Signed Date</strong>
    }
  ];
  const pendingInvitedBackersColumns = [
    {
      field: 'name',
      headerClassName: 'headerStyle',
      width: 300,
      renderCell: (params) => (
        <div>
          <span className="d-flex align-items-center" style={{ fontSize: '13px', fontWeight: 'bold' }}>
            <Avatar
              style={{
                width: '30px',
                height: '30px',
                marginRight: '10px'
              }}
              alt={params.row.name}
              src={params.row.profile_image}
            />

            <span className="d-inline-block">
              <div disabled style={{ pointerEvents: 'none' }}>
                {params.row.name}
              </div>
            </span>
          </span>
        </div>
      ),
      renderHeader: () => <strong>Name</strong>
    },
    {
      field: 'email',
      headerName: 'Email Id',
      width: 300,
      renderCell: (params) => <div>{params.row.email}</div>,
      renderHeader: () => (
        <div style={{ textAlign: 'center' }}>
          <strong>Email Id</strong>
        </div>
      )
    },
    {
      field: 'joined',
      headerName: 'Invited Date',
      width: 150,
      renderCell: (params) => <div>{params.row.joined && moment(params.row.joined).format('Do MMM YYYY')}</div>,
      renderHeader: () => <strong>Invited Date</strong>
    },
    {
      field: 'status',
      headerName: 'Action',
      width: 250,
      sortable: false,
      disableColumnFilter: true,
      disableColumnMenu: true,
      disableColumnSelector: true,
      disableDensitySelector: true,
      disableExtendRowFullWidth: true,
      disableIgnoreModificationsIfProcessingProps: true,
      disableSelectionOnClick: true,
      disableVirtualization: true,
      renderCell: (params) => (
        <div className="d-flex justify-content-between w-100" style={{ width: '400px' }}>
          {params.row.status && params.row.status === 'Invited' && (
            <Button
              variant="outlined"
              style={{
                color: '#0099FF',
                backgroundColor: 'white',
                fontWeight: 'bold',
                height: '38px',
                fontSize: '13px',
                marginRight: '10px'
              }}
              onClick={() => {
                resendInvite(params.row.inv_id, 'no_profile', 're-invite');
                PostHogManager.trackEvent('Invite Again button on Invited tab', {
                  source: 'Invite Again button clicks on Invited tab of Backers page',
                  description: 'When a user clicked on Invite Again button on Invited tab'
                });
              }}
            >
              Re-Invite
            </Button>
          )}
        </div>
      ),
      renderHeader: () => (
        <div className="d-flex justify-content-between w-100" style={{ width: '400px' }}>
          <Button
            variant="outlined"
            style={{
              color: 'white',
              backgroundColor: '#0099FF',
              fontWeight: 'bold',
              height: '38px',
              fontSize: '13px',
              marginRight: '10px'
            }}
            onClick={() => {
              handleReInviteAll('no_profile');
              PostHogManager.trackEvent('Remind All - Profile Created', {
                source: 'Backers Page Invited tab',
                description: 'Whenever the user clicks on Remind All button, trigger an event'
              });
            }}
          >
            Re-Invite All
          </Button>
        </div>
      )
    }
  ];
  const pendingProfileCreatedBackersColumns = [
    {
      field: 'name',
      headerClassName: 'headerStyle',
      width: 300,
      renderCell: (params) => (
        <div>
          <span className="d-flex align-items-center" style={{ fontSize: '13px', fontWeight: 'bold' }}>
            <Avatar
              style={{
                width: '30px',
                height: '30px',
                marginRight: '10px'
              }}
              alt={params.row.name}
              src={params.row.profile_image}
            />

            <span className="d-inline-block">
              <div disabled style={{ pointerEvents: 'none' }}>
                {params.row.name}
              </div>
            </span>
          </span>
        </div>
      ),
      renderHeader: () => <strong>Name</strong>
    },
    {
      field: 'email',
      headerName: 'Email Id',
      width: 300,
      renderCell: (params) => <div>{params.row.email}</div>,
      renderHeader: () => (
        <div style={{ textAlign: 'center' }}>
          <strong>Email Id</strong>
        </div>
      )
    },
    {
      field: 'joined',
      headerName: 'Profile Created Date',
      width: 400,
      renderCell: (params) => <div>{params.row.joined && moment(params.row.joined).format('Do MMM YYYY')}</div>,
      renderHeader: () => <strong>Profile Created Date</strong>
    }
  ];
  const pendingCASentBackersColumns = [
    {
      field: 'name',
      headerClassName: 'headerStyle',
      width: 300,
      renderCell: (params) => (
        <div>
          <span className="d-flex align-items-center" style={{ fontSize: '13px', fontWeight: 'bold' }}>
            <Avatar
              style={{
                width: '30px',
                height: '30px',
                marginRight: '10px'
              }}
              alt={params.row.name}
              src={params.row.profile_image}
            />

            <span className="d-inline-block">
              <div disabled style={{ pointerEvents: 'none' }}>
                {params.row.name}
              </div>
            </span>
          </span>
        </div>
      ),
      renderHeader: () => <strong>Name</strong>
    },
    {
      field: 'email',
      headerName: 'Email Id',
      width: 300,
      renderCell: (params) => <div>{params.row.email}</div>,
      renderHeader: () => (
        <div style={{ textAlign: 'center' }}>
          <strong>Email Id</strong>
        </div>
      )
    },
    {
      field: 'joined',
      headerName: 'CA Sent Date',
      width: 200,
      renderCell: (params) => <div>{params.row.joined && moment(params.row.joined).format('Do MMM YYYY')}</div>,
      renderHeader: () => <strong>CA Sent Date</strong>
    },
    {
      field: 'status',
      headerName: 'Action',
      width: 250,
      sortable: false,
      disableColumnFilter: true,
      disableColumnMenu: true,
      disableColumnSelector: true,
      disableDensitySelector: true,
      disableExtendRowFullWidth: true,
      disableIgnoreModificationsIfProcessingProps: true,
      disableSelectionOnClick: true,
      disableVirtualization: true,
      renderCell: (params) => (
        <div className="d-flex justify-content-between w-100">
          {params.row.status && params.row.status === 'CA Sent' && (
            <Button
              variant="outlined"
              style={{
                color: '#0099FF',
                backgroundColor: 'white',
                fontWeight: 'bold',
                height: '38px',
                fontSize: '13px',
                marginRight: '10px'
              }}
              onClick={() => {
                resendInvite(params.row.inv_id, 'no_ca_sign', 'invite_again');
                PostHogManager.trackEvent('Remind button clicks on CA sign tab', {
                  source: 'Remind button clicks on CA sign tab of Backers page',
                  description: 'When a user clicked on Remind button on Backers tab'
                });
              }}
            >
              Remind
            </Button>
          )}
        </div>
      ),
      renderHeader: () => (
        <div className="d-flex justify-content-between w-100" style={{ width: '400px' }}>
          <Button
            variant="outlined"
            style={{
              color: 'white',
              backgroundColor: '#0099FF',
              fontWeight: 'bold',
              height: '38px',
              fontSize: '13px',
              marginRight: '10px',
              cursor: 'pointer'
            }}
            onClick={() => {
              handleRemindAll('no_ca_sign');
              PostHogManager.trackEvent('Remind All - CA Sent', {
                source: 'Backers Page CA Sent tab',
                description: 'Whenever the user clicks on Remind All button, trigger an event '
              });
            }}
          >
            Remind All
          </Button>
        </div>
      )
    }
  ];

  const rows = userList || [];

  const handlePendingClick = () => {
    setOpen(true);
    setPendingMenu(true);
    setBoarededMenu(false);
  };
  const handleOnboaredClick = () => {
    setSelectedTab('backers');
    PostHogManager.trackEvent('Filter on Backers page', {
      filter: 'Backers',
      source: 'Backers Page',
      description: 'Clicked on the filter in the backers page'
    });
    setOpen(true);
    setBoarededMenu(true);
    setPendingMenu(false);
  };

  const addChip = (chip) =>
    function () {
      PostHogManager.trackEvent('Filter on Backers page', {
        filter: chip,
        source: 'Backers Page',
        description: 'Clicked on the filter in the backers page'
      });
      setSelectedTab(chip);
      switch (chip) {
        case 'Invited':
          setPendingMenu(true);
          setBoarededMenu(false);
          setPendingList(peopleData.invited);
          setUserList(peopleData.invited);
          setChipData(chip);
          return true;
        case 'CA Sent':
          setPendingMenu(true);
          setBoarededMenu(false);
          setPendingList(peopleData.ca_sent);
          setUserList(peopleData.ca_sent);
          setChipData(chip);
          return true;
        case 'Profile Created':
          setPendingMenu(true);
          setBoarededMenu(false);
          setPendingList(peopleData.profile_created);
          setUserList(peopleData.profile_created);
          setChipData(chip);
          return true;
        default:
          return <h1>No project match</h1>;
      }
    };

  const getPeopleApiData = () => {
    const syndicateId = getSyndicateId();
    const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
    const url = `${djangoBaseUrl}/v1/syndicates/syndicate_people_info/?id=${syndicateId}`;
    PostHogManager.trackEvent('Fetch Backers List Submitted', {
      syndicatePermalink,
      source: 'Backers Page',
      description: 'Fetching Backers List'
    });
    setLoading(true);
    callApi(url, httpMethods.HTTP_GET, '', {}, false)
      .then((response) => {
        PostHogManager.trackEvent('Fetch Backers List - Successfull', {
          syndicatePermalink,
          source: 'Backers Page',
          description: 'Fetching Backers List Successfull',
          response_data: response
        });
        const { ca_signed, profile_created_count, ca_sent_count, invited_count, is_locked } = response.data;
        if (chipData === 'Invited') {
          setUserList(response.data.invited);
        } else if (chipData === 'CA Sent') {
          setUserList(response.data.ca_sent);
        } else if (chipData === 'Profile Created') {
          setUserList(response.data.profile_created);
        }
        setIsLocked(is_locked);
        setPeopleData(response.data);
        setOnBoardList(ca_signed);
        setBackersList(ca_signed);
        setPendingCount(profile_created_count + ca_sent_count + invited_count);
        setLoading(false);
      })
      .catch((error) => {
        PostHogManager.trackEvent('Fetch Backers List - Failed', {
          syndicatePermalink,
          source: 'Backers Page',
          description: 'Fetching Backers List Failed',
          response_data: error
        });
        toast.error('something went wrong, Please contact contactus@letsventure.com');
      });
  };

  const performAccessAction = (userId, access) => {
    callApi(`/api/v3/web/syndicates/syndicate/${syndicatePermalink}/give_platform_access`, httpMethods.HTTP_POST, {
      user_id: userId,
      access
    })
      .then((response) => {
        getPeopleApiData();
      })
      .catch((error) => {
        toast.error('something went wrong, Please contact contactus@letsventure.com');
      });
  };

  useEffect(() => {
    getPeopleApiData();
  }, []);

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="container pt-5 flex-wrap mt-4" style={{ display: 'flex', flexDirection: 'row' }}>
      {!loading && !!peopleData && (
        <>
          {/* <h3 style={{ width: '100%' }} className="pb-3">
            <Icon name="multiuser" width="50" height="50" />
            <span style={{ marginLeft: 20 }}>BACKERS</span>
          </h3> */}
          <div width="20%" style={{ marginRight: '20px' }}>
            <List
              style={{
                fontSize: '14px',
                width: '200px',
                backgroundColor: 'white',
                paddingBottom: 0
              }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip placement="bottom" style={{ fontSize: '11px' }}>
                    LV AIF registered investors
                  </Tooltip>
                }
              >
                <ListItemButton
                  onClick={handleOnboaredClick}
                  style={
                    selectedTab === 'backers'
                      ? {
                          display: 'flex',
                          justifyContent: 'space-between',
                          borderRight: '3px solid #09f'
                        }
                      : { display: 'flex', justifyContent: 'space-between' }
                  }
                >
                  <span>
                    {' '}
                    <b>Backers</b>
                  </span>
                  <span>{peopleData.ca_signed_count}</span>
                </ListItemButton>
              </OverlayTrigger>
              <Divider />
              <ListItemButton
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontWeight: '700',
                  cursor: 'default'
                }}
              >
                <span>Pending</span>
                <span>{pendingCount}</span>
              </ListItemButton>

              <Collapse in={open} timeout="auto" unmountOnExit style={{ backgroundColor: '#F9F8F8', paddingBottom: 0 }}>
                <List component="div" disablePadding>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip placement="bottom" style={{ fontSize: '11px' }}>
                        Investors invited to the Platform
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        position: 'relative',
                        fontSize: '14px',
                        cursor: 'pointer'
                      }}
                      onClick={addChip('Invited')}
                    >
                      <LinearProgress
                        className="invitePgrs"
                        variant="determinate"
                        value={100}
                        color="primary"
                        classes={
                          selectedTab === 'Invited' ? { bar: classes.selectInvitedBar } : { bar: classes.invitedBar }
                        }
                        style={{
                          height: '35px',
                          fontSize: '14px',
                          backgroundColor: '#F9F8F8'
                        }}
                      />
                      <Typography
                        style={{
                          position: 'absolute',
                          top: '5px',
                          left: '40px',
                          transform: 'translateX(-50%)',
                          fontSize: '14px',
                          fontWeight: '400',
                          marginLeft: 20
                        }}
                      >
                        Invited
                      </Typography>
                      <Typography
                        style={{
                          position: 'absolute',
                          top: '5px',
                          left: '90%',
                          transform: 'translateX(-50%)',
                          fontSize: '14px'
                        }}
                      >
                        {peopleData.invited_count}
                      </Typography>
                    </div>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip placement="bottom" style={{ fontSize: '11px' }}>
                        Profiles created on LV Platform
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        position: 'relative',
                        fontSize: '14px',
                        cursor: 'pointer'
                      }}
                      onClick={addChip('Profile Created')}
                    >
                      <LinearProgress
                        className="profilePgrs"
                        variant="determinate"
                        value={100}
                        classes={
                          selectedTab === 'Profile Created'
                            ? { bar: classes.selectProfileCreatedBar }
                            : { bar: classes.profileCreatedBar }
                        }
                        style={{
                          height: '35px',
                          fontSize: '14px',
                          backgroundColor: '#F9F8F8'
                        }}
                      />
                      <Typography
                        style={{
                          position: 'absolute',
                          top: '5px',
                          left: '65px',
                          transform: 'translateX(-50%)',
                          fontSize: '14px',
                          marginLeft: 20
                        }}
                      >
                        Profile Created
                      </Typography>
                      <Typography
                        style={{
                          position: 'absolute',
                          top: '5px',
                          left: '90%',
                          transform: 'translateX(-50%)',
                          fontSize: '14px'
                        }}
                      >
                        {peopleData.profile_created_count}
                      </Typography>
                    </div>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip placement="bottom" style={{ fontSize: '11px' }}>
                        Contribution Agreement sent to investors for signing
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        position: 'relative',
                        fontSize: '14px',
                        cursor: 'pointer'
                      }}
                      onClick={addChip('CA Sent')}
                    >
                      <LinearProgress
                        className="sentPgrs"
                        variant="determinate"
                        value={100}
                        // classes={{ bar: classes.caSentBar }}
                        classes={
                          selectedTab === 'CA Sent' ? { bar: classes.selectCaSentBar } : { bar: classes.caSentBar }
                        }
                        style={{
                          height: '40px',
                          fontSize: '14px',
                          backgroundColor: '#F9F8F8'
                        }}
                      />
                      <Typography
                        style={{
                          position: 'absolute',
                          top: '10px',
                          left: '43px',
                          transform: 'translateX(-50%)',
                          fontSize: '14px',
                          marginLeft: 20
                        }}
                      >
                        CA Sent
                      </Typography>
                      <Typography
                        style={{
                          position: 'absolute',
                          top: '10px',
                          left: '90%',
                          transform: 'translateX(-50%)',
                          fontSize: '14px'
                        }}
                      >
                        {peopleData.ca_sent_count}
                      </Typography>
                    </div>
                  </OverlayTrigger>
                </List>
              </Collapse>
            </List>
            {!isLocked && (
              <div className="mt-3">
                <InvitePeopleSlide handlePeopleApiData={getPeopleApiData} />
              </div>
            )}
          </div>

          {/* Backers List */}

          {menuBoaredValue && !menuPendingValue && (
            <div width="80%" style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
              <div
                style={{
                  height: '100vh',
                  width: '100%',
                  backgroundColor: 'white',
                  padding: '30px'
                }}
              >
                <DataGrid
                  className="container"
                  style={{ fontSize: '14px' }}
                  rows={onBoardedBackersRows}
                  columns={onBoardedBackersColumns}
                  //  hideFooter
                />
              </div>
            </div>
          )}

          {/* Backers List Pending Invited */}
          {menuPendingValue && !menuBoaredValue && chipData === 'Invited' && (
            <div width="80%" style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
              <div
                style={{
                  height: '100vh',
                  width: '100%',
                  backgroundColor: 'white',
                  padding: '30px'
                }}
              >
                <DataGrid
                  className="container"
                  style={{ fontSize: '14px' }}
                  rows={rows}
                  columns={pendingInvitedBackersColumns}
                  //  hideFooter
                />
              </div>
            </div>
          )}

          {/* Backers List Pending Profile Created */}
          {menuPendingValue && !menuBoaredValue && chipData === 'Profile Created' && (
            <div
              width="80%"
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '80%'
              }}
            >
              <div
                style={{
                  height: '100vh',
                  width: '100%',
                  backgroundColor: 'white',
                  padding: '30px'
                }}
              >
                <DataGrid
                  className="container"
                  style={{ fontSize: '14px' }}
                  rows={rows}
                  columns={pendingProfileCreatedBackersColumns}
                  // hideFooter
                />
              </div>
            </div>
          )}

          {/* Backers List Pending CA Sent */}
          {menuPendingValue && !menuBoaredValue && chipData === 'CA Sent' && (
            <div
              width="80%"
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '80%'
              }}
            >
              <div
                style={{
                  height: '100vh',
                  width: '100%',
                  backgroundColor: 'white',
                  padding: '30px'
                }}
              >
                <DataGrid
                  className="container"
                  style={{ fontSize: '14px' }}
                  rows={rows}
                  columns={pendingCASentBackersColumns}
                  // hideFooter
                />
              </div>
            </div>
          )}
        </>
      )}
      {openCommunication && (
        <CommunicationModal
          invId={selectedInvId}
          communicationType={typeOfCommunication}
          closeModal={setOpenCommunication}
          selectedInvArr={
            communicationPurpose === 'bulk_remind' || communicationPurpose === 'bulk_reinvite' ? userList : []
          }
          communicationPurpose={communicationPurpose}
        />
      )}
    </div>
  );
}
