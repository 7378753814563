import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import callApi from "../api";
import Loading from "../common/components/Loading";
import { setUserSession } from "../common/utils/common";
import { httpMethods } from "../constants";

const UserLoginRedirection = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [updatedAccessToken, setAccessToken] = useState("");
  const [idTokens, setIdToken] = useState("");
  const {
    getAccessTokenSilently,
    isAuthenticated = false,
    getIdTokenClaims,
    isLoading = true,
    logout,
  } = useAuth0();
  const history = useHistory();

  const getAccessToken = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_GLASSBOARD_AUTH0_AUDIENCE,
        },
      });
      const idToken = await getIdTokenClaims();
      setIdToken(idToken);
      setAccessToken(accessToken);
    } catch (error) {
      toast.error(error.message);
      window.location.href = "/";
    }
  };

  useEffect(() => {
    if (!isLoading) {
      getAccessToken();
    }
  }, [isLoading]);

  const redirectUserTo = async () => {
    // TODO: Add posthog tracking
    try {
      if (isAuthenticated) {
        localStorage.setItem("token", updatedAccessToken);
        const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
        const deviceUrl = `${djangoBaseUrl}/v1/users/create_device_data/`;
        const device = await callApi(
          deviceUrl,
          httpMethods.HTTP_POST,
          {},
          "",
          false
        );
        localStorage.setItem("lv_device_id", device.data.id);
        const userDetailsUrl = `${djangoBaseUrl}/v1/syndicates/get_syndicate_user_details/`;
        const userDetails = await callApi(
          userDetailsUrl,
          httpMethods.HTTP_GET,
          {},
          "",
          false
        );
        setUserSession(
          updatedAccessToken,
          userDetails.data.user,
          userDetails.data.syndicate_id,
          userDetails.data.syndicate_type,
          userDetails.data.syndicate_permalink
        );
        history.push("/my/startup");
      }
    } catch (error) {
      let errorMessage = "";
      if (error.response.status === 404) {
        errorMessage =
          "Email not registered as LV Syndicate Admin. Call your LV contact.";
      } else if (error.response.status === 500) {
        errorMessage =
          "Something went wrong. Please contact contactus@letsventure.com";
      } else {
        errorMessage = "Username or Password is wrong";
      }
      toast.error(errorMessage);
      if (isAuthenticated) {
        setTimeout(() => {
          logout({
            logoutParams: {
              returnTo: `${window.location.origin}`,
            },
          });
          localStorage.clear();
        }, 3000);
      }
    } finally {
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    if (updatedAccessToken) {
      redirectUserTo();
    }
  }, [updatedAccessToken, isAuthenticated]);
  if (isPageLoading) {
    return <Loading />;
  }
  return "";
};

export default UserLoginRedirection;
