import { Button } from '@mui/material';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import PostHogManager from '../analytics/postHogManager';
import callApi from '../api';
import '../assests/style/common.scss';
import Loading from '../common/components/Loading';
import CustomProgressBar from '../common/components/ProgressBar';
import Icon from '../common/icons/Icon';
import { formatAmountByPrecision, getSyndicateId, getSyndicatePermalink } from '../common/utils/common';
import { httpMethods } from '../constants';

export default function MyStartup() {
  const [loading, setLoading] = useState(true);
  const syndicatePermalink = getSyndicatePermalink();
  const syndicateId = getSyndicateId();
  const [startupListData, setStartupListData] = useState([]);
  const [startupList, setStartupList] = useState([]);
  const [filtertab, setFiltertab] = useState('Active');
  const history = useHistory();

  const filterDataList = (fltr) => {
    setFiltertab(fltr);
    PostHogManager.trackEvent('Filter on My Startups', {
      filter: fltr,
      source: 'My Startups',
      description: 'Clicked on the filter in the my startups page',
    });
    switch (fltr) {
      case 'Active':
        return setStartupList(startupListData.ongoing_startups);
      case 'All':
        return setStartupList(startupListData.all_startups);
      case 'Draft':
        return setStartupList(startupListData.saved_startups);
      case 'Canceled':
        return setStartupList(startupListData.canceled_startups);
      case 'Complete':
        return setStartupList(startupListData.closed_startups);

      default:
        return <h1>No project match</h1>;
    }
  };

  const setStartupListResponseData = () => {
    setLoading(true);
    const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
    const url = `${djangoBaseUrl}/v1/syndicates/syndicate_startups/?id=${syndicateId}`;
    PostHogManager.trackEvent('Fetch Startups List', {
      syndicatePermalink,
      source: 'My Startups',
      description: 'Fetching startups list',
      submitted_data: { syndicatePermalink },
    });
    callApi(
      url,
      httpMethods.HTTP_GET,
      '',
      {},
      false
    )
      .then((response) => {
        PostHogManager.trackEvent('Fetch Startups List Completed - Success', {
          syndicatePermalink,
          source: 'My Startups',
          description: 'Fetching startups Successfull',
          response_data: response,
        });
        setStartupListData(response.data);
        setStartupList(response.data.ongoing_startups);
        setLoading(false);
      })
      .catch((error) => {
        PostHogManager.trackEvent('Fetch Startups List Completed - Failed', {
          syndicatePermalink,
          source: 'My Startups',
          description: 'Fetching startups Failed',
          response_data: error,
        });
        toast.error(
          'something went wrong,Please contact contactus@letsventure.com'
        );
      });
  };
  const handleDealDetailRouter = (startupPermalink, dealId) => {
    PostHogManager.trackEvent('Clicked on a Startup', {
      startupPermalink,
      source: 'My Startups',
      description: 'Clicked on a Startup',
    });
    localStorage.setItem('dealDetalilPermalink', startupPermalink);
    localStorage.setItem('deal_id', dealId);
    history.push(`/deal/${startupPermalink}`);
  };

  useEffect(() => {
    setStartupListResponseData();
  }, []);

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="container mt-5 mystartup">
      {!loading && (
        <div width="100%" style={{ display: 'flex', flexDirection: 'column' }}>
          <div width="100%" className="py-3">
            <h4>
              <Icon name="BoxIcon" width="30" height="30" />
              &nbsp;&nbsp;&nbsp;MY STARTUPS
            </h4>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="col-mod-3" style={{ minWidth: 250 }}>
              <div
                className={`mystartup-select-tab ${filtertab === 'Active' ? 'active' : ''
                  }`}
                onClick={() => {
                  filterDataList('Active');
                }}
              >
                <span>Active</span>
                <span>{startupListData.ongoing_syndicate_deal_count}</span>
              </div>

              <div
                className={`mystartup-select-tab ${filtertab === 'Draft' ? 'active' : ''
                  }`}
                onClick={() => {
                  filterDataList('Draft');
                }}
              >
                <span>Draft</span>
                <span>{startupListData.saved_syndicate_deal_count}</span>
              </div>

              <div
                className={`mystartup-select-tab ${filtertab === 'Canceled' ? 'active' : ''
                  }`}
                onClick={() => {
                  filterDataList('Canceled');
                }}
              >
                <span>Canceled</span>
                <span>{startupListData.canceled_syndicate_deal_count}</span>
              </div>

              <div
                className={`mystartup-select-tab ${filtertab === 'Complete' ? 'active' : ''
                  }`}
                onClick={() => {
                  filterDataList('Complete');
                }}
              >
                <span>Complete</span>
                <span>{startupListData.closed_syndicate_deal_count}</span>
              </div>

              <div
                className={`mystartup-select-tab ${filtertab === 'All' ? 'active' : ''
                  }`}
                onClick={() => {
                  filterDataList('All');
                }}
              >
                <span>All</span>
                <span>{startupListData.all_count}</span>
              </div>
              <Button
                variant="primary"
                size="md"
                onClick={() => {
                  PostHogManager.trackEvent('Create New Deal Button Click', {
                    source: 'My Startups',
                    description:
                      'Clicked on Create New Deal Button on My Startups',
                  });
                  history.push('/startup/create_deal');
                }}
                style={{
                  background: '#09f',
                  borderRadius: '3px',
                  fontSize: 14,
                  color: '#fff',
                  width: '100%',
                  marginTop: 10,
                }}
              >
                Create New Deal
              </Button>
            </div>
            <div className="col-md-9">
              {startupList &&
                startupList.length > 0 &&
                startupList.map((row) => (
                  <a
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                    onClick={() => handleDealDetailRouter(row.permalink, row.id)}
                    key={row.id}
                  >
                    <Card
                      style={{
                        backGroundColor: 'white',
                        height: '250px',
                        borderRadius: '0px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        fontSize: '16px',
                        marginBottom: '20px',
                        padding: '20px',
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <img
                          height="200"
                          width="300"
                          src={row.startup_banner}
                          alt=""
                        />
                      </div>

                      <div
                        style={{
                          padding: '0 25px',
                          display: 'flex',
                          flexDirection: 'column',
                          width: '70%',
                        }}
                      >
                        <span
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <img
                            height="30"
                            width="30"
                            src={row.logo}
                          />
                          {row && row.startup_status && (
                            <Chip
                              label={row && row.startup_status}
                              color="error"
                            />
                          )}
                        </span>
                        <span
                          style={{
                            fontWeight: 'bold',
                            padding: '5px',
                            fontSize: '16px',
                          }}
                        >
                          {row.name}
                        </span>
                        <span style={{ padding: '5px', fontSize: '12px' }}>
                          {row.sectors}
                        </span>
                        <span
                          style={{
                            fontSize: '10px',
                            padding: '5px',
                            minWidth: '600px',
                          }}
                        >
                          {row.summary}
                        </span>
                        <div style={{ padding: '5px' }}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              padding: '5px',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <span
                                style={{
                                  backgroundColor: '#0D6EFD',
                                  height: '6px',
                                  width: '6px',
                                  borderRadius: '6px',
                                }}
                              />
                              <span
                                style={{
                                  fontWeight: 'bold',
                                  padding: '5px',
                                  fontSize: '12px',
                                }}
                              >
                                Committed
                              </span>
                              <span
                                style={{ padding: '5px', fontSize: '12px' }}
                              >
                                {' '}
                                {row.total_commitment_diy &&
                                  formatAmountByPrecision(row.total_commitment_diy.amount, 2)}
                                <span> </span>
                                {row.total_commitment_diy &&
                                  row.total_commitment_diy.unit}
                              </span>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <span
                                style={{
                                  backgroundColor: '#C1C1C1',
                                  height: '6px',
                                  width: '6px',
                                  borderRadius: '6px',
                                }}
                              />
                              <span
                                style={{
                                  fontWeight: 'bold',
                                  padding: '5px',
                                  fontSize: '12px',
                                }}
                              >
                                LV Ask
                              </span>
                              <span
                                style={{ padding: '5px', fontSize: '12px' }}
                              >
                                {row.lv_ask && formatAmountByPrecision(row.lv_ask.amount, 2)}
                                <span> </span>
                                {row.lv_ask && row.lv_ask.unit}
                              </span>
                            </div>
                          </div>
                          <CustomProgressBar
                            lvRaisedPercentage={row?.lv_raised_percentage ?? 0}
                            receivedPercentage={row?.lv_total_received_percentage ?? 0}
                          />
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              padding: '5px',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <span
                                style={{
                                  backgroundColor: '#0BC11D',
                                  height: '6px',
                                  width: '6px',
                                  borderRadius: '6px',
                                }}
                              />
                              <span
                                style={{
                                  fontWeight: 'bold',
                                  padding: '5px',
                                  fontSize: '12px',
                                }}
                              >
                                Received
                              </span>
                              <span
                                style={{ padding: '5px', fontSize: '12px' }}
                              >
                                {' '}
                                {row?.lv_total_received &&
                                  formatAmountByPrecision(row?.lv_total_received?.amount, 2)}
                                <span> </span>
                                {row?.lv_total_received &&
                                  row?.lv_total_received?.unit}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </a>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
