import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { Button } from 'react-bootstrap';
import { Oval } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import PostHogManager from '../analytics/postHogManager';
import callApi from '../api';
import TextInput from '../common/formik/components/TextInput';
import Icon from '../common/icons/Icon';
import { getSyndicateId, getSyndicatePermalink, getToken } from '../common/utils/common';
import { httpMethods } from '../constants';

const useStyles = makeStyles({
  photo: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'flex-start'
  },
  syn_input_box: {
    padding: '4px 0',
    width: '75%'
  }
});

export const rupeeFormatter = new Intl.NumberFormat('en-IN', {
  currency: 'INR'
});

const zenoCorpus = () => {
  const classes = useStyles();
  const [zenoCorpusData, setZenoCorpusData] = useState();
  const [savingCorpusData, setSavingCorpusData] = useState(false);
  const [topBarData, setTopBarData] = useState();

  const validationSchema = Yup.object({
    corpus_target: Yup.number().required('Required').positive().min(1, 'Must be more than 0'),
    // gp_commitments: Yup.number().positive().min(1, 'Must be more than 0'),
    investment_period: Yup.number().required('Required').positive().min(1, 'Must be more than 0'),
    max_investment: Yup.number().required('Required').positive().min(1, 'Must be more than 0'),
    no_of_investments: Yup.number().positive().min(1, 'Must be more than 0'),
    no_of_drawdowns: Yup.number().required('Required').positive().min(1, 'Must be more than 0'),
    drawdown_interval: Yup.number().required('Required').positive().min(1, 'Must be more than 0')
  });

  const onSubmit = async (values) => {
    setSavingCorpusData(true);
    const syndicateId = getSyndicateId();
    const token = getToken();
    const option = {
      style: { color: '#fff' }
    };
    const formData = new FormData();
    formData.append('corpus', values.corpus_target);
    // formData.append('gp_commitments', values.gp_commitments);
    formData.append('pre_commit_tenure', values.investment_period);
    formData.append('min_investment', values.min_investment);
    formData.append('max_investment', values.max_investment);
    formData.append('deal_per_year', values.no_of_investments);
    formData.append('no_of_drawdowns', values.no_of_drawdowns);
    formData.append('drawdown_interval', values.drawdown_interval);

    PostHogManager.trackEvent('Zeno Corpus Update Submitted', {
      syndicateId,
      source: 'Zeno Corpus Page',
      description: 'Zeno Corpus Update Submitted',
      submitted_data: values
    });
    callApi(
      `/api/v3/web/syndicates/syndicate/${syndicateId}/update_syndicate_profile_corpus_part`,
      httpMethods.HTTP_POST,
      formData
    )
      .then((response) => {
        PostHogManager.trackEvent('Zeno Corpus Update Completed - Success', {
          syndicateId,
          source: 'Zeno Corpus Page',
          description: 'Zeno Corpus Update Successfull',
          response_data: response
        });
        setSavingCorpusData(false);
        toast.success('Zeno Corpus successfully created', option);
        window.location.reload();
      })
      .catch((error) => {
        PostHogManager.trackEvent('Zeno Corpus Update Completed - Failed', {
          syndicateId,
          source: 'Zeno Corpus Page',
          description: 'Zeno Corpus Update Failed',
          response_data: error
        });
        toast.error(error.message, option);
        setSavingCorpusData(false);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    const syndicateId = getSyndicateId();
    const syndicatePermalink = getSyndicatePermalink();
    const token = getToken();
    const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
    const url = `${djangoBaseUrl}/v1/syndicates/get_syndicate_profile_top_bar_data/?permalink=${syndicatePermalink}`;
    async function init() {
      if (syndicateId) {
        callApi(url, httpMethods.HTTP_GET, '', {}, false)
          .then((res) => {
            setTopBarData(res.data);
            const corpusUrl = `${djangoBaseUrl}/v1/syndicates/get_syndicate_profile_corpus_part/?permalink=${syndicatePermalink}`;
            callApi(corpusUrl, httpMethods.HTTP_GET, '', {}, false)
              .then((response) => {
                setZenoCorpusData(response.data);
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    init();
  }, []);

  return (
    <div>
      <div
        style={{
          marginBottom: 50,
          marginTop: 70,
          padding: '15px 0',
          maxWidth: '90%'
        }}
        className="container"
      >
        <Typography
          style={{
            fontSize: '18px',
            fontWeight: 'bold'
          }}
          className="pt-4 pb-3"
        >
          <span style={{ marginRight: '10px' }}>
            <Icon name="newspapericon" width="32" height="32" />
          </span>
          Zeno Corpus Details
        </Typography>
        <div style={{ backgroundColor: '#effaff', padding: '23px 51px 23px 61px' }}>
          <div
            style={{
              height: '105px',
              backgroundColor: '#ffffff',
              display: 'flex',
              justifyContent: 'space-around',
              padding: '23px 0'
            }}
          >
            <div>
              <div>LV Carry</div>
              <div>
                <b>{topBarData && topBarData.lv_carry} %</b>
              </div>
            </div>
            <div>
              <div>Total Carry</div>
              <div>
                <b>{topBarData && topBarData.total_carry} %</b>
              </div>
            </div>
            <div>
              <div>Hurdle Rate</div>
              <div>
                <b>{topBarData && topBarData.hurdle_rate} %</b>
              </div>
            </div>
            <div>
              <div>Management Fee</div>
              <div>
                <b>{topBarData && topBarData.management_fee} %</b>
              </div>
            </div>
            <div>
              <div>Zeno Tenure</div>
              <div>
                <b>{topBarData && topBarData.pre_commit_tenure} years</b>
              </div>
            </div>
          </div>
          <div className={classes.photo}>
            {topBarData &&
              topBarData.lead_investors.map((lead) => (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: 50
                  }}
                >
                  <div
                    style={{
                      // height: '20px',
                      width: 'fit-content'
                      // border: "1px solid grey",
                    }}
                  >
                    <img
                      src={lead.image}
                      alt=""
                      style={{
                        width: 60,
                        height: 60,
                        borderRadius: 50,
                        border: 'solid 1px #b6b6b6',
                        objectFit: 'cover'
                      }}
                    />
                  </div>
                  <div style={{ marginTop: '10px', marginLeft: '25px' }}>
                    <Typography style={{ fontWeight: 'bold' }}>{lead.name}</Typography>
                    <Typography>
                      Lead Carry: <b>{lead.carry} %</b>
                    </Typography>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div style={{ backgroundColor: '#ffffff' }}>
          <div style={{ padding: '4% 0 2% 4%' }}>
            <Formik
              initialValues={{
                corpus_target: (zenoCorpusData && zenoCorpusData.corpus) || '',
                // gp_commitments:
                //   (zenoCorpusData && zenoCorpusData.gp_commitments) || '',
                investment_period: (zenoCorpusData && zenoCorpusData.pre_commit_tenure) || '',
                min_investment: (zenoCorpusData && zenoCorpusData.min_typical_investment) || '',
                max_investment: (zenoCorpusData && zenoCorpusData.max_typical_investment) || '',
                no_of_investments: (zenoCorpusData && zenoCorpusData.deal_per_year) || '',
                no_of_drawdowns: (zenoCorpusData && zenoCorpusData.no_of_drawdowns) || '',
                drawdown_interval: (zenoCorpusData && zenoCorpusData.drawdown_interval) || ''
              }}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ touched, values, setFieldValue }) => (
                <Form>
                  <div style={{ width: '30%' }}>
                    <TextInput
                      labeltitle="Corpus Target"
                      name="corpus_target"
                      placeholder="Enter Corpus Target"
                      // value={values.syn_name}
                      ismandatory
                      type="number"
                      popoverTitle="Corpus Target"
                      popoverShow
                      // popoverBody="This is just infomation text"
                    />
                  </div>
                  {/* <div style={{ width: '30%' }}>
                    <TextInput
                      labeltitle="GP Commitments"
                      name="gp_commitments"
                      placeholder="Enter GP  Commite"
                      // value={values.syn_name}
                      ismandatory
                      type="number"
                      popoverTitle="GP Commitments"
                      popoverShow
                      // popoverBody="This is just infomation text"
                    />
                  </div> */}
                  <div style={{ width: '30%' }}>
                    <TextInput
                      labeltitle="Investment Period"
                      name="investment_period"
                      placeholder="Enter Investment Period"
                      // value={values.syn_name}
                      ismandatory
                      type="number"
                      popoverTitle="Investment Period"
                      popoverShow
                      // popoverBody="This is just infomation text"
                    />
                  </div>
                  <div className={classes.syn_input_box}>
                    <label className={classNames('edit__profile_label-title')}>
                      Zeno Typical Investment
                      <span className="text-mandatory">*</span>
                    </label>
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: '20px' }}>
                        <TextInput
                          style={{ width: '100%' }}
                          textinfo="Min.ticket size for Investment into Zeno (in lakhs)"
                          // labeltitle="Syndicate Typical Investment"
                          name="min_investment"
                          // value={values.min_investment}
                          type="number"
                          placeholder="Enter Minimum Investment"
                          notes=""
                        />
                      </div>
                      <TextInput
                        style={{ width: '100%' }}
                        textinfo="Max. ticket size for Investment  into Zeno (in lakhs)"
                        // labeltitle="Syndicate Typical Investment"
                        name="max_investment"
                        // value={values.max_investment}
                        type="number"
                        placeholder="Enter Maximum"
                        // ismandatory
                        notes=""
                      />
                    </div>
                  </div>
                  <div style={{ width: '30%' }}>
                    <TextInput
                      labeltitle="Number Of Investments"
                      name="no_of_investments"
                      placeholder="Enter Deal Per year"
                      // value={values.syn_name}
                      ismandatory
                      type="number"
                      popoverTitle="Number Of Investments"
                      popoverShow
                      // popoverBody="This is just infomation text"
                    />
                  </div>
                  <div className={classes.syn_input_box}>
                    <label className={classNames('edit__profile_label-title')}>
                      Drawdown Details
                      <span className="text-mandatory">*</span>
                    </label>
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: '20px' }}>
                        <TextInput
                          style={{ width: '100%' }}
                          textinfo="# of Drawdowns"
                          // labeltitle="Syndicate Typical Investment"
                          name="no_of_drawdowns"
                          // value={values.min_investment}
                          type="number"
                          placeholder="Enter Fee percentage"
                          notes=""
                        />
                      </div>
                      <TextInput
                        style={{ width: '100%' }}
                        textinfo="Expected Drawdowns Interval"
                        // labeltitle="Syndicate Typical Investment"
                        name="drawdown_interval"
                        // value={values.max_investment}
                        type="number"
                        placeholder="Enter Tenure"
                        // ismandatory
                        notes=""
                      />
                    </div>
                  </div>
                  <div className="pt-2 pb-4">
                    <Button
                      variant="primary"
                      size="md"
                      type="submit"
                      style={{
                        background: '#09f',
                        borderRadius: '3px',
                        fontSize: 14
                      }}
                    >
                      {savingCorpusData ? (
                        <div className="d-flex align-items-center">
                          Saving...
                          <Oval height="25" width="25" radius="9" color="#0099ff" />
                        </div>
                      ) : (
                        'Submit'
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default zenoCorpus;
