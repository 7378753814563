
export function ArrowRight(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M5 12h14M12 5l7 7-7 7"
        stroke="#111"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ArrowLeft(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        d="M15.188 9H2.812M7.875 3.938 2.812 9l5.063 5.063"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Document(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 30 30"
      fill="none"
      {...props}
    >
      <path
        fill="transparent"
        stroke="#000"
        d="M18.75 3.75v5A1.25 1.25 0 0 0 20 10h5m-6.25-6.25h-5a2.5 2.5 0 0 0-2.5 2.5v12.5a2.5 2.5 0 0 0 2.5 2.5h8.75a2.5 2.5 0 0 0 2.5-2.5V10m-6.25-6.25L25 10m-5 11.25v2.5a2.5 2.5 0 0 1-2.5 2.5H8.75a2.5 2.5 0 0 1-2.5-2.5v-12.5a2.5 2.5 0 0 1 2.5-2.5h2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      />
    </svg>
  );
}

export function UploadDoc(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        fill="transparent"
        d="M5.833 15a4.013 4.013 0 0 1-2.772-1.098 3.67 3.67 0 0 1-1.148-2.652A3.67 3.67 0 0 1 3.06 8.598 4.013 4.013 0 0 1 5.833 7.5c.246-1.094.964-2.055 1.997-2.673a4.976 4.976 0 0 1 1.688-.624 5.186 5.186 0 0 1 1.825.005 4.97 4.97 0 0 1 1.683.633c.51.309.947.704 1.286 1.165.34.46.575.976.693 1.519.118.542.117 1.1-.005 1.642h.833a2.917 2.917 0 1 1 0 5.833H15m-7.5-2.5L10 10m0 0 2.5 2.5M10 10v7.5"
        stroke="#111"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      />
    </svg>
  );
}

export function FolderIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        opacity=".2"
        d="M4 10V7a1 1 0 0 1 1-1h6.588a.987.987 0 0 1 .7.288L16 10H4zM28 20.5h-9V26h9v-5.5z"
        fill="#000"
      />
      <path
        d="M4 10V7a1 1 0 0 1 1-1h6.588a.987.987 0 0 1 .7.288L16 10M28 20.5h-9V26h9v-5.5z"
        stroke="#000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.075 26h-9.15A.925.925 0 0 1 4 25.075V10h23a1 1 0 0 1 1 1v2"
        stroke="#000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 20.5V19a2.5 2.5 0 0 1 5 0v1.5"
        stroke="#000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function WhiteCheck(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        d="M27 9 13 23l-7-7"
        stroke="#fff"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Reject(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="55px"
      height="55px"
      {...props}
    >
      <linearGradient
        id="wRKXFJsqHCxLE9yyOYHkza"
        x1={9.858}
        x2={38.142}
        y1={9.858}
        y2={38.142}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f44f5a" />
        <stop offset={0.443} stopColor="#ee3d4a" />
        <stop offset={1} stopColor="#e52030" />
      </linearGradient>
      <path
        fill="url(#wRKXFJsqHCxLE9yyOYHkza)"
        d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
      />
      <path
        d="M33.192,28.95L28.243,24l4.95-4.95c0.781-0.781,0.781-2.047,0-2.828l-1.414-1.414 c-0.781-0.781-2.047-0.781-2.828,0L24,19.757l-4.95-4.95c-0.781-0.781-2.047-0.781-2.828,0l-1.414,1.414 c-0.781,0.781-0.781,2.047,0,2.828l4.95,4.95l-4.95,4.95c-0.781,0.781-0.781,2.047,0,2.828l1.414,1.414 c0.781,0.781,2.047,0.781,2.828,0l4.95-4.95l4.95,4.95c0.781,0.781,2.047,0.781,2.828,0l1.414-1.414 C33.973,30.997,33.973,29.731,33.192,28.95z"
        opacity={0.05}
      />
      <path
        d="M32.839,29.303L27.536,24l5.303-5.303c0.586-0.586,0.586-1.536,0-2.121l-1.414-1.414 c-0.586-0.586-1.536-0.586-2.121,0L24,20.464l-5.303-5.303c-0.586-0.586-1.536-0.586-2.121,0l-1.414,1.414 c-0.586,0.586-0.586,1.536,0,2.121L20.464,24l-5.303,5.303c-0.586,0.586-0.586,1.536,0,2.121l1.414,1.414 c0.586,0.586,1.536,0.586,2.121,0L24,27.536l5.303,5.303c0.586,0.586,1.536,0.586,2.121,0l1.414-1.414 C33.425,30.839,33.425,29.889,32.839,29.303z"
        opacity={0.07}
      />
      <path
        fill="#fff"
        d="M31.071,15.515l1.414,1.414c0.391,0.391,0.391,1.024,0,1.414L18.343,32.485 c-0.391,0.391-1.024,0.391-1.414,0l-1.414-1.414c-0.391-0.391-0.391-1.024,0-1.414l14.142-14.142 C30.047,15.124,30.681,15.124,31.071,15.515z"
      />
      <path
        fill="#fff"
        d="M32.485,31.071l-1.414,1.414c-0.391,0.391-1.024,0.391-1.414,0L15.515,18.343 c-0.391-0.391-0.391-1.024,0-1.414l1.414-1.414c0.391-0.391,1.024-0.391,1.414,0l14.142,14.142 C32.876,30.047,32.876,30.681,32.485,31.071z"
      />
    </svg>
  );
}

export function FileDescription(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      {...props}
    >
      <path
        d="M16.332 3.5v4.667a1.167 1.167 0 0 0 1.167 1.166h4.666M16.332 3.5H8.165a2.333 2.333 0 0 0-2.333 2.333v16.334A2.333 2.333 0 0 0 8.165 24.5h11.667a2.333 2.333 0 0 0 2.333-2.333V9.333M16.332 3.5l5.833 5.833M10.5 19.833h7m-7-4.666h7"
        stroke="#fff"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function PublishIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M10 14 21 3M10 14l3.5 7a.55.55 0 0 0 1 0L21 3M10 14l-7-3.5a.55.55 0 0 1 0-1L21 3"
        stroke="#fff"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function FounderIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        d="M2.25 15.75v-1.5a3 3 0 0 1 3-3h3a3 3 0 0 1 3 3v1.5M12 2.348a3 3 0 0 1 0 5.812m3.75 7.59v-1.5a3 3 0 0 0-2.25-2.887M9.75 5.25a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function PresentationIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M3 4h18M4 4v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4m-8 12v4m-3 0h6m-7-8 3-3 2 2 3-3"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CashIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M17 9V7a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2m2-6h10a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2zm7 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function WalletIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M17 8V5a1 1 0 0 0-1-1H6a2 2 0 0 0-2 2m0 0a2 2 0 0 0 2 2h12a1 1 0 0 1 1 1v3M4 6v12a2 2 0 0 0 2 2h12a1 1 0 0 0 1-1v-3m1-4v4h-4a2 2 0 0 1 0-4h4z"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ShowLessIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        d="M12.75 8.25 9 4.5 5.25 8.25M12.75 13.5 9 9.75 5.25 13.5"
        stroke="#111"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ShowMoreIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        d="M5.25 9.75 9 13.5l3.75-3.75M5.25 4.5 9 8.25l3.75-3.75"
        stroke="#111"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ThreeCirclesIcon(props) {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.5 17.917a5.375 5.375 0 1 0 0-10.75 5.375 5.375 0 0 0 0 10.75zm8.958 16.125a5.375 5.375 0 1 0 0-10.75 5.375 5.375 0 0 0 0 10.75zm-17.917 0a5.375 5.375 0 1 0 0-10.75 5.375 5.375 0 0 0 0 10.75z"
        stroke="#000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function DealHeartIcon(props) {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M34.938 22.525 21.5 35.833 8.063 22.525a8.957 8.957 0 0 1 10.69-14.2 8.958 8.958 0 0 1 2.747 2.436 8.957 8.957 0 0 1 16.115 5.129 8.957 8.957 0 0 1-2.677 6.646M21.5 10.75l-5.9 5.9a1.792 1.792 0 0 0 0 2.533l.973.973a3.169 3.169 0 0 0 4.48 0l1.79-1.791a5.701 5.701 0 0 1 8.063 0l4.032 4.03m-12.542 5.376 3.583 3.583m.896-8.062 3.583 3.583"
        stroke="#000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function BackButtonIcon(props) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle opacity={0.2} cx={13} cy={13} r={13} fill="#696969" />
    </svg>
  );
}
