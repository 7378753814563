import bgRedirectImg from '../assests/image/bgcomputer.webp';
import computerImg from '../assests/image/lv_home_computer.webp';
import logo from '../assests/image/lv_logo.webp';
import rightIcon from '../assests/image/right_icon_web.svg';
import '../assests/style/mobile_screen.scss';

export default function RedirectToComputer() {
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        overflow: 'hidden',
        flex: 1,
      }}
    >
      <div
        className="bgimg"
        style={{
          backgroundImage: `url(${bgRedirectImg})`,
          paddingTop: '33px',
          overFlow: 'hidden',
        }}
      >
        <img src={logo} alt="lv logo" className="logo-img" />
        <div>
          <img src={computerImg} alt="lv logo" className="computer-img" />
        </div>
        <div className="text-hello-welcome-parent">
          <div className="text-hello">Hello</div>
          <div className="text-welcome">
            <b>Welcome to LetsVenture!</b>
          </div>
        </div>
        <div className="text-description">
          We are working hard to add support for mobile browsers, meanwhile
          please use LetsVenture on your computer for best experience.
        </div>

        <div className="text-url-parent">
          <span className="text-logonto">log on to </span>
          <span className="text-url">https://glassboard.letsventure.com</span>
          <span className="text-next-icon">
            <img src={rightIcon} alt="right icon" />{' '}
          </span>
        </div>
      </div>
    </div>
  );
}
