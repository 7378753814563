import InfoIcon from '@mui/icons-material/Info';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import renderHTML from 'react-render-html';
import '../assets/styles.scss';

function ErrorView({ errorText, top, symbol }) {
  return (
    <div
      className="text-danger"
      style={
        symbol
          ? { marginTop: '0.3rem', fontSize: 14, width: '100%' }
          : { marginTop: '0.3rem', fontSize: 14 }
      }
    >
      {errorText}
    </div>
  );
}
function TextInput(props) {
  const { errors, touched, customError, smallerText, customizedEditFormClassName } = props;
  const [showPassword, setShowPassword] = useState(false);
  const isError = customError !== undefined ? customError[props.name] : false;
  return (
    <div className="edit-form-row">
      <span
        className={classNames('edit__profile_label-title')}
        style={smallerText ? { fontSize: '16px' } : null}
      >
        {props.labeltitle}{' '}
        {props.ismandatory ? <span className="text-mandatory">*</span> : ''}
        {props.tooltipTitle && (
          <Tooltip title={props.tooltipTitle}>
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        )}
      </span>
      {!!props.textinfo && (
        <div className="edit_profile_notes" style={{ fontSize: '14px' }}>
          {props.textinfo}
        </div>
      )}
      <div
        className={!!customizedEditFormClassName ? customizedEditFormClassName : "edit__profile_form-item"}
        style={
          props && props.symbol && { flexDirection: 'row', flexWrap: 'wrap' }
        }
      >
        <Field
          type={props.showendAdornment && !showPassword ? "password" : "text"}
          className={classNames('edit__profile_input', {
            error: isError,
          })}
          name={props.name}
          component={!!props.showendAdornment ? TextField : null}
          validate={props.validate}
          InputProps={
            !!props.showendAdornment
              ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }
              : null
          }
          {...props}
        />
        <span
          style={{
            alignSelf: 'center',
            fontSize: '16px',
            fontWeight: '500',
            color: 'rgb(0, 0, 0)',
          }}
        >
          {props && props.symbol && props.symbol}
        </span>
        {errors ? (
          <>
            {props.ismandatory && errors[props.name] && touched[props.name] && (
              <ErrorView errorText={errors[props.name]} symbol={props.symbol} />
            )}
          </>
        ) : (
          <ErrorMessage
            name={props.name}
            render={(msg) => (
              <ErrorView
                errorText={msg}
                top={props.top}
                symbol={props.symbol}
              />
            )}
          />
        )}
      </div>
      {props.notes && (
        <div className="edit_profile_notes"> {renderHTML(props.notes)} </div>
      )}
    </div>
  );
}

TextInput.propTypes = {
  labeltitle: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  notes: PropTypes.string,
  ismandatory: PropTypes.bool,
  customError: PropTypes.object,
};

TextInput.defaultProps = {
  ismandatory: false,
};

export default TextInput;
