import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Alert, Stack } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Oval } from 'react-loader-spinner';
import renderHTML from 'react-render-html';

import { toast } from 'react-toastify';
import '../assests/style/deal_detail.scss';
import Icon from '../common/icons/Icon';
import PublishDeal from './PublishDeal';

import PostHogManager from '../analytics/postHogManager';
import callApi from '../api';
import '../assests/style/deal_detail.scss';
import Loading from '../common/components/Loading';
import CustomProgressBar from '../common/components/ProgressBar';
import { formatAmountByPrecision, getSyndicateId, getSyndicatePermalink } from '../common/utils/common';
import { httpMethods } from '../constants';
import AllocationPage from './AllocationPage';
import BackerSelectionModal from './BackerSelectionModal';
import CustomBackerModal from './CustomBackerModal';
import SchemeDocumentModal from './SchemeDocumentModal';

function UploadButton(props) {
  const { name, documentsHash, setdocumentsHash, setFile } = props;

  const handleUpload = (e) => {
    let file = e.currentTarget.files[0];
    let new_files = { ...documentsHash };
    new_files[name] = file.name;
    setFile(file);
    setdocumentsHash(new_files);
  };

  return (
    <div style={{ position: 'relative', width: '25%' }}>
      <Chip
        style={{ width: '100%' }}
        icon={<Icon name="upload-doc" width="30" height="25" stroke="#fff" />}
        label="UPLOAD YOUR DOCUMENT"
        color="primary"
      />
      <input type="file" name={props.name} className="upload-document-button" onChange={handleUpload} />
    </div>
  );
}

function UploadDocument(props) {
  const startupId = localStorage.getItem('deal_id');
  const [showLoader, setShowLoader] = useState(true);
  const [files, setFiles] = useState({});
  const [documentsHash, setdocumentsHash] = useState({});
  const [draftShaFile, setDraftShaFile] = useState(null);
  const [valuationReportFile, setValuationReportFile] = useState(null);
  const [pas4File, setPas4File] = useState(null);
  const [mgt14File, setMgt14File] = useState(null);
  const [savingDocuments, setSavingDocuments] = useState(false);
  const syndicateId = getSyndicateId();

  const getDocuments = () => {
    const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
    const url = `${djangoBaseUrl}/v1/syndicates/get_documents/?id=${syndicateId}&startup_id=${startupId}`;
    callApi(url, httpMethods.HTTP_GET, '', {}, false)
      .then((response) => {
        setShowLoader(false);
        setFiles(response.data);
        setdocumentsHash(response.data);
      })
      .catch((error) => {
        toast.error('something went wrong, Please contact contactus@letsventure.com');
      });
  };

  const uploadFiles = () => {
    const formData = new FormData();
    setSavingDocuments(true);
    if (draftShaFile) formData.append('draft_agreement_file', draftShaFile);
    if (valuationReportFile) formData.append('valuation_report_file', valuationReportFile);
    if (pas4File) formData.append('pas4_file', pas4File);
    if (mgt14File) formData.append('mgt14_file', mgt14File);
    if (!documentsHash.draft_sha && !!files.draft_sha && !draftShaFile)
      formData.append('draft_agreement_file_removed', true);
    if (!documentsHash.valuation_report && !!files.valuation_report && !valuationReportFile)
      formData.append('valuation_report_file_removed', true);
    if (!documentsHash.pas4 && !!files.pas4 && !pas4File) formData.append('pas4_file_removed', true);
    if (!documentsHash.mgt14 && !!files.mgt14 && !mgt14File) formData.append('mgt14_file_removed', true);
    PostHogManager.trackEvent('Documents Modal - Triggered', {
      startupId,
      source: 'Documents Modal',
      description: 'Whenever the user clicks on Submit button',
      formData
    });
    const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
    const url = `${djangoBaseUrl}/v1/syndicates/upload_documents/?id=${syndicateId}&startup_id=${startupId}`;
    callApi(url, httpMethods.HTTP_POST, formData, {}, false)
      .then((response) => {
        setSavingDocuments(false);
        getDocuments();
        toast.success('Documents uploaded Succesfully');
      })
      .catch((error) => {
        toast.error('something went wrong, Please contact contactus@letsventure.com');
      });
  };

  const handleClick = (e, name) => {
    let url = documentsHash[name];
    window.open(url, '_blank', 'noreferrer');
  };

  const handleDelete = (e, name) => {
    let new_files = { ...documentsHash };
    new_files[name] = null;
    setdocumentsHash(new_files);
    if (name === 'draft_sha') setDraftShaFile(null);
    if (name === 'valuation_report') setValuationReportFile(null);
    if (name === 'pas4') setPas4File(null);
    if (name === 'mgt14') setMgt14File(null);
  };

  useEffect(() => {
    getDocuments();
  }, []);

  return (
    <div className="row mx-5 my-3 px-4 py-5 company-details">
      <div className="col-1" />
      <div className="col-11">
        <div className="d-flex" style={{ color: '#9747FF' }}>
          <div className="startup_status_header fw-bold me-2" style={{ fontSize: '1.7rem' }}>
            Documents
          </div>
          <Icon name="document" width="32" height="32" stroke="#9747FF" />
        </div>
        {!!showLoader && (
          <div className="py-5 mt-5" style={{ position: 'relative', marginBottom: '15%' }}>
            <Loading />
          </div>
        )}
        {!showLoader && (
          <div className="py-5">
            {!!documentsHash.pitch_deck && (
              <div className="d-flex py-3">
                <div className="col-sm-3 my-auto fw-bold">PITCH DECK:</div>
                <Chip
                  style={{ width: '25%' }}
                  label="PITCH DECK DOCUMENT"
                  color="primary"
                  variant="outlined"
                  onClick={(e) => handleClick(e, 'pitch_deck')}
                />
              </div>
            )}
            {!!documentsHash.lead_scheme_document && (
              <div className="d-flex py-3">
                <div className="col-sm-3 my-auto fw-bold">LEAD SCHEME DOCUMENT:</div>
                <Chip
                  style={{ width: '25%' }}
                  label="LEAD SCHEME DOCUMENT"
                  color="primary"
                  variant="outlined"
                  onClick={(e) => handleClick(e, 'lead_scheme_document')}
                />
              </div>
            )}
            {!!documentsHash.investor_scheme_document && (
              <div className="d-flex py-3">
                <div className="col-sm-3 my-auto fw-bold">INVESTOR SCHEME DOCUMENT:</div>
                <Chip
                  style={{ width: '25%' }}
                  label="INVESTOR SCHEME DOCUMENT"
                  color="primary"
                  variant="outlined"
                  onClick={(e) => handleClick(e, 'investor_scheme_document')}
                />
              </div>
            )}
            <div className="d-flex py-3">
              <div className="col-sm-3 my-auto fw-bold">SHA:</div>
              {!documentsHash.draft_sha && (
                <UploadButton
                  name="draft_sha"
                  documentsHash={documentsHash}
                  setdocumentsHash={setdocumentsHash}
                  setFile={setDraftShaFile}
                />
              )}
              {!!documentsHash.draft_sha && (
                <Chip
                  style={{ width: '25%' }}
                  label={documentsHash.draft_sha === files.draft_sha ? 'SHA DOCUMENT' : documentsHash.draft_sha}
                  color={documentsHash.draft_sha === files.draft_sha ? 'primary' : 'success'}
                  variant="outlined"
                  onClick={documentsHash.draft_sha === files.draft_sha ? (e) => handleClick(e, 'draft_sha') : null}
                  onDelete={(e) => handleDelete(e, 'draft_sha')}
                />
              )}
            </div>
            <div className="d-flex py-3">
              <div className="col-sm-3 my-auto fw-bold">VALUATION REPORT:</div>
              {!documentsHash.valuation_report && (
                <UploadButton
                  name="valuation_report"
                  documentsHash={documentsHash}
                  setdocumentsHash={setdocumentsHash}
                  setFile={setValuationReportFile}
                />
              )}
              {!!documentsHash.valuation_report && (
                <Chip
                  style={{ width: '25%' }}
                  label={
                    documentsHash.valuation_report === files.valuation_report
                      ? 'VALUATION REPORT DOCUMENT'
                      : documentsHash.valuation_report
                  }
                  color={documentsHash.valuation_report === files.valuation_report ? 'primary' : 'success'}
                  variant="outlined"
                  onClick={
                    documentsHash.valuation_report === files.valuation_report
                      ? (e) => handleClick(e, 'valuation_report')
                      : null
                  }
                  onDelete={(e) => handleDelete(e, 'valuation_report')}
                />
              )}
            </div>
            <div className="d-flex py-3">
              <div className="col-sm-3 my-auto fw-bold">PAS4:</div>
              {!documentsHash.pas4 && (
                <UploadButton
                  name="pas4"
                  documentsHash={documentsHash}
                  setdocumentsHash={setdocumentsHash}
                  setFile={setPas4File}
                />
              )}
              {!!documentsHash.pas4 && (
                <Chip
                  style={{ width: '25%' }}
                  label={documentsHash.pas4 === files.pas4 ? 'PAS4 DOCUMENT' : documentsHash.pas4}
                  color={documentsHash.pas4 === files.pas4 ? 'primary' : 'success'}
                  variant="outlined"
                  onClick={documentsHash.pas4 === files.pas4 ? (e) => handleClick(e, 'pas4') : null}
                  onDelete={(e) => handleDelete(e, 'pas4')}
                />
              )}
            </div>
            <div className="d-flex py-3">
              <div className="col-sm-3 my-auto fw-bold">MGT 14:</div>
              {!documentsHash.mgt14 && (
                <UploadButton
                  name="mgt14"
                  documentsHash={documentsHash}
                  setdocumentsHash={setdocumentsHash}
                  setFile={setMgt14File}
                />
              )}
              {!!documentsHash.mgt14 && (
                <Chip
                  style={{ width: '25%' }}
                  label={documentsHash.mgt14 === files.mgt14 ? 'MGT 14 DOCUMENT' : documentsHash.mgt14}
                  color={documentsHash.mgt14 === files.mgt14 ? 'primary' : 'success'}
                  variant="outlined"
                  onClick={documentsHash.mgt14 === files.mgt14 ? (e) => handleClick(e, 'mgt14') : null}
                  onDelete={(e) => handleDelete(e, 'mgt14')}
                />
              )}
            </div>
          </div>
        )}
        <div className="d-flex">
          <button
            type="button"
            onClick={() => props.setOpenUploadDocument(false)}
            className="btn btn-outline-primary me-4"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={() => {
              if (!savingDocuments) uploadFiles();
            }}
            className="btn btn-outline-primary"
            style={{
              color: '#fff',
              backgroundColor: '#0099ff',
              cursor: 'pointer'
            }}
          >
            {savingDocuments ? <div className="d-flex align-items-center">Saving... </div> : 'Save'}
          </button>
        </div>
      </div>
    </div>
  );
}

function Status(props) {
  const [showBackerModal, setShowBackerModal] = useState(false);
  const [showAllocationPage, setShowAllocationPage] = useState(false);
  const [backerList, setBackerList] = useState([]);
  const [complete, setComplete] = useState(props.isSchemeComplete);
  const [isLoading, setIsLoading] = useState(false);
  const hasSubmitted = [0, 1].includes(props.fund_raising_status);

  const handleBackerCloseModal = () => {
    setShowBackerModal(false);
  };

  const getBackerList = () => {
    setIsLoading(true);
    const syndicate_permalink = props.syndicatePermalink;
    const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
    const url = `${djangoBaseUrl}/v1/syndicates/get_backers/?permalink=${syndicate_permalink}`;
    callApi(url, httpMethods.HTTP_GET, '', {}, false)
      .then((response) => {
        PostHogManager.trackEvent('Fetch Backers List - Successfull', {
          syndicate_permalink,
          source: 'DealDetail Page',
          description: 'Fetching Backers List Successfull',
          response_data: response
        });
        const { backers } = response.data;
        // sorting alphabetically.
        backers.sort((a, b) => a.name.localeCompare(b.name));
        setBackerList(backers);
        setShowBackerModal(true);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error('something went wrong, Please contact contactus@letsventure.com');
      });
  };

  const handleSubmission = () => {
    props.isZeno ? setShowAllocationPage(true) : getBackerList();
  };

  let rejectReasonText = (
    <p>
      Your startup is rejected by letsventure team.
      <br />
      <b>Reason:</b>
      {renderHTML(props.rejectReason || '')}
      {/* <ReadMoreReact
        text={text}
        min={30}
        ideal={30}
        max={100}
        readMoreText='read more'
      /> */}
      <br /> <br />
      For more details email to contactus@letsventure.com.
    </p>
  );

  const data = {
    header: 'Almost There!',
    sub_header:
      'Create the startup. Submit Scheme Doc information. Get LetsVenture approval. Publish the deal when you’re ready.',
    header_2: 'Deal Status',
    status: [
      {
        title: 'Step 1 : Startup Page Created',
        doneIcon: 'whitetick',
        icon: 'whitetick',
        className: 'status_circle complete'
      },
      {
        title: 'Step 2 : Scheme Document',
        description:
          'Submit details for the Scheme Document (ie. terms of the deal) to the LetsVenture team for approval.',
        doneIcon: 'whitetick',
        icon: hasSubmitted || props.submitApproval ? 'whitetick' : 'filedescription',
        className: hasSubmitted || props.submitApproval ? 'status_circle complete' : 'status_circle inprogress'
      },
      {
        title: 'Step 3 : LetsVenture Approval',
        description:
          props.isApproved === true || props.submitApproval
            ? 'The LetsVenture team reviews the startup’s details and terms of the deal. They then approve them or ask for clarifications / suggest edits.'
            : props.isRejected === true && !hasSubmitted
            ? `${rejectReasonText}`
            : 'The LetsVenture team reviews the startup’s details and terms of the deal. They then approve them or ask for clarifications / suggest edits.',
        doneIcon: 'whitetick',
        icon:
          props.isApproved === true ? 'whitetick' : props.isRejected === true && !hasSubmitted ? 'reject' : 'lvlogo',
        className:
          props.isApproved === true
            ? 'status_circle complete'
            : props.isRejected === true && !hasSubmitted
            ? 'status_circle reject'
            : hasSubmitted || props.submitApproval
            ? 'status_circle inprogress'
            : 'status_circle incomplete'
      },
      {
        title: 'Step 4 : Publish Deal',
        description: 'You can Publish the deal once you are ready to make it live / share it with your backers.',
        doneIcon: 'whitetick',
        is_done: false,
        icon: 'publishicon',
        className: props.isApproved === true ? 'status_circle inprogress' : 'status_circle incomplete'
      }
    ]
  };

  return (
    <>
      {!!props.openUploadDocument && <UploadDocument setOpenUploadDocument={props.setOpenUploadDocument} />}
      {!props.openUploadDocument && (
        <div className="row mx-5 my-3 px-4 py-5 company-details">
          <div className="col-1">
            <Icon name="foldericon" />
          </div>
          <div className="col-11">
            <div className="startup_status_header fw-bold mb-2">{data.header}</div>
            <div className="startup_status_description mb-4">{data.description}</div>
            {props.isRejected && !props.isSchemeVerified && (
              <Alert severity="error" className="mb-3">
                <div className="fw-bold mb-2">Your startup is rejected. Please go through the next steps:</div>
                <ol>
                  <li>
                    Read the review comments <b>under step 3</b>(contact the LV team if there are any issues)
                  </li>
                  <li>Update the startup page and scheme document based on the review comments and save them</li>
                  <li>Click on ‘Submit for Approval’ once it is good to go for the LV review</li>
                </ol>
              </Alert>
            )}
            {props.isInitialApproved && !props.isRejected && (
              <Alert severity="info" className="mb-3 custom-blue-alert">
                <div className="fw-bold mb-2">Complete the below steps to publish the deal with your backers:</div>
                <ol>
                  <li>Add &apos;Scheme Info&apos; details and &apos;Submit for Approval&apos;</li>
                  <li>Upon LetsVenture&apos;s approval, review the scheme documents generated</li>
                  <li>&apos;Publish&apos; the deal with your backers</li>
                </ol>
              </Alert>
            )}
            <div className="startup_status_header fw-bold mb-3">{data.header_2}</div>
            <div className="d-flex">
              {data.status.map((item, index) => (
                <div className="col-3" style={{ marginRight: '10px' }}>
                  <div className="d-flex align-items-center py-3">
                    <div className={item.className}>
                      {item.icon === 'lvlogo' ? (
                        <Icon name={item.icon} width="32" height="32" fill="#fff" />
                      ) : (
                        <Icon name={item.icon} width="32" height="32" />
                      )}
                    </div>
                    {data.status.length - 1 !== index && (
                      <span className="horizontal_line" style={{ marginRight: '-10px' }}>
                        {' '}
                      </span>
                    )}
                  </div>
                  <div className="startup_status_title fw-bold my-2">{item.title}</div>
                  <div className="startup_status_sub_title" style={{ minHeight: '30%' }}>
                    {item.title === 'Step 3 : LetsVenture Approval' && props.isRejected
                      ? rejectReasonText
                      : item.description}
                  </div>
                  {item.title === 'Step 2 : Scheme Document' && (
                    <div className="d-flex mt-3">
                      {!(props.isScheme === true) && !hasSubmitted && (
                        <button type="button" onClick={props.handleGenerate} className="btn btn-outline-primary me-4">
                          Add scheme info
                        </button>
                      )}
                      {props.isSchemeVerified !== true && !hasSubmitted && props.isScheme === true && (
                        <button type="button" onClick={props.handleEdit} className="btn btn-outline-primary me-4">
                          Edit
                        </button>
                      )}
                      {props.isSchemeVerified === true && (!!props.schemeDoc || !!props.LeadSchemeDoc) && (
                        <div className="d-flex flex-column">
                          {!!props.LeadSchemeDoc && (
                            <a href={props.LeadSchemeDoc} target="_blank">
                              <button
                                type="button"
                                onClick={() => {
                                  PostHogManager.trackEvent('Clicked View Document', {
                                    startup_id: props.startupId,
                                    scheme_path: props.schemeDoc,
                                    source: 'Deal tracker page before publish',
                                    description: 'Clicked on View Document'
                                  });
                                }}
                                className="btn btn-outline-primary mb-3"
                              >
                                Lead Scheme Document
                              </button>
                            </a>
                          )}
                          {!!props.schemeDoc && (
                            <a href={props.schemeDoc} target="_blank">
                              <button
                                type="button"
                                onClick={() => {
                                  PostHogManager.trackEvent('Clicked View Document', {
                                    startup_id: props.startupId,
                                    scheme_path: props.schemeDoc,
                                    source: 'Deal tracker page before publish',
                                    description: 'Clicked on View Document'
                                  });
                                }}
                                className="btn btn-outline-primary"
                              >
                                Investor Scheme Document
                              </button>
                            </a>
                          )}
                        </div>
                      )}
                      {props.isScheme === true &&
                        !hasSubmitted &&
                        !props.schemeDoc &&
                        props.isSchemeVerified !== true &&
                        !!complete && (
                          <button
                            type="button"
                            onClick={() => {
                              PostHogManager.trackEvent('Clicked Submit for Approval', {
                                startup_id: props.startupId,
                                source: 'Deal tracker page before publish',
                                description: 'Clicked Submit for Approval'
                              });
                              props.handleSubmitApproval();
                            }}
                            className="btn btn-outline-primary"
                            style={{
                              color: '#fff',
                              backgroundColor: '#0099ff',
                              cursor: 'pointer'
                            }}
                          >
                            Submit for Approval
                          </button>
                        )}
                    </div>
                  )}
                  {props.isApproved === true && item.title === 'Step 4 : Publish Deal' && (
                    <div className="d-flex mt-3">
                      {!props.generate && (
                        <button
                          type="button"
                          // onClick={props.handlePublish}
                          onClick={() => {
                            // getBackerList();
                            handleSubmission();
                          }}
                          className="btn btn-outline-primary me-4"
                        >
                          {props.publishLoader || isLoading ? (
                            <div className="d-flex align-items-center">
                              {props.publishLoader ? 'Publishing...' : 'Loading...'}
                              <Oval height="25" width="25" radius="9" color="#0099ff" />
                            </div>
                          ) : (
                            'Publish'
                          )}
                        </button>
                      )}
                    </div>
                  )}
                  {item.title === 'Step 1 : Startup Page Created' &&
                    !hasSubmitted &&
                    ((props.isSchemeVerified !== true && !props.schemeDoc) ||
                      (props.isRejected === true && !props.submitApproval)) && (
                      <div className="d-flex mt-3">
                        <a href={`/startup/update_deal/${props.startupId}`}>
                          <button
                            type="button"
                            onClick={() => {
                              PostHogManager.trackEvent('Clicked Update Deal', {
                                startup_id: props.startupId,
                                source: 'Deal tracker page before publish',
                                description: 'Clicked on Update Deal'
                              });
                            }}
                            className="btn btn-outline-primary"
                          >
                            Edit Startup
                          </button>
                        </a>
                      </div>
                    )}
                </div>
              ))}
            </div>
          </div>
          {props.openModal && (
            <SchemeDocumentModal
              startupId={props.startupId}
              handleCloseModal={props.handleCloseModal}
              openModal={props.openModal}
              closeSchemeModal={props.handleCloseModal}
              handleGenerate={props.handleGenerate}
              currentAction={props.currentAction}
              isSchemeComplete={props.isScheme}
              schemeDoc={props.schemeDoc}
              updateSubmitButtonShow={props.updateSubmitButtonShow}
              updateSetComplete={setComplete}
            />
          )}

          {showBackerModal && (
            <BackerSelectionModal
              startupId={props.startupId}
              handleCloseModal={handleBackerCloseModal}
              openModal={showBackerModal}
              backerList={backerList}
              adminList={props.adminList}
              handlePublish={props.handlePublish}
            />
          )}

          {showAllocationPage && (
            <AllocationPage
              startupId={props.startupId}
              onPublish={props.handlePublish}
              showAllocation={setShowAllocationPage}
              setPublish={props.handleSetPublish}
              loadDealData={props.loadDealData}
            />
          )}
        </div>
      )}
    </>
  );
}

function DealDetail() {
  const [value, setValue] = React.useState();
  const [isZeno, setIsZeno] = useState(false);
  const [schemeData, setSchemeData] = useState();
  const [loading, setLoading] = useState(true);
  const [combinedValue, setCombinedValue] = React.useState();
  const syndicate_permalink = getSyndicatePermalink();
  const syndicateId = getSyndicateId();
  const [showMoreDealInfoIcon, setShowMoreDealInfoIcon] = useState(true);
  const [generate, changeGenerate] = useState(false);
  const [openModal, updateOpenModal] = useState(false);
  const [published, updatePublished] = useState(false);
  const [currentAction, setCurrentAction] = useState('generate');
  const [publishLoader, setPublishLoader] = useState(false);
  const [submitButtonShow, setSubmitButtonShow] = useState(false);
  const [schemeVerified, setSchemeVerified] = useState(false);
  const [submitApproval, setSubmitApproval] = useState(false);
  const [openCustomBackerModal, updateCustomBackerModal] = useState(false);
  const [isModify, setIsModify] = useState(false);
  const [canModify, setCanModify] = useState(false);

  const [shareLoader, setShareLoader] = useState(false);
  const [shareBackerList, setShareBackerList] = useState([]);
  const [adminList, setAdminList] = useState([]);
  const [openUploadDocument, setOpenUploadDocument] = useState(false);

  const option = {
    style: { color: '#fff' },
    autoClose: 7000
  };

  const handleGenerate = () => {
    PostHogManager.trackEvent('Clicked Generate Button', {
      startup_id: `${value.id}`,
      source: 'Deal tracker page before publish',
      description: 'Clicked on Generate Button'
    });
    changeGenerate(false);
    updateOpenModal(true);
  };

  const handleEdit = () => {
    PostHogManager.trackEvent('Clicked Edit Button', {
      startup_id: `${value.id}`,
      source: 'Deal tracker page before publish',
      description: 'Clicked on Edit Button'
    });
    updateOpenModal(true);
    setCurrentAction('edit');
  };

  const handleSubmitApproval = () => {
    PostHogManager.trackEvent('Scheme Generate Submitted', {
      syndicate_permalink,
      source: 'Deal tracker page before publish',
      description: 'Scheme Generate Submitted',
      submitted_data: { startup_id: `${value.id}` }
    });
    const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
    const url = `${djangoBaseUrl}/v1/syndicates/diy_scheme_doc_generation/?id=${syndicateId}`;
    callApi(
      url,
      httpMethods.HTTP_POST,
      {
        startup_id: `${value.id}`
      },
      {},
      false
    )
      .then((res) => {
        setSchemeVerified(true);
        setSubmitApproval(true);
        PostHogManager.trackEvent('Scheme Generate Completed - Success', {
          syndicate_permalink,
          source: 'Deal tracker page before publish',
          description: 'Scheme Generation Successfull',
          deal_status: 'Draft - Scheme Generated',
          response_data: res
        });
        toast.success('Scheme doc submitted for approval. Await the response from LV Legal.', option);
        if (value.is_rejected) {
          setValue({ ...value, is_rejected: false });
        }
      })
      .catch((error) => {
        PostHogManager.trackEvent('Scheme Generate Completed - Failed', {
          syndicate_permalink,
          source: 'Deal tracker page before publish',
          description: 'Scheme Generation Failed',
          deal_status: 'Draft - Scheme Not Generated',
          response_data: error
        });
        toast.error(error.message, option);
      });
  };

  const handleOpenModal = () => {
    updateOpenModal(true);
  };

  const handleCloseModal = () => {
    updateOpenModal(false);
  };

  const handleCustomBackerCloseModal = () => {
    updateCustomBackerModal(false);
  };

  const getDealDetailData = () => {
    const startupPermalink = localStorage.getItem('dealDetalilPermalink');
    const startupId = localStorage.getItem('deal_id');
    const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
    const url = `${djangoBaseUrl}/v1/syndicates/syndicate_current_deal_startup_info/?id=${syndicateId}&startup_id=${startupId}`;
    PostHogManager.trackEvent('Fetching Deal Status Submitted', {
      syndicate_permalink,
      source: 'Deal tracker page',
      description: 'Fetching Deal Status',
      submitted_data: { startupPermalink }
    });
    callApi(url, httpMethods.HTTP_GET, '', {}, false)
      .then((response) => {
        PostHogManager.trackEvent('Fetching Deal Status Completed - Success', {
          syndicate_permalink,
          source: 'Deal tracker page',
          description: 'Fetched Deal Status Successfull',
          response_data: response
        });
        // const backer_list_url = `${djangoBaseUrl}/v1/syndicates/get_backers/?permalink=${syndicate_permalink}`;
        // callApi(backer_list_url, httpMethods.HTTP_GET, '', {}, false).then(
        //   (res) => {
        //     // let backers = res.data.backers;
        //     // backers.sort((a, b) => (a.name).localeCompare(b.name));
        //     // setShareBackerList(backers);
        //   }
        // );
        setValue(response.data.startups_response);
        setIsZeno(response.data.is_zeno);
        setSchemeData(response.data.scheme_data);
        setLoading(false);
        const combined_url = `${djangoBaseUrl}/v1/syndicates/syndicate_current_deal_info_section_combined/?id=${syndicateId}&syn_deal_id=${response.data.startups_response.syndicate_deal_id}`;
        PostHogManager.trackEvent('Fetching Deal Detail Submitted', {
          syndicate_permalink,
          source: 'Deal tracker page',
          description: 'Fetching Deal Detail',
          submitted_data: {
            syndicate_deal_id: response.data.startups_response.syndicate_deal_id
          }
        });
        callApi(combined_url, httpMethods.HTTP_GET, '', {}, false)
          .then((res) => {
            let backers = res.data.backers;
            backers.sort((a, b) => a.name.localeCompare(b.name));
            let admins = res.data.syn_admins;
            admins.sort((a, b) => a.name.localeCompare(b.name));
            setShareBackerList(backers);
            setAdminList(admins);
            setCombinedValue(res.data);
            setCanModify(res.data.can_modify);
            PostHogManager.trackEvent('Fetching Deal Detail Completed - Success', {
              syndicate_permalink,
              source: 'Deal tracker page',
              description: 'Fetching Deal Detail Successfull',
              response_data: res
            });
          })
          .catch((err) => {
            PostHogManager.trackEvent('Fetching Deal Detail Completed - Failed', {
              syndicate_permalink,
              source: 'Deal tracker page',
              description: 'Fetching Deal Detail Failed',
              response_data: err
            });
          });
      })
      .catch((error) => {
        PostHogManager.trackEvent('Fetching Deal Status Completed - Failed', {
          syndicate_permalink,
          source: 'Deal tracker page',
          description: 'Fetched Deal Status Failed',
          response_data: error
        });
      });
  };

  useEffect(() => {
    getDealDetailData();
    // getBackerList();
  }, []);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast.success('Deal URL copied !', option);
  };

  const handlePublish = (selectedBackersData, allBackers) => {
    const selectedBackers = [...selectedBackersData.keys()];
    PostHogManager.trackEvent('Clicked Publish Button', {
      startup_id: `${value.id}`,
      syndicate_permalink,
      source: 'Deal tracker page before publish',
      description: 'Clicked on Publish Button'
    });
    setPublishLoader(true);
    PostHogManager.trackEvent('Deal Publish Submitted', {
      syndicate_permalink,
      source: 'Deal tracker page before publish',
      description: 'Deal Publish submitted',
      submitted_data: { startup_id: `${value.id}`, fass: true }
    });
    const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
    callApi(
      `${djangoBaseUrl}/v1/syndicates/launch_syndicate_deal/?permalink=${syndicate_permalink}`,
      httpMethods.HTTP_POST,
      {
        startupId: `${value.id}`,
        fass: true,
        ...(selectedBackers.length > 0 && { backer_list: selectedBackers })
      },
      {},
      false
    )
      .then((response) => {
        PostHogManager.trackEvent('Deal Publish Completed - Success', {
          syndicate_permalink,
          source: 'Deal tracker page before publish',
          description: 'Deal Publishing Successfull',
          response_data: response
        });
        const startupId = localStorage.getItem('deal_id');
        const url = `${djangoBaseUrl}/v1/syndicates/syndicate_deal_commitment_record/?permalink=${syndicate_permalink}&startup_id=${startupId}`;
        callApi(url, httpMethods.HTTP_POST, { backer_record: [...selectedBackersData.values()] }, {}, false)
          .then((res) => {
            if (res.status === 200) {
              setPublishLoader(false);
              updatePublished(true);
              getDealDetailData();
              if (allBackers) {
                toast.success('Deal was published successfully to all the backers.', option);
              } else {
                toast.success('Deal was published successfully to the selected backers.', option);
              }
            }
          })
          .catch((error) => {
            toast.error(error.message, option);
          });
      })
      .catch((error) => {
        PostHogManager.trackEvent('Deal Publish Completed - Failed', {
          syndicate_permalink,
          source: 'Deal tracker page before publish',
          description: 'Deal Publishing Failed',
          response_data: error
        });
        toast.error(error.message, option);
      });
  };
  const handleShareWith = (backersDataMap) => {
    const selectedBackers = [...backersDataMap.keys()];
    PostHogManager.trackEvent('Clicked Share Button', {
      startup_id: `${value.id}`,
      syndicate_permalink,
      source: 'Deal tracker page after publish',
      description: 'Clicked on Share Button'
    });
    setShareLoader(true);

    callApi(
      `/api/v3/web/syndicates/syndicate/${syndicate_permalink}/invite_selected_syndicate_deal_backer`,
      httpMethods.HTTP_POST,
      {
        startupId: `${value.id}`,
        backer_list: selectedBackers
      }
    )
      .then((response) => {
        PostHogManager.trackEvent('Deal Shared Completed - Success', {
          syndicate_permalink,
          source: 'Deal tracker page after publish',
          description: 'Deal Shared Successfull',
          response_data: response
        });
        const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
        const startupId = localStorage.getItem('deal_id');
        const url = `${djangoBaseUrl}/v1/syndicates/syndicate_deal_commitment_record/?permalink=${syndicate_permalink}&startup_id=${startupId}`;
        callApi(url, httpMethods.HTTP_POST, { backer_record: [...backersDataMap.values()] }, {}, false).then((res) => {
          if (res.status === 200) {
            setShareLoader(false);
            getDealDetailData();
            toast.success('Deal Shared Successfully', option);
            setTimeout(() => {
              window.location.reload();
            }, 6000);
          }
        });
      })
      .catch((error) => {
        toast.error(error.message, option);
      });
  };

  const data = {
    name: value && value.name,
    banner_logo: value && value.startup_banner,
    startup_logo: value && value.logo,
    sector: value && value.sectors,
    stage: value && value.stage,
    privacy: value && value.stealth,
    description: value && value.product_summary,
    ask: value && value.lv_ask?.amount,
    ask_unit: value && value.lv_ask?.unit,
    ask_symbol: value && value.lv_ask?.symbol,
    commited: value && value.total_commitment_diy?.amount,
    commited_unit: value && value.total_commitment_diy?.unit,
    commited_symbol: value && value.total_commitment_diy?.symbol,
    received: value && value?.lv_total_received?.amount,
    received_unit: value && value?.lv_total_received?.unit,
    received_symbol: value && value?.lv_total_received?.symbol,
    received_percentage: value && value?.lv_total_received_percentage?.toFixed(2),
    pitch_deck: value && value.pitch_deck,
    founder_deatils: value && value.founder_deatils,
    pitch_videos: value && value.pitch_videos,
    round_type: value && value.round_type,
    founder_infusion: value && value.founder_infusion,
    all_lead_investor_details: value && value.all_lead_investor_details,
    private_syn_founder_deatils: value && value.private_syn_founder_deatils,
    permalink: value && value.permalink,
    deal_url: value && value.deal_url,
    scheme_doc: schemeData && schemeData.scheme_doc,
    lead_scheme_doc: schemeData && schemeData.lead_scheme_doc
  };
  if (loading) {
    return <Loading />;
  }

  return (
    <div className="container" style={{ marginTop: 100 }}>
      <div className="row mx-5 my-3 p-4 company-details">
        <div className="col-3">
          <img src={data.banner_logo} className="w-100" alt="startup_logo" />
        </div>
        <div className="col-9">
          <div className="d-flex">
            <img src={data.startup_logo} alt="startup_logo" width="50" />

            <div className="ms-auto">{data.privacy}</div>
            <Chip
              className="ms-auto"
              label="Documents"
              color="primary"
              variant={openUploadDocument ? 'outlined' : ''}
              onClick={(e) => {
                setOpenUploadDocument(!openUploadDocument);
                PostHogManager.trackEvent('Documents Button Clicks', {
                  startup_name: `${data.name}`,
                  source: 'Deal kanban view page',
                  description: 'Whenever the user clicks on Documents button, trigger an event'
                });
              }}
            />
            {((value && value.publish_for_syn && combinedValue && !openUploadDocument) || published) &&
              !value.is_zeno && (
                <div style={{ margin: '-3px 0px 0px 19px' }}>
                  <button
                    type="button"
                    onClick={() => {
                      updateCustomBackerModal(true);
                      setIsModify(false);
                      PostHogManager.trackEvent('Share button clicks', {
                        source: 'Deal kanban view page',
                        description: 'Whenever the user clicks on Share button, trigger an event '
                      });
                    }}
                    className="btn btn-outline-primary"
                    style={{
                      background: '#0099ff',
                      color: '#ffffff'
                    }}
                  >
                    {shareLoader ? (
                      <div className="d-flex align-items-center">
                        Sharing... <Oval height="25" width="25" radius="9" color="#0099ff" />
                      </div>
                    ) : (
                      'Share'
                    )}
                  </button>
                </div>
              )}

            {canModify &&
              ((value && value.publish_for_syn && combinedValue && !openUploadDocument) || published) &&
              !value.is_zeno && (
                <div style={{ margin: '-3px 0px 0px 19px' }}>
                  <button
                    type="button"
                    onClick={() => {
                      updateCustomBackerModal(true);
                      setIsModify(true);
                      PostHogManager.trackEvent('Modify button clicks ', {
                        source: 'Deal kanban view page',
                        description: 'Whenever the user clicks on Modify button, trigger an event '
                      });
                    }}
                    className="btn btn-outline-primary"
                    style={{
                      background: '#0099ff',
                      color: '#ffffff'
                    }}
                  >
                    Modify
                  </button>
                </div>
              )}
          </div>
          <div className="d-flex mt-3">
            <div className="startup_name fw-bold mt-2">{data.name}</div>
          </div>
          <div className="startup_sector mb-3">{data.sector}</div>
          <div className="startup_description mb-3">{renderHTML(data.description || '')}</div>
          {value && value.publish_for_syn && (
            <div className="startup_description mb-3 d-flex align-items-center">
              <b style={{ fontSize: '14px' }}>Deal URL : </b>
              <Typography
                sx={{ textDecoration: 'underline', textIndent: 3, color: 'rgba(57, 138, 255, 1)', fontSize: '14px' }}
              >
                {`${data.deal_url && data.deal_url.split('&').slice(0, 2).join('&')}`}
              </Typography>
              {data?.deal_url && (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip placement="bottom" style={{ fontSize: '11px' }} className="custom-deal-tooltip">
                      You can share this link with your backers on WhatsApp and other channels. Only invited backers
                      will get access to see this deal
                    </Tooltip>
                  }
                >
                  <Stack
                    direction="row"
                    style={{
                      border: '1px solid rgba(57, 138, 255, 1)',
                      borderRadius: '5px',
                      padding: '4px 7px',
                      marginLeft: 10,
                      cursor: 'pointer'
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCopy(data.deal_url.split('&').slice(0, 2).join('&'));
                    }}
                  >
                    <ContentCopyIcon style={{ color: 'rgba(57, 138, 255, 1)', fontSize: 13 }} />
                    <Typography
                      style={{ color: 'rgba(57, 138, 255, 1)', fontSize: 10, marginLeft: 2, fontWeight: 300 }}
                    >
                      COPY
                    </Typography>
                  </Stack>
                </OverlayTrigger>
              )}
            </div>
          )}
          <div className="d-flex row" style={{ fontSize: 12 }}>
            <div className="col-6 mb-3">
              <div className="dot commited d-inline-block me-1" />
              <span className="font-weight-bold me-2" style={{ fontWeight: 'bold' }}>
                Committed
              </span>{' '}
              {data?.commited_symbol} {formatAmountByPrecision(data.commited, 2)} {data.commited_unit}
            </div>
            <div className="col-6  mb-3" style={{ textAlign: 'right' }}>
              <div className="dot ask d-inline-block me-1" />
              <span className="font-weight-bold me-2" style={{ fontWeight: 'bold' }}>
                LV Ask
              </span>
              {data?.ask_symbol} {formatAmountByPrecision(data.ask, 2)} {data.ask_unit}
            </div>
          </div>
          <CustomProgressBar
            lvRaisedPercentage={value?.lv_raised_percentage ?? 0}
            receivedPercentage={data?.received_percentage ?? 0}
          />
          <div className="col-6" style={{ marginTop: '1rem', fontSize: '12px' }}>
            <div className="dot received d-inline-block me-1" />
            <span className="font-weight-bold me-2" style={{ fontWeight: 'bold' }}>
              Received
            </span>
            {data?.received_symbol} {formatAmountByPrecision(data.received, 2)} {data.received_unit}
          </div>
        </div>
        <div className="deal_more_info">
          <Accordion style={{ boxShadow: 'none' }}>
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => {
                PostHogManager.trackEvent('Clicked More Info', {
                  startupId: `${value.id}`,
                  source: 'Deal tracker page after publish',
                  description: 'Clicked on More Info',
                  open: showMoreDealInfoIcon
                });
                setShowMoreDealInfoIcon(!showMoreDealInfoIcon);
              }}
            >
              <Typography style={{ marginLeft: '25.5%' }}>
                <span>
                  More Info {showMoreDealInfoIcon ? <Icon name="showmoreicon" /> : <Icon name="showlessicon" />}
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="d-flex row">
                  <div className="col-4">
                    <div className="card-background mx-1 p-3">
                      <Icon name="foundericon" className="icon_position d-block" />
                      <div style={{ marginBottom: 20 }}>Founders</div>
                      {data.founder_deatils &&
                        data.founder_deatils.map((item) => (
                          <div className="d-flex align-items-center" style={{ marginBottom: 15 }}>
                            <Avatar
                              style={{
                                width: '30px',
                                height: '30px'
                              }}
                              alt={item.name}
                              src={item.profile_image}
                            />
                            <div
                              style={{
                                fontSize: 12,
                                marginLeft: 10,
                                fontWeight: 'bold'
                              }}
                            >
                              {item.name}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  {/* <div className="col-3">
                    <div className="card-background mx-1 p-3">
                      <Icon
                        name="presentationicon"
                        className="icon_position d-block"
                      />
                      <div style={{ marginBottom: 20 }}>Documents</div>
                      {data.pitch_deck && (
                        <div className="d-flex align-items-center">
                          <Icon name="pdfreader" width="30" height="30" />
                          <div style={{ fontSize: 12, marginLeft: 20 }}>
                            <span>
                              <a
                                href={data.pitch_deck}
                                target="_blank"
                                style={{
                                  textDecoration: 'none',
                                  color: '#000',
                                  fontWeight: 'bold',
                                }}
                              >
                                Pitch Deck
                              </a>
                            </span>
                          </div>
                        </div>
                      )}
                      {data.lead_scheme_doc && (
                        <div className="d-flex align-items-center mt-3">
                          <Icon name="pdfreader" width="30" height="30" />
                          <div style={{ fontSize: 12, marginLeft: 20 }}>
                            <span>
                              <a
                                href={data.lead_scheme_doc}
                                target="_blank"
                                style={{
                                  textDecoration: 'none',
                                  color: '#000',
                                  fontWeight: 'bold',
                                }}
                              >
                                Lead Scheme Document
                              </a>
                            </span>
                          </div>
                        </div>
                      )}
                      {data.scheme_doc && (
                        <div className="d-flex align-items-center mt-3">
                          <Icon name="pdfreader" width="30" height="30" />
                          <div style={{ fontSize: 12, marginLeft: 20 }}>
                            <span>
                              <a
                                href={data.scheme_doc}
                                target="_blank"
                                style={{
                                  textDecoration: 'none',
                                  color: '#000',
                                  fontWeight: 'bold',
                                }}
                              >
                                Investor Scheme Document
                              </a>
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div> */}
                  <div className="col-4">
                    <div className="card-background mx-1 p-3">
                      <Icon name="cashicon" className="icon_position d-block" />
                      <div style={{ marginBottom: 20 }}>Investors</div>
                      {data &&
                        data.all_lead_investor_details &&
                        data.all_lead_investor_details.map((item) => (
                          <div className="d-flex align-items-center" style={{ marginBottom: 15 }}>
                            <Avatar
                              style={{
                                width: '30px',
                                height: '30px'
                              }}
                              alt={item.name}
                              src={item.profile_image}
                            />
                            <div
                              style={{
                                fontSize: 12,
                                marginLeft: 20,
                                fontWeight: 'bold'
                              }}
                            >
                              {item.name}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="card-background mx-1 p-3">
                      <Icon name="walleticon" className="icon_position d-block" />
                      <div style={{ marginBottom: 20 }}>Investments</div>
                      {data.founder_infusion && (
                        <>
                          <div className="d-flex align-items-center flex-wrap">
                            <div
                              style={{
                                fontWeight: 'bold',
                                width: '100%',
                                fontSize: 12
                              }}
                            >
                              Round Type
                            </div>
                            <div style={{ fontSize: 12, marginTop: 5 }}>{data.round_type}</div>
                          </div>
                          {!isZeno && (
                            <div className="d-flex align-items-center flex-wrap mt-3">
                              <div
                                style={{
                                  fontWeight: 'bold',
                                  width: '100%',
                                  fontSize: 12
                                }}
                              >
                                Personal Money
                              </div>
                              <div style={{ fontSize: 12, marginTop: 5 }}>
                                {data.founder_infusion && data.founder_infusion.amount
                                  ? `${data.founder_infusion.symbol} ${data.founder_infusion.amount} ${data.founder_infusion.unit}`
                                  : '_'}
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      {(!(value && value.publish_for_syn) || !!openUploadDocument) && (
        <Status
          isZeno={isZeno}
          startupId={value.id}
          generate={generate}
          schemeDoc={schemeData.scheme_doc}
          LeadSchemeDoc={schemeData.lead_scheme_doc}
          fund_raising_status={schemeData.fund_raising_status}
          isSchemeComplete={schemeData.is_scheme_complete}
          isSchemeVerified={schemeData.is_scheme_verified || schemeVerified}
          updateSchemeVerified={setSchemeVerified}
          isScheme={schemeData.is_scheme || !!submitButtonShow}
          updateSubmitButtonShow={setSubmitButtonShow}
          isApproved={value.is_approved}
          isInitialApproved={value.is_initial_approved}
          isRejected={value.is_rejected}
          rejectReason={value.reject_reason}
          openModal={openModal}
          handleGenerate={handleGenerate}
          handleEdit={handleEdit}
          // handleView={handleView}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          handlePublish={handlePublish}
          handleSubmitApproval={handleSubmitApproval}
          currentAction={currentAction}
          publishLoader={publishLoader}
          syndicatePermalink={syndicate_permalink}
          handleSetPublish={updatePublished}
          loadDealData={getDealDetailData}
          submitApproval={submitApproval}
          openUploadDocument={openUploadDocument}
          setOpenUploadDocument={setOpenUploadDocument}
          adminList={adminList && adminList.length > 0 && adminList}
        />
      )}
      {openCustomBackerModal && (
        <CustomBackerModal
          startupId={value.id}
          handleCloseModal={handleCustomBackerCloseModal}
          openModal={openCustomBackerModal}
          backerList={shareBackerList && shareBackerList.length > 0 && shareBackerList}
          adminList={adminList}
          handleShareWith={handleShareWith}
          isModify={isModify}
        />
      )}
      {value && value.publish_for_syn && combinedValue && (published || !openUploadDocument) && (
        <PublishDeal combinedValue={combinedValue} isZeno={isZeno} startupId={value.id} startupName={data.name} />
      )}
    </div>
  );
}

export default DealDetail;
