import {
  Container,
  Grid,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import React, { useEffect, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PostHogManager from '../../analytics/postHogManager';
import callApi from '../../api';
import '../../assests/style/non_zeno_backer_detail.scss';
import Loading from '../../common/components/Loading';
import Icon from '../../common/icons/Icon';
import { getSyndicateId } from '../../common/utils/common';
import { httpMethods } from '../../constants';

export const rupeeFormatter = new Intl.NumberFormat('en-IN', {
  currency: 'INR',
});

const NonZenoBackerDetail = () => {
  const { investor_id } = useParams();
  const syndicateId = getSyndicateId();
  const [backerData, setBackerData] = useState({});
  const [backerStartupData, setBackerStartupData] = useState([]);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
  const redirectTo = sessionStorage.getItem('redirect_to');
  const redirectToPath = sessionStorage.getItem('redirect_to_path');

  const getBackerData = () => {
    const url = `${djangoBaseUrl}/v1/syndicates/get_non_zeno_backer_details/?id=${syndicateId}&inv_id=${investor_id}`;
    callApi(url, httpMethods.HTTP_GET, '', {}, false)
      .then((response) => {
        setBackerData(response.data.backer_info);
        setBackerStartupData(response.data.backer_startup_list);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error.detail ||
            'Error while Fetching backer Data please contact at contactus@letsventure.com'
        );
      });
  };

  const toggleInvestorStartupInterest = (startup_id, interested) => {
    const { user_id } = backerData;
    PostHogManager.trackEvent('Mark Not Interested - Triggered', {
      source: 'Manage Investor Page',
      description: 'Mark Not Interested - Triggered',
      data: {
        user_id,
        startup_id,
        interested: interested === null ? false : !interested,
      },
    });
    const apiUrl = `${djangoBaseUrl}/v1/syndicates/mark_backers_interest/?id=${syndicateId}`;
    callApi(
      apiUrl,
      httpMethods.HTTP_POST,
      {
        user_id,
        startup_id,
        interested: interested === null ? false : !interested,
      },
      {},
      false
    )
      .then((response) => {
        PostHogManager.trackEvent('Mark Not Interested - Successfull', {
          source: 'Manage Investor Page',
          description: 'Mark Not Interested - Successfull',
          data: response,
        });
        const interested_value = interested === null ? false : !interested;
        const updatedBackerStartupData = backerStartupData.map((item) => {
          if (item.id === startup_id) {
            return { ...item, interested: interested_value };
          }
          return item;
        });
        setBackerStartupData(updatedBackerStartupData);
        toast.success(`Backer successfully marked as '${interested_value ? 'Interested' : 'Not Interested'}' for the deal`);
      })
      .catch((err) => {
        PostHogManager.trackEvent('Mark Not Interested - Failed', {
          source: 'Manage Investor Page',
          description: 'Mark Not Interested - Failed',
          error: err,
        });
        toast.error(
          err.detail ||
            'Error while updating backer interest please contact at contactus@letsventure.com'
        );
      });
  };

  useEffect(() => {
    getBackerData();
  }, []);
  if (loading) {
    return <Loading />;
  }
  return (
    <Container
      maxWidth={false}
      sx={{
        width: '100%',
        padding: '40px 4% 50px 4% !important',
      }}
    >
      <div
        style={{
          marginBottom: '15px',
          fontSize: '20px',
          fontWeight: 'bold',
          marginTop: 20,
        }}
      >
        <div
          onClick={() => {
            if (redirectTo === 'Backers') {
              history.goBack();
            } else {
              window.location.href = redirectToPath;
            } 
          }}
          style={{ textDecoration: 'none', color: '#000', cursor: 'pointer' }}
        >
          <Icon
            name="ArrowLeft"
            style={{ marginRight: '10px' }}
            height="22"
            width="22"
          />{' '}
          <span style={{ maeginLeft: '10px' }}>Back to {redirectTo}</span>
        </div>
      </div>
      <Stack spacing={2}>
        <Grid
          sm={12}
          sx={{
            height: '292px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            borderRadius: '10px',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '42%',
              padding: '15px 25px 15px 0px',
              background: '#fff',
              borderRadius: 10,
              height: 250,
            }}
            className="box-shadow-view"
          >
            <div style={{ width: '202px', height: '202px' }}>
              <Avatar
                style={{
                  width: '80%',
                  height: '80%',
                  margin: '25px 42px 36px 20px',
                }}
                src={backerData.profile_image}
              />
            </div>
            <div style={{ paddingLeft: '20px' }}>
              <Typography
                variant="h4"
                sx={{ fontSize: '30px', lineHeight: 2, fontWeight: 'bold' }}
              >
                {backerData.name}
              </Typography>
              <Typography sx={{ fontSize: '16px', lineHeight: 1.6 }}>
                {backerData.email}
              </Typography>
              <Typography sx={{ fontSize: '16px', lineHeight: 1.6 }}>
                {backerData.phone_no}
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '15%',
              backgroundColor: '#fff',
              borderRadius: 10,
            }}
          >
            <div>
              <Typography
                sx={{ padding: '10px', fontSize: 16, fontWeight: 'bold' }}
              >
                Committed Amount
              </Typography>
              <Typography
                sx={{
                  padding: '0 10px 10px',
                }}
              >
                ₹ {rupeeFormatter.format(backerData.commited_amount)}
              </Typography>
            </div>
          </div>
          <List
            style={{
              width: '35%',
              lineHeight: 2.5,
              padding: '2%',
              borderRadius: 10,
              height: 250,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
            className="listFont"
          >
            <ListItem
              key={1}
              sx={{ fontSize: '16px' }}
              secondaryAction={
                <span>
                  <b>{backerStartupData.length}</b>
                </span>
              }
              disablePadding
            >
              <span>No of Deals</span>
            </ListItem>
            <ListItem
              key={2}
              sx={{ fontSize: '16px' }}
              secondaryAction={
                <span>
                  <b>{backerData.backing_date}</b>
                </span>
              }
              disablePadding
            >
              <span>Backing Date</span>
            </ListItem>
            <ListItem
              key={3}
              sx={{ fontSize: '16px' }}
              secondaryAction={
                <span>
                  <b>{backerData.access ? 'Public' : 'Private'}</b>
                </span>
              }
              disablePadding
            >
              <span>Platform Access</span>
            </ListItem>
          </List>
        </Grid>
        <Container maxWidth={false} sx={{ padding: '0 !important' }}>
          {backerStartupData.map((backerStartup) => (
            <div className="box-shadow-view startup-box mt-5">
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                  <img
                    height="75"
                    width="75"
                    src={backerStartup.logo}
                    alt={backerStartup.name}
                  />
                </div>
                <div style={{ marginLeft: 20 }}>
                  <div style={{ fontSize: 18 }}>
                    <b>{backerStartup.name}</b>
                  </div>
                  <div>{backerStartup.sectors}</div>
                </div>
              </div>
              <div
                style={{
                  marginLeft: '2%',
                  marginTop: '1%',
                  flexDirection: 'row',
                  display: 'flex',
                }}
              >
                <div className="status-box-parent">
                  <div>
                    <b>Invited</b>
                  </div>
                  <div style={{ height: '20px' }}>{backerStartup.invited_date}</div>
                  <div
                    className={`status-box ${
                      backerStartup.invited ? 'success' : 'pending'
                    }`}
                  >
                  </div>
                </div>
                <div className="status-box-parent">
                  <div>
                    <b>Viewed</b>
                  </div>
                  <div style={{ height: '20px' }}>{backerStartup.view_date}</div>
                  <div
                    className={`status-box ${
                      backerStartup.view ? 'success' : 'pending'
                    }`}
                  >
                  </div>
                </div>
                <div className="status-box-parent">
                  <div>
                    <b>Committed</b>
                  </div>
                  <div style={{ height: '20px' }}>{backerStartup.commited_date}</div>
                  <div
                    className={`status-box ${
                      backerStartup.commited ? 'success' : 'pending'
                    }`}
                  >
                  </div>
                  {backerStartup.commit_amount && (
                    <div className="amount-view">
                      ₹ {rupeeFormatter.format(backerStartup.commit_amount)}
                    </div>
                  )}
                </div>
                <div className="status-box-parent">
                  <div>
                    <b>Drawdown Sent</b>
                  </div>
                  <div style={{ height: '20px' }}>{backerStartup.drawdown_sent_date}</div>
                  <div
                    className={`status-box ${
                      backerStartup.drawdown_sent ? 'success' : 'pending'
                    }`}
                  >
                  </div>
                </div>
                <div className="status-box-parent">
                  <div>
                    <b>Money Received</b>
                  </div>
                  <div style={{ height: '20px' }}>{backerStartup.money_received_date}</div>
                  <div
                    className={`status-box ${
                      backerStartup.money_received ? 'success' : 'pending'
                    }`}
                  >
                  </div>
                  {backerStartup.money_received_amount && (
                    <div className="amount-view">
                      ₹{' '}
                      {rupeeFormatter.format(
                        backerStartup.money_received_amount
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'end', visibility: (backerStartup.commited || backerStartup.drawdown_sent || backerStartup.money_received) ? 'hidden' : null }}>
                <OverlayTrigger
                  overlay={
                    <Tooltip placement="bottom-start">
                      {(backerStartup.interested || backerStartup.interested === null) ? 'Mark backer as not interested for this deal so that they won’t receive any bulk reminders' : 'Mark backer as interested for this deal again'}
                    </Tooltip>
                  }
                >
                  <span className="d-inline-block">
                    <button
                      type="button"
                      className={
                        (backerStartup.interested || backerStartup.interested === null)
                          ? 'cta-button not-interested-button'
                          : 'cta-button interested-button'
                      }
                      onClick={() => {
                        toggleInvestorStartupInterest(
                          backerStartup.id,
                          backerStartup.interested
                        );
                      }}
                    >
                      {(backerStartup.interested || backerStartup.interested === null) ? 'Not Interested' : 'Interested'}
                    </button>
                  </span>
                </OverlayTrigger>
              </div>
            </div>
          ))}
          {backerStartupData && backerStartupData.length === 0 &&
            <h2 className="mt-4 text-center">No startups have shared with the investor yet</h2>}
        </Container>
      </Stack>
    </Container>
  );
};

export default NonZenoBackerDetail;
