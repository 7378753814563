import classNames from 'classnames';
import { ErrorMessage } from 'formik';
import renderHTML from 'react-render-html';
import AsyncSelect from 'react-select/async';
import '../assets/reactselectautocomplete.scss';

function ReactSelectAutocomplete(props) {
  const {
    name,
    setFieldValue,
    placeholder,
    labeltitle,
    ismandatory,
    errors,
    touched,
    disabled,
    notes,
    notInArr,
    multi,
    top,
    handleSelectChange,
    value
  } = props;
  function mapOldJsonToNew(item, _index) {
    return { value: item.id, label: item.text, new_entry: item.new_entry };
  }

  const getOptions = (input, _callback) => {
    const { values } = props;
    switch (name) {
      case 'startup_location_tokens':
        if (input.trim() === '') {
          return Promise.resolve({ options: [] });
        }
        return fetch(`${process.env.REACT_APP_API_BASE_URL}/lookups.json?type=locations&q=${input}`)
          .then((response) => response.json())
          .then((json) => {
            const newJson = json.map(mapOldJsonToNew);
            return newJson;
          });
      case 'sector_tokens':
        if (input.trim() === '') {
          return Promise.resolve({ options: [] });
        }
        return fetch(
          `${process.env.REACT_APP_API_BASE_URL}/lvapi/web/managesectors/all_sectors.json?query=${input}&id_arr=${notInArr}`
        )
          .then((response) => response.json())
          .then((json) => {
            let options = [];
            json.map((value, _index) => {
              if (value.children) {
                options = options.concat(value.children.map(mapOldJsonToNew));
              } else {
                options.push([value].map(mapOldJsonToNew));
              }
            });
            return values.sector_tokens.length >= 3 ? [] : options;
          });
    }
    return [];
  };

  function ErrorView({ errorText, top }) {
    return (
      <div className="text-danger" style={{ marginTop: '0.3rem', fontSize: 14 }}>
        {errorText}
      </div>
    );
  }

  return (
    <div className="edit-form-row react-select-autocomplete">
      {labeltitle && (
        <label className={classNames('edit__profile_label-title')}>
          {labeltitle} {ismandatory ? <span className="text-mandatory">*</span> : ''}
        </label>
      )}
      <div className="mt-2">
        <AsyncSelect
          value={value}
          placeholder={placeholder}
          loadOptions={getOptions}
          noOptionsMessage={({ inputValue }) =>
            !inputValue
              ? 'Please Enter 1 or more character'
              : props.name === 'sector_tokens' && props.value.length >= 3
              ? 'You can add a maximum of 3 sectors'
              : 'No results found'
          }
          onChange={(value) => {
            setFieldValue(name, value);
            if (handleSelectChange) {
              handleSelectChange(value);
            }
          }}
          isMulti={multi}
          isDisabled={disabled}
        />
        {errors ? (
          <>
            {ismandatory && errors[name] && touched[name] && errors[name].value && (
              <ErrorView errorText={errors[name].value} />
            )}
          </>
        ) : (
          <ErrorMessage name={name} render={(msg) => <ErrorView errorText={msg} top={top} />} />
        )}
      </div>
      {notes && <div className="edit_profile_notes mt-3"> {renderHTML(notes)} </div>}
    </div>
  );
}

export default ReactSelectAutocomplete;
