import { useEffect } from 'react';

const CustomProgressBar = ({ lvRaisedPercentage, receivedPercentage }) => {
    const progressColor = (lvRaisedPercentageData, receivedPercentageData) => {
        let moneyReceivedColor = '';
        let lvRaisedColor = '';
        if (lvRaisedPercentageData > receivedPercentageData) {
            moneyReceivedColor = receivedPercentageData
                ? `#0bc11d ${receivedPercentageData}%, #0d6efd ${receivedPercentageData}%, `
                : '';
            lvRaisedColor = lvRaisedPercentageData
                ? `#0d6efd ${lvRaisedPercentageData}%, `
                : '';
        } else {
            moneyReceivedColor = receivedPercentageData
                ? `#0d6efd ${lvRaisedPercentageData}%, #0bc11d ${lvRaisedPercentageData}%, `
                : '';
            lvRaisedColor = receivedPercentageData
                ? `#0bc11d ${receivedPercentageData}%, `
                : '';
        }

        return moneyReceivedColor + lvRaisedColor;
    };

    useEffect(() => { }, [lvRaisedPercentage, receivedPercentage]);
    return (
        <div className="progress" style={{ height: '0.5rem' }}>
            <div
                className="progress-bar"
                style={{
                    background: `linear-gradient(to right,${progressColor(parseFloat(lvRaisedPercentage), parseFloat(receivedPercentage))} #e9ecef 0)`,
                }}
            >
                {' '}
            </div>
        </div>
    );
};

export default CustomProgressBar;