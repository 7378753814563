import React from 'react';

export const Close = (props) => {
  const { name } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <title>{name}</title>
      <g id="clear02" transform="translate(-307.465 -117.5)">
        <path
          id="Rectangle_962"
          fill="none"
          d="M0 0H24V24H0z"
          transform="translate(307.465 117.5)"
        />
        <path
          id="Path_48"
          d="M-541.535 124.5l9 9"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="2"
          transform="translate(857)"
        />
        <path
          id="Path_49"
          d="M-532.535 124.5l-9 9"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="2"
          transform="translate(857)"
        />
      </g>
    </svg>
  );
};
