export function BoxIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        d="M15.75 12V6A1.5 1.5 0 0 0 15 4.702l-5.25-3a1.5 1.5 0 0 0-1.5 0l-5.25 3A1.5 1.5 0 0 0 2.25 6v6A1.5 1.5 0 0 0 3 13.297l5.25 3a1.5 1.5 0 0 0 1.5 0l5.25-3A1.5 1.5 0 0 0 15.75 12z"
        stroke="#111"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.453 5.22 9 9.008l6.547-3.788M9 16.56V9"
        stroke="#111"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function DashboardLayout(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        d="M3 3h4.5v6H3V3zM3 12h4.5v3H3v-3zM10.5 9H15v6h-4.5V9zM10.5 3H15v3h-4.5V3z"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
