import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import React from 'react';
import '../../assests/style/backers_list.scss';
import '../../assests/style/common.scss';

export const rupeeFormatter = new Intl.NumberFormat('en-IN', {
  currency: 'INR',
});

const startupCard = ({ startupList }) => {
  const handlePageSwitch = () => {};
  return (
    <div className="col-md-12">
      {startupList &&
        startupList.length > 0 &&
        startupList.map((row) => (
          <a
            style={{ textDecoration: 'none', cursor: 'pointer' }}
            onClick={() => handlePageSwitch(row.permalink)}
          >
            <Card
              style={{
                backGroundColor: 'white',
                height: '250px',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: '16px',
                marginBottom: '20px',
                padding: '8px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderRadius: '10px',
                  border: '1px solid #cfcdcd',
                  padding: '5px',
                  marginLeft: '1%',
                }}
              >
                <img
                  height="200"
                  width="300"
                  src={row.startup_banner}
                  alt=""
                />
              </div>

              <div
                style={{
                  padding: '25px',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '78%',
                  height: '250px',
                }}
              >
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  {' '}
                  <img height="30" width="30" src={row.logo} />
                  {row && row.startup_status && (
                    <Chip label={row && row.startup_status} color="error" />
                  )}
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    paddingTop: '9px',
                    fontSize: '18px',
                    lineHeight: '18px',
                  }}
                >
                  {row.name}
                </span>
                <span style={{ padding: '5px', fontSize: '14px' }}>
                  {row.sectors}
                </span>
                <span
                  style={{
                    fontSize: '12px',
                    padding: '5px',
                    minWidth: '600px',
                    marginBottom: '20px',
                  }}
                >
                  {row.summary}
                </span>
                <div style={{ padding: '5px', borderTop: '1px solid grey' }}>
                  <div
                    className="commonText syndicateInfo"
                    style={{
                      padding: '10px 0',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div className="infoColumn">
                      <div>LV Ask</div>
                      <div>
                        <b>
                          ₹ {rupeeFormatter.format(row.funding_ask.amount)}{' '}
                          {row.funding_ask.unit}
                        </b>
                      </div>
                    </div>
                    <div className="infoColumn">
                      <div>Committed</div>
                      <div>
                        <b>
                          ₹ {rupeeFormatter.format(row.self_commitment.amount)}{' '}
                          {row.self_commitment.unit}
                        </b>
                      </div>
                    </div>
                    <div
                      className="consentStatus"
                      style={
                        row.consent_status !== 'Consent Awaiting'
                          ? { backgroundColor: '#fdac56' }
                          : {
                              backgroundColor: 'rgb(36, 188, 0)',
                              color: '#fff',
                            }
                      }
                    >
                      {row.consent_status}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </a>
        ))}
    </div>
  );
};

export default startupCard;
