import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import React, { useEffect, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { toast } from 'react-toastify';
import { Oval } from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';
import PostHogManager from '../analytics/postHogManager';
import '../assests/style/deal_detail.scss';
import Icon from '../common/icons/Icon';
import { rupeeFormatter } from './SchemeDocumentModal';
import Modal from '../common/components/Modal';
import callApi from '../api';
import { httpMethods } from '../constants';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

import { getCurrencySymbol } from '../common/utils/common';
import CommunicationModal from './CommunicationModal';

export const SendDrawdownModal = (props) => {
  let { user, getAmount, closeModal } = props;
  const [transferByDate, setTransferByDate] = useState(null);
  const [continueLoader, setcontinueLoader] = useState(false);
  const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
  const [errorMsg, setErrorMsg] = useState(null);

  const handleTransferDateChange = (value, context) => {
    const formattedDate = moment(value).format('YYYY-MM-DD') + 'T10:30:00.000Z';
    setTransferByDate(new Date(formattedDate));
    if (!!errorMsg) setErrorMsg(null);
  };

  const handleSendDrawdown = () => {
    if (!!transferByDate) {
      setErrorMsg(null);
      setcontinueLoader(true);
      const data = {
        investor_commitment_id: `${user.inv_commit_id}`,
        transfer_by_Date: transferByDate
      };
      callApi(
        `${djangoBaseUrl}/v1/investor_drawdown/sent_drawdown_to_investor/`,
        httpMethods.HTTP_POST,
        data,
        {},
        false,
        false
      )
        .then((response) => {
          if (response.status == 200) {
            setcontinueLoader(false);
            closeModal();
            toast.success('Drawdown sent successfully!', {
              style: { color: '#fff' }
            });
            setInterval(() => window.location.reload(), 3000);
          }
        })
        .catch((error) => {
          if (error.response.status === 400 || error.response.status === 404) {
            setcontinueLoader(false);
            toast.error(error.response.data.error, {
              style: { color: '#fff' }
            });
          }
        });
    } else {
      setErrorMsg('Please enter a date');
    }
  };

  return (
    <Modal
      title="Send Drawdown Modal"
      show
      ariaLabel="drawdownlabel"
      width="40%"
      modalMargin="0%"
      style={{ top: '30%', left: '30%' }}
    >
      <div>
        {user.inv_type == 'Foreign' ? (
          <div className="modal-body p-4">
            <div
              onClick={closeModal}
              style={{
                padding: '0 0 10px 0',
                marginLeft: 'auto',
                color: 'rgba(0, 0, 0, 0.45)'
              }}
            >
              <Icon name="close" width="20" height="20" style={{ cursor: 'pointer', float: 'right' }} />
            </div>
            <div style={{ fontSize: '16px' }}>
              Please contact the LetsVenture team at <span className="fw-bold">aif@letsventure.com</span> to send the
              drawdown for this investor as they transfer their funds through their foreign bank account. LetsVenture
              team checks the currency exchange rates and sends the drawdown accordingly.
            </div>
            <div style={{ textAlign: 'center' }}>
              <button
                onClick={closeModal}
                type="button"
                style={{
                  padding: '7px 20px',
                  marginTop: '20px'
                }}
                className="btn btn-primary"
              >
                OK
              </button>
            </div>
          </div>
        ) : (
          <div className="modal-body p-4">
            <div className="d-flex">
              <Avatar
                style={{
                  width: '45px',
                  height: '45px'
                }}
                src={user.img}
                className="investor_list_img"
              />
              <div className="ms-3 my-auto">
                <div className="investor_list_name my-auto">{user.name}</div>
                <div className="d-flex mt-2">
                  {!!user.committed_amount && (
                    <div className="investor_list_sub_text me-3" style={{ fontSize: '12px' }}>
                      Amount:{'  '}
                      <span className="fw-bold">{getAmount(null, user)}</span>
                    </div>
                  )}
                  {!!user.date && (
                    <div className="investor_list_sub_text" style={{ fontSize: '12px' }}>
                      Committed date: <span className="fw-bold">{user.date}</span>
                    </div>
                  )}
                </div>
              </div>
              <div
                onClick={closeModal}
                style={{
                  padding: '0 10px 10px 0',
                  marginLeft: 'auto',
                  color: 'rgba(0, 0, 0, 0.45)',
                  height: '50px'
                }}
              >
                <Icon name="close" width="32" height="32" style={{ cursor: 'pointer' }} />
              </div>
            </div>
            <div style={{ marginTop: 30 }}>
              <div className="fw-bold">Transfer By:</div>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  className="my-3"
                  onChange={handleTransferDateChange}
                  minDate={moment().add(6, 'days')}
                  maxDate={moment().add(15, 'days')}
                />
                {!!errorMsg && (
                  <div className="fw-bold mb-2" style={{ color: 'red' }}>
                    {errorMsg}
                  </div>
                )}
              </LocalizationProvider>
              <div>
                Select the date by which the investor has to transfer the drawdown funds. Dates between 7 and 15 days
                from today can be chosen.
              </div>
            </div>
            <div className="fw-bold mt-3">Note:</div>
            <div>
              You can send the drawdowns only for Indian, NRE and NRO transfer types. For Foreign transfer type, please
              contact LetsVenture team to send the drawdowns.
            </div>
            <button
              type="button"
              style={{
                padding: '7px 20px',
                marginTop: '20px'
              }}
              className="btn btn-primary"
            >
              <span style={{ fontWeight: 'bold' }} onClick={continueLoader ? null : handleSendDrawdown}>
                {continueLoader ? (
                  <div className="d-flex align-items-center">
                    Continue... <Oval height="25" width="25" radius="9" color="#0099ff" />
                  </div>
                ) : (
                  'Continue'
                )}
              </span>
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

function PublishDeal(props) {
  const [openCommunication, setOpenCommunication] = useState(false);
  const [selectedInvId, setSelectedInvId] = useState(null);
  const [typeOfCommunication, setTypeOfCommunication] = useState('');
  const [invCommitId, setInvCommitId] = useState(null);
  const [selectedInvArr, setSelectedInvArr] = useState([]);
  const [communicationPurpose, setCommunicationPurpose] = useState(null);
  const [showDrawdownModal, setShowDrawdownModal] = useState(false);
  const [selectedInv, setSelectedInv] = useState(null);
  const history = useHistory();

  const data = props.isZeno
    ? {
        Committed: props.combinedValue.committed.backers,
        Consented: props.combinedValue.transferred.backers
      }
    : {
        Invited: props.combinedValue.invites_analytics,
        Viewed: props.combinedValue.view_analytics.deal_views,
        // Consent: props.combinedValue.consents.backers,
        'Committed/Consented': props.combinedValue.committed.backers,
        'Money Received': props.combinedValue.transferred.backers
      };
  const [value, setValue] = useState(data);
  const listName = Object.keys(data);
  const headerColor = {
    Invited: 'rgb(80,182,213, 0.2)',
    Viewed: 'rgb(237, 98, 64, 0.2)',
    // Consent: 'rgb(244, 174, 61, 0.2)',
    'Committed/Consented': 'rgb(152, 239, 195, 0.2)',
    'Money Received': 'rgb(108, 194, 148, 0.2)',
    Committed: 'rgb(152, 239, 195, 0.2)',
    Consented: 'rgb(108, 194, 148, 0.2)'
  };

  const showDrawdownPopup = (inv) => {
    setSelectedInv(inv);
    setShowDrawdownModal(true);
  };

  const onCloseModal = () => {
    setShowDrawdownModal(false);
  };

  const filterColumn = (event, columnName) => {
    const searchText = event.currentTarget.value;
    const filteredData = data[columnName].filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase()));
    PostHogManager.trackEvent('Deal Published Search', {
      column_name: columnName,
      search_text: searchText,
      source: 'Deal tracker page after publish',
      description: 'Search on the deal tracker columns after publishing'
    });
    const dataDump = { ...data, [columnName]: filteredData };
    setValue(dataDump);
  };

  const handleInvite = (invId, type, invCommitIdd = null) => {
    if (type === 'not_viewed') {
      PostHogManager.trackEvent('Remind button clicks under Invited tab on Deal Kanban view', {
        source: 'Remind button clicks under Invited tab on Deal Kanban view',
        description: 'When a user clicked on Remind button beside the respective investor in Invite tab'
      });
    } else if (type === 'not_transfered') {
      PostHogManager.trackEvent('Remind button clicks under Committed/Consented tab on Deal Kanban view', {
        source: 'Remind button clicks under Committed/Consented tab on Deal Kanban view',
        description: 'When a user clicked on Remind button beside the respective investor in Committed/Consented tab'
      });
    }
    setSelectedInvId(invId);
    setTypeOfCommunication(type);
    setOpenCommunication(true);
    setCommunicationPurpose('remind');
    setInvCommitId(invCommitIdd);
  };

  const handleRemindAll = (type, dataArray) => {
    let type_of_comm = '';
    if (type === 'Invited') {
      type_of_comm = 'not_viewed';
    } else if (type === 'Committed/Consented') {
      type_of_comm = 'not_transfered';
    } else if (type === 'Viewed') {
      type_of_comm = 'not_committed';
    }
    dataArray = dataArray.filter((item) => item.interested !== false);
    if (type === 'Invited') {
      PostHogManager.trackEvent('Remind All - Deal Invited', {
        source: 'Deal kanban view page',
        description: 'Whenever the user clicks on Remind All button in Invited tab, trigger an event '
      });
    } else if (type === 'Committed/Consented') {
      PostHogManager.trackEvent('Remind All - Deal committed/consented', {
        source: 'Deal kanban view page',
        description: 'Whenever the user clicks on Remind All button in committed/consented tab, trigger an event '
      });
    } else if (type === 'Viewed') {
      PostHogManager.trackEvent('Remind All - Deal Viewed', {
        source: 'Deal kanban view page',
        description: 'Whenever the user clicks on Remind All button in Viewed tab, trigger an event '
      });
    }
    setTypeOfCommunication(type_of_comm);
    setSelectedInvArr(dataArray);
    setCommunicationPurpose('bulk_remind');
    setOpenCommunication(true);
  };

  useEffect(() => {
    const { invites_analytics, view_analytics, committed, transferred } = props.combinedValue;
    if (
      !props.isZeno &&
      !invites_analytics.length &&
      !view_analytics.deal_views.length &&
      !committed.backers.length &&
      !transferred.backers.length
    ) {
      window.location.reload();
    }
  }, [props]);

  const goToInvestor = (investorId) => {
    sessionStorage.setItem('redirect_to', props.startupName);
    sessionStorage.setItem('redirect_to_path', history.location.pathname);
    PostHogManager.trackEvent('Investor Name(Details) clicks - Deal Kanban View', {
      investorId,
      source: 'Deal kanban view page',
      description: 'Whenever the user clicks on any investor name in Deal Kanban view page, trigger an event'
    });
    window.location.href = `/manage/investor/${investorId}`;
  };

  const getAmount = (type, currentValue) => {
    let result = '';
    if (type === 'Money Received') {
      result =
        currentValue?.transferred_amount && currentValue?.transferred_amount_currency
          ? getCurrencySymbol(currentValue?.transferred_amount_currency.name) + ' '
          : '';
      result +=
        rupeeFormatter.format(currentValue?.transferred_amount) +
        ' of ' +
        getCurrencySymbol(currentValue?.transferred_amount_currency.name) +
        ' ';
      result += rupeeFormatter.format(currentValue?.actual_commitment_amount);
    } else {
      result = `${
        currentValue?.committed_amount && currentValue?.committed_amount_currency
          ? getCurrencySymbol(currentValue?.committed_amount_currency.name)
          : ''
      } ${currentValue?.committed_amount ? rupeeFormatter.format(currentValue?.committed_amount) : ''}`;
    }
    return result;
  };

  return (
    <div className="row d-flex mx-5">
      {Object.entries(value).map((item, index) => (
        <div
          className="flex-grow-1 investor_list col-2"
          style={listName[index] === 'Committed/Consented' ? { width: '13%' } : { width: '12%' }}
        >
          <div className="investor_list_header px-3 py-1" style={{ backgroundColor: headerColor[listName[index]] }}>
            <div
              className="investor_list_header-text mb-1"
              style={{
                fontWeight: 'bold',
                fontSize: '16px',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              {`${listName[index]} (${value[listName[index]].length})`}{' '}
              {value[listName[index]].length > 0 &&
                (listName[index] === 'Committed/Consented'
                  ? value[listName[index]].filter((elem) => elem.drawdown_sent).length > 0
                  : true) &&
                (listName[index] === 'Invited' ||
                  listName[index] === 'Viewed' ||
                  listName[index] === 'Committed/Consented') && (
                  <div>
                    <button
                      onClick={() => handleRemindAll(listName[index], value[listName[index]])}
                      style={{
                        border: 'none',
                        backgroundColor: 'inherit'
                      }}
                    >
                      <Chip
                        label="Remind All"
                        size="small"
                        color="primary"
                        variant="outlined"
                        style={{ cursor: 'pointer' }}
                      />
                    </button>
                  </div>
                )}
            </div>
            {/* <div>{data[listName[index]].length}</div> */}
            <input
              type="text"
              style={{
                padding: '5px',
                height: '24px',
                fontSize: '14px',
                width: '100%'
              }}
              placeholder="Search"
              onChange={(event) => filterColumn(event, listName[index])}
            />
          </div>
          <div className="investor_list_container p-1">
            {value[listName[index]].map((elem) => (
              <div
                className="investor_list_item mx-1 my-2 p-3"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: elem.interested === false ? 'rgba(166, 166, 166, 0.54)' : null
                }}
              >
                <div className="d-flex">
                  <Avatar
                    style={{
                      width: '45px',
                      height: '45px'
                    }}
                    src={elem.img}
                    className="investor_list_img"
                  />
                  <div className="ms-3 my-auto">
                    <div className="investor_list_name my-auto" style={props.isZeno ? { color: 'inherit' } : null}>
                      {/* <OverlayTrigger
                        overlay={
                          <Tooltip placement="bottom-start">
                            {elem.email}
                          </Tooltip>
                        }
                      >
                        <span className="d-inline-block">
                          <div onClick={props.isZeno ? null : () => goToInvestor(elem.inv_id)}>
                            {elem.name}
                          </div>
                        </span>
                      </OverlayTrigger> */}
                      <div onClick={props.isZeno ? null : () => goToInvestor(elem.inv_id)}>{elem.name}</div>
                    </div>
                    {!!elem.committed_amount && (
                      <div
                        className="investor_list_sub_text"
                        style={{ opacity: elem.interested === false ? '0.5' : null }}
                      >
                        {getAmount(item[0], elem)}
                      </div>
                    )}
                    {!!elem.date && (
                      <div
                        style={{ fontSize: '9px', opacity: elem.interested === false ? '0.5' : null }}
                        className="investor_list_sub_text"
                      >
                        <span>
                          {listName[index] === 'Invited' ? 'Invited date:' : null}
                          {listName[index] === 'Viewed' ? 'Viewed date:' : null}
                          {listName[index] === 'Committed/Consented' ? 'Committed date:' : null}
                          {listName[index] === 'Money Received' ? 'Money received date:' : null} {elem.date}
                        </span>
                      </div>
                    )}
                    {elem.interested === false && (
                      <div style={{ color: '#d23535' }}>Investor marked as not interested</div>
                    )}
                  </div>
                  {!!elem.drawdown_sent && (
                    <div className="ms-auto my-auto">
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip placement="bottom">Drawdown sent on {elem.drawdown_sent_date}</Tooltip>}
                      >
                        <div role="button">
                          <Icon name="document" className="icon_position d-block" />
                        </div>
                      </OverlayTrigger>
                    </div>
                  )}
                </div>
                <div>
                  {listName[index] === 'Invited' && (
                    <div>
                      <button
                        onClick={() => handleInvite(elem.inv_id, 'not_viewed')}
                        style={{
                          border: 'none',
                          backgroundColor: 'inherit'
                        }}
                      >
                        <Chip
                          className="chip-color"
                          label="Remind"
                          size="small"
                          color="primary"
                          variant="outlined"
                          style={{ cursor: 'pointer' }}
                        />
                      </button>
                    </div>
                  )}
                  {listName[index] === 'Viewed' && (
                    <div>
                      <button
                        onClick={() => handleInvite(elem.inv_id, 'not_committed')}
                        style={{
                          border: 'none',
                          backgroundColor: 'inherit'
                        }}
                      >
                        <Chip
                          className="chip-color"
                          label="Remind"
                          size="small"
                          color="primary"
                          variant="outlined"
                          style={{ cursor: 'pointer' }}
                        />
                      </button>
                    </div>
                  )}
                  {listName[index] === 'Committed/Consented' && !!elem.drawdown_sent && (
                    <div style={{ display: 'flex' }}>
                      <div></div>
                      <button
                        onClick={() => handleInvite(elem.inv_id, 'not_transfered', elem.inv_commit_id)}
                        style={{
                          border: 'none',
                          backgroundColor: 'inherit'
                        }}
                      >
                        <Chip
                          label="Remind"
                          size="small"
                          color="primary"
                          variant="outlined"
                          style={{ cursor: 'pointer' }}
                        />
                      </button>
                    </div>
                  )}
                  {listName[index] === 'Committed/Consented' && !elem.drawdown_sent && (
                    <div style={{ display: 'flex' }}>
                      <div></div>
                      <button
                        onClick={() => showDrawdownPopup(elem)}
                        style={{
                          border: 'none',
                          backgroundColor: 'inherit'
                        }}
                      >
                        <Chip
                          label="Send Drawdown"
                          size="small"
                          color="primary"
                          variant="outlined"
                          style={{ cursor: 'pointer' }}
                        />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
      {showDrawdownModal && (
        <SendDrawdownModal
          show={showDrawdownModal}
          user={selectedInv}
          closeModal={onCloseModal}
          getAmount={getAmount}
        />
      )}
      {openCommunication && (
        <CommunicationModal
          invId={selectedInvId}
          startupId={props.startupId}
          communicationType={typeOfCommunication}
          closeModal={setOpenCommunication}
          invCommitId={invCommitId}
          selectedInvArr={selectedInvArr}
          communicationPurpose={communicationPurpose}
          resendDrawdownNotice
        />
      )}
    </div>
  );
}

export default PublishDeal;
