export function GreenTick(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      {...props}
    >
      <path
        d="m18.563 6.188-9.625 9.625L4.125 11"
        stroke="#0BC11D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ErrorIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path fill="#fff" fillOpacity=".01" d="M0 0h16v16H0z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.51 2.655c.161.067.308.166.432.29l4.112 4.111a1.335 1.335 0 0 1 0 1.889l-4.112 4.111a1.335 1.335 0 0 1-1.888 0L2.942 8.945a1.335 1.335 0 0 1 0-1.889l4.112-4.111a1.335 1.335 0 0 1 1.455-.29zm-.983 6.483a.667.667 0 0 0 1.138-.471V5.334a.667.667 0 0 0-1.333 0v3.333c0 .177.07.346.195.471zm0 2a.667.667 0 1 0 .943-.943.667.667 0 0 0-.943.943z"
        fill="#DE350B"
      />
    </svg>
  );
}

export function PrivateIcon(props) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      {...props}
    >
      <path
        d="M12.667 7.333H3.333C2.597 7.333 2 7.93 2 8.667v4.666c0 .737.597 1.334 1.333 1.334h9.334c.736 0 1.333-.597 1.333-1.334V8.667c0-.737-.597-1.334-1.333-1.334zM4.667 7.333V4.667a3.333 3.333 0 0 1 6.666 0v2.666"
        stroke="#111"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function PasswordIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M3.125 4.375v11.25M8.125 7.5V10M5.75 9.227 8.125 10M6.656 12.023 8.125 10M9.594 12.023 8.125 10M10.5 9.227 8.125 10M15.625 7.5V10M13.25 9.227l2.375.773M14.156 12.023 15.625 10M17.094 12.023 15.625 10M18 9.227 15.625 10"
        stroke="#000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
