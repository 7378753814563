import {
  ArrowLeft,
  ArrowRight,
  BackButtonIcon,
  CashIcon,
  DealHeartIcon,
  Document,
  FileDescription,
  FolderIcon,
  FounderIcon,
  PresentationIcon,
  PublishIcon,
  Reject,
  ShowLessIcon,
  ShowMoreIcon,
  ThreeCirclesIcon,
  UploadDoc,
  WalletIcon,
  WhiteCheck
} from './arrowIcons';
import { Close } from './close';
import { Edit, PaperClip, Thrash } from './edit';
import { Lvlogo, PdfReader } from './logo';
import {
  ChangePassword,
  Logout,
  UserCircle,
  UserManagement,
} from './menuIcons';
import { BoxIcon, DashboardLayout } from './navbarIcons';
import NewspaperIcon from './newspaper';
import { MultiUser, UserIcon, UserPlus } from './usericons';
import { ErrorIcon, GreenTick, PrivateIcon } from './validation';

const Icon = (props) => {
  switch (props.name && props.name.toLowerCase()) {
    case 'usercircle':
      return <UserCircle {...props} />;
    case 'usermanagement':
      return <UserManagement {...props} />;
    case 'logout':
      return <Logout {...props} />;
    case 'changepassword':
      return <ChangePassword {...props} />;
    case 'userManagement':
      return <UserManagement {...props} />;
    case 'privateicon':
      return <PrivateIcon {...props} />;
    case 'arrowleft':
      return <ArrowLeft {...props} />;
    case 'arrowright':
      return <ArrowRight {...props} />;
    case 'boxicon':
      return <BoxIcon {...props} />;
    case 'dashboardlayout':
      return <DashboardLayout {...props} />;
    case 'usericon':
      return <UserIcon {...props} />;
    case 'userplus':
      return <UserPlus {...props} />;
    case 'multiuser':
      return <MultiUser {...props} />;
    case 'greentick':
      return <GreenTick {...props} />;
    case 'whitetick':
      return <WhiteCheck {...props} />;
    case 'reject':
      return <Reject {...props} />;
    case 'erroricon':
      return <ErrorIcon {...props} />;
    case 'edit':
      return <Edit {...props} />;
    case 'close':
      return <Close {...props} />;
    case 'newspapericon':
      return <NewspaperIcon {...props} />;
    case 'filedescription':
      return <FileDescription {...props} />;
    case 'foldericon':
      return <FolderIcon {...props} />;
    case 'publishicon':
      return <PublishIcon {...props} />;
    case 'foundericon':
      return <FounderIcon {...props} />;
    case 'presentationicon':
      return <PresentationIcon {...props} />;
    case 'cashicon':
      return <CashIcon {...props} />;
    case 'walleticon':
      return <WalletIcon {...props} />;
    case 'lvlogo':
      return <Lvlogo {...props} />;
    case 'showmoreicon':
      return <ShowMoreIcon {...props} />;
    case 'threecirclesicon':
      return <ThreeCirclesIcon {...props} />;
    case 'dealhearticon':
      return <DealHeartIcon {...props} />;
    case 'showlessicon':
      return <ShowLessIcon {...props} />;
    case 'pdfreader':
      return <PdfReader {...props} />;
    case 'backbuttonicon':
      return <BackButtonIcon {...props} />;
    case 'document':
      return <Document {...props} />;
    case 'paperclip':
      return <PaperClip {...props} />;
    case 'thrash':
      return <Thrash {...props} />;
    case 'upload-doc':
      return <UploadDoc {...props} />;
    default:
      return <div />;
  }
};

export default Icon;
