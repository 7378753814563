import { makeStyles } from '@material-ui/core';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import callApi from '../api';
import startupBanner from '../assests/image/startup_banner.svg';
import startupLogo from '../assests/image/startup_logo.png';
import '../assests/style/deal_detail.scss';
import Modal from '../common/components/Modal';
import DragAndDrop from '../common/formik/components/DragAndDrop';
import Select from '../common/formik/components/Select';
import TextArea from '../common/formik/components/TextArea';
import TextInput from '../common/formik/components/TextInput';
import { httpMethods } from '../constants';
import LeadInvestorList from '../startup/components/StartupInformation/LeadInvestorList';

import PostHogManager from '../analytics/postHogManager';
import Icon from '../common/icons/Icon';
import { getSyndicateId, getToken } from '../common/utils/common';

const useStyles = makeStyles({
  scheme_input: {
    width: '45%'
  }
});

const converter = require('num-words');

export const rupeeFormatter = new Intl.NumberFormat('en-IN', {
  currency: 'INR'
});

function SchemeDocumentModal(props) {
  console.log('SCHEME DOC PROPS', props);
  const classes = useStyles();

  const data = {
    name: 'Cleartech',
    banner_logo: startupBanner,
    startup_logo: startupLogo,
    sector: 'Environmental',
    stage: 'Early stage',
    privacy: true,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
  };
  // const [openAddInvestorModal, setOpenAddInvestorModal] = useState(false);
  const [schemeDocData, setSchemeDocData] = React.useState();
  const [isZeno, setIsZeno] = useState();
  const [isScheme, setIsScheme] = useState(false);
  const [draftMode, setDraftMode] = useState(true);
  const [savingScheme, setSavingScheme] = useState(false);
  const [generateScheme, setGenerateScheme] = useState(false);
  const [showOTPModal, setshowOTPModal] = useState(props.openModal);
  const [otp_key, setOtpKey] = useState('1234');
  const token = getToken();
  const syndicate_id = getSyndicateId();
  const [disableSaveDraftButton, setDisableSaveDraftButton] = useState(false);
  const [incomplete, setIncomplete] = useState(!props.isSchemeComplete);
  const [schemeReviewpPath, setSchemeReviewpPath] = useState(props.schemeDoc);
  // const [dateObjj, setDateObjj] = useState(new Date());
  // const token = 'tv6JmyMrn1HBp0kxPpzY3Z4cy0U1WwXZ';
  // const dateObjj = new Date();
  const history = useHistory();
  const syndicateType = localStorage.getItem('syndicate_type');
  const homeRouteHandler = () => {
    history.push('/home');
  };

  const handleTabChange = (event, newValue) => {
    setSchemeDocData(newValue);
  };

  const saveLeadInvestor = (setFieldValue, values) => {
    if (values && values.selected_lead_investor && values.lead_investor_list) {
      const filtered_lead_investor = values.lead_investor_list.filter(
        (item) => item.value !== values.selected_lead_investor.value
      );
      filtered_lead_investor.push({
        ...values.selected_lead_investor
      });
      setFieldValue('lead_investor_list', filtered_lead_investor);
    }
    // setOpenAddInvestorModal(false);
    setFieldValue('selected_lead_investor', null);
    // setLeadCarry(null);
  };

  const initialValue = {
    syndicate_name: (schemeDocData && schemeDocData.syndicate_name) || '',
    startup_name: (schemeDocData && schemeDocData.startup_name) || '',
    reg_startup_name: (schemeDocData && schemeDocData.reg_startup_name) || '',
    hurdle_rate: (schemeDocData && schemeDocData.hurdle_rate) || '',
    // investment_round: (schemeDocData && schemeDocData.investment_round) || '',
    round_type: (schemeDocData && schemeDocData.round_type) || '',
    total_ask: (schemeDocData && schemeDocData.total_ask) || '',
    commitment_via_lv: (schemeDocData && schemeDocData.commitment_via_lv) || '',
    pre_money_valuation: (schemeDocData && schemeDocData.pre_money_valuation) || '',
    // pre_money_valuation_currency:
    //   (schemeDocData && schemeDocData.pre_money_valuation_currency) || '',
    fund_carry: (schemeDocData && schemeDocData.fund_carry) || 2.5,
    startup_lead_engagements_arr: (schemeDocData && schemeDocData.startup_lead_engagements_arr) || '',
    share_price: (schemeDocData && schemeDocData.share_price) || '',
    last_date_to_consent: (schemeDocData && schemeDocData.last_date_to_consent) || '',
    securities_description: (schemeDocData && schemeDocData.securities_description) || '',
    conversion_terms: (schemeDocData && schemeDocData.conversion_terms) || '',
    startup_service: (schemeDocData && schemeDocData.startup_service) || '',
    // startup_scheme_name:
    //   (schemeDocData && schemeDocData.startup_scheme_name) ||
    //   `LV ${schemeDocData && schemeDocData.syndicate_name.replace(/ /g, '')} ${
    //     schemeDocData && schemeDocData.startup_name.replace(/ /g, '')
    //   } F I`,
    right_under_negotiations: (schemeDocData && schemeDocData.right_under_negotiations) || '',
    share_price_with_text: (schemeDocData && schemeDocData.share_price_with_text) || '',
    revenue_check:
      (schemeDocData &&
      schemeDocData.revenue_check !== '' &&
      schemeDocData.revenue_check !== null &&
      schemeDocData.revenue_check === true
        ? 'true'
        : 'false') || '',
    draft_agreement_file: (schemeDocData && schemeDocData.draft_agreement_file) || '',
    draft_agreement_file_name: (schemeDocData && schemeDocData.draft_agreement_file_name) || '',
    dpiit_file_name: (schemeDocData && schemeDocData.dpiit_file_name) || '',
    dpiit: (schemeDocData && (schemeDocData.dpiit == null ? '' : schemeDocData.dpiit ? 'Yes' : 'No')) || '',
    lv_transaction_fee: syndicateType == 'zeno' ? 0 : (schemeDocData && schemeDocData.lv_transaction_fee) || '',
    // founder_charge: (schemeDocData && schemeDocData.founder_charge) || '1',
    sector_names: (schemeDocData && schemeDocData.sector_names) || '',
    lead_investor_list: (schemeDocData && schemeDocData.lead_investor_list) || [],
    total_lead_carry: schemeDocData && schemeDocData.total_lead_carry,
    commit_time_window: (schemeDocData && schemeDocData.commit_time_window) || '',
    lv_team_comments: (schemeDocData && schemeDocData.lv_team_comments) || '',
    send_auto_drawdown:
      (schemeDocData &&
        (schemeDocData.send_auto_drawdown == null ? 'Yes' : schemeDocData.send_auto_drawdown ? 'Yes' : 'No')) ||
      'Yes'
  };

  const revenueTypeOptions = [
    { label: 'Select', value: '' },
    { label: 'Over INR 100Cr', value: 'true' },
    { label: 'Less than INR 100Cr', value: 'false' }
  ];

  const securityTypeOptions = [
    { label: 'Select', value: '' },
    { label: 'Equity', value: 'equity' },
    { label: 'CCPS', value: 'ccps' },
    { label: 'CCD', value: 'ccd' },
    { label: 'Convertible Note', value: 'convertible_debt' }
  ];

  const roundTypeOptions = [
    { label: 'Select Round Type', value: '' },
    { label: 'Pre-Seed', value: 'Pre Seed' },
    { label: 'Seed', value: 'Seed' },
    { label: 'Pre-Series A', value: 'Pre Series A' },
    { label: 'Series A', value: 'Series A' },
    { label: 'Pre-Series B', value: 'Pre Series B' },
    { label: 'Series B', value: 'Series B' }
  ];
  const currencyOptions = [
    { label: 'INR', value: 1 }
    // { label: 'USD', value: 2 },
  ];
  const validateFileUploads = (values) => {
    // const isDpit = !!values.dpiit_file_name || !!values.dpiit_certificate;
    const isSha = !!values.draft_agreement_file || !!values.draft_agreement_file_name;
    return isSha;
  };
  const validationSchema = Yup.object({
    revenue_check: Yup.bool().required('Required'),
    hurdle_rate: Yup.number().positive('Must be a Positive Number'),
    // investment_round: Yup.string().required('Required'),
    round_type: Yup.string().required('Required'),
    total_ask: Yup.number()
      .required('Required')
      .positive('Must be a Positive Number')
      .integer('Must be an Integer Number'),
    pre_money_valuation: Yup.number()
      .positive('Must be a Positive Number')
      .when('round_type', {
        is: (round_type) => round_type === 'ccd' || round_type === 'convertible_debt',
        then: Yup.number(),
        otherwise: Yup.number().required('required')
      })
      .moreThan(Yup.ref('total_ask'), 'The pre money valuation must be greater than the total round size'),
    fund_carry: Yup.number().required('Required').positive('Must be a Positive Number'),
    total_lead_carry: Yup.number()
      .required('Required')
      .test(
        'total_lead_carry_test',
        'Lv Carry + Total Lead Carry should be <= 20',
        (value, validationContext) => value + validationContext.parent.fund_carry <= 20
      )
      .test('min', 'Total Lead Carry can not be less than Zero', (value, validationContext) => value >= 0),
    // last_date_to_consent: Yup.date().required('Required').min(new Date(), 'The last date to consent must be later to the present date'),
    // securities_description: Yup.string().required('Required'),
    conversion_terms: Yup.string().when('round_type', {
      is: (round_type) => round_type !== 'equity',
      then: Yup.string().required('required'),
      otherwise: Yup.string()
    }),
    startup_service: Yup.string().required('Required'),
    share_price_with_text: Yup.number()
      .positive('Must be a Positive Number')
      .when('round_type', {
        is: (round_type) => round_type === 'convertible_debt',
        then: Yup.number(),
        otherwise: Yup.number().required('required')
      }),
    lead_investor_list: Yup.array().test('my_lead_carry_test', (value, validationContext) => {
      const total_carry = value.reduce((sum, item) => sum + Number(item.lead_carry), 0.0);

      const EPSILON = 1e-6; // Define a small tolerance

      const checkLeadAllocation = () => {
        let msg = '';

        if (
          validationContext.parent.total_lead_carry <= 0.5 ||
          Math.abs(total_carry - validationContext.parent.total_lead_carry) <= EPSILON
        ) {
          return true;
        }

        if (total_carry < validationContext.parent.total_lead_carry) {
          msg = 'Lead Carry is left to be allocated';
          return validationContext.createError({ message: msg });
        }

        msg = 'Lead Carry is exceeding total lead carry';
        return validationContext.createError({ message: msg });
      };

      const checkResult = checkLeadAllocation();
      return validationContext.parent.total_lead_carry ? checkResult : true;
    }),
    lv_transaction_fee:
      syndicateType == 'zeno' ? '' : Yup.number().required('Required').positive('Must be a Positive Number'),
    // lead_transaction_fee: Yup.number()
    //   .required('Required')
    //   .positive('Must be a Positive Number')
    //   .integer()
    //   .test(
    //     'lead_transaction_fee_test',
    //     'Lv Transaction fee + Lead Transaction fee should be <= 5',
    //     (value, validationContext) =>
    //       value + validationContext.parent.lv_transaction_fee <= 5
    //   ),
    commit_time_window: Yup.number()
      .required('Required')
      .positive('Must be a Positive Number')
      .integer('Must be an Integer Number'),
    commitment_via_lv: Yup.number()
      .required('Required')
      .integer('Must be an Integer Number')
      .test(
        'Min Commit',
        'Minimum commit via LV should not be less than 25 Lakh',
        (value, validationContext) => value >= 2500000
      ),
    // startup_scheme_name: Yup.string().required('Required'),
    right_under_negotiations: Yup.string(),
    dpiit: Yup.string().required('Required'),
    send_auto_drawdown: Yup.string().required('Required')
  });

  const handleNoDaysToCommit = (values) => {
    const dateObjj = new Date();
    dateObjj.setDate(dateObjj.getDate() + (values.commit_time_window || 0) + 7);
    return dateObjj.toISOString().substring(0, 10);
  };

  const lastDayForCommit = (values) => {
    const dateObjj = new Date();
    dateObjj.setDate(dateObjj.getDate() + (values.commit_time_window || 0) + 7);
    const day = dateObjj.toLocaleString('default', { day: '2-digit' });
    const month = dateObjj.toLocaleString('default', { month: 'short' });
    const year = dateObjj.toLocaleString('default', { year: 'numeric' });
    return `${day} ${month} ${year}`;
  };

  const option = {
    style: { color: '#fff' },
    autoClose: 7000
  };

  const onSubmit = (values, formIncomplete) => {
    setSavingScheme(true);
    const formData = new FormData();
    formData.append('current_action', props.currentAction);
    formData.append('startup_name', values.startup_name);
    formData.append('reg_startup_name', values.reg_startup_name);
    formData.append('hurdle_rate', values.hurdle_rate);
    // formData.append('investment_round', values.investment_round);
    formData.append('round_type', values.round_type);
    formData.append('total_ask', values.total_ask);
    formData.append('commitment_via_lv', values.commitment_via_lv);
    formData.append('pre_money_valuation', values.pre_money_valuation);
    formData.append('pre_money_valuation_currency', '1');
    formData.append('fund_carry', values.fund_carry);
    formData.append('total_lead_carry', values.total_lead_carry);
    // formData.append('founder_charge', values.founder_charge);
    formData.append('startup_lead_engagements_arr', values.startup_lead_engagements_arr);
    if(schemeDocData.share_price) {
      formData.append('share_price', schemeDocData.share_price);
    }
    formData.append('last_date', handleNoDaysToCommit(values));
    formData.append('commit_time_window', values.commit_time_window);
    formData.append(
      'securities_description',
      !!values.share_price_with_text
        ? Math.floor(values.commitment_via_lv / values.share_price_with_text)
        : values.securities_description
    );
    formData.append('conversion_terms', values.round_type === 'equity' ? 'Not Applicable' : values.conversion_terms);
    formData.append('startup_service', values.startup_service);
    // formData.append('startup_scheme_name', values.startup_scheme_name);
    formData.append('right_under_negotiations', values.right_under_negotiations);
    formData.append('share_price_with_text', values.share_price_with_text);
    formData.append('revenue_check', values.revenue_check);
    if (!!values.pitchDeck) {
      formData.append('draft_agreement_file', values.pitchDeck);
    }
    // if (!!values.dpitt) formData.append('dpiit_certificate', values.dpitt);
    if (values.dpiit != '') formData.append('dpiit', values.dpiit == 'Yes');
    formData.append('lv_transaction_fee', values.lv_transaction_fee);
    // formData.append('lead_transaction_fee', values.lead_transaction_fee);
    formData.append('startup_id', props.startupId);
    formData.append('lv_team_comments', values.lv_team_comments);
    formData.append('lead_investor_list', JSON.stringify(values.lead_investor_list));
    formData.append('incomplete', formIncomplete);
    if (values.send_auto_drawdown != '') {
      formData.append('send_auto_drawdown', values.send_auto_drawdown === 'Yes');
    }
    PostHogManager.trackEvent('Scheme Save Submitted', {
      syndicate_id,
      source: 'Scheme Creation Modal',
      description: 'Scheme Save Submitted',
      submitted_data: values
    });
    const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
    const url = `${djangoBaseUrl}/v1/syndicates/diy_update_startup_specific_scheme_data/?id=${syndicate_id}`;
    callApi(
      url,
      httpMethods.HTTP_POST,
      formData,
      {},
      false
    )
      .then((response) => {
        PostHogManager.trackEvent('Scheme Save Completed - Success', {
          syndicate_id,
          source: 'Scheme Creation Modal',
          description: 'Scheme Saving Successfull',
          deal_status: 'Draft - Scheme Saved',
          response_data: response
        });
        if (!formIncomplete) {
          // setSchemeReviewpPath(
          //   response.data.data.scheme_document_list[0].scheme_pdf_url
          // );
          props.updateSubmitButtonShow(true);
          setIsScheme(true);
          setIncomplete(false);
        } else {
          setIncomplete(true);
        }
        props.updateSetComplete(!formIncomplete);
        setSavingScheme(false);
        // setDraftMode(false);
        if (!formIncomplete) {
          toast.success('Scheme doc data saved successfully.', option);
        } else {
          toast.success('Scheme doc data saved successfully.', option);
        }
        props.closeSchemeModal();
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((error) => {
        PostHogManager.trackEvent('Scheme Save Completed - Failed', {
          syndicate_id,
          source: 'Scheme Creation Modal',
          description: 'Scheme Saving Failed',
          deal_status: 'Draft - Scheme Not Saved',
          response_data: error
        });
        toast.error(error.message, option);
        // setDraftMode(true);
        setSavingScheme(false);
      });
  };

  // const addInvestors = (id) => {
  //   setOpenAddInvestorModal(true);
  // };

  const removeInvestor = (id, setFieldValue, leadInvestorList) => {
    const confirmation = window.confirm('Do you really want to remove this Investor?');
    if (leadInvestorList && confirmation) {
      const filtered_lead_investor = leadInvestorList.filter((item) => item.value !== id);
      setFieldValue('lead_investor_list', filtered_lead_investor);
    }
  };

  // const handleClose = () => setOpenAddInvestorModal(false);

  const loadInvestorList = async (input, _callback) => {
    if (input.trim() === '') {
      return Promise.resolve({ options: [] });
    }
    PostHogManager.trackEvent('Fetch Lead Investor List', {
      syndicate_id,
      source: 'Scheme Creation Modal',
      description: 'Fetching Lead Investors List',
      submitted_data: { q: input, current_user_id: user.user_id }
    });
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/lookups.json?type=all_angel_investors&custom=true&is_lead=true&q=${input}&current_user_id=${user.user_id}`
    );
    const json = await response.json();
    PostHogManager.trackEvent('Fetched Lead Investor List - Success', {
      syndicate_id,
      source: 'Scheme Creation Modal',
      description: 'Fetched Lead Investors List',
      response_data: json
    });
    const newJson = json.map((field, k) => ({
      label: field.text,
      options: field.children
        .filter((item) => item.new_entry !== true)
        .map((child, k_1) => ({
          value: child.id,
          label: child.text,
          role: child.role,
          location: child.location,
          profile_image: child.profile_image,
          logo_img: child.logo_img,
          new_entry: child.new_entry,
          type: child.type
        }))
    }));
    return newJson;
  };

  const generateSchemeDoc = () => {
    setGenerateScheme(true);
    PostHogManager.trackEvent('Scheme Generate Submitted', {
      syndicate_id,
      source: 'Scheme Creation Modal',
      description: 'Scheme Generate Submitted',
      submitted_data: { startup_id: `${props.startupId}` }
    });
    callApi(`/api/v3/web/syndicates/syndicate/${syndicate_id}/diy_scheme_doc_generation`, httpMethods.HTTP_POST, {
      startup_id: `${props.startupId}`
    })
      .then((res) => {
        PostHogManager.trackEvent('Scheme Generate Completed - Success', {
          syndicate_id,
          source: 'Scheme Creation Modal',
          description: 'Scheme Generation Successfull',
          deal_status: 'Draft - Scheme Generated',
          response_data: res
        });
        props.handleCloseModal();
        toast.success('Scheme doc submitted for approval. Await the response from LV Legal.', option);
        setGenerateScheme(false);
        window.location.reload();
      })
      .catch((error) => {
        PostHogManager.trackEvent('Scheme Generate Completed - Failed', {
          syndicate_id,
          source: 'Scheme Creation Modal',
          description: 'Scheme Generation Failed',
          deal_status: 'Draft - Scheme Not Generated',
          response_data: error
        });
        toast.error(error.message, option);
        setGenerateScheme(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    async function init() {
      if (!!syndicate_id) {
        PostHogManager.trackEvent('Scheme Fetch Triggered', {
          syndicate_id,
          source: 'Scheme Creation Modal',
          description: 'Scheme Fetching Triggered',
          submitted_data: { startup_id: `${props.startupId}` }
        });
        const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
        const url = `${djangoBaseUrl}/v1/syndicates/diy_fetch_scheme_data/?id=${syndicate_id}&startup_id=${props.startupId}`;
        callApi(
          url,
          httpMethods.HTTP_GET,
          '',
          {},
          false
        )
          .then((response) => {
            PostHogManager.trackEvent('Scheme Fetch Triggered - Success', {
              syndicate_id,
              source: 'Scheme Creation Modal',
              description: 'Scheme Fetching Successfull',
              response_data: response
            });
            if (response.data.data.specific_data.is_zeno) {
              setIsZeno(response.data.data.specific_data.is_zeno);
            }
            const schemeData = response.data.data.specific_data;
            if (!Number(schemeData.pre_money_valuation)) {
              schemeData.pre_money_valuation = '';
            }
            setSchemeDocData(schemeData);
            setIsScheme(response.data.data.specific_data.is_scheme);
            // setDraftMode(true);
          })
          .catch((error) => {
            PostHogManager.trackEvent('Scheme Fetch Triggered - Failed', {
              syndicate_id,
              source: 'Scheme Creation Modal',
              description: 'Scheme Fetching Failed',
              response_data: error
            });
            toast.error(error.message);
            setSavingScheme(false);
            // setDraftMode(true);
          });
      }
    }
    init();
  }, []);

  const share_price_change = (e, values, setFieldValue) => {
    const securityDesc =
      Number(e.target.value) > 0 ? Math.floor(values.commitment_via_lv / Number(e.target.value)) : '';
    setFieldValue('share_price_with_text', Number(e.target.value) > 0 ? Number(e.target.value) : '');
    setFieldValue('securities_description', securityDesc);
  };

  console.log('Modal Value', schemeDocData, initialValue);

  return (
    <div>
      <Modal title="Enter One-Time Password" show ariaLabel="otpLabel" className="scheme-modal">
        <Formik
          initialValues={initialValue}
          // onSubmit={onSubmit}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setFieldError,
            setFieldTouched,
            validateField,
            validateForm
          }) => (
            <Form>
              <div className="modal-body" style={{ margin: '30px 30px 30px 50px' }}>
                <div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'grid' }}>
                      <img
                        src={schemeDocData && schemeDocData.startup_logo}
                        // src="https://imgsv.imaging.nikon.com/lineup/dslr/df/img/sample/img_01.jpg"
                        width={29}
                        height={29}
                        alt="Logo"
                        style={{
                          // border: '1px solid',
                          // borderRadius: 50,
                          // borderColor: 'black',
                          margin: '2px 24px 21px 0px'
                        }}
                      />
                      <div
                        style={{
                          width: '500px',
                          height: '18px',
                          margin: '0 17px 45px 0',
                          fontFamily: 'ProximaNova',
                          fontSize: '20px',
                          fontWeight: 'bold',
                          fontStretch: 'normal',
                          fontStyle: 'normal',
                          lineHeight: '0.9',
                          letterSpacing: 'normal',
                          textAlign: 'left',
                          color: '#000'
                        }}
                      >
                        {schemeDocData && schemeDocData.startup_name}{' '}
                      </div>
                    </div>
                    <div onClick={props.handleCloseModal}>
                      <Icon name="close" width="32" height="32" style={{ cursor: 'pointer' }} />
                    </div>
                  </div>
                  <div className="col-sm-24">
                    <h3 className="font-weight-bold">Scheme Document</h3>
                    {/* <div className="form-group">
                      <label htmlFor="otp_key" className="modal-inner-title">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna
                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea Lorem ipsum dolor
                        sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut
                        enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat.
                      </label>
                    </div> */}
                    <div className={classes.scheme_input}>
                      <TextInput
                        labeltitle="Name of Syndicate"
                        name="syndicate_name"
                        type="text"
                        notes=""
                        disabled
                        style={{ width: '100%' }}
                      />
                    </div>
                    <div className={classes.scheme_input}>
                      <TextInput
                        labeltitle="Name of Startup"
                        name="startup_name"
                        type="text"
                        notes=""
                        disabled
                        style={{ width: '100%' }}
                      />
                    </div>
                    <div className={classes.scheme_input}>
                      <TextInput
                        labeltitle="Registered Name of Startup"
                        name="reg_startup_name"
                        type="text"
                        notes=""
                        disabled
                        style={{ width: '100%' }}
                      />
                    </div>
                    <div className={classes.scheme_input}>
                      <TextInput
                        labeltitle="Sector(s) the Startup is engaged in"
                        name="sector_names"
                        type="text"
                        notes=""
                        ismandatory
                        style={{ width: '100%' }}
                        disabled
                      />
                    </div>
                    <div className={classes.scheme_input}>
                      <TextInput
                        labeltitle="Business the Startup is engaged in (1 line description)"
                        name="startup_service"
                        type="text"
                        notes=""
                        ismandatory
                        style={{ width: '100%' }}
                      />
                    </div>
                    {/* <div className={classes.scheme_input}>
                      <Select
                        name="round_type"
                        labeltitle="What type of securities are being issued?"
                        placeholder="Select Security Type"
                        touched={touched}
                        options={securityTypeOptions}
                        multi={false}
                        notes="Contact the LetsVenture team for other security options"
                        ismandatory
                        errorText="Required"
                      />
                    </div> */}
                    <div className={classes.scheme_input} style={{ width: '26%' }}>
                      <Select
                        name="round_type"
                        labeltitle="Type of securities being issued"
                        placeholder="Select"
                        touched={touched}
                        options={securityTypeOptions}
                        multi={false}
                        notes="Connect with your contact at LetsVenture if you have any questions"
                        ismandatory
                        errorText="Required"
                      />
                    </div>
                    <div className={classes.scheme_input}>
                      {/* <div
                        style={{
                          fontSize: '20px',
                          fontWeight: '600',
                          color: '#000',
                          width: '700px'
                        }}
                      >
                        What is the Pre-Money Valuation of the Startup?
                      </div> */}
                      <TextInput
                        labeltitle="Pre-Money Valuation"
                        name="pre_money_valuation"
                        type="number"
                        notes={
                          values.pre_money_valuation && !String(values.pre_money_valuation).includes('.')
                            ? `INR ${rupeeFormatter.format(values.pre_money_valuation)}/- ${
                                values.pre_money_valuation < 999999999 && values.pre_money_valuation > 0
                                  ? converter(values.pre_money_valuation).replace(/^./, (str) => str.toUpperCase())
                                  : ''
                              } only`
                            : ''
                        }
                        ismandatory={
                          values.round_type === 'equity' || values.round_type === 'ccps' || !values.round_type
                        }
                        symbol="INR"
                        style={{ maxWidth: '25%', textAlign: 'end' }}
                      />
                    </div>
                    <div className={classes.scheme_input}>
                      <TextInput
                        labeltitle="Total Round Size (all LV and non-LV investors)"
                        name="total_ask"
                        type="number"
                        notes={
                          values.total_ask && !String(values.total_ask).includes('.')
                            ? `INR ${rupeeFormatter.format(values.total_ask)}/- ${
                                values.total_ask < 999999999 && values.total_ask > 0
                                  ? converter(values.total_ask).replace(/^./, (str) => str.toUpperCase())
                                  : ''
                              } only`
                            : ''
                        }
                        ismandatory
                        symbol="INR"
                        style={{ maxWidth: '25%', textAlign: 'end' }}
                      />
                    </div>
                    <div className={classes.scheme_input}>
                      <TextInput
                        labeltitle="Approx. how much will LV be investing (investment amount via this Scheme)?"
                        name="commitment_via_lv"
                        type="number"
                        notes={
                          values.commitment_via_lv && !String(values.commitment_via_lv).includes('.')
                            ? `INR ${rupeeFormatter.format(values.commitment_via_lv)}/- ${
                                values.commitment_via_lv < 999999999 && values.commitment_via_lv > 0
                                  ? converter(values.commitment_via_lv).replace(/^./, (str) => str.toUpperCase())
                                  : ''
                              } only`
                            : ''
                        }
                        symbol="INR"
                        ismandatory
                        style={{ maxWidth: '25%', textAlign: 'end' }}
                      />
                    </div>
                    {/* <TextInput
                        labeltitle=
                        name="revenue_check"
                        type="text"
                        notes=""
                        ismandatory
                        style={{ width: '100%' }}
                      /> */}
                    <div className={classes.scheme_input}>
                      <TextInput
                        labeltitle="Price per share / security"
                        name="share_price_with_text"
                        type="number"
                        onChange={(e) => {
                          share_price_change(e, values, setFieldValue);
                        }}
                        notes={
                          values.share_price_with_text && !String(values.share_price_with_text).includes('.')
                            ? `INR ${rupeeFormatter.format(values.share_price_with_text)}/- ${
                                values.share_price_with_text < 999999999 && values.share_price_with_text > 0
                                  ? converter(values.share_price_with_text).replace(/^./, (str) => str.toUpperCase())
                                  : ''
                              } only`
                            : ''
                        }
                        value={
                          values.round_type === 'convertible_debt' ? 'Not Applicable' : values.share_price_with_text
                        }
                        disabled={values.round_type === 'convertible_debt'}
                        ismandatory
                        placeholder={values.round_type === 'convertible_debt' ? 'Not Applicable' : ''}
                        symbol="INR"
                        style={{ maxWidth: '20%', textAlign: 'end' }}
                      />
                    </div>
                    <div className={classes.scheme_input}>
                      <TextInput
                        labeltitle="Approx. # of Securities being Issued to LV investors in this scheme"
                        name="securities_description"
                        type="text"
                        notes={
                          values.securities_description
                            ? `${rupeeFormatter.format(values.securities_description)} (${
                                values.securities_description < 999999999 && values.securities_description > 0
                                  ? converter(values.securities_description).replace(/^./, (str) => str.toUpperCase())
                                  : ''
                              })`
                            : ''
                        }
                        ismandatory
                        placeholder={
                          !!values.share_price_with_text && values.share_price_with_text > 0
                            ? Math.floor(values.commitment_via_lv / values.share_price_with_text)
                            : values.securities_description
                        }
                        value={
                          values.round_type === 'convertible_debt' ? 'Not Applicable' : values.securities_description
                        }
                        disabled
                        style={{ maxWidth: '22.5%' }}
                      />
                    </div>
                    {values.round_type !== 'equity' && (
                      <div className={classes.scheme_input}>
                        <TextArea
                          labeltitle="Conversion Terms"
                          labelName="conversion_terms"
                          ismandatory
                          size="500"
                          style={{ width: '100%' }}
                        />
                      </div>
                    )}
                    {values.round_type === 'equity' && (
                      <div className={classes.scheme_input}>
                        <TextInput
                          labeltitle="Conversion Terms"
                          name="conversion_terms"
                          type="text"
                          notes=""
                          value="Not Applicable"
                          disabled
                          // ismandatory
                          style={{ width: '100%' }}
                        />
                      </div>
                    )}
                    {syndicateType != 'zeno' && (
                      <div className={classes.scheme_input}>
                        <TextInput
                          labeltitle="LV Platform Fee"
                          name="lv_transaction_fee"
                          type="number"
                          notes="2% + GST is LV’s standard Platform Fee. If you specify a different number, please mention why in the comments box at the bottom of this form."
                          symbol="% + GST"
                          ismandatory
                          style={{ maxWidth: '20%', textAlign: 'end' }}
                        />
                      </div>
                    )}
                    {/* <div className={classes.scheme_input}>
                      <TextInput
                        labeltitle="Founder Charge (%)"
                        name="founder_charge"
                        type="number"
                        notes=""
                        symbol="%"
                        ismandatory
                        style={{ maxWidth: '20%' }}
                      />
                    </div>{' '} */}
                    <div className={classes.scheme_input}>
                      <TextInput
                        labeltitle="LV Carry"
                        name="fund_carry"
                        type="number"
                        notes="2.5% is LV’s standard Carry. If you specify a different number, please mention why in the comments box at the bottom of this form"
                        symbol="%"
                        ismandatory
                        disabled={syndicateType == 'zeno'}
                        style={{ maxWidth: '20%', textAlign: 'end' }}
                      />
                    </div>{' '}
                    {/* <div className={classes.scheme_input}>
                      <TextInput
                        labeltitle="Lead Transaction fee (%)"
                        name="lead_transaction_fee"
                        placeholder="Enter Lead Transaction fee"
                        type="number"
                        notes=""
                        symbol="%"
                        ismandatory
                        style={{ maxWidth: '20%' }}
                      />
                    </div>{' '} */}
                    {/* <div className={classes.scheme_input}>
                      <Select
                        name="revenue_check"
                        labeltitle="The startup has NOT had Revenue in excess of INR 100Cr in any one year since its existence?"
                        placeholder="Select Revenue Type"
                        touched={touched}
                        options={revenueTypeOptions}
                        multi={false}
                        ismandatory
                        errorText="Required"
                        setDisableSaveDraftButton={setDisableSaveDraftButton}
                      />
                    </div> */}
                    {/* <div className={classes.scheme_input}>
                      <Select
                        name="investment_round"
                        labeltitle="What Investment Round is this?"
                        placeholder="Select Investment Round"
                        value={values.investment_round}
                        touched={touched}
                        options={roundTypeOptions}
                        multi={false}
                        ismandatory
                        errorText="Required"
                      />
                    </div> */}
                    {/* <TextInput
                        labeltitle="What Investment Round is this?"
                        name="investment_round"
                        type="text"
                        notes=""
                        ismandatory
                        style={{ width: '100%' }}
                      /> */}
                    <div className={classes.scheme_input}>
                      <TextInput
                        labeltitle="Total Lead Carry"
                        type="number"
                        step=".01"
                        name="total_lead_carry"
                        ismandatory
                        placeholder="Enter total lead carry"
                        symbol="%"
                        style={{ maxWidth: '20%', textAlign: 'end' }}
                      />
                    </div>
                    <div className="startup_input_box">
                      <LeadInvestorList
                        user="leadInvestor"
                        isMandatory={false}
                        carryError
                        labelTitle="Lead Investors"
                        labelName="lead_investor_list"
                        investorList={values.lead_investor_list}
                        removeInvestor={removeInvestor}
                        // addInvestors={addInvestors}
                        setFieldValue={setFieldValue}
                        saveLeadInvestor={saveLeadInvestor}
                        values={values}
                        innerText="Add Investor"
                        errors={errors}
                        removeIcon
                        isZeno={isZeno}
                        validateField={validateField}
                        textinfo="Only your syndicate’s backers / admins who are registered with LV’s AIF can be Leads and we take their entity name to display in the scheme document and not the profile name."
                      />
                    </div>
                    <div className={classes.scheme_input}>
                      <TextInput
                        labeltitle="Hurdle rate (Optional)"
                        name="hurdle_rate"
                        type="number"
                        notes=""
                        symbol="%"
                        disabled={syndicateType == 'zeno'}
                        // ismandatory
                        style={{ maxWidth: '20%', textAlign: 'end' }}
                      />
                    </div>
                    {/* <TextInput
                        labeltitle="What type of securities are being issued?"
                        name="round_type"
                        type="text"
                        notes=""
                        ismandatory
                        style={{ width: '100%' }}
                      /> */}
                    {/* <div className={classes.scheme_input}>
                      <div>
                        <div
                          style={{
                            fontSize: '20px',
                            fontWeight: '600',
                            color: '#000',
                          }}
                        >
                          Full Names of all Leads along with their split of Carry
                        </div>
                        {schemeDocData &&
                          schemeDocData.lead_investors.map((inv) => (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '15px 0',
                              }}
                            >
                              <div
                                style={{
                                  fontSize: '18px',
                                  fontWeight: '500',
                                  color: '#000',
                                }}
                              >
                                {inv.investor_name}
                              </div>
                              <div
                                style={{
                                  fontSize: '18px',
                                  fontWeight: '500',
                                  color: '#000',
                                  marginLeft: 30,
                                }}
                              >
                                {inv.carry} %
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>{' '} */}
                    <div className={classes.scheme_input} style={{ marginTop: 30, width: '50%' }}>
                      <TextInput
                        labeltitle="# of days to commit once the deal has been published"
                        name="commit_time_window"
                        ismandatory
                        placeholder="Enter value"
                        type="number"
                        symbol="days"
                        style={{ maxWidth: '20%', textAlign: 'end' }}
                      />
                    </div>
                    <div className={classes.scheme_input}>
                      <TextInput
                        labeltitle="Rights under Negotiation for this Scheme"
                        name="right_under_negotiations"
                        type="text"
                        style={{ width: '100%' }}
                      />
                    </div>{' '}
                    <div style={{ width: '40%', marginTop: 30 }}>
                      <TextInput
                        labeltitle="Last day for Investors to consent to the Scheme Doc"
                        name="last_date_to_consent"
                        // type="date"
                        notes=""
                        value={lastDayForCommit(values)}
                        ismandatory
                        style={{ maxWidth: '43%' }}
                        disabled
                      />
                    </div>{' '}
                    {/* <div className={classes.scheme_input}>
                      <TextInput
                        labeltitle="Scheme Name"
                        name="startup_scheme_name"
                        type="text"
                        ismandatory
                        style={{ width: '100%' }}
                        notes="Please Follow this Nomenclature - 'LV SyndicateName StartupName F I'"
                      />
                    </div>{' '} */}
                    <div className={classes.scheme_input} style={{ marginTop: 30 }}>
                      <Field
                        title="Upload the draft Agreement"
                        name="draft_agreement_file"
                        component={DragAndDrop}
                        labelTitle="Please upload the draft Agreement (SHA / CCD / CN / etc)"
                        Pitch_deck_error=""
                        pitch_deck_file_name={values && values.draft_agreement_file_name}
                        pitch_deck_file_size={values && values.pitch_deck_file_size}
                        remove_pitch_deck={values && values.remove_pitch_deck}
                        setFieldValue={setFieldValue}
                        ismandatory
                      />
                      <div className="edit_profile_notes" style={{ maxWidth: '100%' }}>
                        {' '}
                        Please upload the executed version of the document if available. If not, please upload a draft
                        copy of the agreement for us to verify the round terms.{' '}
                      </div>
                    </div>
                    <div className={classes.scheme_input} style={{ marginTop: 30 }}>
                      <label className="edit__profile_label-title" style={{ marginTop: '-3px', width: '90%' }}>
                        Do you want the investor to pay the investment amount immediately after commitment?{' '}
                        <span className="text-mandatory">*</span>
                      </label>
                      <FormControl style={{ marginTop: '17px' }}>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          defaultValue="Yes"
                          name="controlled-radio-buttons-group"
                          row
                          value={values.send_auto_drawdown}
                          onChange={(e) => {
                            setFieldValue('send_auto_drawdown', e.target.value);
                          }}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={
                              <Radio
                                sx={{
                                  color: 'gray',
                                  '&.Mui-checked': {
                                    color: '#343434'
                                  }
                                }}
                              />
                            }
                            label={
                              <Typography
                                sx={values.dpiit === 'Yes' ? { fontWeight: 'bold', fontSize: 13 } : { fontSize: 13 }}
                              >
                                Yes
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            value="No"
                            control={
                              <Radio
                                sx={{
                                  color: 'gray',
                                  '&.Mui-checked': {
                                    color: '#343434'
                                  }
                                }}
                              />
                            }
                            label={
                              <Typography
                                sx={values.dpiit === 'No' ? { fontWeight: 'bold', fontSize: 13 } : { fontSize: 13 }}
                              >
                                No
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                      <div className="edit_profile_notes" style={{ maxWidth: '100%' }}>
                        {' '}
                        If ‘No’ is selected, investor has to wait until you send a drawdown notice to transfer the
                        investment amount.{' '}
                      </div>
                      {errors && errors.send_auto_drawdown && (
                        <div className="text-mandatory mb-2">{errors.send_auto_drawdown}</div>
                      )}
                    </div>
                    <div className={classes.scheme_input} style={{ marginTop: 30 }}>
                      {/* <Field
                        title="Upload the DPIIT Certificate or an Affidavit stating that the company fulfils the criteria to qualify as startup"
                        name="dpiit_certificate"
                        component={DragAndDropDPIIT}
                        labelTitle="Please upload the DPIIT Certificate or an Affidavit"
                        Pitch_deck_error=""
                        pitch_deck_file_name={values && values.dpiit_file_name}
                        pitch_deck_file_size={values && values.dpiit__file_size}
                        remove_pitch_deck={values && values.remove_dpiit}
                        setFieldValue={setFieldValue}
                        ismandatory
                      />
                      <div
                        className="edit_profile_notes"
                        style={{ maxWidth: '100%' }}
                      >
                        {' '}
                        Please upload the DPIIT certificate if already received.
                        If not, please provide an affidavit stating that the
                        DPIIT certificate has been applied for.{' '}
                      </div> */}
                      <label className="edit__profile_label-title" style={{ marginTop: '-3px', width: '90%' }}>
                        Is the Startup been recognized by DPIIT <span className="text-mandatory">*</span>
                      </label>
                      <FormControl style={{ marginTop: '17px' }}>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          defaultValue="Yes"
                          name="controlled-radio-buttons-group"
                          row
                          value={values.dpiit}
                          onChange={(e) => {
                            setFieldValue('dpiit', e.target.value);
                          }}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={
                              <Radio
                                sx={{
                                  color: 'gray',
                                  '&.Mui-checked': {
                                    color: '#343434'
                                  }
                                }}
                              />
                            }
                            label={
                              <Typography
                                sx={values.dpiit === 'Yes' ? { fontWeight: 'bold', fontSize: 13 } : { fontSize: 13 }}
                              >
                                Yes
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            value="No"
                            control={
                              <Radio
                                sx={{
                                  color: 'gray',
                                  '&.Mui-checked': {
                                    color: '#343434'
                                  }
                                }}
                              />
                            }
                            label={
                              <Typography
                                sx={values.dpiit === 'No' ? { fontWeight: 'bold', fontSize: 13 } : { fontSize: 13 }}
                              >
                                No
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                      {errors && errors.dpiit && <div className="text-mandatory mb-2">{errors.dpiit}</div>}
                    </div>
                    <div className={classes.scheme_input}>
                      <div className="startup_input_box">
                        <TextArea
                          labeltitle="Comments/ notes for LV"
                          labelName="lv_team_comments"
                          // ismandatory
                          size="500"
                          style={{ width: '100%', textAlign: 'start' }}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ paddingTop: '3%' }}>
                    {!!Object.keys(errors).length && (
                      <div className="text-mandatory mb-2">
                        Please fill in all the mandatory fields to generate the scheme.
                      </div>
                    )}
                    <button
                      className="btn btn-outline-primary"
                      type="button"
                      style={{
                        padding: '10px 20px',
                        // marginLeft: '20px',
                        color: '#0099ff',
                        backgroundColor: '#fff'
                      }}
                      onClick={async () => {
                        const formValid = await validateForm();
                        const isFileUpload = validateFileUploads(values);
                        // if (Object.keys(formValid).length) window.scrollTo(0, 0);
                        onSubmit(values, !!Object.keys(formValid).length || !isFileUpload);
                      }}
                      disabled={disableSaveDraftButton}
                    >
                      <span style={{ fontWeight: 'bold' }}>
                        {savingScheme ? (
                          <div className="d-flex align-items-center">
                            Saving... <Oval height="25" width="25" radius="9" color="#0099ff" />
                          </div>
                        ) : (
                          'Save'
                        )}
                      </span>
                    </button>
                    {!incomplete && !!schemeReviewpPath && (
                      <>
                        {false && (
                          <a href={schemeReviewpPath} target="_blank" rel="noreferrer">
                            <button
                              type="button"
                              style={{
                                padding: '10px 20px',
                                marginLeft: '20px',
                                color: '#0099ff',
                                backgroundColor: '#fff'
                              }}
                              onClick={() => {
                                PostHogManager.trackEvent('Clicked View Document', {
                                  startup_id: props.startupId,
                                  scheme_path: props.schemeDoc,
                                  source: 'Deal tracker page before publish',
                                  description: 'Clicked on View Document'
                                });
                              }}
                              className="btn btn-outline-primary"
                            >
                              <span style={{ fontWeight: 'bold' }}>Preview</span>
                            </button>
                          </a>
                        )}
                        {false && (
                          <button
                            className="btn btn-outline-primary"
                            type="button"
                            // dataToggle="tooltip"
                            // dataPlacement="top"
                            title="Please 'Save the Scheme document' and then 'Submit for Approval'"
                            onClick={generateSchemeDoc}
                            disabled={!isScheme || generateScheme}
                            style={{
                              padding: '10px 25px',
                              marginLeft: '20px',
                              color: '#fff',
                              backgroundColor: '#0099ff',
                              cursor: 'pointer'
                            }}
                          >
                            <span style={{ fontWeight: 'bold' }}>
                              {generateScheme ? (
                                <div className="d-flex align-items-center">
                                  Submitting... <Oval height="25" width="25" radius="9" color="#0099ff" />
                                </div>
                              ) : (
                                'Submit for Approval'
                              )}
                            </span>
                          </button>
                        )}
                      </>
                    )}
                    <div style={{ opacity: '0.8', marginTop: '20px' }}>
                      Please &apos;Save the Scheme document&apos; and then &apos;Submit for Approval&apos;
                    </div>
                  </div>
                </div>
              </div>
              {/* <Modal show={openAddInvestorModal} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Add Lead Investor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <AsyncSelect
                    loadOptions={loadInvestorList}
                    placeholder="Enter the name of your investor"
                    noOptionsMessage={({ inputValue }) =>
                      !inputValue
                        ? 'Please Enter 1 or more character'
                        : 'No results found'
                    }
                    onChange={(investor) => {
                      setFieldValue('selected_lead_investor', investor);
                    }}
                  />
                  {values && values.selected_lead_investor && (
                    <>
                      <div className="d-flex align-items-center mt-3">
                        <div>
                          <img
                            src={values.selected_lead_investor.profile_image}
                            width="50"
                            height="50"
                            className="rounded-circle"
                          />
                        </div>
                        <div style={{ marginLeft: 20 }}>
                          {values.selected_lead_investor.label}
                        </div>
                      </div>
                      <div className="form-group mt-4">
                        <label htmlFor="leadCarry">Lead Carry Percentage</label>
                        <input
                          type="email"
                          className="form-control"
                          id="leadCarry"
                          aria-describedby="leadCarry"
                          placeholder="Enter lead carry percentage"
                          // onChange={(e) => {
                          //   setLeadCarry(e.target.value);
                          // }}
                        />
                        <small id="emailHelp" className="form-text text-muted">
                          Enter 5 for 5% Lead carry{' '}
                        </small>
                        <br />
                        <br />
                        <small id="emailHelp" className="form-text text-muted">
                          <span style={{ color: 'red' }}>
                            <b>
                              {' '}
                              Your Lead Carry + the lead carry of the lead
                              investor added by you should be less than or equal
                              to 20%
                            </b>
                          </span>{' '}
                        </small>
                      </div>
                    </>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => saveLeadInvestor(setFieldValue, values)}
                  >
                    Save
                  </Button>
                </Modal.Footer>
              </Modal> */}
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
}

export default SchemeDocumentModal;
